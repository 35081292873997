import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
} from "reactstrap";
import * as Yup from "yup";
import { Field, FieldProps, Formik } from "formik";
import apiGlobal from "../../global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "index";
import env from 'environment_system/env_system'
import ErrorTooltip from "Components/ErrorTooltip";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { loadSatelliteCommByVessel } from "VesselMaster/vesselMaster.hooks";
import Loading from "Components/Loading";
import { errResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import { queryClient } from "react-query/queryClient";
import { VesselConfigrationTabs } from "shared/constants";
import PhoneInput from "react-phone-input-2";
import { commonValidationMessages } from "Components/ValidationErrorMessages";

interface SatelliteCommunicationType {
    toggleTab?: (activeTab: number) => void,
    activeTab?: number | string,
    VesselConfActiveTab?: number
}

const SatelliteCommunication = ({
    toggleTab,
    activeTab,
    VesselConfActiveTab
}: SatelliteCommunicationType) => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [countryCode, setCountryCode] = useState<string>('')
    /** State varibles end */

    /** Queries */
    /** Sate communication object used for edit */
    const { data: satelliteComm, isLoading: satelliteCommLoading } = useQuery(
        [queryKeyes.vessel.SatelliteCommunicationByVessel.key, VesselID],
        async () => {
            return await loadSatelliteCommByVessel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign initial values to Satellite communication's formik object */
    const getInitialValues = () => {
        if (satelliteComm && satelliteComm[0]?.id > 0) {
            return satelliteComm[0];
        } else {
            return ({
                email: "",
                phone_bridge: "",
                phone_master: "",
                fax: "",
                immrst_c1: "",
                immrst_c2: "",
                mmmsi: "",
                vessel: VesselID,
            })
        }
    }

    /** Satellite communication's formik object */
    const SatelliteCommFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Must be a valid Email")
                .required("Please enter vessel's email id"),
            phone_bridge: Yup.string()
                .test('required', commonValidationMessages.required, (value) => {
                    return !(`+${countryCode} ` === value)
                }),
            // .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only")
            phone_master: Yup.string().nullable(),
            // .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only"),
            fax: Yup.string().nullable(),
            // .matches(/^[0-9]{1,14}$/, "Please enter upto 14 digits only"),
            immrst_c1: Yup.string().nullable(),
            // .matches(
            //     /^[4][0-9]{1,9}$/,
            //     "Please enter 9 upto digits only and the first digit should be 4"
            // ),
            immrst_c2: Yup.string().nullable(),
            // .matches(
            //     /^[4][0-9]{1,9}$/,
            //     "Please enter 9 upto digits only and the first digit should be 4"
            // ),
            mmmsi: Yup.string().nullable(),
            // .matches(/^[0-9]{1,9}$/, "Please enter upto 9 digits only")
        }),
    };

    /** useEffect */
    useEffect(() => {
        SatelliteCommFormik.initialValues = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteComm, VesselID])
    /** useEffect end */

    /** Satellite communication's submit function */
    const submitSatelliteComm = (
        values: { [key: string]: string | number | boolean | Date },
        actions: { setSubmitting: (arg: boolean) => void }
    ) => {
        if (satelliteComm && satelliteComm[0]?.id > 0) {
            apiGlobal.put(`/${queryKeyes.vessel.SatelliteCommunication.url()}${values?.id}/`, values)
                .then(() => {
                    successToast("Data saved successfully!");
                    if (env?.form_validation === true) {
                        toggleTab(activeTab as number + 1);
                    }
                    queryClient.invalidateQueries(queryKeyes.vessel.SatelliteCommunicationByVessel.key);
                    actions.setSubmitting(false);
                })
                .catch((err) => {
                    if (errResponse.includes(err?.response?.status)) {
                        errorToast("Internal error occured, please contact the admin");
                        actions.setSubmitting(false);
                    }
                });
        } else {
            apiGlobal.post(`/${queryKeyes.vessel.SatelliteCommunication.url()}`, values)
                .then(() => {
                    successToast("Data saved successfully!");
                    if (env?.form_validation === true) {
                        toggleTab(activeTab as number + 1);
                    }
                    queryClient.invalidateQueries(queryKeyes.vessel.SatelliteCommunicationByVessel.key);
                    actions.setSubmitting(false);
                })
                .catch((err) => {
                    if (errResponse.includes(err?.response?.status)) {
                        errorToast("Internal error occured, please contact the admin");
                        actions.setSubmitting(false);
                    }
                });
        }
    }

    return (
        <React.Fragment>
            {satelliteCommLoading && <Loading message="Loading required data!" />}
            {!satelliteCommLoading &&
                <Formik
                    onSubmit={async (
                        values: { [key: string]: string | number | boolean | Date },
                        actions: { setSubmitting: (arg: boolean) => void }
                    ) => {
                        actions.setSubmitting(true);
                        submitSatelliteComm(values, actions);
                    }}
                    initialValues={SatelliteCommFormik.initialValues}
                    validationSchema={SatelliteCommFormik.validationSchema}
                >
                    {props => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            if (VesselConfActiveTab === VesselConfigrationTabs.VESSEL_DATA && activeTab !== "2") {
                                props?.setTouched({})
                                props?.setErrors({})
                            }
                            if (VesselConfActiveTab !== VesselConfigrationTabs.VESSEL_DATA) {
                                props?.setTouched({})
                                props?.setErrors({})
                            }
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                        }, [activeTab, VesselConfActiveTab,]); return (
                            <Form noValidate autoComplete="off" onSubmit={props?.handleSubmit}>
                                <Row className="mb-2">
                                    <Col lg={4}>
                                        <Label className="asteric mb-0" htmlFor="email">Email id</Label>
                                        <Field name="email">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    name={field.name}
                                                    onChange={props?.handleChange}
                                                    onBlur={props?.handleBlur}
                                                    defaultValue={props?.values?.email as string}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.email && props?.errors.email &&
                                            <ErrorTooltip
                                                target="email"
                                                message={props?.errors.email}
                                                open={
                                                    props?.errors.email ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label htmlFor="phone_bridge" className="asteric mb-0">Phone bridge</Label>
                                        <Field name={`phone_bridge`}>
                                            {({ field, form }: FieldProps) => (
                                                <PhoneInput
                                                    {...field}
                                                    containerStyle={{ width: '100%' }}
                                                    inputStyle={{ width: '100%' }}
                                                    country={'in'}
                                                    value={props?.values?.phone_bridge as string}
                                                    onBlur={(e: any, country: any) => {
                                                        props?.handleBlur(e);
                                                        setCountryCode(country.dialCode);
                                                        const phoneNumber = e.target.value.substring(country.dialCode.length + 2);
                                                        form.setFieldValue(field.name, `+${country.dialCode} ${phoneNumber}`);
                                                        e.target.id = "phone_bridge";
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.phone_bridge &&
                                            props?.errors.phone_bridge &&
                                            <ErrorTooltip
                                                target="phone_bridge"
                                                message={props?.errors.phone_bridge}
                                                open={
                                                    props?.errors.phone_bridge ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label htmlFor="phone_master" className="mb-0">Phone master</Label>
                                        <Field name="phone_master">
                                            {({ field, form }: FieldProps) => (
                                                <PhoneInput
                                                    {...field}
                                                    containerStyle={{ width: '100%' }}
                                                    inputStyle={{ width: '100%' }}
                                                    country={'in'}
                                                    value={props?.values?.phone_master as string}
                                                    onBlur={(e: any, country: any) => {
                                                        props?.handleBlur(e);
                                                        const phoneNumber = e.target.value.substring(country.dialCode.length + 2);
                                                        form.setFieldValue(field.name, `+${country.dialCode} ${phoneNumber}`);
                                                        e.target.id = "phone_master";
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.phone_master &&
                                            props?.errors.phone_master &&
                                            <ErrorTooltip
                                                target="phone_master"
                                                message={props?.errors.phone_master}
                                                open={
                                                    props?.errors.phone_master ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={4}>
                                        <Label htmlFor="fax" className=" mb-0">Fax</Label>
                                        <Field name="fax">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="fax"
                                                    name={field.name}
                                                    onChange={props?.handleChange}
                                                    onBlur={props?.handleBlur}
                                                    defaultValue={props?.values?.fax as string}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.fax && props?.errors.fax &&
                                            <ErrorTooltip
                                                target="fax"
                                                message={props?.errors.fax}
                                                open={
                                                    props?.errors.fax ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label htmlFor="immrst_c1" className=" mb-0">Immrst.C1</Label>
                                        <Field name="immrst_c1">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="immrst_c1"
                                                    name={field.name}
                                                    onChange={props?.handleChange}
                                                    onBlur={props?.handleBlur}
                                                    defaultValue={props?.values?.immrst_c1 as string}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.immrst_c1 &&
                                            props?.errors.immrst_c1 &&
                                            <ErrorTooltip
                                                target="immrst_c1"
                                                message={props?.errors.immrst_c1}
                                                open={
                                                    props?.errors.immrst_c1 ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label htmlFor="immrst_c2" className="mb-0">Immrst.C2</Label>
                                        <Field name="immrst_c2">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="immrst_c2"
                                                    name={field.name}
                                                    onChange={props?.handleChange}
                                                    onBlur={props?.handleBlur}
                                                    defaultValue={props?.values?.immrst_c2 as string}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.immrst_c2 &&
                                            props?.errors.immrst_c2 &&
                                            <ErrorTooltip
                                                target="immrst_c2"
                                                message={props?.errors.immrst_c2}
                                                open={
                                                    props?.errors.immrst_c2 ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={4}>
                                        <Label htmlFor="mmmsi" className="mb-0">MMSI</Label>
                                        <Field name="mmmsi">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="mmmsi"
                                                    name={field.name}
                                                    onChange={props?.handleChange}
                                                    onBlur={props?.handleBlur}
                                                    defaultValue={props?.values?.mmmsi as string}
                                                />
                                            )}
                                        </Field>
                                        {env?.form_validation && props?.touched.mmmsi && props?.errors.mmmsi &&
                                            <ErrorTooltip
                                                target="mmmsi"
                                                message={props?.errors.mmmsi}
                                                open={
                                                    props?.errors.mmmsi ? true : false
                                                }
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size4_5_cstm"
                                            disabled={isConfigurationButtonDisabled(vessel)}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="danger"
                                            className="btn_size4_5_cstm"
                                            onClick={() => {
                                                props?.resetForm();
                                            }}
                                            disabled={isConfigurationButtonDisabled(vessel)}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </Row>
                                <FormValuesDebug values={[props?.values, props?.errors, props?.touched, SatelliteCommFormik.initialValues]} />
                            </Form>
                        )
                    }}
                </Formik>
            }
        </React.Fragment>
    );
};

export default SatelliteCommunication;
