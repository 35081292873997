import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import { loadCompletedReports } from 'VesselMaster/vesselMaster.hooks';
import { CardBody } from 'reactstrap';
import ToolTip from 'Components/ToolTip';
import ViewReport from '../Media/VD2.png'
import ViewReportModal from '../Components/ViewReportModal';
import VesselTableHeadComponent from './VesselTableHeadComponent';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';

const CompletedReports = () => {
    const { VoyageID, VesselID } = useSelector((state: RootState) => state.Reporting);
    const [viewReport, setViewReport] = useState(false);
    const [vesselId, setVesselId] = useState(0);
    const [reportId, setReportId] = useState(0);
    const [hoverId, setHoverId] = useState<string | null>(null)
    const [pageURL, setPageURL] = useState("");
    const [PopOverState, setPopOverState] = useState(false);

    /**
     * useEffect to set page url
     */
    useEffect(() => {
        setPageURL(queryKeyes.pagination.completeReportPage.url(VesselID, VoyageID));
    }, [VesselID, VoyageID]);
    /**
    * fucntion returns Vessel Voyage Reporting information 
    * @param number VesselID
    * @param number VoyageID
    * @returns mix
    */
    const { data: reports, isFetching: reportsFetching, isError: reportsError } = useQuery(
        [VesselID, VoyageID, pageURL, queryKeyes.pagination.completeReportPage.key],
        async () => { return await loadCompletedReports(VesselID, VoyageID, pageURL) },
        {
            enabled: true
        })

    const handleViewChanges = (id: number) => {
        setViewReport(true);
        setReportId(id);
    }

    function sendEmail(vesselId: number, reportId: number) {
        setPopOverState(false); // Ensure this executes regardless of success or failure
    
        return apiGlobal.post(queryKeyes.vessel.sendEmail.url(), {
            vessel_id: vesselId,
            report_id: reportId,
        })
        .then(res => {
            // Check if the response is successful
            if (res.status === 201 || res.status === 200) {
                if(res?.data?.state === true){
                const successMessage = res?.data?.message || "Email sent successfully!";
                successToast(successMessage); // Display success message from response if available
                }
            }
        })
        .catch(err => {
            // Extract the most useful error message
            const errorMessage = 
                err?.response?.data?.error ||  // Custom API error message
                err?.response?.data?.message ||
                err?.response?.statusText ||   // Default HTTP status text
                err?.message ||                // Generic error message from Axios
                "An unknown error occurred";   // Fallback message
            errorToast(errorMessage); // Show the error message in a toast
        });
    }

    return (
        <CardBody className='p-0'>
            <div className="table-responsive" id='owner_table'>
                <div className='ele_row mb-2'>
                    <h4 id='owner_table' className='mb-3'>Vessel Reporting Information</h4>
                </div>
                {reportsFetching && (
                    (<div
                        className="alert-border-left mb-0 alert alert-info alert-dismissible fade show"
                        role="alert">
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        <strong>Loading</strong> - Vessel reporting information</div>)
                )}
                {reportsError && (
                    <div className="alert-border-left alert alert-danger alert-dismissible fade show" role="alert">
                        <button type="button" className="btn-close" aria-label="Close"></button>
                        <i className="mdi mdi-block-helper me-3 align-middle"></i>
                        <strong>Error</strong> while fetching Vessel reporting information!!</div>
                )}
                {((!reportsFetching && !reportsError) && reports?.results?.length > 0) &&
                    <table className="table mb-0">
                        <VesselTableHeadComponent />
                        <tbody>
                            {reports?.results?.length > 0 &&
                                reports?.results?.map((report: any, index: number) => (
                                    <tr key={report.id}>
                                        <td className="p-2 align-middle text-center">{index + 1}</td>
                                        <td className="p-2 align-middle">{report.reporting_event_name}</td>
                                        <td className="p-2 align-middle text-center">{report.reporting_type}</td>
                                        <td className="p-2 align-middle text-center">{report.reporting_time_utc.slice(0, 10)} {report.reporting_time_utc.slice(11, 16)}</td>
                                        <td className="p-2 align-middle text-center">
                                            <img
                                                alt="View Report"
                                                className='align-middle pointer mr-2'
                                                src={ViewReport}
                                                height="29"
                                                id={`view_details_${report.id}`}
                                                onMouseEnter={() => setHoverId(`view_details_${report.id}`)}
                                                onMouseLeave={() => setHoverId(null)}
                                                onClick={() => {
                                                    handleViewChanges(report.id)
                                                    setHoverId(null);
                                                }}
                                            />
                                            <i
                                                className=' bx bx-mail-send icon_wave align-middle pointer'
                                                id={`send_email_${report.id}`}
                                                onMouseEnter={() => setHoverId(`send_email_${report.id}`)}
                                                onMouseLeave={() => setHoverId(null)}
                                                onClick={() => {
                                                    setHoverId(null);
                                                    setReportId(report.id);
                                                    setVesselId(report.vessel);
                                                    setPopOverState(true);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            {reports?.results?.length > 0 &&
                                <tr>
                                    <td colSpan={5} className="p-2">
                                        <PageNavButton setPageUrl={setPageURL} pageobj={reports} pageUrl={pageURL} />
                                    </td>
                                </tr>
                            }
                        </tfoot>
                    </table>
                }
                {hoverId !== null &&
                    <ToolTip
                        target={hoverId}
                        message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                        isOpen={hoverId !== null}
                    />
                }
                {PopOverState && reportId !== 0 &&
                    <PopOver
                    state={PopOverState}
                    setState={setPopOverState}
                    message={'Are you sure you want to send email ?'}
                    target={`send_email_${reportId}`}
                    handleClick={() => {
                        sendEmail(vesselId, reportId);
                    }}
                />
                }
            </div>
            <ViewReportModal
                state={viewReport}
                setState={setViewReport}
                vesselId={VesselID}
                reportId={reportId}
                modalId={`${VesselID}${reportId}`}
            />
        </CardBody>
    )
}

export default CompletedReports
