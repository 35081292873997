import React, { useEffect, useState } from 'react'
import { CardBody, Col, Row } from 'reactstrap'
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import { GetPageWithID } from '../pendingpage.hook';
import PageNavButton from '../PageNavButton';
import { FileStatus, VesselTypeConstant } from 'shared/constants';
import { checkInvalidPrimaryKey } from 'GenericForms/Helper';

const PendingLNGCargoDetails = () => {
    /** State variables */
    const { Vessels, VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [pageUrl, setPageUrl] = useState("");
    /** State variables end */

    useEffect(() => {
        if (!checkInvalidPrimaryKey(VesselID)) {
            return;
        }
        if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
            setPageUrl(queryKeyes.pagination.LNGCargoPendingFilesPage.url(VesselID, FileStatus.PENDING));
        }
    }, [VesselID, vessel?.vessel_type]);

    /** useQuery start */
    /** Load N2 corrections of selected vessel pending for user approval */
    const { data: N2AdjustmentObj, isLoading: N2AdjustmentLoading } = useQuery(
        [VesselID, pageUrl],
        async () => await GetPageWithID(VesselID, pageUrl), {
        enabled: true,
        staleTime: Infinity,
    }
    );
    /** useQuery end */
    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {N2AdjustmentLoading && <Loading message='Loading required data!' />}
                </Col>
            </Row>
            {!N2AdjustmentLoading &&
                <CardBody className='px-0 py-0 pb-0 mt-2'>
                    <div className="table-responsive ">
                        <table className="table mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th className='p-2 align-middle sr-no-width'>#</th>
                                    <th className='p-2 align-middle'>Duration</th>
                                    <th className='p-2 align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {N2AdjustmentObj?.results?.length > 0 ? (
                                    N2AdjustmentObj?.results?.map((n2: any, index: number) => (
                                        <tr key={index}>
                                            <td className="p-2 align-middle text-center">{index + 1}</td>
                                            <td className="p-2 align-middle">
                                                {n2?.end_report !== null ? (
                                                    <React.Fragment>
                                                        {n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                        {n2?.start_date_time?.toString().substring(11, 16)}&nbsp;to&nbsp;
                                                        {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                        {n2?.end_date_time?.toString().substring(11, 16)}
                                                    </React.Fragment>
                                                ) : (
                                                    <>Currently in progress</>
                                                )}
                                            </td>
                                            <td className="p-2 align-middle">
                                                <Link to="/n2_adjustment" color="primary" className="btn btn-primary">
                                                    Add Details
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="p-2 align-middle text-center">
                                            No files are pending for upload
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {N2AdjustmentObj?.results?.length > 0 && (
                                    <tr>
                                        <td colSpan={5} className="p-2 ">
                                            <PageNavButton setPageUrl={setPageUrl} pageobj={N2AdjustmentObj} pageUrl={pageUrl} />
                                        </td>
                                    </tr>
                                )}
                            </tfoot>
                        </table>
                    </div>
                </CardBody>
            }
        </React.Fragment>
    )
}

export default PendingLNGCargoDetails