import React, { useEffect, useState } from 'react';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import '../../../global/GlobalCSS.css';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { apiMedia } from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { checkInvalidPrimaryKey, dateTimeFormat, errResponse } from 'GenericForms/Helper';
import { errorToast, successToast } from 'Components/Toasts';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import { GetPageWithID } from '../pendingpage.hook';
import PageNavButton from '../PageNavButton';

const CIIPendingFiles = () => {
    /** State variables */
    const [modalState, setModalState] = useState(false);
    const [fileObject, setFileObject] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [CIIFile, setCIIFiles] = useState<any>([{}]);
    const [pageUrl, setPageUrl] = useState("");
    /** State variables end */

    /** Queries */
    /** CII adjustment event's files that are not yet uploaded  */
    useEffect(() => {
        if (!checkInvalidPrimaryKey(VesselID)) {
            return;
        }
        setPageUrl(queryKeyes.pagination.CIIPendingFilesPage.url(VesselID));
    }, [VesselID]);

    const { data: pendingCIIFileUploadingPage, isLoading, isError } = useQuery(
        [queryKeyes.pagination.CIIPendingFilesPage.key, pageUrl, VesselID],
        async () => await GetPageWithID(VesselID, pageUrl),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Queries end */
    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
     * set modal true to upload file
     * set current object to setFileObject
     * @param file 
     * @param obj 
     */
    const handleUploadButtonClick = (obj: any) => {
        setModalState(!modalState);
        setFileObject(obj);
    }


    /** to upload CII Files */
    const PendingFileUploadSubmit = async () => {
        if (fileObject && CIIFile && CIIFile.length > 0) {
            fileObject.vessel_reporting_cii_adjustment_supporting_file_name = CIIFile[0].file
            await apiMedia.put(`/vessel_reporting_cii_adjustment_supporting_file/${fileObject.id}/`, fileObject)
                .then(res => {
                    if (res.status === 200) {
                        setModalState(!modalState);
                        setCIIFiles([{}])
                        successToast("Success! Files uploaded!");
                        queryClient.invalidateQueries(queryKeyes.pagination.CIIPendingFilesPage.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {isLoading && <Loading message='Loading required data!' />}
                    {isError && 0 && <ErrorComponent message='Unable to load required data!' />}
                </Col>
            </Row>
            {!isLoading && !isError &&
                <React.Fragment>
                    <CardBody className='p-0 pb-0 mt-2'>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle' style={{ width: '22%' }}>Duration</th>
                                        <th className='p-2 align-middle'>CII Adjustment event</th>
                                        <th className='p-2 align-middle'>Document</th>
                                        <th className='p-2 align-middle text-center' style={{ width: '15%' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingCIIFileUploadingPage?.results?.length === 0 ?
                                        <tr>
                                            <td colSpan={5} className='align-middle text-center'>
                                                No pending files to upload.
                                            </td>
                                        </tr>
                                        : pendingCIIFileUploadingPage?.results?.events?.map((event: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='align-middle p-2 text-center'>{index + 1}</td>
                                                    <td className={`align-middle p-2`}>
                                                        {dateTimeFormat(event?.cii_begin_date_time)} <b>to</b> <br />{dateTimeFormat(event?.cii_end_date_time)}
                                                    </td>
                                                    <td className='align-middle p-2'>{event?.cii_adjustment_name}</td>
                                                    <td className='align-middle p-0' colSpan={2}>
                                                        <table className='table-bordered width-100'>
                                                            <tbody>
                                                                {event?.files?.map((field: any) =>
                                                                    <tr>
                                                                        <td className='align-middle p-2' style={{ width: '70%' }}>{field.cii_document_name}</td>
                                                                        <td className='align-middle p-2 text-center'>
                                                                            <Button
                                                                                type="button"
                                                                                className='btn-sm'
                                                                                color="primary"
                                                                                onClick={() => handleUploadButtonClick(field)}>
                                                                                Upload
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    {pendingCIIFileUploadingPage?.results?.events?.length > 0 &&
                                        <tr>
                                            <td colSpan={5} className="p-2 ">
                                                <PageNavButton setPageUrl={setPageUrl} pageobj={pendingCIIFileUploadingPage} pageUrl={pageUrl} />
                                            </td>
                                        </tr>
                                    }
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                    <Modal
                        size='md'
                        isOpen={modalState}
                        toggle={() => tog_backdrop()}
                        backdrop={"static"}
                        id="staticBackdrop"
                    >
                        <ModalHeader className='p-2'>Upload Pending CII Adjustment File
                            <button
                                onClick={async () => {
                                    setModalState(false);
                                    setCIIFiles([{}])
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </ModalHeader>
                        <ModalBody className='p-2'>
                            <ReportingFileUpload
                                setFile={setCIIFiles}
                                file={CIIFile}
                                index={0}
                                sm={5}
                            />
                            <div className="mt-1 mb-5">
                                <Button type="submit" color='primary' className='pos-end' onClick={() => {
                                    setModalState(false)
                                    PendingFileUploadSubmit()
                                }}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default CIIPendingFiles