import { Col, Row, Card, CardBody, Form, Input, CardFooter, Button } from "reactstrap";
import { fetchMachinaryOptions, loadVesselRunningHoursMachinery } from '../../../vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";
import { handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import ToolTip from "Components/ToolTip";
import React from "react";
import { queryClient } from "react-query/queryClient";

interface GeneralSettingsType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
}

const GeneralSettings = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
}: GeneralSettingsType) => {
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    /** Queries */
    /** Machinery on vessel */
    const { data: Machinary, isLoading: GeneralSettingLoading, isError: GeneralSettingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselMachineries.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                // staleTime: Infinity,
            }
        );

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: RunningHrsMachineries,
    } = useQuery(
        [queryKeyes.vessel.vesselRunningHoursMachinery.key, VesselID],
        async () => {
            return await loadVesselRunningHoursMachinery(VesselID);
        },
        { staleTime: Infinity }
    );

    /** Assign initial values to formik object */
    const getInitailValueGeneralSettings = () => {
        if (Machinary?.length > 0 && Machinary[0].id > 0) {
            return Machinary
        } else {
            return []
        }
    }

    /** General Settings Formik Object */
    const GeneralSettingsFormik = {
        initialValues: {
            generalSettings: getInitailValueGeneralSettings()
        },
        validationSchema: Yup.object().shape({
            generalSettings: Yup.array(Yup.object({
                running_hour: Yup.boolean(),
                running_counter: Yup.boolean(),
                energy_parameter: Yup.boolean(),
                sfoc_required: Yup.boolean()
            }))
        })
    }

    return (
        <Card className='border-0'>
            {(GeneralSettingLoading) && <Loading message='Loading required data!' />}
            {(GeneralSettingError) && <ErrorComponent message='Unable to load required data!' />}
            {!(GeneralSettingLoading) && !(GeneralSettingError) &&
                <Formik
                    onSubmit={async (values: any, actions: any) => {
                        actions.setSubmitting(false);
                        let responseArray: any[] = [];
                        if (Machinary?.length > 0 && Machinary[0].id > 0) {
                            values.generalSettings.forEach((machine: any) => {
                                responseArray.push(apiGlobal.put(`/vessel_machinery_list/${machine.id}/`, machine));
                            })
                            /** handle server response */
                            await handleServerResponse(responseArray).then(async (res) => {
                                if (res) {
                                    await queryClient.invalidateQueries(queryKeyes.vessel.EconFMCounterObject.key);
                                    await queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                                    await queryClient.invalidateQueries(queryKeyes.vessel.vesselRunningHoursMachinery.key);
                                    setRefreshVesselMachineries(refreshVesselMachineries + 1);
                                }
                            });
                        }
                    }}
                    initialValues={GeneralSettingsFormik.initialValues}
                    validationSchema={GeneralSettingsFormik.validationSchema}
                    key={refreshVesselMachineries}
                >
                    {props => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <CardBody className='p-0'>
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive p-0">
                                            <table className="table mb-2" key={refreshVesselMachineries}>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                        <th className='p-2 align-middle'>Vessel machinery name</th>
                                                        <th className='p-2 align-middle'>Machinery</th>
                                                        <th className='p-2 text-center align-middle' id="running_hour_msg">Runnning hours <i className='bx bx-info-circle ml-2p' /></th>
                                                        <th className='p-2 text-center align-middle' id="running_counter_msg">Runnning Counter <i className='bx bx-info-circle ml-2p' /></th>
                                                        <th className='p-2 text-center align-middle' id="energy_parameter_msg">Energy parameter <i className='bx bx-info-circle ml-2p' /></th>
                                                        <ToolTip target="energy_parameter_msg" message="Check the box if machinery is fitted with energy meter / Kilowatt-hour meter" />
                                                        <ToolTip target="running_hour_msg" message="Check the box if running hours need to be reported in the daily reporting" />
                                                        <ToolTip target="running_counter_msg" message="Check the fields if running counters need to be reported in daily reporting." />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray name='generalSettings'>
                                                        {() => (
                                                            <React.Fragment>
                                                                {props?.values?.generalSettings && props?.values?.generalSettings.length > 0 ? (
                                                                    props.values.generalSettings.map((generalSettings: any, index: number) => (
                                                                        <tr key={generalSettings.id}>
                                                                            <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                            <td className='p-2 align-middle'>{generalSettings.vessel_machinery_name}</td>
                                                                            <td className='p-2 align-middle'>{generalSettings.machinery}</td>
                                                                            <td className='p-2 align-middle text-center'>
                                                                                <Field name={`generalSettings.${index}.running_hour`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            id={`general-setting-${index}`}
                                                                                            name={field.name}
                                                                                            checked={field.value}
                                                                                            onChange={(e: any) => {
                                                                                                props?.handleChange(e);
                                                                                            }}
                                                                                            defaultValue={props.values?.generalSettings[index]?.running_hour}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </td>
                                                                            <td className='p-2 align-middle text-center'>
                                                                                <Field name={`generalSettings.${index}.running_counter`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            id={`general-setting-${index}`}
                                                                                            name={field.name}
                                                                                            checked={field.value}
                                                                                            onChange={(e: any) => {
                                                                                                props?.handleChange(e);
                                                                                            }}
                                                                                            defaultValue={props.values?.generalSettings[index]?.running_counter}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </td>
                                                                            <td className='p-2 align-middle text-center'>
                                                                                <Field name={`generalSettings.${index}.energy_parameter`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            id={`general-setting-${index}`}
                                                                                            name={field.name}
                                                                                            checked={field.value}
                                                                                            onChange={(e: any) => {
                                                                                                props?.handleChange(e);
                                                                                            }}
                                                                                            defaultValue={props.values?.generalSettings[index]?.energy_parameter}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={5} className="text-center">
                                                                            No General Settings data available. Please add general settings details.
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2'>
                                <Button type="submit" color='primary' className='justify_right' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                            </CardFooter>
                            <Row className='mt-2'>
                                <FormValuesDebug values={[props?.values, props?.errors, GeneralSettingsFormik.initialValues]} />
                            </Row>
                        </Form>
                    )}
                </Formik>
            }
        </Card >
    );
};
export default GeneralSettings;
