import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardFooter, Col, Form, Label, Row } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { LoadOnboardingSpeedAndConsumption, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import Select from 'react-select';
import { LoadConditionConstant, Roles, VesselConfigrationTabs } from 'shared/constants';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import * as Yup from "yup";
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';
import { errResponse, handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import DeletePopOver from 'Components/DeletePopOver';
import { setVesselState } from 'Store/Generic/ReportingSlice';
import { useLocation } from 'react-router-dom';
import { hasRole } from 'utils/auth/authUtils';
import PopUp from 'Components/PopUp';
import ConfigurationPendingFileStatus from './ConfigurationPendingFileStatus';


interface SpeedConsumptionType {
    VesselConfActiveTab: number,
}


const SpeedConsumptionTable = ({
    VesselConfActiveTab,
}: SpeedConsumptionType) => {
    /** State variables */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [selectedFuel, setSelectedFuel] = useState<number>(null);
    const [selectedFuelName, setSelectedFuelName] = useState<string>(null);
    const [deleteBool, setDeleteBool] = useState(false);
    const [deleteId, setDeleteId] = useState<number>(null);
    const [deleteIndex, setDeleteIndex] = useState<number>(null);
    const [deleteString, setDeleteString] = useState<string>(null);
    const [configStatusBool, setConfigStatusBool] = useState<boolean>(false);
    const dispatch = useDispatch();
    let location = useLocation();
    /** State variables end */

    /** useQueries */
    /** Load speed and consumption on vessel */
    const {
        data: SpeedAndConsumption,
        isLoading: SpeedAndConsumptionLoading
    }: { data: any[]; isLoading: any } = useQuery(
        [queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key, VesselID],
        async () => {
            return await LoadOnboardingSpeedAndConsumption(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Load distinct fuel types configured on vessel */
    const {
        data: VesselFuelTypes,
        isLoading: VesselFuelTypesLoading,
        isError: VesselFuelTypesError,
    } = useQuery(
        [queryKeyes.vessel.vesselFuelType.key, VesselID],
        async () => {
            return await loadVesselFuelTypes(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        if (SpeedAndConsumption && SpeedAndConsumption.length > 0) {
            setSelectedFuel(SpeedAndConsumption[0]?.vessel_fuel)
            setSelectedFuelName(SpeedAndConsumption[0]?.vessel_fuel_name)
            setRefreshKey(refreshKey + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpeedAndConsumption])

    /** Asign initial values to formik object */
    const getInitialValues = (condition: string) => {
        if (SpeedAndConsumption && SpeedAndConsumption.length > 0) {
            if (condition === 'laden') {
                const filteredItems = SpeedAndConsumption.filter((item: any) => item.load_condition === LoadConditionConstant.LADEN);
                if (filteredItems.length > 0) {
                    return filteredItems.map((item: any) => ({
                        ...item,
                        title: 'Laden Condition',
                    }));
                } else {
                    let arr: { [key: string]: string | number | boolean | null }[] = [];
                    arr.push({
                        speed: null,
                        fuel_consumption: null,
                        power: null,
                        vessel_fuel: selectedFuel ?? null,
                        load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                        title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                        vessel: VesselID,
                    })
                    return arr;
                }
            } else {
                const filteredItems = SpeedAndConsumption.filter((item: any) => item.load_condition === LoadConditionConstant.BALLAST);
                if (filteredItems.length > 0) {
                    return filteredItems.map((item: any) => ({
                        ...item,
                        title: 'Ballast Condition',
                    }));
                } else {
                    let arr: { [key: string]: string | number | boolean | null }[] = [];
                    arr.push({
                        speed: null,
                        fuel_consumption: null,
                        power: null,
                        vessel_fuel: selectedFuel ?? null,
                        load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                        title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                        vessel: VesselID,
                    })
                    return arr;
                }
            }
        } else {
            let arr: { [key: string]: string | number | boolean | null }[] = [];
            arr.push({
                speed: null,
                fuel_consumption: null,
                power: null,
                vessel_fuel: selectedFuel ?? null,
                load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                vessel: VesselID,
            })
            return arr;
        }
    }

    /** Function to delete vessel lube oil */
    const deleteSpeedAndConsumption = async (props: any) => {
        if (deleteId) {
            apiGlobal.delete(`${queryKeyes.vessel.OnboardingSpeedAndConsumption.url()}${deleteId}/`).then(async res => {
                if (res.status === 200 || res.status === 204) {
                    successToast("Data deleted successfully!");
                    props?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.splice(deleteIndex, 1);
                    queryClient.invalidateQueries(queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key);
                    setDeleteBool(false);
                    setDeleteIndex(null)
                    setDeleteId(null);
                    setDeleteString(null);
                }
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
        }
        else {
            props?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.splice(deleteIndex, 1);
            setDeleteBool(false);
            setDeleteIndex(null)
            setDeleteId(null);
            setDeleteString(null);
        }
    }

    /** formik object */
    const SpeedConsumptionFormik = {
        initialValues: {
            ladenSpeedConsumption: getInitialValues('laden'),
            ballastSpeedConsumption: getInitialValues('ballast'),
        },
        validationSchema: Yup.object().shape({
            ladenSpeedConsumption: Yup.array(
                Yup.object({
                    vessel_fuel: Yup.number().required(commonValidationMessages.required),
                })
            ),
            ballastSpeedConsumption: Yup.array(
                Yup.object({
                    vessel_fuel: Yup.number().required(commonValidationMessages.required),
                })
            ),
        })
    }

    useEffect(() => {
        SpeedConsumptionFormik.initialValues.ladenSpeedConsumption = getInitialValues('laden');
        SpeedConsumptionFormik.initialValues.ballastSpeedConsumption = getInitialValues('ballast');
        setSelectedFuel(SpeedAndConsumption && SpeedAndConsumption[0]?.vessel_fuel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpeedAndConsumption])
    return (
        <React.Fragment>
            {(VesselFuelTypesLoading || SpeedAndConsumptionLoading) && <Loading message='Loading required data!' />}
            {VesselFuelTypesError && <ErrorComponent message='Unable to load required data!' />}
            {!(VesselFuelTypesLoading || SpeedAndConsumptionLoading) && !VesselFuelTypesError &&
                <Formik
                    initialValues={SpeedConsumptionFormik.initialValues}
                    validationSchema={SpeedConsumptionFormik.validationSchema}
                    onSubmit={async (values, actions) => {
                        const responseArray: any[] = []
                        actions.setSubmitting(true);
                        // Set vessel_fuel for each item in ladenSpeedConsumption and ballastSpeedConsumption
                        Object.keys(values)?.forEach((speedConsumption: any) => {
                            values[speedConsumption as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.forEach((item: any) => {
                                item.vessel_fuel = selectedFuel;
                            });
                        });
                        // Function to handle PUT and POST requests
                        const handleSpeedConsumption = (speedConsumptionList: any[]) => {
                            speedConsumptionList.forEach((value) => {
                                const url = queryKeyes.vessel.OnboardingSpeedAndConsumption.url();
                                if (value &&
                                    value?.speed > "0" && value?.fuel_consumption > "0" && value?.power > "0") {
                                    const request = value.id
                                        ? apiGlobal.put(`${url}${value.id}/`, value)  // PUT if id exists
                                        : apiGlobal.post(url, value);  // POST if no id
                                    responseArray.push(request);
                                }
                            });
                        };
                        // Handle both ballastSpeedConsumption and ladenSpeedConsumption
                        handleSpeedConsumption(values.ballastSpeedConsumption);
                        handleSpeedConsumption(values.ladenSpeedConsumption);
                        // Wait for all requests to finish
                        await handleServerResponse(responseArray).then(async (res) => {
                            if (res) {
                                await queryClient.invalidateQueries(queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key);
                                setRefreshKey(refreshKey + 1);
                            }
                        })
                        if (location.pathname === '/onboarding') {
                            dispatch(setVesselState(null));
                        }
                        actions.setSubmitting(false);
                    }}
                    key={refreshKey}
                >
                    {(props: any) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            if (VesselConfActiveTab !== VesselConfigrationTabs.SPEED_CONSUMPTION) {
                                props?.setTouched({})
                            }
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                        }, [VesselConfActiveTab]);
                        return (
                            <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                                <Row className='mb-2'>
                                    <h5 className='mb-0'>Main Engine Consumption</h5>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <Label className="mb-0">Select fuel</Label>
                                        <Field name={`ladenSpeedConsumption.0.vessel_fuel`}>
                                            {({ field, form }: FieldProps) => (
                                                <Select
                                                    name={field.name}
                                                    inputId='vessel_fuel_id'
                                                    options={VesselFuelTypes}
                                                    className='mb-2 max-width-13'
                                                    getOptionLabel={(option: any) => option?.fuel_type_name}
                                                    getOptionValue={(option: any) => option.fuel_type}
                                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                                    onChange={(e: any) => {
                                                        form.setFieldValue(field.name, e?.fuel_type);
                                                        Object.keys(props?.values)?.forEach((speedConsumption: any) => {
                                                            props?.values[speedConsumption as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.forEach((item: any) => {
                                                                item.vessel_fuel = e?.fuel_type;
                                                            });
                                                        });
                                                        setSelectedFuel(e?.fuel_type);
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={customStyle}
                                                    defaultValue={SpeedAndConsumption && SpeedAndConsumption.length > 0 &&
                                                    {
                                                        fuel_type: selectedFuel,
                                                        fuel_type_name: selectedFuelName,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {
                                            props?.errors && props?.touched &&
                                            props?.touched.ladenSpeedConsumption &&
                                            props?.errors?.ladenSpeedConsumption &&
                                            props?.touched?.ladenSpeedConsumption[0] &&
                                            props.errors.ladenSpeedConsumption[0] &&
                                            env?.form_validation === true && (
                                                <ErrorTooltip
                                                    target="vessel_fuel_id"
                                                    message={props.errors.ladenSpeedConsumption[0].vessel_fuel}
                                                    open={!!props.errors.ladenSpeedConsumption[0]?.vessel_fuel}
                                                />
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    {Object.keys(props?.values)?.map((value: any, indx: number) => (
                                        <Col sm={6} key={indx}>
                                            <React.Fragment>
                                                <h6 className='mb-0'>{props?.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.[0]?.title}</h6>
                                                <div className="table-responsive mb-3">
                                                    <table className="table mb-0" key={refreshKey}>
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className='p-2 align-middle sr-no-width'>#</th>
                                                                <th className='p-2 align-middle text-center text-center'>Speed</th>
                                                                <th className='p-2 align-middle text-center'>Consumption</th>
                                                                <th className='p-2 align-middle text-center'>Power</th>
                                                                <th className='p-2 align-middle text-center' colSpan={2}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray name={value}>
                                                                {({ remove, push }) => (
                                                                    <React.Fragment>
                                                                        {props?.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.map((speedConsumption: string, index: number) => (
                                                                            <tr key={index}>
                                                                                <td className='p-2 text-center align-middle'>
                                                                                    <Label className='mb-0'>{index + 1}</Label>
                                                                                </td>
                                                                                <td className='p-2'>
                                                                                    <div className='input-group'>
                                                                                        <Field
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            id='speed'
                                                                                            name={`${value}.${index}.speed`}
                                                                                        />
                                                                                        <div className='input-group-text small-unit-size'>knots</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='p-2'>
                                                                                    <div className='input-group'>
                                                                                        <Field
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            id='fuel_consumption'
                                                                                            name={`${value}.${index}.fuel_consumption`}
                                                                                        />
                                                                                        <div className='input-group-text'>mt/day</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='p-2'>
                                                                                    <div className='input-group'>
                                                                                        <Field
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            id='power'
                                                                                            name={`${value}.${index}.power`}
                                                                                        />
                                                                                        <div className='input-group-text small-unit-size'>kW</div>
                                                                                    </div>
                                                                                </td>
                                                                                {props?.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.length === 1 ? null :
                                                                                    <td className='p-2 align-middle'>
                                                                                        {isConfigurationButtonDisabled(vessel) ?
                                                                                            <i className="dripicons-trash icon_s18 disabled-icon" /> :
                                                                                            <i className="dripicons-trash icon_s18"
                                                                                                id={`delete_icon_${value}_${index}`}
                                                                                                onClick={() => {
                                                                                                    setDeleteBool(true);
                                                                                                    setDeleteString(value);
                                                                                                    setDeleteIndex(index);
                                                                                                    setDeleteId(props.values[value][index].id)
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                }
                                                                                {index === (props?.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.length - 1) &&
                                                                                    index <= 10 &&
                                                                                    <td className='align-middle p-1'>
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn"
                                                                                            color='primary'
                                                                                            disabled={isConfigurationButtonDisabled(vessel)}
                                                                                            onClick={() => {
                                                                                                push({
                                                                                                    speed: null,
                                                                                                    fuel_consumption: null,
                                                                                                    power: null,
                                                                                                    load_condition: value === 'ladenSpeedConsumption' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                                                                                                    vessel_fuel: selectedFuel,
                                                                                                    vessel: VesselID,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <i className="dripicons-plus icon_s18 pointer" />
                                                                                        </Button>
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        ))}
                                                                        {deleteBool && deleteString &&
                                                                            <DeletePopOver
                                                                                target={`delete_icon_${deleteString}_${deleteIndex}`}
                                                                                state={deleteBool}
                                                                                setState={setDeleteBool}
                                                                                onClick={async () => {
                                                                                    setDeleteString(null)
                                                                                    setDeleteIndex(null)
                                                                                    remove(deleteIndex);
                                                                                    await deleteSpeedAndConsumption(props)
                                                                                }}
                                                                            />
                                                                        }
                                                                    </React.Fragment>
                                                                )}
                                                            </FieldArray>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </React.Fragment>
                                        </Col>
                                    ))
                                    }
                                </Row>
                                {deleteBool && deleteString &&
                                    <DeletePopOver
                                        target={`delete_icon_${deleteString}_${deleteIndex}`}
                                        state={deleteBool}
                                        setState={setDeleteBool}
                                        onClick={() => deleteSpeedAndConsumption(props)}
                                    />
                                }
                                <CardFooter className='p-2'>
                                    <Row className='mb-2'>
                                        <Col sm={11} className='text-end'>
                                            {hasRole(Roles.ES_ADMIN) &&
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn btn-primary"
                                                    onClick={() => setConfigStatusBool(true)}
                                                >Configuration Status</Button>
                                            }

                                        </Col>
                                        <Col className='text-end'>
                                            <Button
                                                type="submit"
                                                className="btn btn-primary"
                                                color="primary"
                                                disabled={isConfigurationButtonDisabled(vessel)}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                    <p className='mb-0'>- This table is necessary for generating alerts in vessel reporting, unless sufficient historical data is available.</p>
                                    <FormValuesDebug
                                        values={[
                                            props.values,
                                            props.errors,
                                            SpeedConsumptionFormik.initialValues,
                                        ]}
                                    />
                                </CardFooter>
                                {configStatusBool &&
                                    <PopUp
                                        title="Configuration Status List"
                                        state={configStatusBool}
                                        setState={setConfigStatusBool}
                                        body={
                                            <ConfigurationPendingFileStatus VesselID={VesselID} />
                                        }
                                        size="md"
                                    />
                                }
                            </Form>
                        )
                    }}
                </Formik >
            }
        </React.Fragment >
    )
}

export default SpeedConsumptionTable