import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { apiCall, apiMediaCall } from "../../utils/middlewares/ApiActions";
import { messages } from './Messages';
import { errorToast, infoToast, successToast } from "Components/Toasts";

export interface Generic {
    [key: string]: string | number | boolean | string[],
}
export type RecordState = null | 'VIEW' | 'DELETE' | 'EDIT' | 'NEW';
export type messageType = null | 'ERROR' | 'WARNING' | 'SUCCESS' | 'INFO';
export type messageDetailsObject = {
    message: string,
    messageType: messageType,
    state: boolean,
}
export interface GenericState {
    records: Generic[],
    loading: boolean,
    error: string | null,
    recordID: number,
    recordState: RecordState,
    messageDetails?: messageDetailsObject,
    flags: Generic[]
}

const initialState: GenericState = {
    records: [],
    loading: false,
    error: null,
    recordID: -1,
    recordState: null,
    messageDetails: {
        message: '',
        state: false,
        messageType: null,
    },
    flags: []
}

const genericSlice = createSlice({
    name: "generic",
    initialState,
    reducers: {
        apiRequested: (state) => {
            state.loading = true;
            infoToast(messages.loading);
        },
        apiRequestedFailed: (state) => {
            state.loading = false;
            errorToast(messages.loading);
        },
        setRecordState: (state, action) => {
            state.recordState = action.payload
        },
        setRecordId: (state, action) => {
            state.recordID = action.payload
        },
        getRecords: (state, action) => {
            state.records = action.payload;
            state.loading = false;
        },
        setData: (state, action) => {
            state.records.push(action.payload);
            state.loading = false;
            state.recordState = null;
            successToast(messages.success);
        },
        setFlags: (state, action) => {
            state.flags.push(action.payload);
            state.loading = false;
        },
        updateData: (state, action) => {
            const recordToUpdate = state.records.find(record => record.id === action.payload.id);
            if (recordToUpdate) {
                Object.assign(recordToUpdate, action.payload);
            }
            state.recordState = null;
            state.recordID = -1;
            successToast(messages.success);
        },
        deteteData: (state, action: PayloadAction<number>) => {
            let records = state.records.filter(
                (record) => record.id !== action.payload
            );
            records = state.records.filter(record => record.id !== state.recordID)
            state.records = records;
            state.recordID = -1;
            state.recordState = null;
            successToast(messages.success);
        },
    }
});


export const {
    apiRequested,
    apiRequestedFailed,
    setRecordState,
    setRecordId,
    getRecords,
    setData,
    updateData,
    deteteData,
    setFlags
} = genericSlice.actions;
export default genericSlice.reducer;

// Action functions for generic forms
export const getRecordsAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getRecords.type,
        onError: apiRequestedFailed.type,
    }
)

export const setDataAction = (url: string, data: Generic) => apiCall(
    {
        url: url + '/',
        method: "POST",
        data,
        onStart: apiRequested.type,
        onSuccess: setData.type,
        onError: apiRequestedFailed.type,
    }
)

export const setMediaDataAction = (url: string, data: Generic) => apiMediaCall(
    {
        url: url + '/',
        method: "POST",
        data,
        onStart: apiRequested.type,
        onSuccess: setData.type,
        onError: apiRequestedFailed.type,
    }
)

export const updateDataAction = (url: string, data: Generic) => apiCall(
    {
        url: `${url}/${data.id}/`,
        method: "PATCH",
        data,
        onStart: apiRequested.type,
        onSuccess: updateData.type,
        onError: apiRequestedFailed.type,
    }
)
export const deleteDataAction = (url: string, id: number | null) => apiCall(
    {
        url: `${url}/${id}/`,
        method: "DELETE",
        onStart: apiRequested.type,
        onSuccess: deteteData.type,
        onError: apiRequestedFailed.type,
    }
)