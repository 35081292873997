import React, { useState } from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import Dropzone, { Accept } from 'react-dropzone';
import { maxUploadSizeFile, FileType } from 'shared/constants';
import { MediaBaseURL } from 'global/api.global';
import { CSSModule } from 'reactstrap/types/lib/utils';
import FilePreviewComponent from './FilePreviewComponent';

interface parameter {
    [key: string]: string | Object;
}
interface ReportingFileUploadInterface {
    setFile: (value: parameter[]) => void;
    file: any[];
    deleteURL?: string;
    invalidateQuery?: string;
    deleteID?: number;
    fileUploadStatus?: boolean;
    refreshKey?: number;
    fileURL?: string;
    setRefreshKey?: any;
    title?: string;
    DeleteFileObj?: any;
    isFileUploadDisable?: boolean;
    sm?: number;
    index?: number;
    maxFiles?: number;
    maxFileLength?: number;
    CustomAcceptableFileTypes?: any;
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}
/**
 * Props:
 * - `setFile`: Function to update the list of files.
 * - `file`: Array of currently selected or uploaded files.
 * - `deleteURL`: URL endpoint for deleting uploaded files.
 * - `invalidateQuery`: Query key to invalidate after file deletion.
 * - `deleteID`: ID of the file to delete.
 * - `fileUploadStatus`: Boolean indicating if a file is already uploaded.
 * - `refreshKey`: Unique key to force component re-rendering.
 * - `fileURL` (optional): URL of the previously uploaded file.
 * - `setRefreshKey`: Function to update the refresh key.
 * - `title` (optional): Label/title for the file upload section.
 * - `DeleteFileObj`: Object defining the file deletion behavior. { image_file: null, image_path: null,}
 * - `isFileUploadDisable` (optional): Boolean to disable file uploads, default is `true`.
 * - `sm` (optional): Column size for responsiveness, default is `2`.
 * - `index` (optional): Index of the current file, used for managing files in a list.
 * - `innerRef` (optional): React ref for the component's root element.
 * - `cssModule` (optional): Custom CSS module for styling.
 * - `isMultiple` (optional): Boolean to allow multiple file uploads, default is `false`.
 *
 * @param {ReportingFileUploadInterface} props - The props for the component.
 * @returns {JSX.Element} The rendered ReportingFileUpload component.
 */
const ReportingFileUpload = ({
    setFile,
    file,
    deleteURL,
    invalidateQuery,
    deleteID,
    fileUploadStatus,
    refreshKey,
    title,
    DeleteFileObj,
    fileURL,
    setRefreshKey,
    index,
    isFileUploadDisable = false,
    sm = 2,
    isMultiple = false,
    maxFiles = 1,
    CustomAcceptableFileTypes,
}: ReportingFileUploadInterface) => {
    const [fileErrorMsg, setFileErrorMsg] = useState<string>();
    const [fileRemoveKey, setFileRemoveKey] = useState<number>(0);
    const [fileLenght, setFileLength] = useState<number>(0);
    const maxLimitMsg = 'You’ve reached the maximum file limit.'

    /**
     * vaildates file and add to file uploaded file list 
     * handle multiple or single files
     * @param acceptedFiles 
     */
    const handleAcceptedFiles = (acceptedFiles: any[]) => {
        if (isMultiple) {
            const validatedFile = validateFile(acceptedFiles);
            if (validatedFile.errorMsg) {
                setFileErrorMsg(validatedFile.errorMsg);
            }
            if (validatedFile.validFiles.length > 0) {
                const updatedFiles = validatedFile.validFiles.map((uploadedFile: any) => ({
                    name: uploadedFile.name,
                    preview: URL.createObjectURL(uploadedFile),
                    file: uploadedFile,
                }));
                setFile([...file, ...updatedFiles]);
            }
        } else {
            const validatedFile = validateFile(acceptedFiles[0]);
            if (validatedFile.errorMsg) {
                setFileErrorMsg(validatedFile.errorMsg);
                removeIndexFile(index)
            }
            else {
                const uploadedFile = acceptedFiles[0];
                let updateFile = [...file]
                updateFile[index] = {
                    name: uploadedFile.name,
                    preview: URL.createObjectURL(uploadedFile),
                    file: uploadedFile,
                };
                setFile(updateFile);
                setFileErrorMsg(null)
                setFileRemoveKey(fileRemoveKey + 1)
            }
        }
    }

    /**
    * vailidate file size and maximum number of files and be upload
    * handle multiple or single files
    * @param file 
    * @returns {errorMsg, validFiles }
    */
    const validateFile = (acceptedFiles: any) => {
        let errorMsg: string | null = null;
        let validFiles: any[] = [];
        if (isMultiple) {
            const fileLength = file?.filter(image => image.file).length;
            const AcceptedFileLength = acceptedFiles.length;
            setFileLength(fileLength + AcceptedFileLength)
            setFileErrorMsg(null);
            if (fileLenght > maxFiles) {
                errorMsg = `You can upload a maximum of ${maxFiles} files.`
                if (errorMsg) {
                    setFileErrorMsg(errorMsg)
                }
            }
            if (!errorMsg) {
                /** Filter out the files that exceed the max size and create an error message for each  */
                validFiles = acceptedFiles.filter((file: any) => {
                    if (file.size > maxUploadSizeFile) {
                        errorMsg = `File '${file.name}' should not be greater than ${maxUploadSizeFile / (1024 * 1024)} MB`;
                        return false; // Exclude the file from the list if it exceeds the size
                    }
                    return true; // Keep the file in the list if it's valid
                });
                if (errorMsg) {
                    setFileErrorMsg(errorMsg)
                }
            }
            return {
                errorMsg: errorMsg,
                validFiles: validFiles
            };
        } else {
            if (acceptedFiles.size > maxUploadSizeFile) {
                errorMsg = `File should not be greater than ${maxUploadSizeFile / (1024 * 1024)} MB`;
            }
            return {
                errorMsg: errorMsg,
                validFiles: acceptedFiles
            }
        }
    };

    /**
     * removes rejected file 
     */
    const handleRejectedFiles = (rejectedFiles: any) => {
        /** Check if any files were rejected due to file type issues */
        const fileTypeError = rejectedFiles.some((file: any) =>
            file.errors.some((error: any) => error.code === 'file-invalid-type')
        );
        /** If file type error exists */
        if (fileTypeError) {
            /** Generate the allowed file types message  */
            const allowedFileTypes = Object.keys(acceptedFileTypes)
                .map((key) => `${acceptedFileTypes[key].join(', ')}`)
                .join(', ');
            /** Update error message dynamically  */
            setFileErrorMsg(`Only ${allowedFileTypes} files are allowed`);
        }
        /** Remove files after rejection */
        removeIndexFile(index);
        setFileRemoveKey(fileRemoveKey + 1);
    };

    /** Remove a file from the list */
    const removeIndexFile = (index: number) => {
        let updatedFiles = [...file];
        updatedFiles[index] = {}
        setFile(updatedFiles);
    };

    /**
     * Acceptable file Types
     * also handles file type dynamically
     */
    const acceptedFileTypes: Accept =
        CustomAcceptableFileTypes ?
            CustomAcceptableFileTypes : {
                'application/pdf': ['.pdf'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
            };

    return (
        <Row>
            <Col sm={sm ?? 2}>
                <Label className="mb-0">{title && title}</Label>
                <Dropzone
                    onDropAccepted={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                    onDropRejected={handleRejectedFiles}
                    multiple={isMultiple}
                    accept={acceptedFileTypes}
                    disabled={isFileUploadDisable}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="needsclick">
                            <input {...getInputProps()} />
                            <Button
                                type="button"
                                color="primary"
                                className={`${isFileUploadDisable && `disabled`} btn waves-effect btn-label waves-light`}
                            >
                                <i className="mdi mdi-upload label-icon" />
                                {fileUploadStatus ? 'Replace Files' : 'Upload Files'}
                            </Button>
                            <p>{fileErrorMsg && <p className="error">{fileErrorMsg}</p>}</p>
                            <p>{fileLenght === maxFiles && maxLimitMsg}</p>
                        </div>
                    )}
                </Dropzone>
            </Col>
            {/* Display file previews for each uploaded file */}
            <React.Fragment key={`selected-${fileRemoveKey}`}>
                {isMultiple ? (
                    file.length > 0 && file.map((fileItem, idx) => (
                        fileItem.preview && (
                            <FilePreviewComponent
                                key={idx} // Ensure unique key for each file preview
                                fileName={fileItem.file?.name}
                                url={fileItem.preview}
                                title={'Selected File'}
                                setFile={setFile}
                                size={fileItem.file?.size}
                                file={file}
                                fileType={FileType.SELECTED}
                                index={idx}
                                sm={sm ?? 2}
                                fileRemoveKey={fileRemoveKey}
                                setFileRemoveKey={setFileRemoveKey}
                            />
                        )
                    ))
                ) : (
                    !!file[index]?.preview !== false && (
                        <FilePreviewComponent
                            key={index} // Use the `index` as a key in this case
                            fileName={file[index].name}
                            url={file[index].preview}
                            title={'Selected File'}
                            setFile={setFile}
                            size={file[index].file?.size}
                            file={file}
                            fileType={FileType.SELECTED}
                            index={index}
                            sm={sm ?? 2}
                            fileRemoveKey={fileRemoveKey}
                            setFileRemoveKey={setFileRemoveKey}
                        />
                    )
                )}
            </React.Fragment>
            {/* See preview of uploaded file */}
            <React.Fragment key={`uploaded-${fileRemoveKey}`}>
                {fileUploadStatus && (
                    <FilePreviewComponent
                        key={refreshKey}
                        fileName={fileURL?.split('/')?.pop()}
                        url={`${MediaBaseURL}/${fileURL}`}
                        title={'Uploaded File'}
                        invalidateQuery={invalidateQuery}
                        deleteURL={deleteURL}
                        deleteID={deleteID}
                        DeleteFileObj={DeleteFileObj}
                        refreshKey={refreshKey}
                        setRefreshKey={setRefreshKey}
                        fileType={FileType.UPLOADED}
                        index={index}
                        sm={sm ?? 2}
                        fileRemoveKey={fileRemoveKey}
                        setFileRemoveKey={setFileRemoveKey}
                    />
                )}
            </React.Fragment>
        </Row>
    );
};

export default ReportingFileUpload;
