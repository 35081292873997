import React, { useState } from "react";
import {
  CardBody,
  Card,
  Col,
  Row,
  Label,
  Button,
  Form,
  Modal,
} from "reactstrap";
import apiGlobal from "../../../global/api.global";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import {
  loadMachineryOptions,
  loadVesselMachineries,
} from "../../../VesselMaster/vesselMaster.hooks";
import { errorToast, successToast } from "../../../Components/Toasts";
import { errResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { queryClient } from "react-query/queryClient";
import ToolTip from "Components/ToolTip";
import DeletePopOver from "Components/DeletePopOver";
import { customStyle } from "shared/CommonCSS";

interface MachineryMasterType {
  refreshVesselMachineries: number;
  setRefreshVesselMachineries: (value: number) => void;
  setRefreshInnerTables: (value: boolean) => void;
}

const MachineryMaster = ({
  refreshVesselMachineries,
  setRefreshVesselMachineries,
  setRefreshInnerTables,
}: MachineryMasterType) => {
  /** State variables */
  const [machinery, setMachinery] = useState(0);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
  const [hoverId, setHoverId] = useState<string | null>(null);
  const [popOverBool, setPopOverBool] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [popOverAddNewBool, setPopOverAddNewBool] = useState<boolean>(false);
  const [addNewdeleteIndex, setAddNewDeleteIndex] = useState<number | null>(null);
  /** State variables end */

  /** Open close modal */
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  /** Queries */
  /** Load Vessel Machinary */
  const {
    data: vesselMachineries,
    isLoading: vesselMachineriesLoading,
    isError: vesselMachineriesError,
  } = useQuery(
    [queryKeyes.vessel.VesselMachineries.key, VesselID],
    async () => {
      return await loadVesselMachineries(VesselID);
    },
    { staleTime: Infinity }
  );
  /** Machinery master */
  const { data: Machinery, isLoading: MachineryLoading, isError: MachineryError }:
    { data: any[], isLoading: any, isError: any } = useQuery(
      [queryKeyes.masters.MachineryMaster.key],
      async () => {
        return await loadMachineryOptions();
      },
      { staleTime: Infinity }
    );
  /** Queries end */

  /** Machinery Master's formik object */
  const MachineryMasterFormik = {
    initialValues: {
      machines: [
        {
          vessel_machinery_name: "",
          machinery_name: "",
          vessel: VesselID,
        },
      ],
    },
    validationSchema: Yup.object().shape({
      machines: Yup.array(
        Yup.object({
          vessel_machinery_name: Yup.string()
            .matches(
              /^[a-zA-Z0-9/\s]{1,50}$/,
              "Please enter upto 50 alphabets only"
            )
            .required(commonValidationMessages.required),
        })
      ),
    }),
  };

  const deleteVesselMachinery = (id: number) => {
    apiGlobal
      .delete(`/vessel_machinery_list/${id}/`)
      .then(async (res) => {
        if (res.status === 200 || res.status === 204) {
          successToast("Data deleted successfully!");
          await queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
          await queryClient.invalidateQueries(queryKeyes.vessel.DistinctMachinary.key);
          setRefreshVesselMachineries(refreshVesselMachineries + 1);
        }
      })
      .catch((err) => {
        if (errResponse.includes(err.response.status)) {
          errorToast("Internal error occured, please contact the admin");
        }
      });
  };


  return (
    <React.Fragment>
      {(vesselMachineriesLoading || MachineryLoading) && <Loading message="Loading required data!" />}
      {MachineryError && <ErrorComponent message="Error loading required data!" />}
      {!(vesselMachineriesLoading || MachineryLoading) &&
        !MachineryError &&
        <React.Fragment>
          <div className="ele_row">
            <h4 className="mb-3 navbar_menu">Vessel Machinery List</h4>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light mb-3"
              data-toggle="modal"
              data-target=".bs-example-modal-xl"
              onClick={() => {
                tog_xlarge();
              }}
              disabled={isConfigurationButtonDisabled(vessel)}
            >
              <i className="dripicons-plus font-size-16 align-middle me-2" id="new_machinery"></i>
              New Machinery
            </button>
            <ToolTip target="new_machinery" message="Click to add machines which you intend to track the parameters." />
            <Modal
              size="lg"
              isOpen={modal_xlarge}
              toggle={() => {
                tog_xlarge();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Add New
                </h5>
                <button
                  onClick={() => {
                    setmodal_xlarge(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <CardBody>
                    {vesselMachineriesLoading && (
                      <Loading message="Loading required data!" />
                    )}
                    {vesselMachineriesError && (
                      <ErrorComponent message="Unable to load required data!" />
                    )}
                    {!vesselMachineriesLoading && !vesselMachineriesError && (
                      <Formik
                        initialValues={MachineryMasterFormik.initialValues}
                        validationSchema={MachineryMasterFormik.validationSchema}
                        onSubmit={(values, actions) => {
                          actions.setSubmitting(true);
                          /** Post submit actions based on response */
                          const handleResponse = (response: any) => {
                            if (
                              response.status === 201 ||
                              response.status === 200
                            ) {
                              successToast("Data saved successfully!");
                              queryClient.invalidateQueries(
                                queryKeyes.vessel.VesselMachineries.key
                              );
                              queryClient.invalidateQueries(queryKeyes.vessel.DistinctMachinary.key);
                              setRefreshVesselMachineries(refreshVesselMachineries + 1);
                              setmodal_xlarge(false);
                            }
                          };
                          values?.machines?.map((machine: any) => {
                            machine.machinery_name = machinery;
                            return machine;
                          });
                          apiGlobal
                            .post(`/vessel_machinery_list/`, values?.machines)
                            .then((res) => {
                              handleResponse(res);
                              actions.setSubmitting(false);
                            })
                            .catch((err) => {
                              if (errResponse.includes(err.response.status)) {
                                errorToast(
                                  "Internal error occured, please contact the admin"
                                );
                              }
                              actions.setSubmitting(false);
                            });
                        }}
                      >
                        {(props) => (
                          <Form
                            onSubmit={props.handleSubmit}
                            autoComplete="false"
                            noValidate
                          >
                            <Row className="mb-3">
                              <Col lg={4}>
                                <Label id="select_machinery_id" className="asteric mb-0">
                                  Select machinery
                                  <i className='bx bx-info-circle ml-2p' />
                                </Label>
                                <ToolTip target="select_machinery_id" message="Select machinery type from the list" />
                                <Field name={`machines.${0}.machinery_name`}>
                                  {({ form }: FieldProps) => (
                                    <Select
                                      name="machinery_name"
                                      options={Machinery}
                                      getOptionLabel={(e: any) =>
                                        e.machinery_name
                                      }
                                      getOptionValue={(e: any) => e.id}
                                      value={
                                        Machinery.find(
                                          (option: any) => option.id === form.values.machines[0].machinery_name
                                        ) || null
                                      }
                                      onChange={(e: any) => {
                                        setMachinery(e?.id);
                                        props?.values.machines.forEach(
                                          (machine: any, index: number) => {
                                            form.setFieldValue(
                                              `machines.${index}.machinery_name`,
                                              e?.id
                                            );
                                          }
                                        );
                                      }}
                                      styles={customStyle}
                                      className="select-height"
                                      menuPortalTarget={document.querySelector(
                                        ".MuiDialog-root"
                                      )}
                                    />
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <FieldArray name="machines">
                              {({ push, remove }) => (
                                <React.Fragment>
                                  {props?.values &&
                                    props?.values?.machines?.map(
                                      (value: any, index: number) => {
                                        return (
                                          <div key={index}>
                                            <Row className="mb-3">
                                              <Col lg={4}>
                                                <Label id="vessel_machinery_name_id_of" className="asteric mb-0">
                                                  Vessel machinery name
                                                  <i className='bx bx-info-circle ml-2p' />
                                                </Label>
                                                <ToolTip target="vessel_machinery_name_id_of" message="Enter the name of a machine." />
                                                <Field
                                                  type="text"
                                                  id={`machines.${index}.vessel_machinery_name`}
                                                  name={`machines.${index}.vessel_machinery_name`}
                                                  className="form-control"
                                                />
                                              </Col>
                                              <Col lg={1}>
                                                <div className="ele_row1">
                                                  {props?.values?.machines
                                                    ?.length === 1 ? null : (
                                                    <button
                                                      type="button"
                                                      className="btn mt-3"
                                                    >
                                                      <i
                                                        id={`delete_new_machinery_${index}`}
                                                        className="dripicons-trash icon_s18"
                                                        onClick={() => {
                                                          setPopOverAddNewBool(true)
                                                          setAddNewDeleteIndex(index)
                                                        }
                                                        }
                                                      />
                                                    </button>
                                                  )}
                                                  {index ===
                                                    props?.values?.machines
                                                      .length -
                                                    1 && (
                                                      <Button
                                                        type="button"
                                                        className="btn ms-1 mt-3"
                                                        color="primary"
                                                        onClick={() =>
                                                          push({
                                                            vessel_machinery_name:
                                                              "",
                                                            machinery_name: "",
                                                            vessel: VesselID,
                                                          })
                                                        }
                                                      >
                                                        <i
                                                          className="dripicons-plus icon_s18 navbar_menu"
                                                        />
                                                      </Button>
                                                    )}
                                                </div>
                                              </Col>
                                            </Row>
                                            {popOverAddNewBool && addNewdeleteIndex === index && props?.values?.machines?.length > 1 &&
                                              <DeletePopOver
                                                target={`delete_new_machinery_${index}`}
                                                state={popOverAddNewBool}
                                                setState={setPopOverAddNewBool}
                                                onClick={async () => {
                                                  remove(index)
                                                  setPopOverAddNewBool(false)
                                                  setRefreshInnerTables(true)
                                                  setRefreshVesselMachineries(refreshVesselMachineries + 1)
                                                }}
                                                /> 
                                              }
                                          </div>

                                        );
                                      }
                                    )}
                                </React.Fragment>
                              )}
                            </FieldArray>
                            <Row>
                              <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="btn_size4_5_cstm"
                                  disabled={props.isSubmitting}
                                >
                                  {props.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  color="danger"
                                  className="btn_size4_5_cstm"
                                  onClick={() => { props.resetForm() }}
                                >
                                  Reset
                                </Button>
                              </div>
                            </Row>
                            <FormValuesDebug
                              values={[
                                props?.values,
                                props?.errors,
                                MachineryMasterFormik.initialValues,
                              ]}
                            />
                          </Form>
                        )}
                      </Formik>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </div>
          <div className="table-responsive mb-3">
            <table className="table" key={refreshVesselMachineries}>
              <thead className="table-light">
                <tr>
                  <th className="p-2 sr-no-width align-middle">#</th>
                  <th className="p-2 align-middle">Vessel machinery name</th>
                  <th className="p-2 align-middle">Machinery</th>
                  <th className="p-2 align-middle" id="machinery_actions_msg">Actions<i className='bx bx-info-circle ml-2p' /></th>
                </tr>
              </thead>
              <tbody>
                {vesselMachineries && vesselMachineries.length > 0 ? (
                  <React.Fragment>
                    {vesselMachineries
                      .sort((a: any, b: any) => {
                        const nameA = a.machinery_name ? String(a.machinery_name) : '';
                        const nameB = b.machinery_name ? String(b.machinery_name) : '';
                        return nameA.localeCompare(nameB);
                      })
                      .map((vesselMachinery: any, index: number) => (
                        <tr key={vesselMachinery.id}>
                          <td className="p-2 align-middle text-center">{index + 1}</td>
                          <td className="p-2">{vesselMachinery.vessel_machinery_name}</td>
                          <td className="p-2">{vesselMachinery.machinery}</td>
                          <td className="p-2">
                            {isConfigurationButtonDisabled(vessel) ? (
                              <i className="dripicons-trash icon_s18 disabled-icon" />
                            ) : (
                              <i
                                id={`Delete_Vessel_Machinery_${index}`}
                                className="dripicons-trash icon_s18"
                                onMouseEnter={() =>
                                  setHoverId(`Delete_Vessel_Machinery_${index}`)
                                }
                                onMouseLeave={() => setHoverId(null)}
                                onClick={() => {
                                  setPopOverBool(true);
                                  setDeleteId(vesselMachinery.id);
                                  setOpenPopoverIndex(index);
                                  setHoverId(null);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    {hoverId !== null && (
                      <ToolTip
                        target={hoverId}
                        message="Delete the wrong entry."
                        isOpen={hoverId !== null}
                      />
                    )}
                    {popOverBool && openPopoverIndex !== null && deleteId !== null && (
                      <DeletePopOver
                        target={`Delete_Vessel_Machinery_${openPopoverIndex}`}
                        state={popOverBool}
                        setState={setPopOverBool}
                        onClick={() => {
                          deleteVesselMachinery(deleteId);
                          setOpenPopoverIndex(null);
                        }}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {vesselMachineries
                        ? "No machinery found. Please add machinery to this vessel."
                        : "Please add machinery to this vessel."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <p>- Add to the list, machinery present in this vessel.</p>
          <ToolTip target="machinery_actions_msg" message="Click here to perform actions on the machinery" />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default MachineryMaster;
