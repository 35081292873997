import { errorToast, successToast } from 'Components/Toasts';
import { clearAuthKey, encrypt } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import React, { useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';



const TempAuth = ({ setAuthenticated }: { setAuthenticated: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [password, setPassword] = useState<string>("");
  const [PasswordType, setPasswordType] = useState<'password' | 'text'>('password');

  /**
   * Function to handle authentication, encrypts the password and set the state
   * @param password
   * @returns nothing
   */
  const HandelAuth = async (password: string) => {
    if (password.length < 1) {
      errorToast("Please enter password");
      return;
    }
    const encryptedPassword = encrypt(password);
    await apiGlobal.post(queryKeyes.user.authenticate.url(), { password: encryptedPassword }).then((res) => {
      if (res.status === 200) {
        setAuthenticated(true);
        localStorage.setItem("Authorization_key", res.data.authorization_key);
        sessionStorage.setItem("Authorization_key", res.data.authorization_key);
        successToast("Authenticated successfully");
        clearAuthKey();
      } else {
        errorToast("Error occurred while authenticating");
      }
    }).catch((err) => {
      console.error(err.response?.data?.message);
      console.error(err);
      errorToast("Error occurred while authenticating" + err.response?.data?.message);
    });
  };

  return (
    <React.Fragment>
      <div className="text-left">
        <Label>Your password</Label>
      </div>
      <Row className="align-items-center d-flex">
        <Col>
          <Input
            type={PasswordType}
            placeholder="Enter new password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <i
            className={`mdi ${PasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye mr-1`}
            onClick={() => setPasswordType(PasswordType === 'password' ? 'text' : 'password')}
          />
        </Col>
        <Col xs="auto">
          <Button color="primary" size="sm" onClick={() => HandelAuth(password)} style={{ height: '38px' }}>
            Authenticate
          </Button>
        </Col>
      </Row>
      <Col className="mt-2">
        <Label>User Name</Label>
      </Col>
      <Col xs="auto">
        <Label>Temporary Password  ****** </Label>
      </Col>
    </React.Fragment>
  );
};

export default TempAuth;