import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import { GetPageWithID } from 'Dashboard/PendingFiles/pendingpage.hook';
import { checkInvalidPrimaryKey, errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import { Label, Row, Col, Button } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import { hasRole } from 'utils/auth/authUtils';

const DisapprovedVoyageAdjustment = ({
    active
}: any) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [voyageAdjustment, setVoyageAdjustment] = useState<any>({});
    const [revokeBool, setRevokeBool] = useState<any>({});
    const [url, setUrl] = useState<string>('');
    /** State variables end */

     /** useEffect start */
        useEffect(() => {
            if (checkInvalidPrimaryKey(VesselID)) {
                setUrl(queryKeyes.pagination.VoyageAdjustmentPage.url(VesselID, AdjustmentApprovalStatus.DISAPPROVED));
            }
        }, [VesselID, active])
    
        /** Queries */
        const { data: DisapprovedVoyageAdjustment } = useQuery(
            [queryKeyes.pagination.VoyageAdjustmentPage.key, VesselID, url],
            async () => await GetPageWithID(VesselID, url),
            {
                enabled: true,
                staleTime: Infinity,
            }
        )
        /** Open-close modal */
    /** Function to revoke N2 Correction */
    const revokeVoyageAdjustment = () => {
        voyageAdjustment.user_approved = AdjustmentApprovalStatus.PENDING;
        apiGlobal.put(`/vessel_reporting_cii_adjustment/${voyageAdjustment?.id}/`, voyageAdjustment).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.pagination.VoyageAdjustmentPage.key);
                setRevokeBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    return (
        <React.Fragment>
            {DisapprovedVoyageAdjustment?.results?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-2'>No Voayge Adjustments are disapproved for this vessel</Label>
                </div> :
                <React.Fragment>
                    <Row className='mb-0'>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th className='p-2 text-center aligen-middle' style={{ width: '6.1%' }}>#</th>
                                            <th className='p-2'>Duration</th>
                                            {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                <th className='p-2 aligen-middle text-center' style={{ width: '23%' }}>Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DisapprovedVoyageAdjustment && DisapprovedVoyageAdjustment?.results?.map((cii: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            <React.Fragment>
                                                                <b>{cii?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_begin_date_time?.toString().substring(11, 16)}</b> to
                                                                <b> {cii?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_end_date_time?.toString().substring(11, 16)}</b>
                                                            </React.Fragment>
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <td className='align-middle text-center p-2'>
                                                                <Button
                                                                    type='button'
                                                                    className='btn btn-primary waves-effect btn-label waves-light'
                                                                    color='primary'
                                                                    id={`disapprove_btn${cii?.id}`}
                                                                    onClick={() => {
                                                                        setVoyageAdjustment(cii);
                                                                        setRevokeBool(true);
                                                                    }}
                                                                >
                                                                    <i className="bx bx-left-arrow-circle label-icon"></i>
                                                                    Mark as pending
                                                                </Button>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        {DisapprovedVoyageAdjustment?.results?.length > 0 &&
                                            <tr>
                                                <td colSpan={5} className="p-2 ">
                                                    <PageNavButton setPageUrl={setUrl} pageobj={DisapprovedVoyageAdjustment} pageUrl={url} />
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                    {revokeBool === true &&
                                        <PopOver
                                            target={`disapprove_btn${voyageAdjustment?.id}`}
                                            handleClick={revokeVoyageAdjustment}
                                            message={'Are you sure you want to revoke?'}
                                            state={revokeBool}
                                            setState={setRevokeBool}
                                        />
                                    }
                                </table>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default DisapprovedVoyageAdjustment