import React, { useEffect } from "react";
import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import * as Yup from "yup";
import {
    loadMachineryRunningHoursObject,
    loadVesselRunningHoursMachinery,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldArray, Formik } from "formik";
import apiGlobal from "global/api.global";
import { useQuery } from "react-query";
import {
    Row,
    Col,
    Form,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
} from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import { handleServerResponse } from "GenericForms/Helper";
import env from "environment_system/env_system";
import ErrorTooltip from "Components/ErrorTooltip";

interface RunningHoursComponentType {
    VesselID: number;
    VoyageID: number;
    ReportID: number;
    toggleTab: any;
    activeTab: any;
    setErrorMessage: any,
}

const RunningHoursComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleTab,
    activeTab,
    setErrorMessage,
}: RunningHoursComponentType) => {
    /** Queries */
    /** Load Vessel Machinary */
    const {
        data: RunningHrsMachineries,
        isLoading: RunningHrsMachineriesLoading,
        isError: RunningHrsMachineriesError,
    } = useQuery(
        [queryKeyes.vessel.vesselRunningHoursMachinery.key, VesselID],
        async () => {
            return await loadVesselRunningHoursMachinery(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Machinery running hours object used for edit */
    const {
        data: RunningHoursObject,
        isLoading: RunningHoursObjectLoading,
        isError: RunningHoursObjectError,
    } = useQuery(
        [queryKeyes.vessel.RunningHoursObject.key, VesselID, ReportID],
        async () => {
            return await loadMachineryRunningHoursObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign initial values to formik object 
     * If RunningHoursObject exists and is not empty, initialize it
     * If RunningHrsMachineries exists and has data
     * If MachineryRunningHoursMachinery exists and has data
     * If MachineryRunningHoursMachinery error
     */
    const getInitialValues = () => {
        let obj: any[] = [];

        // Initialize obj with RunningHoursObject if available
        if (RunningHoursObject && RunningHoursObject.length > 0) {
            obj = [...RunningHoursObject];
        }

        // If RunningHrsMachineries exists and has data
        if (RunningHrsMachineries && RunningHrsMachineries.length > 0) {
            RunningHrsMachineries.forEach((machine: any) => {
                // Find the existing machine in obj
                const existingMachine = obj.find(
                    (item: any) =>
                        item.vessel_machinery === machine.id &&
                        item.precedence_id === machine.precedence_id &&
                        item.vessel === machine.vessel
                );

                if (!existingMachine){ 
                    // If machine is NOT present, add a new object
                    obj.push({
                        precedence_id: machine.precedence_id,
                        vessel_machinery: machine.id,
                        running_hours:  null,
                        machinery: machine.vessel_machinery_name,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        running_counter_value: null,
                    });
                }
            });
        }
        return obj;
    };

    /** useEffect */
    useEffect(() => {
        RunningHoursFormik.initialValues.runningHrs = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RunningHrsMachineries, RunningHoursObject]);
    /** useEffect end */

    /** Running Hours Formik object */
    let RunningHoursFormik = {
        initialValues: {
            runningHrs: getInitialValues(),
        },
        validationSchema: Yup.object().shape({
            runningHrs: Yup.array().of(
                Yup.object().shape({
                    running_hours: Yup.string()
                        .matches(/^(?:25(?:\.00?)?|(?:[0-1]?\d|2[0-4])(?:\.\d{1,2})?)$/, commonValidationMessages.max25)
                        .when("vessel_machinery", {
                            is: (value: any) => !(RunningHrsMachineries?.filter((machine: any) => machine.id === value)[0]?.running_hour), // Condition check
                            then: (schema) => schema.nullable(),
                            otherwise: (schema) => schema.required(commonValidationMessages.required),
                        }),
                    running_counter_value: Yup
                        .string().matches(/^\d{0,10}(\.\d{1,4})?$/, `${commonValidationMessages.before10after4}`).nullable(),
                })
            ),
        }),
    };

    /** Running hours form submit */
    const RunningHoursSubmit = async (values: any | any[], actions: any) => {
        const responseArray: any = [];
        if (
            RunningHoursObject?.length > 0 &&
            RunningHoursObject[0].id > 0
        ) {
            values.runningHrs.map((val: any) => {
                if (val?.id > 0) {
                    responseArray.push(apiGlobal.put(`/machinery_running_hours/${val.id}/`, val));
                } else {
                    responseArray.push(apiGlobal.post(`/machinery_running_hours/`, val));
                }
            })
        } else {
            responseArray.push(apiGlobal.post(`/machinery_running_hours/`, values.runningHrs));
        }
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                queryClient.invalidateQueries(queryKeyes.vessel.RunningHoursObject.key);
                if (env?.form_validation === true) {
                    setErrorMessage(null)
                    toggleTab(activeTab + 1);
                }
            } else {
                setErrorMessage(res)
            }
            actions.setSubmitting(false);
        })
        actions.setSubmitting(false);
    }

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Machinery Running Hours
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last noon report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(RunningHrsMachineriesLoading || RunningHoursObjectLoading) && (
                <Loading message="Loading required data!" />
            )}
            {RunningHrsMachineriesError && (
                <ErrorComponent message="Unable to load required data!" />
            )}
            {RunningHoursObjectError && getInitialValues()}
            {!(RunningHrsMachineriesLoading || RunningHoursObjectLoading) &&
                !RunningHrsMachineriesError && (
                    <Formik
                        onSubmit={async (values: any, actions: any) => {
                            if (env?.form_validation === false) {
                                setErrorMessage(null)
                                toggleTab(activeTab + 1);
                            }
                            actions.setSubmitting(true);
                            RunningHoursSubmit(values, actions);
                            queryClient.invalidateQueries(queryKeyes.vessel.RunningHoursObject.key);
                            queryClient.invalidateQueries(queryKeyes.vessel.vesselRunningHoursMachinery.key);
                        }}
                        initialValues={RunningHoursFormik.initialValues}
                        validationSchema={env?.form_validation === true ? RunningHoursFormik.validationSchema : null}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            setErrors,
                            touched,
                            handleBlur,
                            isSubmitting,
                        }: {
                            values: any;
                            errors: any;
                            handleSubmit: any;
                            handleChange: any;
                            setErrors: any;
                            handleBlur: any;
                            touched: any;
                            isSubmitting: any;
                        }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                <CardBody className="engineer-card-body">
                                    <Row>
                                        <Col sm={{ size: 6, offset: 3 }}>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2 mt-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="align-middle p-2 sr-no-width">
                                                                #
                                                            </th>
                                                            <th className="align-middle p-2">Machinery</th>
                                                            <th
                                                                className="align-middle p-2 text-center asteric"
                                                                style={{ width: "35%" }}
                                                            >
                                                                Running hours
                                                            </th>
                                                            <th
                                                                className="align-middle p-2 text-center"
                                                                style={{ width: "35%" }}
                                                            >
                                                                Running counter
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name="runningHrs">
                                                            {() => (
                                                                <React.Fragment>
                                                                    {values &&
                                                                        values?.runningHrs?.sort((a: any, b: any) =>
                                                                            a.vessel_machinery - b.vessel_machinery && a.precedence_id - b.precedence_id).map((hrs: any, index: any) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="align-middle p-2 text-center">
                                                                                            {index + 1}
                                                                                        </td>
                                                                                        <td className="align-middle p-2">
                                                                                            {hrs.machinery}
                                                                                        </td>
                                                                                        <td className="align-middle p-2 text-center">
                                                                                            <div className="d-inline-block">
                                                                                                <div className="input-group">
                                                                                                    <Field
                                                                                                        name={`runningHrs.${values.runningHrs.indexOf(
                                                                                                            hrs
                                                                                                        )}.running_hours`}
                                                                                                    >

                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                name={`runningHrs.${values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )}.running_hours`}
                                                                                                                id={`running_hours-${values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )}`}
                                                                                                                className="form-control max-width-7 text-right"
                                                                                                                onBlur={handleBlur}
                                                                                                                onChange={(e: any) =>
                                                                                                                    handleChange(e)
                                                                                                                }
                                                                                                                defaultValue={!(RunningHrsMachineries?.filter((machine: any) => machine.id === hrs?.vessel_machinery)[0]?.running_hour) ? null : values.runningHrs[
                                                                                                                    values.runningHrs.indexOf(
                                                                                                                        hrs
                                                                                                                    )
                                                                                                                ]?.running_hours
                                                                                                                }
                                                                                                                disabled={!(RunningHrsMachineries?.filter((machine: any) => machine.id === hrs?.vessel_machinery)[0]?.running_hour)}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text round_border">
                                                                                                        hr
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {errors?.runningHrs &&
                                                                                                touched?.runningHrs &&
                                                                                                touched?.runningHrs[
                                                                                                    values.runningHrs.indexOf(hrs)
                                                                                                ]?.running_hours &&
                                                                                                errors?.runningHrs[
                                                                                                    values.runningHrs.indexOf(hrs)
                                                                                                ]?.running_hours &&
                                                                                                env?.form_validation ===
                                                                                                true && (
                                                                                                    <ErrorTooltip
                                                                                                        target={`running_hours-${values.runningHrs.indexOf(
                                                                                                            hrs
                                                                                                        )}`}
                                                                                                        message={
                                                                                                            errors?.runningHrs[
                                                                                                                values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )
                                                                                                            ]?.running_hours
                                                                                                        }
                                                                                                        open={
                                                                                                            errors?.runningHrs &&
                                                                                                                errors?.runningHrs[
                                                                                                                    values.runningHrs.indexOf(
                                                                                                                        hrs
                                                                                                                    )
                                                                                                                ]?.running_hours
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                        </td>
                                                                                        <td className="align-middle p-2 text-center">
                                                                                            <div className="d-inline-block">
                                                                                                <div className="input-group">
                                                                                                    <Field
                                                                                                        name={`runningHrs.${values.runningHrs.indexOf(
                                                                                                            hrs
                                                                                                        )}.running_counter_value`}
                                                                                                    >
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                name={`runningHrs.${values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )}.running_counter_value`}
                                                                                                                id={`running_counter_value-${values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )}`}
                                                                                                                className="form-control max-width-7 text-right"
                                                                                                                onBlur={handleBlur}
                                                                                                                onChange={(e: any) =>
                                                                                                                    handleChange(e)
                                                                                                                }
                                                                                                                disabled={!(RunningHrsMachineries?.filter((machine: any) => machine.id === hrs?.vessel_machinery)[0]?.running_counter)}
                                                                                                                defaultValue={!(RunningHrsMachineries?.filter((machine: any) => machine.id === hrs?.vessel_machinery)[0]?.running_counter) ? null : values.runningHrs[
                                                                                                                    values.runningHrs.indexOf(
                                                                                                                        hrs
                                                                                                                    )
                                                                                                                ]?.running_counter_value
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text round_border">
                                                                                                        hr
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {errors?.runningHrs &&
                                                                                                touched?.runningHrs &&
                                                                                                touched?.runningHrs[
                                                                                                    values.runningHrs.indexOf(hrs)
                                                                                                ]?.running_counter_value &&
                                                                                                errors?.runningHrs[
                                                                                                    values.runningHrs.indexOf(hrs)
                                                                                                ]?.running_counter_value &&
                                                                                                env?.form_validation ===
                                                                                                true && (
                                                                                                    <ErrorTooltip
                                                                                                        target={`running_counter_value-${values.runningHrs.indexOf(
                                                                                                            hrs
                                                                                                        )}`}
                                                                                                        message={
                                                                                                            errors?.runningHrs[
                                                                                                                values.runningHrs.indexOf(
                                                                                                                    hrs
                                                                                                                )
                                                                                                            ]?.running_counter_value
                                                                                                        }
                                                                                                        open={
                                                                                                            errors?.runningHrs &&
                                                                                                                errors?.runningHrs[
                                                                                                                    values.runningHrs.indexOf(
                                                                                                                        hrs
                                                                                                                    )
                                                                                                                ]?.running_counter_value
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                    }
                                                                </React.Fragment>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="p-2 py-3">
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm"
                                                onClick={() => {
                                                    setErrors({});
                                                    toggleTab(activeTab - 1);
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug
                                    values={[values, errors, RunningHoursFormik.initialValues]}
                                />
                            </Form>
                        )}
                    </Formik>
                )
            }
        </Card >
    );
};

export default RunningHoursComponent;
