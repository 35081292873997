import React, { useState } from 'react'
import { Tooltip } from 'reactstrap';
interface ErrorTooltipType {
    message: any,
    target: any,
    open: any,
    key?: number,
}
export default function ErrorTooltip(props: ErrorTooltipType) {
    const [tbottom, settbottom] = useState(false);
    const { message, target, open, key } = props
    return (
        <Tooltip
            placement="bottom"
            id={target}
            isOpen={open}
            target={target}
            toggle={() => {
                settbottom(!tbottom);
            }}
            style={{ background: '#fd625e' }}
            className='redArrow'
            key={key ?? "tooltip"}
        >
            <p style={{ color: 'white' }} className='mb-0'>{message}</p>
        </Tooltip>
    )
}
