import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment_system/env_system';
import { Field, FieldProps, Formik } from 'formik';
import { handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import { customStyle } from 'shared/CommonCSS';
import { VesselConfigrationTabs, VesselTypeConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadVesselOtherSettingsOperation } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';

const Other = (VesselConfActiveTab: any) => {
    /** State variables */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const [count, setCount] = useState(0);
    const [windRefreshkey, setWindRefreshKey] = useState(0);
    const [EEDIRefreshkey, setEEDIRefreshKey] = useState(0);
    const fwindOptions = [
        { value: 0.95, label: 0.95 },
        { value: 0.97, label: 0.97 },
        { value: 0.99, label: 0.99 },
    ];
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;
    /** State variables end */

    /** Queries */
    /** Vessel's other setting;s object used for edit */
    const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
            async () => { return await loadVesselOtherSettingsOperation(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** End Queries */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (OtherSettings && OtherSettings[0]?.id > 0) {
            return OtherSettings[0];
        } else {
            return ({
                is_vse_mention: false,
                vse_value: null,
                reefer_container_name: "",
                is_wind_assistance_system_available: false,
                pwind: null,
                fwind: null,
                pprop: null,
                power_rating: null,
                is_steam_dump_valve_in_use: false,
                reefer_container_available: false,
                vessel: VesselID
            })
        }
    }

    /** Other Setting's formik object */
    const OtherSettingFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            vse_value: Yup.number().nullable().when('is_vse_mention', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }),
            pwind: Yup.number().nullable(),
            pprop: Yup.number().nullable(),
            fwind: Yup.number().when('is_wind_assistance_system_available', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }).max(1, commonValidationMessages.max1).min(0, commonValidationMessages.min0),
        })
    }

    if (OtherSettingsError) {
        setCount(count + 1);
    }

    return (
        <CardBody className='pb-0 pt-0'>
            {OtherSettingsLoading && <Loading message='Loading required data!' />}
            {!OtherSettingsLoading &&
                <Formik
                    key={count}
                    initialValues={OtherSettingFormik.initialValues}
                    validationSchema={OtherSettingFormik.validationSchema}
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(true);
                        let responseArray: any[] = [];
                        if (!values?.is_vse_mention) {
                            values.vse_value = null; // Reset to empty string if unchecked
                        }
                        if (!values?.is_wind_assistance_system_available) {
                            values.fwind = null; // Reset to empty string if unchecked
                            values.pwind = null; // Reset to empty string if unchecked
                            values.pprop = null;
                        }
                        if (OtherSettings && OtherSettings[0]?.id > 0) {
                            responseArray.push(apiGlobal.put(`/other_setting/${values?.id}/`, values));
                        } else {
                            responseArray.push(apiGlobal.post(`/other_setting/`, values));
                        }
                        /** handle server response */
                        await handleServerResponse(responseArray).then(async (res) => {
                            if (res) {
                                await queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                                setCount(count + 1);
                            }
                        });
                    }}
                >
                    {props => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            if (VesselConfActiveTab !== VesselConfigrationTabs.OTHER) {
                                props?.setTouched({})
                            }
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                        }, [VesselConfActiveTab]);
                        return (
                            <Form autoComplete="off" onSubmit={props?.handleSubmit} noValidate>
                                <Row className='mb-2'>
                                    <Col sm={4}>
                                        <div className="d-flex flex-wrap mt-4">
                                            <Label className='width-17' for='is_vse_mention'>Is f<sub>iVSE</sub> mentioned in EEDI / EEXI?</Label>
                                            <Field name="is_vse_mention">
                                                {({ field, form }: FieldProps) => (
                                                    <div className="square-switch sqswitch">
                                                        <Input
                                                            type="checkbox"
                                                            switch="none"
                                                            name={field.name}
                                                            id={field.name}
                                                            onChange={(e: any) => {
                                                                form.setFieldValue(field.name, e.target.checked)
                                                                if (e.target.checked === false) {
                                                                    form.setFieldValue("vse_value", null)
                                                                    setEEDIRefreshKey(EEDIRefreshkey + 1)
                                                                }
                                                            }}
                                                            defaultChecked={props?.values?.is_vse_mention}
                                                        />
                                                        <Label
                                                            htmlFor={field.name}
                                                            data-on-label='Yes'
                                                            data-off-label='No'
                                                            className='mb-0'
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col sm={4} key={EEDIRefreshkey}>
                                        <Label className='mb-0 asteric' for=''>f<sub>iVSE</sub> value</Label>
                                        <Field
                                            type="text"
                                            name="vse_value"
                                            id="vse_value"
                                            className="form-control max-width-7 text-right mb-2"
                                            disabled={!props?.values?.is_vse_mention}
                                        />
                                        {props?.errors && env?.form_validation === true && props?.touched?.vse_value &&
                                            props?.values?.is_vse_mention === true &&
                                            <ErrorTooltip
                                                target='vse_value'
                                                message={props?.errors?.vse_value}
                                                open={props?.errors?.vse_value ? true : false}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col sm={4}>
                                        <div className="d-flex flex-wrap mt-4">
                                            <Label className='width-17' for='is_wind_assistance_system_available'>
                                                Is wind assistance system available?
                                            </Label>
                                            <Field name="is_wind_assistance_system_available">
                                                {({ field, form }: FieldProps) => (
                                                    <div className="square-switch sqswitch">
                                                        <Input
                                                            type="checkbox"
                                                            switch="none"
                                                            name={field.name}
                                                            id={field.name}
                                                            onChange={(e: any) => {
                                                                form.setFieldValue(field.name, e.target.checked)
                                                                if (e.target.checked === false) {
                                                                    form.setFieldValue(`fwind`, null)
                                                                    form.setFieldValue('pwind', null)
                                                                    form.setFieldValue('pprop', null)
                                                                    setWindRefreshKey(windRefreshkey + 1)
                                                                }
                                                            }
                                                            }
                                                            defaultChecked={props?.values?.is_wind_assistance_system_available}
                                                        />
                                                        <Label
                                                            htmlFor={field.name}
                                                            data-on-label='Yes'
                                                            data-off-label='No'
                                                            className='mb-0'
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col sm={2} >
                                        <Label className='mb-0 asteric' for=''>f<sub>wind</sub></Label>
                                        <Field name="fwind">
                                            {({ field, form }: FieldProps) => (
                                                <Select
                                                    key={windRefreshkey}
                                                    name={field.name}
                                                    options={fwindOptions as any[]}
                                                    getOptionLabel={(option: any) => option.label}
                                                    getOptionValue={(option: any) => option.label}
                                                    value={{
                                                        id: props?.values?.fwind,
                                                        label: props?.values?.fwind
                                                    }}
                                                    onChange={(e: any) =>
                                                        form.setFieldValue(field.name, e?.label)
                                                    }
                                                    styles={customStyle}
                                                    isDisabled={!props?.values?.is_wind_assistance_system_available}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col sm={2}>
                                        <Label className='mb-0' for=''>P<sub>wind</sub></Label>
                                        <div className='input-group'>
                                            <Field
                                                key={windRefreshkey}
                                                type="text"
                                                name="pwind"
                                                id="pwind"
                                                className="form-control max-width-7 text-right"
                                                value={props?.values?.pwind}
                                                disabled={!props?.values?.is_wind_assistance_system_available}
                                            />
                                            <div className='input-group-text'>kW</div>
                                        </div>
                                    </Col>
                                    <Col sm={2}>
                                        <Label className='mb-0' for=''>P<sub>prop</sub></Label>
                                        <div className='input-group'>
                                            <Field
                                                key={windRefreshkey}
                                                type="text"
                                                name="pprop"
                                                id="pprop"
                                                className="form-control max-width-7 text-right"
                                                disabled={!props?.values?.is_wind_assistance_system_available}
                                            />
                                            <div className='input-group-text'>kW</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col sm={4}>
                                        <div className="d-flex flex-wrap mt-4">
                                            <Label className='width-17' for='is_steam_dump_valve_in_use'>
                                                Is Steam dump valve used?<br />(Only for steam ship)
                                            </Label>
                                            <Field name="is_steam_dump_valve_in_use">
                                                {({ field, form }: FieldProps) => (
                                                    <div className="square-switch sqswitch">
                                                        <Input
                                                            type="checkbox"
                                                            switch="none"
                                                            name={field.name}
                                                            id={field.name}
                                                            defaultChecked={props?.values?.is_steam_dump_valve_in_use}
                                                            onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)}
                                                        />
                                                        <Label
                                                            htmlFor={field.name}
                                                            data-on-label='Yes'
                                                            data-off-label='No'
                                                            className='mb-0'
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                {(vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                                    <Row className='mb-2'>
                                        <Col sm={4}>
                                            <div className="d-flex flex-wrap mt-4">
                                                <Label className='width-17' for='reefer_container_available'>
                                                    Does this vessel have a provision to carry reefer container?
                                                </Label>
                                                <Field name="reefer_container_available">
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="square-switch sqswitch">
                                                            <Input
                                                                type="checkbox"
                                                                switch="none"
                                                                name={field.name}
                                                                id={field.name}
                                                                onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)}
                                                                defaultChecked={props?.values?.reefer_container_available}
                                                            />
                                                            <Label
                                                                htmlFor={field.name}
                                                                data-on-label='Yes'
                                                                data-off-label='No'
                                                                className='mb-0'
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                        <Button type="submit" color="primary" className='btn_size4_5_cstm' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                                        <Button type="reset" color="danger" className='btn_size4_5_cstm' disabled={isConfigurationButtonDisabled(vessel)}>Reset</Button>
                                    </div>
                                </Row>
                                <FormValuesDebug values={[props?.values, props?.errors, OtherSettingFormik.initialValues]} />
                            </Form>
                        )
                    }}
                </Formik>
            }
            <p> - Set values.</p>
        </CardBody>
    )
}

export default Other
