import React from 'react';
import { StrictMode } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './react-query/queryClient';
import { ToastContainer } from 'react-toastify';
import env from './environment_system/env_system';
import { BrowserRouter as BRouter } from 'react-router-dom';
import { HashRouter as HRouter } from 'react-router-dom';
//import { HashRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import { configureStore } from './Store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configureStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {
          env.desktop_app === true ?
            <HRouter basename={env.base_name}>
              <App />
            </HRouter>
            : <BRouter basename={env.base_name}>
              <App />
            </BRouter>
        }
      </Provider>
      <ToastContainer />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </StrictMode>
);
