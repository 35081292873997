import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import env from "environment_system/env_system";

export const successToast = (message: string, redirectTo: string = null) => {
    toast.dismiss();
    toast.success(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: 'colored',
        onClose: () => {
            if (redirectTo) {
                setTimeout(() => {
                    if (env?.desktop_app === true) {
                        const currentLocation = window.location.href;
                        if (currentLocation.includes("#/login")) {
                            const newLocation = currentLocation.replace("#/login", redirectTo);
                            window.location.href = newLocation;
                        }
                        window.location.reload();
                    } else {
                        window.location.assign(redirectTo);
                    }
                }, 1000);
            }
        },
    });
}

export const errorToast = (message: string) => {
    toast.dismiss();
    toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: "colored",
    });
}

export const voyageErrorToast = (message: string) => {
    toast.dismiss();
    toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        theme: "colored",
    });
}

export const infoToast = (message: string) => {
    toast.dismiss();
    toast.info(message, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const warningToast = (message: string) => {
    toast.dismiss();
    toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}