import React, { useEffect, useRef, useCallback } from 'react';
import { Popover, PopoverBody, Button } from 'reactstrap';

interface DeletePopOverProps {
    target: string;
    onClick: () => void;
    state: boolean;
    setState: (state: boolean) => void;
    message?: string;
    noClick?: () => void;
}

const DeletePopOver: React.FC<DeletePopOverProps> = ({
    target,
    onClick,
    state,
    setState,
    message,
    noClick
}) => {
    const popoverRef = useRef(null);

    // Toggle the popover state
    const togglePopover = useCallback(() => {
        setState(!state);
    }, [state, setState]);

    // Click outside handler
    const handleClickOutside = useCallback(
        (event: any) => {
            if (
                event.target.id !== target
            ) {
                setState(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setState]
    );

    // Add/remove event listener for outside clicks
    useEffect(() => {
        if (state) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [state, handleClickOutside]);
    return (
        <Popover
            id={target}
            placement="top"
            isOpen={state}
            target={target}
            toggle={togglePopover}
            ref={popoverRef}
        >
            <PopoverBody
                id={target}
            >
                {message ? <p>{message}</p> : <p>Are you sure you want to delete this record?</p>}
                <Button
                    id={target}
                    type="button"
                    color="danger"
                    className="mr-2 mt-3 popover_btn"
                    onClick={() => {
                        onClick();
                    }}
                >
                    Yes
                </Button>
                <Button
                    type="button"
                    color="primary"
                    className="mt-3"
                    onClick={() => {
                        if (noClick) {
                            noClick();
                        }
                        togglePopover();
                    }}
                >
                    No
                </Button>
            </PopoverBody>
        </Popover>
    );
};

export default DeletePopOver;
