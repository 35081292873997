import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import React from 'react';
import { useQuery } from 'react-query';
import { Table } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import { fetchConfigurationList } from 'VesselMaster/vesselMaster.hooks';

interface ConfigurationPendingFileStatusProps {
    VesselID: number;
}

const ConfigurationPendingFileStatus = ({ VesselID }: ConfigurationPendingFileStatusProps) => {
    const { data, isLoading, isError } = useQuery(
        [queryKeyes.vessel.ConfigurationList.key, VesselID],
        async () => {
            return await fetchConfigurationList(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    return (
        <div className='table-responsive'>
            <Table className='tabel mb-2'>
                <thead className='tabel-light'>
                    <tr>
                        <th className="p-2 align middle sr-no-width" style={{ width: '10%' }}>#</th>
                        <th className="p-2 align middle sr-no-width" style={{ width: '40%' }}>Form Name</th>
                        <th className="p-2 align middle sr-no-width" style={{ width: '40%' }}>Status</th>
                    </tr>
                </thead>
                {isLoading && <Loading message='Loading required data!' />}
                {isError && <ErrorComponent message='Error loading required data!' />}
                <tbody>
                    {data?.map((item: any, index: number) => (
                        <tr key={index}>
                            <td className="p-2 align-middle">{index + 1}</td>
                            <td className="p-2 align-middle">{item.Form}</td>
                            <td className='p2 align-middle'>
                                {item.Status === "pending" && (
                                    <i className='bx bx-time-five label-red' />
                                )}
                                {item.Status === "completed" && (
                                    <i className='bx bx-check-circle color-blue' />
                                )}
                                <span className='ml-1'>{item.Status}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ConfigurationPendingFileStatus;
