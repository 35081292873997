import React, { useEffect, useState } from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import apiGlobal from 'global/api.global';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Row } from 'reactstrap';
import { DO_ENGG_ReportingStatus } from 'shared/constants';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { loggedInUser } from 'utils/auth/authUtils';
import { Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { getDeckOfficerRemark } from 'VesselMaster/vesselMaster.hooks';
import { queryClient } from 'react-query/queryClient';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { handleServerResponse } from 'GenericForms/Helper';

interface RemarksType {
    record: any,
    handleVesselState: any,
    toggleTab: any,
    activeTab: number
    setErrorMessage: any
}

const Remarks = ({
    record,
    handleVesselState,
    toggleTab,
    activeTab,
    setErrorMessage,
}: RemarksType) => {
    /** State variables start */
    const { ReportID, VesselID, VoyageID } = useSelector((state: RootState) => state.Reporting);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    /** State variables end */

    /** Queries */
    /** get engineer Remark by vessel_id and vessel_reporting_id*/
    const {
        data: DeckOfficerRemark,
        isLoading: DeckOfficerRemarkLoading,
        isError: DeckOfficerRemarkError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.DeckOfficerRemark.key, VesselID, ReportID],
        async () => {
            return await getDeckOfficerRemark(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * send POST request for remark
     * @param remark 
     */
    const saveRemark = async (remark: any) => {
        const responseArray: any = [];
        /** Remarks' submit request */
        if (DeckOfficerRemark && DeckOfficerRemark.length > 0) {
            responseArray.push(apiGlobal.put(`/deck_officer_remark/${remark.id}/`, remark))
        } else {
            responseArray.push(apiGlobal.post(`/deck_officer_remark/`, remark))
        }
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                setErrorMessage(null)
                queryClient.invalidateQueries(queryKeyes.vessel.DeckOfficerRemark.key);
                setRefreshKey(refreshKey + 1)
            } else {
                setErrorMessage(res)
            }
        })
    }

    /** Actions to perform when clicked on button 'Save & Complete' */
    const handleSaveandComplete = async (remark: any) => {
        let report = { ...record }
        report.officer_reporting_status = DO_ENGG_ReportingStatus.COMPLETE;
        const responseArray: any = [];
        if (remark.remarks) {
            saveRemark(remark)
        }
        responseArray.push(apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report));
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                setErrorMessage(null)
                handleVesselState('VESSEL_REPORTING');
            } else {
                setErrorMessage(res)
            }
        })

    }

    /** Assign values to formik's initial object */
    const getInitialValues = () => {
        if (DeckOfficerRemark && DeckOfficerRemark.length > 0) {
            return DeckOfficerRemark[0];
        } else {
            return ({
                remarks: "",
                user: loggedInUser(),
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** useEffect */
    useEffect(() => {
        RemarksFormik.initialValues = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DeckOfficerRemark]);

    /** Formik object for Remarks */
    const RemarksFormik = {
        initialValues: getInitialValues()
    }
    return (
        <Card>
            <CardHeader className='p-2 pb-0'>
                <div className="text-center mb-3">
                    <Row>
                        <Col>
                            <h5 className="page_title pos-start mb-0">Remarks (if any)</h5>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {DeckOfficerRemarkLoading && <Loading message="Loading required data!" />}
            {DeckOfficerRemarkError && <ErrorComponent message="Unable to load required data!" />}
            <Formik
                onSubmit={(values: any, actions: any) => {
                    actions.setSubmitting(true);
                    /** Remarks' submit request */
                    if (values.remarks) {
                        saveRemark(values)
                    }
                }}
                initialValues={RemarksFormik.initialValues}
                key={refreshKey}
            >
                {props => (
                    <Form className="needs-validation" autoComplete="off" onSubmit={props?.handleSubmit}>
                        <CardBody className='deck-card-body'>
                            <Row>
                                <div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'alignment', 'bulletedList', 'numberedList'],
                                        }}
                                        data={props.values.remarks}
                                        onBlur={(event, editor) => {
                                            props?.setFieldValue('remarks', editor.getData());
                                        }}
                                    />
                                </div>
                            </Row>
                        </CardBody>
                        <CardFooter className='p-2 py-3'>
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5 ">
                                    <Button type="submit" color="primary" className="btn_size_cstm pos-end12">Save</Button>
                                    <Button type="button" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                        handleSaveandComplete(props.values);
                                    }}>Save & Complete</Button>
                                    <Button type="button" color="primary" className="btn_size_cstm " onClick={() => {
                                        props.setSubmitting(false);
                                        toggleTab(activeTab - 1);
                                    }}><i className="bx bx-chevron-left me-1" />Previous</Button>
                                </div>
                            </Row>
                        </CardFooter>
                        <FormValuesDebug values={[props?.values, props?.errors, RemarksFormik.initialValues]} />
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default Remarks