import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadVesselSensorByVesselID } from 'VesselMaster/vesselMaster.hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { errResponse } from 'GenericForms/Helper';
import { queryClient } from 'react-query/queryClient';
import EditIcon from '../../../Media/ED2.png';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import AddSensorModal from './AddSensorModal';

const AdditionalConfiguration = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const [deleteIndex, setDeleteIndex] = useState<number>();
    const [deleteSubIndex, setDeleteSubIndex] = useState<number>();
    const [deleteObject, setDeleteObject] = useState<any>();
    const [savedDeletePopOverState, setSavedDeletePopOverState] = useState<boolean>(false);
    const [modalState, setModalState] = useState<boolean>(false)
    const [editID, setEditID] = useState<number>(null)
    /** Queries start */
    /** Load Reporting Sensor Master List Array */
    const {
        data: VesselSensor,
        isLoading: VesselSensorLoading,
        isError: VesselSensorError,
    } = useQuery(
        [queryKeyes.vessel.VesselSensorByVesselID.key, VesselID],
        async () => {
            return await loadVesselSensorByVesselID(VesselID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */
    /** Assign initial values */
    const getInitialValues = (): any => {
        if (VesselSensor) {
            return VesselSensor
        } else {
            return []
        }
    }

    /** set edit if to null when we close modal */
    useEffect(() => {
        if (modalState === false) {
            setEditID(null)
        }
    }, [modalState]);

    /**
     * sensor master Formik
     */
    const SensorMasterFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: { sensor: getInitialValues() },
        onSubmit: () => { }
    })


    /**
     * To remove sensor from specific index
     * @param index removeble index
     */
    const removeSensor = async () => {
        if (deleteObject) {
            await apiGlobal.delete(`${queryKeyes.vessel.VesselSensor.url()}${deleteObject.id}/`).then(res => {
                if (res.status === 200 || res.status === 204) {
                    successToast("Data deleted successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselSensorByVesselID.key);
                    setRefreshKey(refreshKey + 1)
                }
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
            setDeleteObject(null)
        }
    }

    return (
        <React.Fragment>
            <div className='ele_row'>
                <h4 className="mb-3 navbar_menu">Vessel Sensor</h4>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mb-3"
                    data-toggle="modal"
                    data-target=".bs-example-modal-xl"
                    onClick={() => {
                        setModalState(true);
                    }}
                >
                    <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                    Add New
                </button>
            </div >
            <div className="table-responsive">
                {VesselSensorLoading && <Loading message="Loading required data!" />}
                {VesselSensorError && <ErrorComponent message="Unable to load required data!" />}

                {!VesselSensorLoading && !VesselSensorError && (
                    VesselSensor?.length > 0 ? (
                        VesselSensor.filter((value: any, index: number, self: any) =>
                            index === self.findIndex((t: any) => t.reporting_sensor_precedence_id === value.reporting_sensor_precedence_id)
                        ).map((vesselSensor: any, index: any) => (
                            <React.Fragment key={refreshKey}>
                                <h4>{vesselSensor.reporting_sensor_name}</h4>
                                <table className="table">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align-middle sr-no-width">#</th>
                                            <th className="p-2 align-middle">Sensor Name</th>
                                            <th className="p-2 align-middle">Vessel Machinery</th>
                                            <th className="p-2 align-middle">Sensor Unit</th>
                                            <th className="p-2 align-middle text-center">
                                                Actions <i className="bx bx-info-circle ml-2p" id="action_msg" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {VesselSensor.filter((sensor: any) =>
                                            sensor.reporting_sensor_precedence_id === vesselSensor.reporting_sensor_precedence_id
                                        ).map((vesselSensor: any, sensorIndex: number) => (
                                            <tr key={vesselSensor.id}>
                                                <td className="p-2 align-middle text-center">{sensorIndex + 1}</td>
                                                <td className="p-2 align-middle">{vesselSensor.vessel_sensor_name}</td>
                                                <td className="p-2 align-middle">{vesselSensor.vessel_machinery_name}</td>
                                                <td className="p-2 align-middle">{vesselSensor.sensor_name}</td>
                                                <td className="p-2 pb-0 align-middle text-center">
                                                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                                                        <button
                                                            type="button"
                                                            className="btn p-0 px-2"
                                                            onClick={() => {
                                                                setModalState(true);
                                                                setEditID(vesselSensor.id);
                                                            }}
                                                        >
                                                            <img
                                                                alt="Edit details"
                                                                className="pointer mb-2"
                                                                src={EditIcon}
                                                                height="25"
                                                                id={`Edit_Fc_Vessel_Machinery_${sensorIndex}`}
                                                            />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            id={`DeleteSavedSensor${index}_${sensorIndex}`}
                                                            className="btn p-0 px-2"
                                                            onClick={() => {
                                                                setSavedDeletePopOverState(true);
                                                                setDeleteIndex(index);
                                                                setDeleteObject(vesselSensor);
                                                                setDeleteSubIndex(sensorIndex)
                                                            }}
                                                        >
                                                            <i className="dripicons-trash icon_s18 fs-3"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        ))
                    ) : (
                        <div className='d-flex justify-content-center'><p>No vessel sensor configured for this vessel</p></div>
                    )
                )}

                {/* delete sensor record form server */}
                {savedDeletePopOverState &&
                    <PopOver
                        target={`DeleteSavedSensor${deleteIndex}_${deleteSubIndex}`}
                        handleClick={async () => await removeSensor()}
                        message={`Are you sure you want to delete this vessel sensor record permanently`}
                        state={savedDeletePopOverState}
                        setState={setSavedDeletePopOverState}
                    />
                }
                <FormValuesDebug values={[SensorMasterFormik.values, SensorMasterFormik.errors, SensorMasterFormik.initialValues]} />
            </div>
            {/* add or edit sensor modal */}
            {
                modalState &&
                <AddSensorModal
                    state={modalState}
                    setState={setModalState}
                    editID={editID}
                    setParentRefreshKey={setRefreshKey}
                    parentRefreshkey={refreshKey}
                />
            }
        </React.Fragment >
    )
}

export default AdditionalConfiguration