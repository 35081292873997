import { FieldArray, Field, FieldProps, useFormikContext } from 'formik';
import { RootState } from 'index';
import React from 'react'
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

interface VesselFuelTableType {
    fuel_class_precedence_id: number,
    fuel_class_name: string,
    refreshKey?: number
}

const VesselFuelTable = ({
    fuel_class_precedence_id,
    fuel_class_name,
    refreshKey
}: VesselFuelTableType) => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const { values, handleBlur } = useFormikContext<{ [key: string]: { [key: string]: string | number | boolean | Date | null }[] }>();
    // Get selected fuel classes
    /** State variables end */

    return (
        <div className="table-responsive p-0">
            <table className="table mb-2" key={VesselID}>
                <thead className="table-light">
                    <tr>
                        <th className="p-2 align-middle sr-no-width">
                            #
                        </th>
                        <th className="p-2 align-middle">
                            {fuel_class_name}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="vesselFuels">
                        {() => {
                            const filteredFuels = values?.vesselFuels?.filter(
                                (item) => item.fuel_class_precedence_id === fuel_class_precedence_id
                            );
                            return (
                                <React.Fragment key={refreshKey}>
                                    {filteredFuels && filteredFuels.length > 0 ? (
                                        filteredFuels.map(
                                            (
                                                value: {
                                                    [key: string]: string | number | boolean | Date | null;
                                                },
                                                index: number
                                            ) => {
                                                const fieldIndex = values?.vesselFuels.indexOf(value);
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Field name={`vesselFuels.${fieldIndex}.selected`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="checkbox"
                                                                        id={`selected_${fieldIndex}`}
                                                                        name={field.name}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                            form.setFieldValue(field.name, e.target.checked)
                                                                        }
                                                                        defaultChecked={value?.selected as boolean}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </td>
                                                        <td>
                                                            <Label
                                                                className="mb-0"
                                                                htmlFor={`selected_${fieldIndex}`}
                                                            >
                                                                {value?.fuel_type_name as string}
                                                            </Label>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>No fuels available for the selected category</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </FieldArray>

                </tbody>
            </table>
        </div>
    )
}

export default VesselFuelTable