import React, { useEffect, useState } from "react";
import { Col, Row, Input, Label, Form, Button } from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, FieldProps } from "formik";
import "../../../global/GlobalCSS.css";
import "flatpickr/dist/themes/material_blue.css";
import { apiMedia } from '../../../global/api.global';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { loadHullTypes, loadVesselGenInfoObject, loadVesselOwners } from 'VesselMaster/vesselMaster.hooks';
import { errorToast, successToast } from '../../../Components/Toasts';
import { customStyle } from '../../../shared/CommonCSS';
import { errResponse, searchPorts } from 'GenericForms/Helper';
import { VesselGenInfoMessages } from 'Components/ValidationErrorMessages';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { queryClient } from 'react-query/queryClient';
import { RootState } from 'index';
import Loading from 'Components/Loading';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import env from 'environment_system/env_system'
import ErrorTooltip from "Components/ErrorTooltip";
// import ReportingFileUpload from "Components/ReportingFileUpload";
import { FileItem } from "shared/CommonInterface";
import Select from "react-select";
import { isConfigurationButtonDisabled } from 'GenericForms/Helper';
// import { VesselImages } from "shared/constants";

interface VesselGeneralInfoTypes {
    toggleTab?: (activeTab: number) => void,
    activeTab?: number | string,
    VesselConfActiveTab?: number,
}

const VesselGeneralInfo = ({
    toggleTab,
    activeTab,
}: VesselGeneralInfoTypes) => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector(
        (state: RootState) => state.Reporting
    );
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [images, setImages] = useState<FileItem[]>([]);
    // const [refreshKey, setRefreshKey] = useState<number>(0)
    // const DeleteFileObj: any = {
    //     image_file: null,
    // }
    // const imageLength = images.filter(image => image.file).length;
    /** State variables end */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSetFile = (files: any[]) => {
        const adaptedFiles: FileItem[] = files.map(file => ({
            name: file.name || '',
            preview: file.preview || '',
            file: file.file || null,
        }));
        setImages(adaptedFiles); // Update the state with the transformed data
    };
    /** Queries start */
    /** Vessel general info object used for edit */
    const {
        data: VesselGenInfoObject,
        isLoading: VesselGenInfoObjectLoading,
        isError: VesselGenInfoObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.VesselGenInfoObject.key, VesselID],
        async () => {
            return await loadVesselGenInfoObject(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** Queries end */
    // const isConfigurationButtonDisabled = (): boolean => {
    //     if (vessel?.onboarding_status === OnboardingStatus.CONFIGURED || vessel?.onboarding_status === OnboardingStatus.ONBOARDED) {
    //         return true
    //     }
    //     if (hasRole(Roles.MANAGER) || hasRole(Roles.MASTER)) {
    //         return true
    //     }
    //     return false
    // }
    /**useEffect start */
    useEffect(() => {
        VesselGenInfoFormik.initialValues = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselGenInfoObject]);

    /** useEffect end */
    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) {
            return VesselGenInfoObject[0];
        } else {
            return {
                call_sign: "",
                date_of_built: null,
                official_number: "",
                hull_number: "",
                dead_weight: null,
                vessel_description: "",
                images: null,
                vessel: vessel?.id,
                country_name: null,
                port_of_registry: null,
                vessel_owner: null,
                hull_type_name: null,
                gross_tonage: null,
                net_tonage: null,
                normal_ballast_fwd_draft: 0,
                normal_ballast_mid_draft: 0,
                normal_ballast_aft_draft: 0,
                normal_laden_fwd_draft: 0,
                normal_laden_mid_draft: 0,
                normal_laden_aft_draft: 0,
            };
        }
    };

    /** Vessel General Info Formik Object */
    const VesselGenInfoFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object().shape({
            call_sign: Yup.string().nullable(),
            //     .matches(
            //     /^[A-Za-z0-9][a-zA-Z0-9-_.]{1,7}$/,
            //     VesselGenInfoMessages.call_sign
            // ),
            official_number: Yup.string().nullable(),
            // .matches(
            //     /^[0-9]{1,10}$/,
            //     VesselGenInfoMessages.official_number
            // ),
            hull_number: Yup.string().nullable(),
            //     .matches(
            //     /^[A-Za-z0-9][a-zA-Z0-9-_.]{11}$/,
            //     "Please enter 12 aplphabets/digits only"
            // ),
            dead_weight: Yup.string()
                .matches(/^[0-9]+$/, "Please enter digits only")
                .required(VesselGenInfoMessages.dead_weight
                ),
            gross_tonage: Yup.string()
                .required("This field is required")
                .matches(/^[0-9]+$/, "Please enter digits only")
                .max(6, "Please enter a maximum of 6 digits"
                ),
            net_tonage: Yup.string()
                .required("This field is required")
                .matches(/^[0-9]+$/, "Please enter digits only")
                .max(6, "Please enter a maximum of 5 digits"
                ),
        }),
    }

    return (
        <React.Fragment>
            {VesselGenInfoObjectLoading && (
                <Loading message="Loading required info!" />
            )}
            {VesselGenInfoObjectError && getInitialValues()}
            {!VesselGenInfoObjectLoading && (
                <Formik
                    initialValues={VesselGenInfoFormik.initialValues}
                    validationSchema={VesselGenInfoFormik.validationSchema}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true); // Start spinner
                        const formData = new FormData();
                        Object.keys(values).forEach((item: any) => {
                            if (values[item] === null || undefined) return; // if null assign blank string
                            formData.append(item, values[item]);// else continue with value
                        });
                        images.forEach((image: any) => {
                            formData.append("image", image);
                        })
                        const savePromise = VesselGenInfoObject[0]?.id
                            ? apiMedia.put(`vessel_general_information/${VesselGenInfoObject[0]?.id}/`, formData)
                            : apiMedia.post(`vessel_general_information/`, formData);
                        savePromise
                            .then((res) => {
                                if (res.status === 200 || 201) {
                                    successToast("Data saved successfully!");
                                    queryClient.invalidateQueries(queryKeyes.vessel.VesselGenInfoObject.key);
                                    actions.setSubmitting(false);
                                    if (env?.form_validation === true) {
                                        toggleTab(activeTab as number + 1);
                                    }
                                }
                            })
                            .catch((err) => {
                                actions.setSubmitting(false);
                                if (errResponse.includes(err?.response?.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                    }}>
                    {props => {
                        return (
                            <Form onSubmit={props?.handleSubmit} autoComplete='off' noValidate>
                                <Row className='mb-2'>
                                    <Col lg={4}>
                                        <Label>Vessel name</Label>
                                        <h5>{vessel?.vessel_name}</h5>
                                    </Col>
                                    <Col lg={4}>
                                        <Label>IMO number</Label>
                                        <h5>{vessel?.IMO_number}</h5>
                                    </Col>
                                    <Col lg={4}>
                                        <Label>Vessel type</Label>
                                        <h5>{vessel?.vessel_type}</h5>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col lg={3}>
                                        <Label>Select flag administrator</Label>
                                        <h5>{props?.values?.country}</h5>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0 asteric">Enter port of registry</Label>
                                        <Field name="port_of_registry">
                                            {({ field }: FieldProps) => (
                                                <AsyncSelect
                                                    name={field.name}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={(e: any) => searchPorts(e)}
                                                    getOptionLabel={(e: any) => e.port_name}
                                                    getOptionValue={(e: any) => e.id}
                                                    onChange={(selectedOption: any) => {
                                                        props?.setFieldValue(field.name, selectedOption.id)
                                                        props?.setFieldValue('country_name', selectedOption.country_name)
                                                        props?.setFieldValue('country', selectedOption.country)
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={customStyle}
                                                    defaultValue={
                                                        VesselGenInfoObject &&
                                                        VesselGenInfoObject[0]?.id > 0 && {
                                                            id: VesselGenInfoObject[0]?.port_of_registry,
                                                            port_name: VesselGenInfoObject[0]?.port_name,
                                                        }
                                                    }
                                                    noOptionsMessage={(e: any) => {
                                                        if (e?.inputValue?.toString()?.length > 2) {
                                                            return "Please select the Other option and enter the port name in the textbox provided";
                                                        }
                                                        return "Please enter the first 3 characters of port name";
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0">Select vessel owner</Label>
                                        <Field name="vessel_owner">
                                            {({ field }: FieldProps) => (
                                                <AsyncSelect
                                                    name={field.name}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={loadVesselOwners}
                                                    getOptionLabel={(e: any) => e.company_name}
                                                    getOptionValue={(e: any) => e.id}
                                                    onChange={(e: any) =>
                                                        props?.setFieldValue(field.name, e.id)
                                                    }
                                                    menuPortalTarget={document.body}
                                                    defaultValue={
                                                        VesselGenInfoObject &&
                                                        VesselGenInfoObject[0]?.id > 0 && {
                                                            id: VesselGenInfoObject[0]?.vessel_owner,
                                                            company_name:
                                                                VesselGenInfoObject[0]?.vessel_owner_name,
                                                        }
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0">Call sign</Label>
                                        <Field
                                            type="text"
                                            name="call_sign"
                                            id="call_sign"
                                            className="form-control max-width-8"
                                        />
                                        {props?.errors && env?.form_validation === true && props?.touched?.call_sign && (
                                            <ErrorTooltip
                                                target="call_sign"
                                                message={props?.errors?.call_sign as string}
                                                open={
                                                    props?.errors?.call_sign as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col lg={3}>
                                        <Label className="mb-0">Year of Built</Label>
                                        <Field name="date_of_built" className="p-0 ">
                                            {({ field, form }: FieldProps) => (
                                                <Select
                                                    name={field.name}
                                                    id={field.name} // Provide the id for accessibility
                                                    value={field.value ? { value: field.value, label: field.value } : null}
                                                    onChange={(selectedOption) => {
                                                        const value = selectedOption ? selectedOption.value : '';
                                                        form.setFieldValue(field.name, value); // Update the form state directly
                                                    }}
                                                    options={Array.from({ length: 100 }, (_, i) => {
                                                        const year = new Date().getFullYear() - i;
                                                        return { value: year, label: year.toString() }; // Ensure `label` is a string
                                                    })}
                                                    placeholder="Select Year"
                                                    filterOption={(candidate, input) => {
                                                        if (!input) return true; // Show all options if there's no input
                                                        const inputValue = typeof input === 'string' ? input.toLowerCase() : '';
                                                        const candidateValue = typeof candidate.label === 'string' ? candidate.label.toLowerCase() : '';
                                                        return candidateValue.includes(inputValue);
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0">Official number</Label>
                                        <Field
                                            type="text"
                                            name="official_number"
                                            id="official_number"
                                            className="form-control "
                                        />
                                        {props?.errors && env?.form_validation === true && props?.touched?.official_number && (
                                            <ErrorTooltip
                                                target="official_number"
                                                message={props?.errors?.official_number as string}
                                                open={
                                                    props?.errors?.official_number as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0">Select hull type</Label>
                                        <Field name="hull_type_name">
                                            {({ field }: FieldProps) => (
                                                <AsyncSelect
                                                    name={field.name}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={loadHullTypes}
                                                    getOptionLabel={(e: any) => e.hull_type_name}
                                                    getOptionValue={(e: any) => e.id}
                                                    onChange={(e: any) =>
                                                        props?.setFieldValue(field.name, e.id)
                                                    }
                                                    menuPortalTarget={document.body}
                                                    defaultValue={
                                                        VesselGenInfoObject &&
                                                        VesselGenInfoObject[0]?.id > 0 && {
                                                            id: VesselGenInfoObject[0]?.hull_type_name,
                                                            hull_type_name: VesselGenInfoObject[0]?.hull_type,
                                                        }
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col lg={3}>
                                        <Label className="mb-0">Hull number</Label>
                                        <Field
                                            type="text"
                                            name="hull_number"
                                            id="hull_number"
                                            className="form-control max-width-8"
                                        />
                                        {props?.errors && env?.form_validation === true && props?.touched?.hull_number && (
                                            <ErrorTooltip
                                                target="hull_number"
                                                message={props?.errors?.hull_number as string}
                                                open={
                                                    props?.errors?.hull_number as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Row>
                                        <Col lg={2}>
                                            <Label className="mb-0 asteric">Deadweight</Label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    name="dead_weight"
                                                    id="dead_weight"
                                                    className="form-control max-width-8 text-right"
                                                />
                                                <div className="input-group-text">
                                                    mt
                                                </div>
                                            </div>
                                            {props?.errors && env?.form_validation === true && props?.touched?.dead_weight && (
                                                <ErrorTooltip
                                                    target="dead_weight"
                                                    message={props?.errors?.dead_weight as string}
                                                    open={
                                                        props?.errors?.dead_weight as string
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col lg={2}>
                                            <Label className="mb-0 asteric">Gross tonnage</Label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    name="gross_tonage"
                                                    id="gross_tonage"
                                                    className="form-control max-width-8 text-right"
                                                />
                                                <div className="input-group-text">
                                                    mt
                                                </div>
                                            </div>
                                            {props?.errors && env?.form_validation === true && props?.touched?.gross_tonage && (
                                                <ErrorTooltip
                                                    target="gross_tonage"
                                                    message={props?.errors?.gross_tonage as string}
                                                    open={
                                                        props?.errors?.gross_tonage as string
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col lg={2} >
                                            <Label className="mb-0 asteric">Net tonnage</Label>
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    name="net_tonage"
                                                    id="net_tonage"
                                                    className="form-control max-width-8 text-right"
                                                />
                                                <div className="input-group-text">
                                                    mt
                                                </div>
                                            </div>
                                            {props?.errors && env?.form_validation === true && props?.touched?.net_tonage && (
                                                <ErrorTooltip
                                                    target="net_tonage"
                                                    message={props?.errors?.net_tonage as string}
                                                    open={
                                                        props?.errors?.net_tonage as string
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col lg={4}>
                                            <Label className="mb-0 ms-2">Vessel description</Label>
                                            <Field name="vessel_description" className="ms-2">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        name={field.name}
                                                        type="text"
                                                        id="vessel_description"
                                                        className="form-control ms-2"
                                                        value={field.value}
                                                        onChange={field.onChange} // Ensure changes are handled
                                                        onBlur={field.onBlur} // Handle blur events
                                                        rows={4}
                                                    />
                                                )}
                                            </Field>
                                            {props?.errors && env?.form_validation === true && props?.touched?.vessel_description && (
                                                <ErrorTooltip
                                                    target="vessel_description"
                                                    message={props?.errors?.vessel_description as string}
                                                    open={!!props?.errors?.vessel_description}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Row>

                                <Row className="mt-2">
                                    <Col lg={6}>
                                        <h5>Normal ballast draft</h5>
                                    </Col>
                                    <Col lg={6}>
                                        <h5>Normal laden draft</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>
                                        <Label className='mb-0 '>Fwd draft </Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_ballast_fwd_draft"
                                                id="normal_ballast_fwd_draft"
                                                className="form-control max-width-8 text-right"
                                            /><div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_ballast_fwd_draft && (
                                            <ErrorTooltip
                                                target="normal_ballast_fwd_draft"
                                                message={props?.errors?.normal_ballast_fwd_draft as string}
                                                open={
                                                    props?.errors?.normal_ballast_fwd_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={2}>
                                        <Label className='mb-0'>Mid draft </Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_ballast_mid_draft"
                                                id="normal_ballast_mid_draft"
                                                className="form-control max-width-8 text-right"
                                            />
                                            <div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_ballast_mid_draft && (
                                            <ErrorTooltip
                                                target="normal_ballast_mid_draft"
                                                message={props?.errors?.normal_ballast_mid_draft as string}
                                                open={
                                                    props?.errors?.normal_ballast_mid_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={2}>
                                        <Label className='mb-0 '>Aft draft </Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_ballast_aft_draft"
                                                id="normal_ballast_aft_draft"
                                                className="form-control max-width-8 text-right"
                                            />
                                            <div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_ballast_aft_draft && (
                                            <ErrorTooltip
                                                target="normal_ballast_aft_draft"
                                                message={props?.errors?.normal_ballast_aft_draft as string}
                                                open={
                                                    props?.errors?.normal_ballast_aft_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={2}>
                                        <Label className="mb-0 ">Fwd draft</Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_laden_fwd_draft"
                                                id="normal_laden_fwd_draft"
                                                className="form-control max-width-8 text-right"
                                            />
                                            <div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_laden_fwd_draft && (
                                            <ErrorTooltip
                                                target="normal_laden_fwd_draft"
                                                message={props?.errors?.normal_laden_fwd_draft as string}
                                                open={
                                                    props?.errors?.normal_laden_fwd_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={2}>
                                        <Label className="mb-0 ">Mid draft</Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_laden_mid_draft"
                                                id="normal_laden_mid_draft"
                                                className="form-control max-width-8 text-right"
                                            /><div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_laden_mid_draft && (
                                            <ErrorTooltip
                                                target="normal_laden_mid_draft"
                                                message={props?.errors?.normal_laden_mid_draft as string}
                                                open={
                                                    props?.errors?.normal_laden_mid_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col lg={2}>
                                        <Label className="mb-0 ">Aft draft</Label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                name="normal_laden_aft_draft"
                                                id="normal_laden_aft_draft"
                                                className="form-control max-width-8 text-right"
                                            /><div className="input-group-text">
                                                m
                                            </div>
                                        </div>
                                        {props?.errors && env?.form_validation === true && props?.touched?.normal_laden_aft_draft && (
                                            <ErrorTooltip
                                                target="normal_laden_aft_draft"
                                                message={props?.errors?.normal_laden_aft_draft as string}
                                                open={
                                                    props?.errors?.normal_laden_aft_draft as string
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                                {/* <Row className='mb-2 pt-2'>
                                    <Card>
                                        <ReportingFileUpload
                                            setFile={handleSetFile}
                                            file={images}
                                            fileUploadStatus={false}
                                            fileURL={'image_path'}
                                            deleteURL={`/vessel_general_information/`}
                                            invalidateQuery={queryKeyes.vessel.VesselGenInfoObject.key}
                                            deleteID={vessel?.id}
                                            refreshKey={refreshKey}
                                            setRefreshKey={setRefreshKey}
                                            isFileUploadDisable={VesselImages.maxFile === imageLength}
                                            DeleteFileObj={DeleteFileObj}
                                            index={0}
                                            isMultiple={true}
                                            maxFiles={VesselImages.maxFile}
                                            CustomAcceptableFileTypes={{
                                                'image/jpeg': ['.jpg', '.jpeg'],
                                                'image/png': ['.png']
                                            }}
                                        />
                                    </Card>
                                </Row> */}
                                <Row className="p-2">
                                    <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_6_cstm"
                                            disabled={isConfigurationButtonDisabled(vessel)
                                                || props?.isSubmitting
                                            }
                                        >
                                            {props?.isSubmitting &&
                                                (<i className="ms-2 spinner-border spinner-border-sm text-light" />)}
                                            Save
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="danger"
                                            className="btn_size_6_cstm"
                                            onClick={() => {
                                                props?.resetForm();
                                            }}
                                            disabled={isConfigurationButtonDisabled(vessel)}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </Row>
                                <FormValuesDebug
                                    values={[
                                        props?.values,
                                        props?.errors,
                                        VesselGenInfoFormik.initialValues,
                                    ]}
                                />
                            </Form>
                        )
                    }}
                </Formik>
            )}
        </React.Fragment >

    )
}

export default VesselGeneralInfo;
