import React, { useState } from 'react';
import { Card, NavItem, TabContent, TabPane, NavLink, Container, Nav, CardHeader, Row, Col } from "reactstrap";
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import classnames from "classnames";
import '../../global/GlobalCSS.css'
import GeneralSettings from './VesselMachinery/GeneralSettings/GeneralSetings';
import SFOC from './SFOC';
import Engines from './Engines';
import FuelMachineryConfiguration from './FuelMachineryConfiguration';
import SpecialOperations from './SpecialOperations/SpecialOperations';
import LubeOilMaster from './LubeOil/LubeOilMaster';
import SensorsMaster from './SensorsMaster';
import VesselDetailsHeader from '../../Components/VesselDetailsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselMachineryConfig from './VesselMachinery/VesselMachineryConfig';
import Other from './Other/Other';
import { VesselConfigrationTabs, VesselTypeConstant } from 'shared/constants';
import { RootState } from 'index';
import VesselFuelConfiguration from 'VesselMaster/VesselConfiguration/VesselFuelConfiguration/VesselFuelConfiguration';
import VesselData from './VesselData';
import SpeedConsumptionTable from './SpeedConsumptionTable';
import AdditionalConfiguration from './SensorMaster/SensorMaster';

const VesselConfiguration = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(VesselConfigrationTabs.VESSEL_DATA);
    const dispatch = useDispatch();
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: { [key: string]: string | number | boolean | Date | null }) => rec.id === VesselID);
    const [refreshVesselMachineries, setRefreshVesselMachineries] = useState(0);

    const toggle = (tab: number) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <Layout children={Navbar} />
                <div className="page-content">
                    <Container fluid>
                        <Card>
                            <CardHeader className="px-2 py-0">
                                <Row className='pb-2'>
                                    <Col sm="2" className="d-flex align-items-center">
                                        <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState(null) }}>
                                            <i className="bx bx-chevron-left me-1" /> Back
                                        </button>
                                    </Col>
                                    <Col sm="10">
                                        <VesselDetailsHeader />
                                    </Col>
                                </Row>
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.VESSEL_DATA,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.VESSEL_DATA);
                                            }}
                                        >
                                            Vessel Data
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.MACHINERY,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.MACHINERY);
                                            }}
                                        >
                                            Machinery
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.GENERAL_SETTINGS,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.GENERAL_SETTINGS);
                                            }}
                                        >
                                            General Settings
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SFOC,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.SFOC);
                                            }}
                                        >
                                            SFOC
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.VESSEL_FUEL,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.VESSEL_FUEL);
                                            }}
                                        >
                                            Vessel Fuel
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.MACHINERY_FUEL,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.MACHINERY_FUEL);
                                            }}
                                        >
                                            Machinery Fuel
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.ENGINES,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.ENGINES);
                                            }}
                                        >
                                            Engines
                                        </NavLink>
                                    </NavItem>
                                    {Object.values(VesselTypeConstant).includes(vessel?.vessel_type) &&
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeSettingsTab === VesselConfigrationTabs.SPECIAL_OPERATIONS,
                                                })}
                                                onClick={() => {
                                                    toggle(VesselConfigrationTabs.SPECIAL_OPERATIONS);
                                                }}
                                            >
                                                Special Operations
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.LUBE_OIL,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.LUBE_OIL);
                                            }}
                                        >
                                            Lube Oil
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SENSOR,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.SENSOR);
                                            }}
                                        >
                                            Sensors
                                        </NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.OTHER,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.OTHER);
                                            }}
                                        >
                                            Other
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SPEED_CONSUMPTION,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.SPEED_CONSUMPTION);
                                            }}
                                        >
                                            Speed & Consumption
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.ADDITIONAL_CONFIGURATION,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.ADDITIONAL_CONFIGURATION);
                                            }}
                                        >
                                            Sensors
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={activeSettingsTab}>
                                <TabPane tabId={VesselConfigrationTabs.MACHINERY} className='margin-16'>
                                    <VesselMachineryConfig
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.GENERAL_SETTINGS} className='margin-16'>
                                    <GeneralSettings
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SFOC} className='margin-16'>
                                    <SFOC
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.ENGINES} className='margin-16'>
                                    <Engines
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.VESSEL_FUEL} className='margin-16'>
                                    <VesselFuelConfiguration
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.MACHINERY_FUEL} className='margin-16'>
                                    <FuelMachineryConfiguration
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SPECIAL_OPERATIONS} className='margin-16'>
                                    <SpecialOperations
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.LUBE_OIL} className='margin-16'>
                                    <LubeOilMaster
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SENSOR} className='margin-16'>
                                    <SensorsMaster />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.OTHER} className='margin-16'>
                                    <Other VesselConfActiveTab={activeSettingsTab} />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.VESSEL_DATA} className='margin-16'>
                                    <VesselData
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SPEED_CONSUMPTION} className='margin-16'>
                                    <SpeedConsumptionTable
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.ADDITIONAL_CONFIGURATION} className='margin-16'>
                                    <AdditionalConfiguration />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default VesselConfiguration;
