import axios, { AxiosError } from "axios";
import env from "../environment_system/env_system";
export const BaseURL = env.base_url;
export const MediaBaseURL = env.media_base_url ?? "";
type authDataType = {
  token: string;
  roles: string[];
  user_id: number;
  first_name: string;
  last_name: string;
  user_name: string;
}
let authData: authDataType;
if (localStorage.getItem("authData") !== null) {
  authData = JSON.parse(localStorage.getItem("authData") ?? "");
}
let localToken: string = "";

if (authData) {
  localToken = `${env.token_prefix} ${authData.token ?? ""}`;
}

export const GlobalToken = localToken;

const apiGlobal = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-type": "application/json",
    Authorization: `${GlobalToken}`,
  },
  //withCredentials: true
});

export const apiMedia = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `${GlobalToken}`,
  },
});

export const handleApiError = (error: AxiosError) => {
  try {
    //errorToast('API Request failed:' + error.message);
    console.error("API Request failed:", error.message);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (status === 404) {
        console.error("Resource not found");
      } else if (status === 500) {
        console.error("Internal server error");
      } else if (status === 400) {
        console.error("Bad request");
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export default apiGlobal;
