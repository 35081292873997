import React, { useState } from 'react';
import { Formik, FieldArray, Field, Form, FieldProps } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { CardBody, Row, Col, Label, CardFooter, Button } from 'reactstrap';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { successToast } from 'Components/Toasts';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import apiGlobal from 'global/api.global';
import { loadDistinctMachinary } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import ErrorTooltip from 'Components/ErrorTooltip';
import { queryClient } from 'react-query/queryClient';
import { useQuery } from 'react-query';

interface VesselMachinery {
    id: number;
    name: string;
    status: boolean;
}

interface DynamicGroupFormProps {
    group: string;
    VesselID: number;
    machineryList: VesselMachinery[];
    isLoading: boolean;
    isError: boolean;
    refreshState: boolean;
    setRefreshState: (value: boolean) => void;
    groupURL: string;
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
}

const DynamicGroupForm = ({
    group,
    VesselID,
    machineryList,
    isLoading,
    isError,
    refreshState,
    setRefreshState,
    groupURL,
    refreshVesselMachineries,
    setRefreshVesselMachineries,
}: DynamicGroupFormProps) => {

    //useState for delete machinery
    const [selectedMachineryId, setSelectedMachineryId] = useState<number | null>(0);
    const [refreshMachineryList, setRefreshMachineryList] = useState<number | null>(0);
    // Initial form values
    const initialValues = groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? {
        vessel_machinery_ec_group_name: '',
        vessel: VesselID,
        machinery: null as number | null,
        vessel_machinery: [] as number[],
    } : {
        vessel_machinery_fc_group_name: '',
        vessel: VesselID,
        machinery: null as number | null,
        vessel_machinery: [] as number[],
    };

    /**
     * Fetches the list of vessel machineries for a given vessel.
     * This query is performed using React Query and utilizes caching.
     */
    const {
        data: vesselMachineriesList,
        isLoading: vesselMachineriesListLoading,
        isError: vesselMachineriesListError,
    }: { data: any, isLoading: any, isError: any } = useQuery(
        [queryKeyes.vessel.DistinctMachinary.key, VesselID],
        async () => {
            return await loadDistinctMachinary(VesselID);
        },
        { staleTime: Infinity }
    );

    // Define validation schema using Yup
    const validationSchema = groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? Yup.object().shape({
        vessel_machinery_ec_group_name: Yup.string().required('Group name is required'),
    }) : Yup.object().shape({
        vessel_machinery_fc_group_name: Yup.string().required('Group name is required'),
    });

    /**
     * Handle form submission
     * @param values - The form values submitted by the user
     * @param actions - Formik actions for managing form state
     */
    const handleSubmit = async (values: typeof initialValues, actions: any) => {
        try {
            await apiGlobal.post(`${groupURL}`, values);
            successToast('Group created successfully');
            actions.resetForm();
            setRefreshState(!refreshState);
            if (group === 'fuel') {
                queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
            } else {
                queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
            }
            setRefreshVesselMachineries(refreshVesselMachineries + 1);
            actions.setSubmitting(false);
        } catch (err) {
            console.error('Error submitting form:', err);
            actions.setSubmitting(false);
        }
    };

    // Render loading or error components as needed
    if (isLoading) return <Loading message="Loading required data..." />;
    if (isError) return <ErrorComponent message="Unable to load required data!" />;

    return (
        <React.Fragment>
            {vesselMachineriesListLoading && <Loading message="Loading required data!" />}
            {vesselMachineriesListError && <ErrorComponent message="Error loading required data!" />}
            {!vesselMachineriesListLoading && !vesselMachineriesListError &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(true);
                        handleSubmit(values, actions);
                    }}
                >
                    {({ values, setFieldValue, isSubmitting, errors }) => (
                        <div className="form-container ps-4 pe-4">
                            <Form noValidate autoComplete="off">
                                {/* Group Name Input */}
                                <div className="form-group mb-2">
                                    <Label for={groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? "vessel_machinery_ec_group_name" : "vessel_machinery_fc_group_name"} className="mb-0">
                                        Group name
                                    </Label>
                                    <Field
                                        type="text"
                                        name={groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? "vessel_machinery_ec_group_name" : "vessel_machinery_fc_group_name"}
                                        id={groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? "vessel_machinery_ec_group_name" : "vessel_machinery_fc_group_name"}
                                        placeholder="Enter group name"
                                        className="form-control max-width-15"
                                    />
                                    <ErrorTooltip
                                        target={groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? "vessel_machinery_ec_group_name" : "vessel_machinery_fc_group_name"}
                                        message={errors && groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ? errors?.vessel_machinery_ec_group_name : errors.vessel_machinery_fc_group_name}
                                        open={
                                            (errors && groupURL === queryKeyes.vessel.MachinaryEnergyGroup.url ?
                                                errors?.vessel_machinery_ec_group_name : errors.vessel_machinery_fc_group_name && refreshState) ?
                                                true : false
                                        }
                                        key={refreshMachineryList}
                                    />
                                </div>
                                {/* Machinery Selection Dropdown */}
                                <div className="form-group mb-2">
                                    <Label for="machinery" className="mb-0">
                                        Select machinery
                                    </Label>
                                    <Field name="machinery">
                                        {({ field, form }: FieldProps) => (
                                            <Select
                                                name={field.name}
                                                inputId={field.name}
                                                options={vesselMachineriesList}
                                                getOptionLabel={(option: any) => option.machinery}
                                                getOptionValue={(option: any) => option.machinery_name}
                                                className="max-width-15"
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue(field.name, selectedOption.machinery_name);
                                                    setSelectedMachineryId(selectedOption.precedence_id);
                                                    setRefreshMachineryList(refreshMachineryList + 1);
                                                    form.setFieldValue("vessel_machinery", []);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </div>
                                {/* Machinery Checkbox List */}
                                <CardBody className="p-0">
                                    <Row>
                                        <Col className="ps-2 pe-2">
                                            <div className="table-responsive mb-0">
                                                <table className="table table-striped table-bordered w-100" key={refreshMachineryList}>
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="text-center">#</th>
                                                            <th>Machinery List</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name="vessel_machinery">
                                                            {({ form: { setFieldValue } }) => (
                                                                machineryList
                                                                    .filter((machinery: any) => machinery.precedence_id === selectedMachineryId)
                                                                    .map((machinery: any, index: number) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center align-middle">{index + 1}</td>
                                                                            <td className="align-middle">
                                                                                <div className="d-flex align-items-center">
                                                                                    <Field name="vessel_machinery">
                                                                                        {({ field }: FieldProps) => {
                                                                                            // Ensure field.value is an array
                                                                                            const currentValues = Array.isArray(field.value) ? field.value : [];
                                                                                            const isChecked = currentValues.includes(machinery.id);

                                                                                            return (
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={`checkbox-${machinery.id}`}
                                                                                                        className="form-check-input"
                                                                                                        checked={isChecked}
                                                                                                        onChange={(e) => {
                                                                                                            const updatedValues = e.target.checked
                                                                                                                ? [...currentValues, machinery.id]
                                                                                                                : currentValues.filter((id: number) => id !== machinery.id);
                                                                                                            setFieldValue('vessel_machinery', updatedValues);
                                                                                                        }}
                                                                                                    />
                                                                                                    <Label for={`checkbox-${machinery.id}`} className="ms-2 mb-0">
                                                                                                        {machinery.vessel_machinery_name}
                                                                                                    </Label>
                                                                                                </div>
                                                                                            );
                                                                                        }}
                                                                                    </Field>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                {/* Form Actions */}
                                <CardFooter className="p-2 py-3 mb-3">
                                    <Row>
                                        <Col>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="float-end"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                                {/* Debug Values */}
                                <Row>
                                    <FormValuesDebug values={[values, initialValues]} />
                                </Row>
                            </Form>
                        </div>
                    )}
                </Formik>
            }
        </React.Fragment>
    );
};

export default DynamicGroupForm;