import DeletePopOver from 'Components/DeletePopOver';
import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import env from 'environment_system/env_system';
import { useFormik } from 'formik';
import { errResponse, handleServerResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { VesselConfigrationTabs } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadVesselReportingEmail } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from "yup";
interface EmailReportingType {
    activeTab: string,
    VesselConfActiveTab: number
}

const EmailReporting = ({ activeTab, VesselConfActiveTab }: EmailReportingType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [popOverBool, setPopOverBool] = useState<boolean | null>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);

    /** Queries */
    const { data: emailReportingObject, isLoading: emailReportingObjectLoading, isError: emailReportingObjectError } = useQuery(
        [queryKeyes.vessel.VesselReportingEmail.key, VesselID],
        async () => {
            return await loadVesselReportingEmail(VesselID);
        },
        { staleTime: Infinity }
    );

    /** Assign initial values */
    const getInitialValues = () => {
        if (emailReportingObject && emailReportingObject.length > 0) {
            let emails: any = [];
            emailReportingObject.forEach((email: any) => {
                if (email.display === true){
                    emails.push(email);
                }
            });
            return { emails: emails };
        } else {
            return {
                emails: [
                    {
                        name: null,
                        email: null,
                        vessel: VesselID as number || null,
                    }
                ]
            };
        }
    };

    const handleDelete = (index: number) => {
        const emailToDelete = emailReportingFormik.values.emails[index];
        const updatedEmails = emailReportingFormik.values.emails.filter((_: any, i: any) => i !== index);
        if (emailToDelete.id) {
            // If the object has an ID, send a DELETE request
            apiGlobal
                .delete(`/${queryKeyes.vessel.VesselReportingEmail.url()}${emailToDelete.id}/`)
                .then(() => {
                    successToast("Email deleted successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingEmail.key);
                    // Update the emails list after successful deletion
                    emailReportingFormik.setFieldValue("emails", updatedEmails);
                })
                .catch((err) => {
                    if (errResponse.includes(err?.response?.status)) {
                        errorToast("Failed to delete email, please contact the admin");
                    }
                });
        } else {
            // If no ID, simply remove it from the Formik state
            emailReportingFormik.setFieldValue("emails", updatedEmails);
        }
    };

    const emailReportingFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            emails: Yup.array().of(
                Yup.object({
                    name: Yup.string().required("Name is required"),
                    email: Yup.string().email("Invalid email address").required("Email address is required"), 
                })
            ),
        }),
        onSubmit: async (values: any, actions: any) => {
            actions?.setSubmitting(true);
            // Iterate through emails and send separate requests
            const requests = values?.emails?.map((email: any) => {
                if (email.id) {
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingEmail.key);
                    // If an ID exists, send a PUT request
                    return apiGlobal.put(
                        `/${queryKeyes.vessel.VesselReportingEmail.url()}${email.id}/`,
                        email
                    );
                } else {
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingEmail.key);
                    // Otherwise, send a POST request
                    return apiGlobal.post(`/${queryKeyes.vessel.VesselReportingEmail.url()}`, email);
                }
            });
            await handleServerResponse(requests);
            queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingEmail.key);
            actions?.setSubmitting(false);
        },
    });

    useEffect(() => {
        emailReportingFormik.initialValues = getInitialValues();
        emailReportingFormik.values = getInitialValues();
        setRefreshKey(refreshKey + 1);
        queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingEmail.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailReportingObject, emailReportingFormik.initialValues, VesselID]);

    /** Add email entry */
    const addEmailEntry = () => {
        emailReportingFormik.setFieldValue("emails", [
            ...emailReportingFormik.values.emails,
            { name: "", email: "", vessel: VesselID as number || null },
        ]);
        emailReportingFormik.setFieldTouched(`emails.${emailReportingFormik.values.emails.length}`, true, false);
    };

    useEffect(() => {
        if ( activeTab !== "3") {
            emailReportingFormik?.setTouched({})
            emailReportingFormik?.setErrors({})
        }
        if (VesselConfActiveTab !== VesselConfigrationTabs.VESSEL_DATA ) {
            emailReportingFormik?.setTouched({})
            emailReportingFormik?.setErrors({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, VesselConfActiveTab,]);

    return (
        <React.Fragment>
            {emailReportingObjectLoading && <Loading message="Loading required data!" />}
            {emailReportingObjectError && <ErrorComponent message="Unable to load required data!" />}
            {!emailReportingObjectLoading && (
                <Card>
                    <CardBody>
                        <form onSubmit={emailReportingFormik.handleSubmit} key={refreshKey}>
                            {emailReportingFormik.values.emails.map((email: any, index: number) => (
                                <Row className="mb-3" key={index}>
                                    <Col lg={4}>
                                        <Label className="mb-1">Name</Label>
                                        <Input
                                            name={`emails.${index}.name`}
                                            id={`email_name_${index}`}
                                            value={email.name}
                                            onChange={(e) => {
                                                emailReportingFormik.setFieldValue(`emails.${index}.name`, e.target.value);
                                                emailReportingFormik.setFieldTouched(`emails.${index}.name`, true, false);
                                            }
                                            }
                                            className={`form-control`}
                                        />
                                        {
                                            emailReportingFormik?.touched?.emails?.[index]?.name &&
                                                emailReportingFormik?.errors?.emails?.[index]?.name && env?.form_validation === true ? (
                                                <ErrorTooltip
                                                    target={`email_name_${index}`}
                                                    message={emailReportingFormik.errors?.emails?.[index]?.name}
                                                    open={!!emailReportingFormik?.errors?.emails?.[index]?.name}
                                                />
                                            ) : null
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label className="mb-1">Email</Label>
                                        <Input
                                            name={`emails.${index}.email`}
                                            id={`email_email_${index}`}
                                            value={email.email}
                                            onChange={(e) => {
                                                emailReportingFormik.setFieldValue(`emails.${index}.email`, e.target.value);
                                                emailReportingFormik.setFieldTouched(`emails.${index}.email`, true, false);
                                            }}
                                            className={`form-control`}
                                        />
                                        {
                                            emailReportingFormik?.touched?.emails?.[index]?.email &&
                                                emailReportingFormik?.errors?.emails?.[index]?.email && env?.form_validation === true ? (
                                                <ErrorTooltip
                                                    target={`email_email_${index}`}
                                                    message={emailReportingFormik.errors?.emails?.[index]?.email}
                                                    open={!!emailReportingFormik?.errors?.emails?.[index]?.email}
                                                />
                                            ) : null
                                        }
                                    </Col>
                                    <Col lg={4} className="d-flex align-items-center">
                                        {emailReportingFormik.values.emails.length > 1 &&
                                            <button
                                                type="button"
                                                className="btn mt-4"
                                                name={`delete_${index}`}
                                            >
                                                <i
                                                    id={`delete_${index}`}
                                                    className="dripicons-trash icon_s18 cursor-pointer"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        // emailReportingFormik.setError({});
                                                        setDeleteIndex(index);
                                                        setPopOverBool(true);
                                                    }}
                                                />
                                            </button>
                                        }
                                        {index === emailReportingFormik.values.emails.length - 1 && emailReportingFormik.values.emails.length <= 9 && (
                                            <Button
                                                color="primary"
                                                type="button"
                                                className="ms-2 mt-3"
                                                onClick={addEmailEntry}
                                            >
                                                <i
                                                    className="dripicons-plus icon_s18 navbar_menu"
                                                />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                            {popOverBool && deleteIndex !== null &&
                                <DeletePopOver
                                    target={`delete_${deleteIndex}`}
                                    state={popOverBool}
                                    setState={setPopOverBool}
                                    onClick={async () => {
                                        await handleDelete(deleteIndex);
                                        setPopOverBool(false);
                                    }}
                                />
                            }
                            <Row className='mb-2 justify-content-end'>
                                <Col lg={1}>
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => emailReportingFormik.resetForm()}
                                    >
                                        Reset
                                    </Button>
                                </Col>
                                <Col lg={1}>
                                    <Button type="submit" color="primary">
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        <FormValuesDebug values={[emailReportingFormik.values, emailReportingFormik.errors, emailReportingFormik.initialValues]} />
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
};

export default EmailReporting;

