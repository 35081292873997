import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";

class ComplianceBalanceGraph extends Component<any> {
    Chart = () => {

        return {
            xAxis: {
                type: 'category',
                data: ['Jan-25', 'Feb-25', 'Mar-25', 'Apr-25', 'May-25', 'Jun-25', 'Jul-25', 'Aug-25', 'Sep-25', 'Oct-25', 'Nov-25', 'Dec-25'],
                axisLabel: {
                    interval: 0, // Show all labels
                    rotate: 45,  // Rotate labels if necessary
                },
            },
            yAxis: {
                type: 'value',
                interval: 5000000, // Set interval for gridlines
                axisLabel: {
                    formatter: '{value}',
                },
            },
            series: [
                {
                    data: this.props.graphData?.map((item: any) => {
                        return item?.fuel_eu_record?.cumulative_cb
                    }),
                    type: 'line',
                    // symbol: 'none',
                    lineStyle: {
                        width: 2,
                    },
                },
            ],
            grid: {
                bottom: 80,  // Adjust grid for rotated labels
            },
        };
    };

    render() {
        return (
            <Row>
                {this.props.graphData && this.props.graphData?.length > 0 ?
                    <ReactEcharts style={{ height: "350px" }} option={this.Chart()} /> :
                    <p className="mb-0">No data available</p>
                }
            </Row>
        );
    }
}

export default ComplianceBalanceGraph;
