import React, { useEffect, useState } from 'react';
import { Button, CardBody, Col, Modal, Row } from 'reactstrap';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { checkInvalidPrimaryKey } from 'GenericForms/Helper';
import BunkeringFileUpload from 'ReportingWizard/EngineerReport/partials/Bunkering/BunkeringFileUpload';
import Loading from 'Components/Loading';
import { GetPageWithID } from '../pendingpage.hook';
import PageNavButton from '../PageNavButton';
import { FileStatus } from 'shared/constants';

const PendingBDNFiles = () => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [modalState, setModalState] = useState(false);
    const [reportID, setReportID] = useState<any>({});
    const [pageUrl, setPageUrl] = useState("");
    const [refreshKey, setRefreshkey] = useState<number>(0);
    /** State variables end */

    useEffect(() => {
        if (!checkInvalidPrimaryKey(VesselID)) {
            return;
        }
        setPageUrl(queryKeyes.pagination.BDNPendingFilesPage.url(VesselID, FileStatus.PENDING));
    }, [VesselID]);

    /**Query for pending BDN files page with status pending */
    const { data: BDNBDNfileUploadingPage, isLoading: isLoadingBDNFileUploadingPage } = useQuery(
        [queryKeyes.pagination.BDNPendingFilesPage.key, pageUrl, VesselID],
        async () => await GetPageWithID(VesselID, pageUrl),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /**End of query  */

    /**
     * Adds a CSS class to the document's body to handle modal styling.
     */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /**
     * Toggles the state of the modal and applies the necessary body styling.
     */
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
     * Sets the modal state to open and stores the file name and object for uploading.
     * 
     * @param file - The file object associated with the selected BDN entry.
     */
    const handleUploadButtonClick = (record: any) => {
        setReportID(record.vessel_reporting_information_id)
        setModalState(!modalState);
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {isLoadingBDNFileUploadingPage && <Loading message='Loading required data!' />}
                </Col>
            </Row>
            {!isLoadingBDNFileUploadingPage &&
                <CardBody className="px-0 py-0 pb-0 mt-2">
                    <div className="table-responsive">
                        <table className="table mb-0" key={refreshKey}>
                            <thead className="table-light">
                                <tr>
                                    <th className="p-2 align-middle sr-no-width">#</th>
                                    <th className="p-2 align-middle">Date & Time(UTC)</th>
                                    <th className="p-2 align-middle">Name of Report</th>
                                    <th className="p-2 align-middle">BDN No.</th>
                                    <th className="p-2 align-middle">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {BDNBDNfileUploadingPage && BDNBDNfileUploadingPage?.results?.length > 0 ? (
                                    BDNBDNfileUploadingPage?.results?.map((record: any, index: number) => (
                                        <tr key={index}>
                                            <td className="p-2 align-middle text-center">{index + 1}</td>
                                            <td className="p-2 align-middle">{new Date(record.reporting_date).toUTCString()}</td>
                                            <td className="p-2 align-middle">{record.name_of_report}</td>
                                            <td className="p-2 align-middle">{record.bdn_identification_number || "N/A"}</td>
                                            <td className="p-2 align-middle">
                                                <Button
                                                    type="button"
                                                    className='btn-sm'
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(record)}>
                                                    View Files
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="p-2 align-middle text-center">
                                            No files are pending for upload
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {BDNBDNfileUploadingPage?.results?.length > 0 &&
                                    <tr>
                                        <td colSpan={5} className="p-2">
                                            <PageNavButton setPageUrl={setPageUrl} pageobj={BDNBDNfileUploadingPage} pageUrl={pageUrl} />
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </div>
                </CardBody>
            }
            <Modal
                size='xl'
                isOpen={modalState}
                toggle={() => tog_backdrop()}
                backdrop={true}
                id="staticBackdrop"
            >
                <button
                    onClick={async () => {
                        setModalState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <BunkeringFileUpload
                    ReportID={reportID}
                    VesselID={VesselID}
                    setModalState={setModalState}
                    modalState={modalState}
                    refreshKey={refreshKey}
                    setRefreshkey={setRefreshkey}
                />
            </Modal>
        </React.Fragment>
    );
};

export default PendingBDNFiles;