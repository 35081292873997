import ErrorTooltip from "Components/ErrorTooltip";
import Loading from "Components/Loading";
import { errorToast, successToast } from "Components/Toasts";
import { dateTimeFormat, errResponse } from "GenericForms/Helper";
import {
  loadPilotArrivalDetailsObject,
  loadPilotDepartureDetailsObject,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldProps, Formik } from "formik";
import apiGlobal from "global/api.global";
import env from "environment_system/env_system";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Row,
  Col,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { EventPrecedence, ReportingTypeConstant } from "shared/constants";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import * as Yup from 'yup';

interface PilotDetailsComponentType {
  record: any;
  toggleAddTab: any;
  activeAddTab: number;
  toggleTab: any;
  activeTab: number;
  VesselID: number;
  VoyageID: number;
  ReportID: number;
}

const PilotDetailsComponent = ({
  record,
  toggleAddTab,
  activeAddTab,
  toggleTab,
  activeTab,
  VesselID,
  VoyageID,
  ReportID,
}: PilotDetailsComponentType) => {
  /** State varibles */
  const [formikKey, setFormikKey] = useState(0);
  /** State variables end */

  /** Queries */
  /** Pilot Arrival Details Object used for edit */
  const {
    data: PilotArrivalDetailsObject,
    isLoading: PilotArrivalDetailsObjectLoading,
    isError: PilotArrivalDetailsObjectError,
  } = useQuery(
    [queryKeyes.vessel.LfondsPilotArrivalDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadPilotArrivalDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Pilot Departure Details Object used for edit */
  const {
    data: PilotDepartureDetailsObject,
    isLoading: PilotDepartureDetailsObjectLoading,
    isError: PilotDepartureDetailsObjectError,
  } = useQuery(
    [queryKeyes.vessel.LfondsPilotDepartureDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadPilotDepartureDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Queries end */

  /** Assign values to initial object of Pilot Details' object */
  const getInitialPilotDetailsValues = () => {
    if (PilotArrivalDetailsObject && PilotArrivalDetailsObject[0]?.id > 0) {
      return PilotArrivalDetailsObject[0];
    } else {
      return {
        pilot_onboard: null,
        pilot_disembarked: null,
        etb: null,
        first_line_ashore: null,
        all_made_fast: null,
        nor: null,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };

  /** Assign values to initial object of Pilot Details' object */
  const getInitialPilotDetailsDepartureValues = () => {
    let obj: any;
    if (PilotDepartureDetailsObject && PilotDepartureDetailsObject[0]?.id > 0) {
      return PilotDepartureDetailsObject[0];
    } else {
      obj = {
        is_this_departure_report: false,
        water_density: 0,
        bending_moment_frame: 0,
        gm: 0,
        shearing_force: 0,
        shearing_force_frame: 0,
        bending_moment: 0,
        torsional_moment: 0,
        all_cast_off: null,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
      return obj;
    }
  };

  /** UseEffect */
  useEffect(() => {
    PilotDetailsFormik.initialValues.arrivalDetails =
      getInitialPilotDetailsValues();
    PilotDetailsFormik.initialValues.departureDetails =
      getInitialPilotDetailsDepartureValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PilotArrivalDetailsObject, PilotDepartureDetailsObject]);
  useEffect(() => {
    setFormikKey(formikKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PilotArrivalDetailsObject, PilotDepartureDetailsObject]);
  /** UseEffect end */

  /** Pilot Details' formik object */
  const PilotDetailsFormik = {
    initialValues: {
      arrivalDetails: getInitialPilotDetailsValues(),
      departureDetails: getInitialPilotDetailsDepartureValues(),
    },
    validationSchema: Yup.object().shape({
      departureDetails: Yup.object().shape({
        water_density: Yup.number(),
        bending_moment_frame: Yup.number(),
        gm: Yup.number(),
        shearing_force: Yup.number(),
        shearing_force_frame: Yup.number(),
        bending_moment: Yup.number(),
        torsional_moment: Yup.number(),
      })
    })
  }
  return (
    <Card className="p-0 mb-0">
      <CardHeader className="p-2">
        <div className="text-center">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Pilot Details</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      {(PilotArrivalDetailsObjectLoading ||
        PilotDepartureDetailsObjectLoading) && (
          <Loading message="Loading required data!" />
        )}
      {PilotArrivalDetailsObjectError && getInitialPilotDetailsValues()}
      {PilotDepartureDetailsObjectError &&
        getInitialPilotDetailsDepartureValues()}
      {!PilotArrivalDetailsObjectLoading && (
        <Formik
          key={formikKey}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            const handleResponse = (response: any) => {
              if (response.status === 201 || response.status === 200) {
                successToast("Data saved successfully!");
              }
            };
            if (
              PilotArrivalDetailsObject &&
              PilotArrivalDetailsObject[0]?.id > 0
            ) {
              apiGlobal
                .put(
                  `/lfonds_pilot_details/${values?.arrivalDetails?.id}/`,
                  values?.arrivalDetails
                )
                .then((res) => {
                  handleResponse(res);
                })
                .catch((err) => {
                  if (errResponse.includes(err.response.status)) {
                    errorToast(
                      "Internal error occured, please contact the admin"
                    );
                  }
                });
            } else {
              apiGlobal
                .post(`/lfonds_pilot_details/`, values?.arrivalDetails)
                .then((res) => {
                  handleResponse(res);
                })
                .catch((err) => {
                  if (errResponse.includes(err.response.status)) {
                    errorToast(
                      "Internal error occured, please contact the admin"
                    );
                  }
                });
            }
            if (
              PilotDepartureDetailsObject &&
              PilotDepartureDetailsObject[0]?.id > 0
            ) {
              apiGlobal
                .put(
                  `/lfonds_pilot_departure_detail/${values?.departureDetails?.id}/`,
                  values?.departureDetails
                )
                .then((res) => {
                  handleResponse(res);
                })
                .catch((err) => {
                  if (errResponse.includes(err.response.status)) {
                    errorToast(
                      "Internal error occured, please contact the admin"
                    );
                  }
                });
            } else {
              apiGlobal
                .post(
                  `/lfonds_pilot_departure_detail/`,
                  values?.departureDetails
                )
                .then((res) => {
                  handleResponse(res);
                })
                .catch((err) => {
                  if (errResponse.includes(err.response.status)) {
                    errorToast(
                      "Internal error occured, please contact the admin"
                    );
                  }
                });
            }
          }}
          initialValues={PilotDetailsFormik.initialValues}
          validationSchema={PilotDetailsFormik.validationSchema}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleChange,
            touched,
            handleBlur
          }: {
            values: any;
            errors: any;
            handleSubmit: any;
            handleChange: any;
            touched: any,
            handleBlur: any
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <CardBody className="px-2 py-0 mt-2">
                <Row className="mb-2">
                  <Col sm={3}>
                    <Label className="mb-0" for='pilot_onboard'>Pilot onboard(UTC)</Label>
                    <Field name="arrivalDetails.pilot_onboard">
                      {({ field }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="datetime-local"
                          max="9999-12-31T23:59"
                          className="form-control mt-0 text-uppercase"
                          id='pilot_onboard'
                          onBlur={(e: any) => handleBlur(e)}
                          onChange={(e: any) => handleChange(e)}
                          defaultValue={dateTimeFormat(
                            values?.arrivalDetails?.pilot_onboard,
                            false,
                            true
                          )}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={3}>
                    <Label className="mb-0" for="pilot_disembarked">
                      Pilot disembarked(UTC)
                    </Label>
                    <Field name="arrivalDetails.pilot_disembarked">
                      {({ field }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="datetime-local"
                          max="9999-12-31T23:59"
                          className="form-control mt-0 text-uppercase"
                          id="pilot_disembarked"
                          onChange={(e: any) => handleChange(e)}
                          onBlur={(e: any) => handleBlur(e)}
                          defaultValue={dateTimeFormat(
                            values?.arrivalDetails?.pilot_disembarked,
                            false,
                            true
                          )}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={3}>
                    <Label className="mb-0" for="etb">
                      ETB(UTC)
                    </Label>
                    <Field name="arrivalDetails.etb">
                      {({ field }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="datetime-local"
                          max="9999-12-31T23:59"
                          className="form-control mt-0 text-uppercase"
                          id={"etb"}
                          onBlur={(e: any) => handleBlur(e)}
                          onChange={(e: any) => handleChange(e)}
                          disabled={
                            record &&
                            ((record?.reporting_type ===
                              ReportingTypeConstant.INPORT ||
                              record?.event_precedence_id ===
                              EventPrecedence.END_OF_SEA_PASSAGE) &&
                              record?.event_precedence_id !==
                              EventPrecedence.AT_BERTH
                              ? false
                              : true)
                          }
                          defaultValue={dateTimeFormat(
                            values?.arrivalDetails?.etb,
                            false,
                            true
                          )}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={3}>
                    <Label className="mb-0" for="first_line_ashore">
                      First line ashore(UTC)
                    </Label>
                    <Field name="arrivalDetails.first_line_ashore">
                      {({ field, form }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="time"
                          className="form-control max-width-7"
                          id={"first_line_ashore"}
                          onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
                          defaultValue={values?.arrivalDetails?.first_line_ashore}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={3}>
                    <Label className="mb-0" for="all_made_fast">
                      All made fast(UTC)
                    </Label>
                    <Field name="arrivalDetails.all_made_fast">
                      {({ field, form }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="time"
                          className="form-control max-width-7"
                          id={"all_made_fast"}
                          onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
                          defaultValue={values?.arrivalDetails?.all_made_fast}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={3}>
                    <Label className="mb-0" for="nor">
                      NOR(UTC)
                    </Label>
                    <Field name="arrivalDetails.nor">
                      {({ field, form }: FieldProps) => (
                        <Input
                          name={field.name}
                          type="time"
                          className="form-control max-width-7"
                          id={"nor"}
                          onChange={(e: any) => form.setFieldValue(field.name, e.target.value)}
                          defaultValue={values?.arrivalDetails?.nor}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Label className="mb-0 w-5 mt-3" for="is_this_departure_report">
                      Is this a departure from port report?
                    </Label>
                  </Col>
                  <Col>
                    <Field name={"departureDetails.is_this_departure_report"}>
                      {({ field }: FieldProps) => (
                        <div className="square-switch sqswitch mt-3">
                          <Input
                            type="checkbox"
                            switch="none"
                            name={field.name}
                            id="is_this_departure_report"
                            onChange={handleChange}
                            defaultChecked={
                              values?.departureDetails?.is_this_departure_report
                            }
                          />
                          <Label
                            for="is_this_departure_report"
                            data-on-label="Yes"
                            data-off-label="No"
                          ></Label>
                        </div>
                      )}
                    </Field>
                  </Col>
                </Row>
                {values &&
                  values?.departureDetails?.is_this_departure_report ===
                  true && (
                    <>
                      <Row className="mb-2">
                        <Col sm={3}>
                          <Label className="mb-0" for="water_density">
                            Water density
                          </Label>
                          <div className="input-group">
                            <Field name="departureDetails.water_density">
                              {() => (
                                <Input
                                  type="text"
                                  id="water_density"
                                  name="departureDetails.water_density"
                                  className="form-control"
                                  onBlur={(e: any) => handleBlur(e)}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.departureDetails?.water_density}
                                />
                              )}
                            </Field>
                            <div className="input-group-text">gm/ml</div>
                          </div>
                          {errors && touched &&
                            touched?.departureDetails?.water_density &&
                            errors?.departureDetails?.water_density &&
                            env?.form_validation === true && (
                              <ErrorTooltip
                                target="water_density"
                                message={errors?.departureDetails.water_density}
                                open={
                                  errors?.departureDetails.water_density
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </Col>
                        <Col sm={3}>
                          <Label className="mb-0" for="gm">
                            GM
                          </Label>
                          <div className="input-group">
                            <Field name="departureDetails.gm">
                              {() => (
                                <Input
                                  type="text"
                                  id="gm"
                                  name="departureDetails.gm"
                                  className="form-control"
                                  onBlur={(e: any) => handleBlur(e)}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.departureDetails?.gm}
                                />
                              )}
                            </Field>
                            <div className="input-group-text">m</div>
                          </div>
                          {errors && touched &&
                            touched?.departureDetails?.gm &&
                            errors?.departureDetails?.gm &&
                            env?.form_validation === true && (
                              <ErrorTooltip
                                target="gm"
                                message={errors?.departureDetails.gm}
                                open={
                                  errors?.departureDetails.gm
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </Col>
                        <Col sm={6}>
                          <Label className="mb-0" for="shearing_force">
                            Shearing force
                          </Label>
                          <div className="ele_row1">
                            <div className="input-group">
                              <Field name="departureDetails.shearing_force">
                                {() => (
                                  <Input
                                    type="text"
                                    id="shearing_force"
                                    name="departureDetails.shearing_force"
                                    className="form-control"
                                    onBlur={(e: any) => handleBlur(e)}
                                    onChange={(e: any) => handleChange(e)}
                                    defaultValue={values?.departureDetails?.shearing_force}
                                  />
                                )}
                              </Field>
                              <div className="input-group-text">%</div>
                            </div>
                            {errors && touched &&
                              touched?.departureDetails?.shearing_force &&
                              errors?.departureDetails?.shearing_force &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="shearing_force"
                                  message={errors?.departureDetails.shearing_force}
                                  open={
                                    errors?.departureDetails.shearing_force
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            <Label
                              className="mt-2 ml-1 mr-1"
                              for="shearing_force_frame"
                            >
                              @Frame{" "}
                            </Label>
                            <Field name="departureDetails.shearing_force_frame">
                              {() => (
                                <Input
                                  type="text"
                                  id="shearing_force_frame"
                                  name="departureDetails.shearing_force_frame"
                                  className="form-control"
                                  onBlur={(e: any) => handleBlur(e)}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.departureDetails?.shearing_force_frame}
                                />
                              )}
                            </Field>
                          </div>
                          {errors && touched &&
                            touched?.departureDetails?.shearing_force_frame &&
                            errors?.departureDetails?.shearing_force_frame &&
                            env?.form_validation === true && (
                              <ErrorTooltip
                                target="shearing_force_frame"
                                message={errors?.departureDetails.shearing_force_frame}
                                open={
                                  errors?.departureDetails.shearing_force_frame
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Label className="mb-0" for="bending_moment">
                            Bending moment
                          </Label>
                          <div className="ele_row1">
                            <div className="input-group">
                              <Field name="departureDetails.bending_moment">
                                {() => (
                                  <Input
                                    type="text"
                                    id="bending_moment"
                                    name="departureDetails.bending_moment"
                                    className="form-control"
                                    onBlur={(e: any) => handleBlur(e)}
                                    onChange={(e: any) => handleChange(e)}
                                    defaultValue={values?.departureDetails?.bending_moment}
                                  />
                                )}
                              </Field>
                              <div className="input-group-text">%</div>
                            </div>
                            {errors && touched &&
                              touched?.departureDetails?.bending_moment &&
                              errors?.departureDetails?.bending_moment &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="bending_moment"
                                  message={errors?.departureDetails.bending_moment}
                                  open={
                                    errors?.departureDetails.bending_moment
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            <Label
                              className="mt-2 ml-1 mr-1"
                              for="bending_moment_frame"
                            >
                              @Frame{" "}
                            </Label>
                            <Field name="departureDetails.bending_moment_frame">
                              {() => (
                                <Input
                                  type="text"
                                  id="bending_moment_frame"
                                  name="departureDetails.bending_moment_frame"
                                  className="form-control"
                                  onBlur={(e: any) => handleBlur(e)}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.departureDetails?.bending_moment_frame}
                                />
                              )}
                            </Field>
                          </div>
                          {errors && touched &&
                            touched?.departureDetails?.bending_moment_frame &&
                            errors?.departureDetails?.bending_moment_frame &&
                            env?.form_validation === true && (
                              <ErrorTooltip
                                target="bending_moment_frame"
                                message={errors?.departureDetails.bending_moment_frame}
                                open={
                                  errors?.departureDetails.bending_moment_frame
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </Col>
                        <Col sm={3}>
                          <Label className="mb-0" for="torsional_moment">
                            Torsional moment
                          </Label>
                          <div className="input-group">
                            <Field name="departureDetails.torsional_moment">
                              {() => (
                                <Input
                                  type="text"
                                  id="torsional_moment"
                                  name="departureDetails.torsional_moment"
                                  className="form-control"
                                  onBlur={(e: any) => handleBlur(e)}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.departureDetails?.torsional_moment}
                                />
                              )}
                            </Field>
                            <div className="input-group-text">%</div>
                          </div>
                          {errors && touched &&
                            touched?.departureDetails?.torsional_moment &&
                            errors?.departureDetails?.torsional_moment &&
                            env?.form_validation === true && (
                              <ErrorTooltip
                                target="torsional_moment"
                                message={errors?.departureDetails.torsional_moment}
                                open={
                                  errors?.departureDetails.torsional_moment
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </Col>
                        <Col sm={3}>
                          <Label className="mb-0" for="all_cast_off">
                            All cast off
                          </Label>
                          <Field name="departureDetails.all_cast_off">
                            {({ field }: FieldProps) => (
                              <Input
                                name={field.name}
                                type="time"
                                className="form-control max-width-7"
                                id={"all_cast_off"}
                                onChange={(e: any) => handleChange(e)}
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </>
                  )}
              </CardBody>
              <CardFooter className="p-2">
                <Row className="ele_row1">
                  <div className="d-flex flex-wrap gap-5">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn_size_cstm pos-end"
                      onClick={() => {
                        toggleAddTab(activeAddTab + 1);
                      }}
                    >
                      Next <i className="bx bx-chevron-right" />
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="btn_size_cstm"
                      onClick={() => {
                        (record &&
                          record?.event_precedence_id ===
                          EventPrecedence.DROP_ANCHOR) ||
                          (record &&
                            record?.event_precedence_id ===
                            EventPrecedence.HEAVE_ANCHOR)
                          ? toggleAddTab(activeAddTab - 1)
                          : toggleTab(activeTab - 1);
                      }}
                    >
                      <i className="bx bx-chevron-left me-1" /> Previous
                    </Button>
                  </div>
                </Row>
              </CardFooter>
              <FormValuesDebug
                values={[values, errors, PilotDetailsFormik.initialValues]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default PilotDetailsComponent;
