import React from 'react'
import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { Formik, Field, FieldProps } from 'formik';
import { searchPorts, dateTimeFormat, searchCountries } from 'GenericForms/Helper';
import env from 'environment_system/env_system';
import AsyncSelect from 'react-select/async';
import { Row, Col, Label, Input, Button, Form, CardFooter } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { PortConstant } from 'shared/constants';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadOtherPorts } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import NavigationButtons from 'Components/NavigationButtons';

interface CreateVoyageStructureType {
    VoyageObjectLoading: boolean;
    OtherPortsLoading: boolean;
    voyageSubmit: (values: { [key: string]: string | number | Date | boolean }) => void;
    VoyageFormik: {
        initialValues: { [key: string]: string | number | Date | boolean },
        validationSchema: Yup.ObjectSchema<{ [key: string]: string | number | Date | boolean }>
    }
    otherPort: boolean;
    setOtherPort: (value: boolean) => void;
    addOtherPort: boolean;
    setAddOtherPort: (value: boolean) => void;
    OtherPortsObject: { [key: string]: string | number | Date | boolean }[],
    VoyageObject: { [key: string]: string | number | Date | boolean },
    toggleTab?: (activeTab: number) => void,
    activeTab?: number
}

const CreateVoyageStructure = ({
    VoyageObjectLoading,
    OtherPortsLoading,
    voyageSubmit,
    VoyageFormik,
    otherPort,
    setOtherPort,
    addOtherPort,
    setAddOtherPort,
    OtherPortsObject,
    VoyageObject,
    toggleTab,
    activeTab
}: CreateVoyageStructureType) => {
    const { VesselState } = useSelector(
        (state: any) => state.Reporting
    );
    return (
        <div id="owner_table">
            <h4 className="mb-3">Voyage Reporting</h4>
            {(VoyageObjectLoading || OtherPortsLoading) && (
                <Loading message="Loading required data!" />
            )}
            {/* {VoyageObjectError && getInitialValues()} */}
            {!(VoyageObjectLoading || OtherPortsLoading) && (
                <Formik
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(true); // Set submitting state to true before submission
                        try {
                            await voyageSubmit(values);  // Assuming voyageSubmit is async
                            actions.setSubmitting(false);
                        } catch (error) {
                            // Handle error if needed (could show a toast, log it, etc.)
                            console.error('Submission error:', error);
                            actions.setSubmitting(false);
                        }
                    }}
                    initialValues={VoyageFormik.initialValues}
                    validationSchema={VoyageFormik.validationSchema}
                >
                    {(props: any) => (
                        <Form
                            onSubmit={props?.handleSubmit}
                            autoComplete="off"
                            noValidate
                        >
                            <Row className="mb-3">
                                <Col sm={3}>
                                    <Label
                                        for="voyage_number"
                                        className="required_field asteric mb-0"
                                    >
                                        Voyage number
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="voyage_number"
                                        onBlur={props.handleBlur}
                                        name="voyage_number"
                                    />
                                    {props?.errors?.voyage_number &&
                                        props?.touched?.voyage_number &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target={"voyage_number"}
                                                message={props?.errors?.voyage_number}
                                                open={props?.errors?.voyage_number ? true : false}
                                            />
                                        )}
                                </Col>
                                <Col sm={3}>
                                    <Label className="asteric mb-0" for="departure_port">
                                        Depature port name
                                    </Label>
                                    <Field name="departure_port">
                                        {({ field, form }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                inputId={"departure_port"}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={(e: any) => searchPorts(e)}
                                                getOptionLabel={(e: any) => e.port_name}
                                                getOptionValue={(e: any) => e.id}
                                                onBlur={props.handleBlur}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e?.id);
                                                    if (
                                                        e?.precedence_id === PortConstant.OTHER &&
                                                        addOtherPort === false
                                                    ) {
                                                        setOtherPort(true);
                                                    } else if (
                                                        e?.precedence_id !== PortConstant.OTHER
                                                    ) {
                                                        setOtherPort(false);
                                                        setAddOtherPort(false);
                                                        form.setFieldValue(`other_port`, null);
                                                        form.setFieldValue(`other_port_name`, null);
                                                        form.setFieldValue(`other_country`, null);
                                                    }
                                                }}
                                                defaultValue={{
                                                    id: props?.values?.departure_port,
                                                    port_name: props?.values?.departure_port_name,
                                                }}
                                                menuPortalTarget={document.body}
                                                styles={customStyle}
                                                noOptionsMessage={(e: any) => {
                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                        return "Please select the Other option and enter the port name in the textbox provided";
                                                    }
                                                    return "Please enter the first 3 characters of port name";
                                                }}
                                            />
                                        )}
                                    </Field>
                                    {props?.errors?.departure_port &&
                                        props?.touched?.departure_port &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target={"departure_port"}
                                                message={props?.errors?.departure_port}
                                                open={
                                                    props?.errors?.departure_port ? true : false
                                                }
                                            />
                                        )}
                                </Col>
                                <Col sm={3}>
                                    <Label
                                        className="asteric mb-0"
                                        for="departure_date_time"
                                    >
                                        Departure date & time(UTC)
                                    </Label>
                                    <br />
                                    <Field name="departure_date_time">
                                        {({ field }: FieldProps) => (
                                            <Input
                                                type="datetime-local"
                                                id="departure_date_time"
                                                max={"9999-12-31T00:00"}
                                                name={field.name}
                                                onBlur={props.handleBlur}
                                                onChange={(e: any) => props?.handleChange(e)}
                                                className="datetimepicker text-uppercase mt-0 max-width-15"
                                                defaultValue={dateTimeFormat(
                                                    props?.values?.departure_date_time,
                                                    false,
                                                    true
                                                )}
                                            />
                                        )}
                                    </Field>
                                    {props?.errors?.departure_date_time &&
                                        props?.touched?.departure_date_time &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target={"departure_date_time"}
                                                message={props?.errors?.departure_date_time}
                                                open={
                                                    props?.errors?.departure_date_time
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                </Col>
                            </Row>
                            {otherPort === true &&
                                OtherPortsObject &&
                                OtherPortsObject?.length > 0 && (
                                    <Col sm={3}>
                                        <Label
                                            className="mb-0 asteric"
                                            for="other_port_name_dropdown"
                                        >
                                            Other port
                                        </Label>
                                        <Field name="other_port">
                                            {({ field, form }: FieldProps) => (
                                                <AsyncSelect
                                                    name={field.name}
                                                    inputId="other_port_name_dropdown"
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={() => loadOtherPorts()}
                                                    getOptionLabel={(e: any) => e.port_name}
                                                    getOptionValue={(e: any) => e.id}
                                                    onBlur={() =>
                                                        form.setFieldTouched(field.name, true)
                                                    }
                                                    onChange={(selectedOption: any) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            selectedOption?.id
                                                        );
                                                        form.setFieldValue(
                                                            `other_port_name`,
                                                            selectedOption?.port_name
                                                        );
                                                        form.setFieldValue(
                                                            `other_country`,
                                                            selectedOption?.country
                                                        );
                                                    }}
                                                    defaultValue={
                                                        VoyageObject &&
                                                        VoyageObject?.id as number > 0 && {
                                                            id: VoyageObject?.other_port,
                                                            port_name: VoyageObject?.other_port_name,
                                                        }
                                                    }
                                                    menuPortalTarget={document.body}
                                                    styles={customStyle}
                                                    noOptionsMessage={(e: any) => {
                                                        if (e?.inputValue?.toString()?.length > 2) {
                                                            return "Please select the Other option and enter the port name in the textbox provided";
                                                        }
                                                        return "Please enter the first 3 characters of port name";
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <p
                                            className="link_color_blue pointer"
                                            onClick={() => {
                                                setAddOtherPort(true);
                                                setOtherPort(false);
                                                props?.setFieldValue(`other_port`, null);
                                                props?.setFieldValue(`other_port_name`, null);
                                                props?.setFieldValue(`other_country`, null);
                                            }}
                                        >
                                            Click here to add new port
                                        </p>
                                    </Col>
                                )}
                            {(addOtherPort === true || !OtherPortsObject) && (
                                <Row className='mb-3'>
                                    <Col sm={3}>
                                        <Label className="mb-0 asteric">
                                            Other port name
                                        </Label>
                                        <Field name={`other_port_name`}>
                                            {() => (
                                                <Input
                                                    type="text"
                                                    name={`other_port_name`}
                                                    id="other_port_name"
                                                    className="form-control"
                                                    onBlur={(e: any) => props?.handleChange(e)}
                                                    defaultValue={props?.values?.other_port_name}
                                                />
                                            )}
                                        </Field>
                                        {props?.errors &&
                                            props?.touched &&
                                            props?.touched?.other_port_name &&
                                            props?.errors?.bunkering?.other_port_name &&
                                            env?.form_validation === true && (
                                                <ErrorTooltip
                                                    target={`other_port_name`}
                                                    message={props?.errors?.other_port_name}
                                                    open={
                                                        props?.errors &&
                                                            props?.errors?.other_port_name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                    </Col>
                                    <Col sm={3}>
                                        <Label className="mb-0 asteric">
                                            Other country name
                                        </Label>
                                        <Field name={`other_country`}>
                                            {({ field, form }: FieldProps) => (
                                                <AsyncSelect
                                                    name={field.name}
                                                    id={"other_country"}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={(e: any) => searchCountries(e)}
                                                    getOptionLabel={(e: any) => e.country_name}
                                                    getOptionValue={(e: any) => e.id}
                                                    onBlur={() => {
                                                        form.setFieldTouched(field.name, true);
                                                    }}
                                                    onChange={(e: any) => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, e?.id);
                                                    }}
                                                    defaultValue={
                                                        VoyageObject &&
                                                        VoyageObject?.id as number > 0 && {
                                                            id: props?.values?.other_country,
                                                            country_name:
                                                                props?.values?.other_country_name,
                                                        }
                                                    }
                                                    styles={customStyle}
                                                    noOptionsMessage={() => {
                                                        return "Please enter the first 3 characters of country name";
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {props?.errors &&
                                            props?.touched &&
                                            props?.touched?.other_country &&
                                            props?.errors?.other_country &&
                                            env?.form_validation === true && (
                                                <ErrorTooltip
                                                    target={`other_country`}
                                                    message={props?.errors?.other_country}
                                                    open={
                                                        props?.errors && props?.errors?.other_country
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                    </Col>
                                </Row>
                            )}
                            {(VesselState === "CREATE_VOYAGE_REPORTING" || VesselState === "VOYAGE_REPORTING_EDIT") ?
                                <Row>
                                    <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size4_5_cstm"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="danger"
                                            className="btn_size4_5_cstm"
                                            onClick={() => {
                                                props?.resetForm();
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </Row> :
                                <CardFooter className="p-2 py-3">
                                    <NavigationButtons
                                        activeTab={activeTab}
                                        toggleTab={toggleTab}
                                        isSubmitting={props.isSubmitting}
                                    />
                                </CardFooter>
                            }
                            <FormValuesDebug
                                values={[
                                    props?.values,
                                    props?.errors,
                                    VoyageFormik.initialValues,
                                ]}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    )
}

export default CreateVoyageStructure