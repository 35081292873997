import { checkInvalidPrimaryKey } from "GenericForms/Helper";
import apiGlobal from "global/api.global";

export const GetPageWithID = async (vesselId: number, url: string) => {
    if (!checkInvalidPrimaryKey(vesselId)) {
        return null;
    }
    if (!url) return null;
    try {
        const response = await apiGlobal.get(url);
        return response.data;
    } catch (error) {
        console.error(error)
        return [];
    }
}