import { Field, FieldArray, useFormikContext } from 'formik';
import React from 'react'
import { Button, Col, Modal, Row } from 'reactstrap';
import "../../../../global/GlobalCSS.css"
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';

const SpOpFuelBatchConsumption = ({
    state,
    setState,
    id,
    machinery,
    totalConsumption
}: any) => {
    const { values, errors, touched, isSubmitting }: { values: any, errors: any, touched: any, isSubmitting: any } = useFormikContext();
    const handlePopUpState = () => {
        setState(!state);
    }
    return (
        <Modal
            size='md'
            isOpen={state}
            toggle={() => {
                handlePopUpState();
            }}
            backdrop={"static"}
            id={id}
        >
            <div className="modal-header">
                <strong className="modal-title" id={id}>{machinery[0]?.vessel_machinery_fc_group_name} fuel consumption by batch</strong>
            </div>
            <div className="modal-body">
                <Row>
                    <Row className='mb-2'>
                        <div className='ele_row1'><p style={{ fontSize: 12.5, marginBottom: 1 }}>Total fuel consumption:&nbsp;</p>
                            <strong style={{ fontSize: 12.5, marginBottom: 1 }}>{totalConsumption} mt</strong></div>
                    </Row>
                    <FieldArray name='fuelSubTypeConsumption'>
                        {() => (
                            <>
                                {values && values?.fuelSubTypeConsumption?.filter((item: any) =>
                                    item.vessel_machinery_fc_group === machinery[0]?.vessel_machinery_fc_group)?.map((batch: any, index: number) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <strong style={{ fontSize: 12, marginBottom: 1 }}>{batch?.fuel_consumption_batch}</strong>
                                                    <div className='input-group mb-2'>
                                                        <Field
                                                            type='text'
                                                            id={`fuelSubTypeConsumption_${values.fuelSubTypeConsumption.indexOf(batch)}`}
                                                            name={`fuelSubTypeConsumption.${values?.fuelSubTypeConsumption.indexOf(batch)}.fuel_consumption`}
                                                            className='form-control max-width-7 text-right'
                                                        />
                                                        <div className='input-group-text'>mt</div>
                                                    </div>
                                                </div>
                                                {
                                                    errors?.fuelSubTypeConsumption && touched?.fuelSubTypeConsumption &&
                                                    touched?.fuelSubTypeConsumption[values.fuelSubTypeConsumption.indexOf(batch)]?.fuel_consumption &&
                                                    errors?.fuelSubTypeConsumption[values.fuelSubTypeConsumption.indexOf(batch)]?.fuel_consumption &&
                                                    env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={`fuelSubTypeConsumption_${values.fuelSubTypeConsumption.indexOf(batch)}`}
                                                        message={errors?.fuelSubTypeConsumption
                                                        [values.fuelSubTypeConsumption.indexOf(batch)]?.fuel_consumption}
                                                        open={
                                                            (errors && errors?.fuelSubTypeConsumption
                                                            [values.fuelSubTypeConsumption.indexOf(batch)]?.fuel_consumption) ?
                                                                true : false
                                                        }
                                                    />
                                                }
                                            </>
                                        )
                                    })}
                            </>
                        )}
                    </FieldArray>
                </Row>
                <Row>
                    <Col lg={{ size: 2, offset: 10 }}>
                        <Button type='button' className='btn justify_right' color='primary' onClick={() => {
                            // if (!errors?.fuelSubTypeConsumption && touched?.fuelSubTypeConsumption) {
                            setState(false);
                            // }
                        }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                            Save</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default SpOpFuelBatchConsumption