import React, { useState } from 'react';
import { Card, NavItem, TabContent, TabPane, NavLink, Container, Nav, CardHeader, Row, Col } from "reactstrap";
import '../global/GlobalCSS.css';
import Layout from '../HorizontalMenu/Menu';
import Navbar from '../HorizontalMenu/Navbar';
import { useDispatch } from 'react-redux';
import { VesselState, setVesselState } from "../Store/Generic/ReportingSlice";
import VesselDetailsHeader from '../Components/VesselDetailsHeader';
import classnames from "classnames";
import OngoingReports from './OngoingReports';
import CompletedReports from './CompletedReports';

const VesselReportingInfo = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');
    const dispatch = useDispatch();

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }
    return (
        <>
            <React.Fragment>
                <Layout children={Navbar} />
                <div className="page-content">
                    <Container fluid>
                        <Card>
                            <CardHeader className='pb-0'>
                                <Row>
                                    <Col sm={2}>
                                        <button color='primary' className='btn btn-primary mb-3' onClick={() => { handleVesselState('VOYAGE_REPORTING') }}><i className="bx bx-chevron-left me-1" />Back</button>
                                    </Col>
                                    <Col sm={10}>
                                        <VesselDetailsHeader />
                                    </Col>
                                </Row>
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1");
                                            }}
                                        >
                                            Ongoing Reports
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Completed Reports
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={activeSettingsTab} className="text-muted">
                                <TabPane tabId="1" className='margin-16'>
                                    <OngoingReports />
                                </TabPane>
                                <TabPane tabId="2" className='margin-16'>
                                    <CompletedReports />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}
export default VesselReportingInfo;