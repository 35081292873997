import React, { useState } from 'react'
import classnames from "classnames";
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SatelliteCommunication from 'VesselMaster/AdditionalInformation/SatelliteComm';
import VesselGeneralInfo from 'VesselMaster/AdditionalInformation/VesselGeneralInfo/VesselGeneralInfo';
import EmailReporting from 'VesselMaster/AdditionalInformation/EmailReporting';

const VesselData = (VesselConfActiveTab: any) => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }
    return (
        <React.Fragment>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "1",
                            })}
                            onClick={() => {
                                toggle("1");
                            }} to={''}
                        >
                            Vessel General Information
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "2",
                            })}
                            onClick={() => {
                                toggle("2");
                            }} to={''}
                        >
                            Satellite Communication
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "3",
                            })}
                            onClick={() => {
                                toggle("3");
                            }} to={''}
                        >
                            Email Reporting
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId="1" className='m-0'>
                    <VesselGeneralInfo
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab.VesselConfActiveTab}
                    />
                </TabPane>
                <TabPane tabId="2">
                    <SatelliteCommunication
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab.VesselConfActiveTab}
                    />
                </TabPane>
                <TabPane tabId="3">
                    <EmailReporting
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab.VesselConfActiveTab}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment>
    )
}

export default VesselData
