export const customStyle = {
    control: (styles: any) => ({
        ...styles,
        borderColor: '#858484cc',
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    }),
    option: (styles: any) => ({
        ...styles,
        borderColor: '#858484cc',
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 10000,
    }),
};

export const errorStyle = {
    control: (styles: any) => ({
        ...styles,
        //borderColor: '#fd625e'
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    }),
    option: (styles: any) => ({
        ...styles,
        borderColor: '#858484cc',
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    })
}