import React, { useLayoutEffect } from 'react';
import * as Yup from "yup";
import { Col, Row, Input, Label, Button, Form, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { Field, FieldProps, Formik } from 'formik';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import apiGlobal from 'global/api.global';
import { loadSTSOngoingObject } from 'VesselMaster/vesselMaster.hooks';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import { dateTimeFormat, handleServerResponse } from 'GenericForms/Helper';
import env from 'environment_system/env_system';
import { STSOperations } from 'shared/constants';
import ErrorTooltip from 'Components/ErrorTooltip';

interface STSOperationType {
    record: any,
    lastRecord: any,
    VesselID: number,
    ReportID: number,
    activeTab: number,
    toggleTab: any,
    setErrorMessage: any
}

const STSOperationComponent = ({
    record,
    lastRecord,
    VesselID,
    ReportID,
    activeTab,
    toggleTab,
    setErrorMessage,
}: STSOperationType) => {
    /** Queries */
    /** STS ongoing object used for edit */
    const { data: STSOngoingObject, isLoading: STSOngoingObjectIsLoading, isError: STSOngoingObjectIsError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.STSOngoingObject.key, VesselID],
            async () => {
                return await loadSTSOngoingObject(VesselID);
            },
            { staleTime: Infinity }
        )
    /** Queries end */

    /** Assign values to initial object of STS Loading */
    const getInitailLoadingValues = () => {
        if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.LOADING)[0]?.id > 0) {
            let obj: any = STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.LOADING)[0];;
            obj.loading = true;
            return obj;
        } else {
            return {
                loading: false,
                sts_start_time: null,
                sts_end_time: null,
                ongoing_status: false,
                vessel: VesselID,
                sts_start_report: null,
                sts_end_report: null,
                user_approved: false,
                class_approved: false,
                sts_operation: STSOperations.LOADING,
            }
        }
    }

    /** Assign values to initial object of STS Discharging */
    const getInitailDischargingValues = () => {
        if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.DISCHARGING)[0]?.id > 0) {
            let obj: any = STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.DISCHARGING)[0];
            obj.discharging = true;
            return obj;
        } else {
            return {
                dicharging: false,
                sts_start_time: null,
                sts_end_time: null,
                ongoing_status: false,
                vessel: VesselID,
                sts_start_report: null,
                sts_end_report: null,
                user_approved: false,
                class_approved: false,
                sts_operation: STSOperations.DISCHARGING,
            }
        }
    }

    /** Assign values to initial object of STS Port Discharging */
    const getInitialPortDischargingValues = () => {
        if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.PORT_DISCHARGING)[0]?.id > 0) {
            let obj: any = STSOngoingObject?.filter((item: any) => item.sts_operation === STSOperations.PORT_DISCHARGING)[0];
            obj.discharging = true;
            return obj;
        } else {
            return {
                discharging: false,
                sts_start_time: null,
                sts_end_time: null,
                ongoing_status: false,
                vessel: VesselID,
                sts_start_report: null,
                sts_end_report: null,
                user_approved: false,
                class_approved: false,
                sts_operation: STSOperations.PORT_DISCHARGING,
            }
        }
    }

    /** UseEffect */
    useLayoutEffect(() => {
        STSOperationFormik.initialValues.stsLoading = getInitailLoadingValues();
        STSOperationFormik.initialValues.stsDischarging = getInitailDischargingValues();
        STSOperationFormik.initialValues.portDischarging = getInitialPortDischargingValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [STSOngoingObject])
    /** UseEffect end */

    /** Convert string into required date format */
    const parseDateString = (dateString: any) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    /** STS Operation formik object */
    const STSOperationFormik = {
        initialValues: {
            stsLoading: getInitailLoadingValues(),
            stsDischarging: getInitailDischargingValues(),
            portDischarging: getInitialPortDischargingValues()
        },
        validationSchema: Yup.object().shape({
            stsLoading: Yup.object({
                sts_start_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
                sts_end_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
            }),
            stsDischarging: Yup.object({
                sts_start_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
                sts_end_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
            }),
            portDischarging: Yup.object({
                sts_start_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
                sts_end_time: Yup.date().min(
                    new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                    `${commonValidationMessages.minDateLastRecord}`
                )
                    .max(new Date(dateTimeFormat(record?.reporting_time_utc)),
                        `${commonValidationMessages.maxDateCurrentRecord}`).nullable(),
            }),
        }),
    }

    const handleSTSLoadingStartChanges = (e: any, form: any) => {
        form.setFieldValue('stsLoading.ongoing_status', true);
        form.setFieldValue('stsLoading.sts_start_report', ReportID);
    }

    const handleSTSLoadingEndChanges = (e: any, form: any) => {
        form.setFieldValue('stsLoading.ongoing_status', false);
        form.setFieldValue('stsLoading.sts_end_report', ReportID);
    }

    const handleSTSDischargingStartChanges = (e: any, form: any) => {
        form.setFieldValue('stsDischarging.ongoing_status', true);
        form.setFieldValue('stsDischarging.sts_start_report', ReportID);
    }

    const handleSTSDischargingEndChanges = (e: any, form: any) => {
        form.setFieldValue('stsDischarging.ongoing_status', false);
        form.setFieldValue('stsDischarging.sts_end_report', ReportID);
    }

    const handlePortDischargingStartChanges = (e: any, form: any) => {
        form.setFieldValue('portDischarging.ongoing_status', true);
        form.setFieldValue('portDischarging.sts_start_report', ReportID);
    }

    const handlePortDischargingEndChanges = (e: any, form: any) => {
        form.setFieldValue('portDischarging.ongoing_status', false);
        form.setFieldValue('portDischarging.sts_end_report', ReportID);
    }
    return (
        <React.Fragment>
            <Card className='p-0 mb-0 border-0'>
                <CardHeader className='p-2 border-bottom-0'>
                    <div className="text-center">
                        <Row>
                            <Col>
                                <h4 className="page_title pos-start mb-0">STS Operation</h4>
                                <p className="card-title-desc pos-start">All readings since last report</p>
                            </Col>
                            <Col>
                                <ReportDetailsHeader />
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                {(STSOngoingObjectIsLoading) &&
                    <Loading message='Loading required data!' />}
                {STSOngoingObjectIsError && getInitailLoadingValues() && getInitailDischargingValues()}
                {!STSOngoingObjectIsLoading &&
                    <React.Fragment>
                        <Formik
                            onSubmit={async (values, actions) => {
                                if (env?.form_validation === false) {
                                    setErrorMessage(null)
                                    toggleTab(activeTab + 1);
                                }
                                actions.setSubmitting(true);
                                const responseArray: any[] = [];
                                if (values.stsLoading.loading === true) {
                                    /** STS Loading submit */
                                    if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                        item.sts_operation === STSOperations.LOADING)[0]?.id > 0) {
                                        responseArray.push(apiGlobal.put(`/sts_operation_detail/${values?.stsLoading.id}/`, values?.stsLoading))
                                    } else {
                                        responseArray.push(apiGlobal.post(`/sts_operation_detail/`, values.stsLoading))
                                    }
                                }
                                if (values.stsDischarging.discharging === true) {
                                    /** STS Discharging submit */
                                    if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                        item.sts_operation === STSOperations.DISCHARGING)[0]?.id > 0) {
                                        responseArray.push(apiGlobal.put(`/sts_operation_detail/${values?.stsDischarging.id}/`, values?.stsDischarging))
                                    } else {
                                        responseArray.push(apiGlobal.post(`/sts_operation_detail/`, values.stsDischarging))
                                    }
                                }
                                if (values.portDischarging.discharging === true) {
                                    /** STS Port Discharging submit */
                                    if (STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                        item.sts_operation === STSOperations.PORT_DISCHARGING)[0]?.id > 0) {
                                        responseArray.push(apiGlobal.put(`/sts_operation_detail/${values?.portDischarging?.id}/`, values?.portDischarging))
                                    } else {
                                        responseArray.push(apiGlobal.post(`/sts_operation_detail/`, values.portDischarging))
                                    }
                                }
                                await handleServerResponse(responseArray).then(res => {
                                    if (res === true) {
                                        if (env?.form_validation === true) {
                                            setErrorMessage(null)
                                            toggleTab(activeTab + 1);
                                            queryClient.invalidateQueries(queryKeyes.vessel.STSOngoingObject.key);
                                        }
                                    } else {
                                        setErrorMessage(res)
                                    }
                                    actions.setSubmitting(false);
                                })
                            }}
                            initialValues={STSOperationFormik.initialValues}
                            validationSchema={env?.form_validation === true ? STSOperationFormik.validationSchema : null}
                        >
                            {({ values, errors, handleSubmit, handleChange, isSubmitting }:
                                { values: any, errors: any, handleSubmit: any, handleChange: any, isSubmitting: any }) => (
                                <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                    <CardBody className='p-0'>
                                        <div className="table-responsive p-2 pt-0">
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="align-middle p-2"></th>
                                                        <th className="align-middle p-2">Operation</th>
                                                        <th className="align-middle p-2 text-center">Start time(UTC)</th>
                                                        <th className="align-middle p-2 text-center">End Time(UTC)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='align-middle p-2'>
                                                            <div className="form-check">
                                                                <Field name={'stsLoading.loading'}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="loading_checkbox"
                                                                            name={field.name}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                form.setFieldValue('stsDischarging.discharging', false);
                                                                                form.setFieldValue('portDischarging.discharging', false);
                                                                                if (e.target.checked === false) {
                                                                                    form.setFieldValue('stsLoading.sts_start_time', null);
                                                                                    form.setFieldValue('stsLoading.sts_end_time', null);
                                                                                }
                                                                            }}
                                                                            checked={values?.stsLoading.loading}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </td>
                                                        <td className='align-middle p-2'>
                                                            <Label className='mb-0' for="loading_checkbox">STS Loading<i className='bx bx-info-circle ml-2p' /></Label>
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            {
                                                                STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                                                    item.sts_operation === STSOperations.LOADING)[0]?.id > 0 ?
                                                                    <Col lg={{ size: 7, offset: 2 }}>
                                                                        <Field
                                                                            type="text"
                                                                            className="mt-0 form-control ms-4 max-width-9-5 text-center"
                                                                            name='stsLoading.sts_start_time'
                                                                            value={parseDateString(STSOngoingObject?.filter((item: any) =>
                                                                                item.sts_operation === STSOperations.LOADING)[0].sts_start_time)}
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                    :
                                                                    (
                                                                        <React.Fragment>
                                                                            <Field name={'stsLoading.sts_start_time'}>
                                                                                {({ field, form }: FieldProps) => (
                                                                                    <input
                                                                                        type="datetime-local"
                                                                                        name={field.name}
                                                                                        id='stsLoading_sts_start_time'
                                                                                        className='datetimepicker text-uppercase mt-0'
                                                                                        disabled={values?.stsLoading.loading === true ? false : true}
                                                                                        min={lastRecord ? lastRecord.reporting_time_utc?.toString().substring(0, 16) : '31-12-2022'}
                                                                                        max={record && record?.reporting_time_utc?.toString().substring(0, 16)}
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                            handleSTSLoadingStartChanges(e, form);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            {errors?.stsLoading && errors?.stsLoading?.sts_start_time && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target='stsLoading_sts_start_time'
                                                                                    message={errors?.stsLoading?.sts_start_time}
                                                                                    open={errors?.stsLoading && errors?.stsLoading?.sts_start_time ? true : false}
                                                                                />
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            <Field name={'stsLoading.sts_end_time'}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <input
                                                                        type="datetime-local"
                                                                        name={field.name}
                                                                        id={'stsLoading_sts_end_time'}
                                                                        className='datetimepicker text-uppercase mt-0'
                                                                        min={lastRecord && lastRecord?.reporting_time_utc?.toString().substring(0, 16)}
                                                                        max={record && record?.reporting_time_utc.toString().substring(0, 16)}
                                                                        disabled={values?.stsLoading.loading === true || (STSOngoingObject?.length > 0 &&
                                                                            STSOngoingObject?.filter((item: any) =>
                                                                                item.sts_operation === STSOperations.LOADING)[0]?.id > 0)
                                                                            ? false : true
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            handleSTSLoadingEndChanges(e, form);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.stsLoading && errors?.stsLoading?.sts_end_tim && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='stsLoading_sts_end_time'
                                                                    message={errors?.stsLoading?.sts_end_tim}
                                                                    open={errors?.stsLoading && errors?.stsLoading?.sts_end_tim ? true : false}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='align-middle p-2'>
                                                            <div className="form-check">
                                                                <Field name={'stsDischarging.discharging'}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="discharging_checkbox"
                                                                            name={field.name}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e)
                                                                                form.setFieldValue('stsLoading.loading', false);
                                                                                form.setFieldValue('portDischarging.discharging', false);
                                                                                if (e.target.checked === false) {
                                                                                    form.setFieldValue('stsDischarging.sts_start_time', null);
                                                                                    form.setFieldValue('stsDischarging.sts_end_time', null);
                                                                                }
                                                                            }}
                                                                            checked={values?.stsDischarging.discharging}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </td>
                                                        <td className='align-middle p-2'>
                                                            <Label className='mb-0' for="discharging_checkbox">STS Discharging<i className='bx bx-info-circle ml-2p' /></Label>
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            {
                                                                STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                                                    item.sts_operation === STSOperations.DISCHARGING)[0]?.id > 0 ?
                                                                    <Col lg={{ size: 7, offset: 2 }}>
                                                                        <Field
                                                                            type="text"
                                                                            className="mt-0 form-control ms-4 max-width-9-5 text-center"
                                                                            name='stsDischarging.sts_start_time'
                                                                            value={
                                                                                STSOngoingObject?.filter((item: any) =>
                                                                                    item.sts_operation === STSOperations.DISCHARGING)[0].sts_start_time.substring(0, 10) + ' ' +
                                                                                STSOngoingObject?.filter((item: any) =>
                                                                                    item.sts_operation === STSOperations.DISCHARGING)[0].sts_start_time.substring(11, 16)}
                                                                            disabled
                                                                        />
                                                                    </Col> :
                                                                    (
                                                                        <React.Fragment>
                                                                            <Field name={'stsDischarging.sts_start_time'}>
                                                                                {({ field, form }: FieldProps) => (
                                                                                    <input
                                                                                        type="datetime-local"
                                                                                        name={field.name}
                                                                                        id={'stsDischarging_sts_start_time'}
                                                                                        className='datetimepicker text-uppercase mt-0'
                                                                                        disabled={values?.stsDischarging.discharging === true ? false : true}
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                            handleSTSDischargingStartChanges(e, form);
                                                                                        }}
                                                                                        min={lastRecord && lastRecord.reporting_time_utc?.toString().substring(0, 16)}
                                                                                        max={record && record?.reporting_time_utc?.toString().substring(0, 16)}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            {errors?.stsDischarging && errors?.stsDischarging?.sts_start_time && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target='stsDischarging_sts_start_time'
                                                                                    message={errors?.stsDischarging?.sts_start_time}
                                                                                    open={errors?.stsDischarging && errors?.stsDischarging?.sts_start_time ? true : false}
                                                                                />
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            <Field name={'stsDischarging.sts_end_time'}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <input
                                                                        type="datetime-local"
                                                                        name={field.name}
                                                                        id={'stsDischarging_sts_end_time'}
                                                                        className='datetimepicker text-uppercase mt-0'
                                                                        min={lastRecord && lastRecord?.reporting_time_utc?.toString().substring(0, 16)}
                                                                        max={record && record?.reporting_time_utc.toString().substring(0, 16)}
                                                                        disabled={(values?.stsDischarging.discharging === true || (STSOngoingObject?.length > 0 &&
                                                                            STSOngoingObject?.filter((item: any) =>
                                                                                item.sts_operation === STSOperations.DISCHARGING)[0]?.id > 0)) ?
                                                                            false : true
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            handleSTSDischargingEndChanges(e, form);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.stsDischarging && errors?.stsDischarging?.sts_end_time && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='stsDischarging_sts_end_time'
                                                                    message={errors?.stsDischarging?.sts_end_time}
                                                                    open={errors?.stsDischarging && errors?.stsDischarging?.sts_end_time ? true : false}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='align-middle p-2 ps-2'>
                                                            <div className="form-check">
                                                                <Field name={'portDischarging.discharging'}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="port_discharging_checkbox"
                                                                            name={field.name}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e)
                                                                                form.setFieldValue('stsDischarging.discharging', false);
                                                                                form.setFieldValue('stsLoading.loading', false);
                                                                                if (e.target.checked === false) {
                                                                                    form.setFieldValue('portDischarging.sts_start_time', null);
                                                                                    form.setFieldValue('portDischarging.sts_end_time', null);
                                                                                }
                                                                            }}
                                                                            checked={values?.portDischarging.discharging}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </td>
                                                        <td className='align-middle p-2 ps-0'>
                                                            <Label className='mb-0' for="port_discharging_checkbox">Port Discharging after STS Loading<i className='bx bx-info-circle ml-2p' /></Label>
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            {
                                                                STSOngoingObject?.length > 0 && STSOngoingObject?.filter((item: any) =>
                                                                    item.sts_operation === STSOperations.PORT_DISCHARGING)[0]?.id > 0 ?
                                                                    <Col lg={{ size: 7, offset: 2 }}>
                                                                        <Field
                                                                            type="text"
                                                                            className="mt-0 form-control ms-4 max-width-9-5 text-center"
                                                                            name='portDischarging.sts_start_time'
                                                                            value={
                                                                                STSOngoingObject?.filter((item: any) =>
                                                                                    item.sts_operation === STSOperations.PORT_DISCHARGING)[0].sts_start_time.substring(0, 10) + ' ' +
                                                                                STSOngoingObject?.filter((item: any) =>
                                                                                    item.sts_operation === STSOperations.PORT_DISCHARGING)[0].sts_start_time.substring(11, 16)}
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                    :
                                                                    (
                                                                        <React.Fragment>
                                                                            <Field name={'portDischarging.sts_start_time'}>
                                                                                {({ field, form }: FieldProps) => (
                                                                                    <input
                                                                                        type="datetime-local"
                                                                                        name={field.name}
                                                                                        id={'portDischarging_sts_start_time'}
                                                                                        className='datetimepicker text-uppercase mt-0'
                                                                                        disabled={values?.portDischarging.discharging === true ? false : true}
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                            handlePortDischargingStartChanges(e, form);
                                                                                        }}
                                                                                        min={lastRecord && lastRecord?.reporting_time_utc?.toString()?.substring(0, 16)}
                                                                                        max={record && record?.reporting_time_utc?.toString()?.substring(0, 16)}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            {errors?.portDischarging && errors?.portDischarging?.sts_start_time && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target='portDischarging_sts_start_time'
                                                                                    message={errors?.portDischarging?.sts_start_time}
                                                                                    open={errors?.portDischarging && errors?.portDischarging?.sts_start_time ? true : false}
                                                                                />
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            <Field name={'portDischarging.sts_end_time'}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <input
                                                                        type="datetime-local"
                                                                        name={field.name}
                                                                        id={'portDischarging_sts_end_time'}
                                                                        className='datetimepicker text-uppercase mt-0'
                                                                        min={lastRecord && lastRecord?.reporting_time_utc?.toString().substring(0, 16)}
                                                                        max={record && record?.reporting_time_utc.toString().substring(0, 16)}
                                                                        disabled={(values?.portDischarging.discharging === true || (STSOngoingObject?.length > 0 &&
                                                                            STSOngoingObject?.filter((item: any) =>
                                                                                item.sts_operation === STSOperations.PORT_DISCHARGING)[0]?.id > 0)) ?
                                                                            false : true
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            handlePortDischargingEndChanges(e, form);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.portDischarging && errors?.portDischarging?.sts_end_time && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='portDischarging_sts_end_time'
                                                                    message={errors?.portDischarging?.sts_end_time}
                                                                    open={errors?.portDischarging && errors?.portDischarging?.sts_end_time ? true : false}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <Row>
                                    <CardBody>
                                        <Row className="justify-content-center">
                                            <Col xl={10}>
                                                <div className="timeline">
                                                    <div className="timeline-container">
                                                        <div className="timeline-end">
                                                            <p>Timeline</p>
                                                        </div>
                                                        <div className="timeline-continue">
                                                            <Row className="timeline-left">
                                                                <Col md={6} className="d-md-none d-block">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-user-pin text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="timeline-box sts-timeline-box">
                                                                        <div className="event-content">
                                                                            <div className="timeline-text">
                                                                                <h3 className="font-size-18 mb-0">
                                                                                    Departure
                                                                                </h3>
                                                                                <h6>Port: Houston</h6>
                                                                                <p className="mb-0 mt-2 pt-1">
                                                                                    25 Jun 2023 18:00 UTC
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} className="d-md-block d-none">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-briefcase-alt-2 text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="timeline-right">
                                                                <Col md={6}>
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-bar-chart-square text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="timeline-box bg-light sts-timeline-box">
                                                                        <div className="timeline-date bg-danger text-center rounded">
                                                                            <h2><i className='mdi mdi-cancel icon_s28 text-white' /></h2>
                                                                        </div>
                                                                        <div className="event-content">
                                                                            <div className="timeline-text">
                                                                                <h3 className="font-size-18 text-secondary">
                                                                                    Loading operation start
                                                                                </h3>
                                                                                <p className="mb-0 mt-2 pt-1 text-secondary">
                                                                                    25 Jun 2023 02:00 UTC
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="timeline-left">
                                                                <Col md={6} className="d-md-none d-block">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-user-pin text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="timeline-box sts-timeline-box">
                                                                        <div className="event-content">
                                                                            <div className="timeline-text">
                                                                                <h3 className="font-size-18 mb-0">
                                                                                    Arrival
                                                                                </h3>
                                                                                <h6>Port: Pajaritos</h6>
                                                                                <p className="mb-0 mt-2 pt-1">
                                                                                    29 Jun 2023 18:00 UTC
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} className="d-md-block d-none">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-briefcase-alt-2 text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="timeline-right">
                                                                <Col md={6}>
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-pie-chart-alt text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="timeline-box">
                                                                        <div className="timeline-date bg-primary text-center rounded">
                                                                            <h3 className="text-white mb-0">23</h3>
                                                                            <p className="mb-0 text-white-50">July</p>
                                                                        </div>
                                                                        <div className="event-content">
                                                                            <div className="timeline-text">
                                                                                <h3 className="font-size-18">
                                                                                    Timeline Event Five
                                                                                </h3>

                                                                                <p className="mb-0 mt-2 pt-1 text-muted">
                                                                                    Excepturi, obcaecati, quisquam id
                                                                                    molestias eaque asperiores voluptatibus
                                                                                    cupiditate error assumenda delectus odit
                                                                                    similique earum voluptatem Odit, itaque,
                                                                                    deserunt corporis vero ipsum nisi
                                                                                    repellat ...{" "}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="timeline-left">
                                                                <Col md={6} className="d-md-none d-block">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-home-alt text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="timeline-box">
                                                                        <div className="timeline-date bg-primary text-center rounded">
                                                                            <h3 className="text-white mb-0">25</h3>
                                                                            <p className="mb-0 text-white-50">June</p>
                                                                        </div>
                                                                        <div className="event-content">
                                                                            <div className="timeline-text">
                                                                                <h3 className="font-size-18">
                                                                                    Timeline Event End
                                                                                </h3>
                                                                                <p className="mb-0 mt-2 pt-1 text-muted">
                                                                                    Suspendisse tempor porttitor elit non
                                                                                    maximus. Sed suscipit, purus in
                                                                                    convallis condimentum, risus ex
                                                                                    pellentesque sapien, vel tempor arcu
                                                                                    dolor ut est. Nam ac felis id mauris
                                                                                    fermentum nisl pharetra auctor.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} className="d-md-block d-none">
                                                                    <div className="timeline-icon">
                                                                        <i className="bx bx-home-alt text-primary h2 mb-0"></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="timeline-start">
                                                            <p>End</p>
                                                        </div>
                                                        <div className="timeline-launch">
                                                            <div className="timeline-box">
                                                                <div className="timeline-text">
                                                                    <h3 className="font-size-18">
                                                                        Launched our company on 21 June 2021
                                                                    </h3>
                                                                    <p className="text-muted mb-0">
                                                                        Pellentesque sapien ut est.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Row> */}
                                    </CardBody>
                                    <CardFooter className='p-2 py-3'>
                                        <Row className="ele_row1">
                                            <div className="d-flex flex-wrap gap-5">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="btn_size_cstm pos-end"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                    Next <i className="bx bx-chevron-right ms-1" />
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={() => {
                                                        toggleTab(activeTab - 1);
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1" /> Previous
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardFooter>
                                    <FormValuesDebug values={[values, errors, STSOperationFormik.initialValues]} />
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                }
            </Card>
        </React.Fragment>
    )
}

export default STSOperationComponent