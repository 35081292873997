import React from 'react'
import { Button, Col, Row } from 'reactstrap';

const AdjustmentPaginationComponent =
    ({ count, setCount, tableDetails}:
        { count: any, setCount: any, tableDetails: any, setTableDetails: any }) => {
        /** State variables */

        /** Pagination previous */
        const handlePrevPageClick = () => {
            tableDetails.index = (tableDetails?.index > 0) ? tableDetails?.index - 1 : 0;
            setCount(count + 1);
        }

        /** Pagination next */
        const handleNextPageClick = () => {
            tableDetails.index = (tableDetails?.index < (Math.ceil(tableDetails?.ciiAdjustment?.length / tableDetails?.size) - 1)) &&
                tableDetails?.index + 1
            setCount(count + 1);
        }

        return (
            <Row className='p-2'>
                <Col className='py-3 pr-3' lg={{ size: 2, offset: 10 }}>
                    <Button
                        type='button'
                        color='primary'
                        className='btn pos-end'
                        onClick={() => handleNextPageClick()}
                    >
                        <i className="bx bx-chevron-right" />
                    </Button>
                    <Button
                        type='button'
                        color='primary'
                        className='btn ms-5'
                        onClick={() => handlePrevPageClick()}
                    >
                        <i className="bx bx-chevron-left" />
                    </Button>
                </Col>
            </Row>
        )
    }

export default AdjustmentPaginationComponent