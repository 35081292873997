import ToolTip from 'Components/ToolTip';
import React, { useEffect, useState } from 'react'
import { Label, Col, Row } from 'reactstrap';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import VoyageAdjustmentPopup from './VoyageAdjustmentPopup';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import VoyageAdjustmentTableHeadComponent from './VoyageAdjustmentTableHeadComponent';
import ValidateIcon from '../../Media/VLD1.png'
import { GetPageWithID } from 'Dashboard/PendingFiles/pendingpage.hook';
import { useQuery } from 'react-query';
import { checkInvalidPrimaryKey } from 'GenericForms/Helper';
import { queryKeyes } from 'shared/queryKeys';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';

const PendingVoyageAdjustment = ({ active }: any) => {
    /** State variables start */
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [eventState, setEventState] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const [url, setUrl] = useState<string>('');
    /** State varibles end */

    /** useEffect start */
    useEffect(() => {
        if (checkInvalidPrimaryKey(VesselID)) {
            setUrl(queryKeyes.pagination.VoyageAdjustmentPage.url(VesselID, AdjustmentApprovalStatus.PENDING));
        }
    }, [VesselID, active])

    /** Queries */
    const { data: PenddingVoyageAdjustment } = useQuery(
        [queryKeyes.pagination.VoyageAdjustmentPage.key, VesselID, url],
        async () => await GetPageWithID(VesselID, url),
        {
            enabled: true,
            staleTime: Infinity,
        }
    )
    /** Open-close modal */

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Consider enum 'loading, sts_discharging, port_discharging' whichever op pass it to pop up & hit url based on the op */

    return (
        <React.Fragment>
            {PenddingVoyageAdjustment?.results?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No Voyage Adjustments available for this vessel</Label>
                </div> :
                <React.Fragment>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <VoyageAdjustmentTableHeadComponent />
                                    <tbody>
                                        {PenddingVoyageAdjustment && PenddingVoyageAdjustment?.results?.map((cii: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            {cii?.cii_end_date_time !== null ?
                                                                <>
                                                                    <b>{cii?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {cii?.cii_begin_date_time?.toString().substring(11, 16)}</b> to
                                                                    <b> {cii?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {cii?.cii_end_date_time?.toString().substring(11, 16)}</b>
                                                                </>
                                                                : <><strong>Currently in progress</strong></>}
                                                        </td>
                                                        <td className='nopadtop align-middle p-2'>{cii?.cii_adjustment_name}</td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <React.Fragment>
                                                                <td className='nopadtop d-flex flex-warp p-2 gap-2 justify-content-center'>
                                                                    <img
                                                                        src={ValidateIcon}
                                                                        alt="Validate Icon"
                                                                        id={`cii_validate${index}`}
                                                                        className='text-centre pointer'
                                                                        height="30"
                                                                        onMouseEnter={() => setHoverId(`cii_validate${index}`)}
                                                                        onMouseLeave={() => setHoverId(null)}
                                                                        onClick={() => {
                                                                            setAdjustmentPopup(true);
                                                                            setEventState(cii);
                                                                            setHoverId(null);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {hoverId !== null &&
                                            <ToolTip
                                                target={hoverId}
                                                message={`Validate Voyage Adjustment`}
                                                isOpen={hoverId !== null}
                                            />
                                        }
                                        {adjustmentPopup === true &&
                                            <VoyageAdjustmentPopup
                                                state={adjustmentPopup}
                                                setState={setAdjustmentPopup}
                                                toggle={tog_backdrop}
                                                event={eventState}
                                                target={`popup${eventState?.id}`}
                                                root="pending"
                                            />
                                        }
                                    </tbody>
                                    <tfoot>
                                        {PenddingVoyageAdjustment?.results?.length > 0 &&
                                            <tr>
                                                <td colSpan={5} className="p-2 ">
                                                    <PageNavButton setPageUrl={setUrl} pageobj={PenddingVoyageAdjustment} pageUrl={url} />
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default PendingVoyageAdjustment