import { successToast } from 'Components/Toasts';
import ToolTip from 'Components/ToolTip';
import React, { useEffect, useState } from 'react'
import { Button, CardFooter, Col, Label } from 'reactstrap'

interface DisplayPasswordType {
    userName: string;
    tempPassword: string;
    setState: (state: boolean) => void;

}

const DisplayPassword = ({ userName, tempPassword, setState }: DisplayPasswordType) => {
    const [showPassword, setShowPassword] = useState<boolean>(true);

    useEffect(() => {
        // Show the password for 30 seconds
        const timer = setTimeout(() => setShowPassword(false), 30000);
        return () => clearTimeout(timer);
    }, [userName]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(tempPassword)
        successToast("Copied to clipboard");
    };

    return (
        <React.Fragment>
            <Label>{userName}</Label>
            <Col>
                <Label >Temporary password:</Label>
                <Label className='ml-1'> {showPassword ? tempPassword : "********"}</Label>
                <i
                    className="dripicons-copy ml-1 pointer"
                    onClick={copyToClipboard}
                    id="copy-to-clipboard"
                />
                <ToolTip target='copy-to-clipboard' message='Copy' placement="top" />
            </Col>
            <CardFooter className="p-2 d-flex justify-content-end border rounded">
                <Button color="primary" className="btn_size_cstm" onClick={() => setState(false)}>
                    Ok
                </Button>
            </CardFooter>
        </React.Fragment>
    )
}

export default DisplayPassword