import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React, { useState } from 'react';
import { Card, CardHeader, Col, Row, TabContent, TabPane } from 'reactstrap';
import { EventPrecedence, ReportingOpConstant } from 'shared/constants';
import SludgeBilgeWaterComponent from './partials/SludgeBilgeWaterComponent';
import EngineParameterComponent from './partials/EngineParameterComponent';
import ThermalOilHeaterComponent from './partials/ThermalOilHeaterComponent';

interface LfondsAddInformationType {
    activeTab: number,
    toggleTab: any,
    tabsIdList: any[],
    toggleDynamicTabs: any
    record: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    previousTabIndex: number
}

const LfondsAddInformation = ({
    toggleDynamicTabs,
    record,
    ReportID,
    VesselID,
    VoyageID,
    previousTabIndex
}: LfondsAddInformationType) => {
    /** State variables */
    const [activeAddTab, setactiveAddTab] = useState(1);
    /** State variables end */

    /** Change wizard's tabs */
    const toggleAddTab = (tab: number) => {
        if (activeAddTab !== tab) {
            if (tab >= 1 && tab <= (record?.event_precedence_id === EventPrecedence.NOON ? 3 : 2)) {
                setactiveAddTab(tab);
            }
        }
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={activeAddTab} className="tabHeight">
                    <TabPane tabId={record && record?.event_precedence_id === EventPrecedence.NOON ? 1 : 0}>
                        <SludgeBilgeWaterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                        />
                    </TabPane>
                    <TabPane tabId={(record && record?.event_precedence_id === EventPrecedence.NOON) ? 2 :
                        (record && record?.event_precedence_id !== EventPrecedence.NOON &&
                            record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ? 1 : 0}>
                        <EngineParameterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                            activeAddTab={activeAddTab}
                            record={record}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                        />
                    </TabPane>
                    <TabPane tabId={record && record?.event_precedence_id === EventPrecedence.NOON ? 3 :
                        (record && record?.event_precedence_id !== EventPrecedence.NOON &&
                            record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ? 2 : 1}>
                        <ThermalOilHeaterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                            record={record}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default LfondsAddInformation