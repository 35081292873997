import React, { useState } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormFeedback,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";

//redux
import withRouter from "../Components/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import images
import logo from "../Media/LogoLogin.png";

//Import config
import CarouselPage from "../Components/CarouselPage";
import { useMutation, useQueryClient } from "react-query";
import { errorToast, infoToast, successToast } from "../Components/Toasts";
import { loginApi, setAuthData } from "../utils/auth/authUtils";
import env from '../environment_system/env_system';

// interface LoginProps {
//     history: object;
//     router: any;
// }

const Login = () => {
    const [PasswordType, setPasswordType] = useState<'password' | 'text'>('password');

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(loginApi, {
        onSuccess: (data) => {
            queryClient.setQueryData('user', data);
            setAuthData(JSON.stringify(data));
            if (env.desktop_app === true) {
                successToast('Logged in successfully!', "#/dashboard");
            } else {
                successToast("Logged in successfully!", "/dashboard");
            }
        },
        onError: (error: any) => {
            errorToast('Login failed! ' + error.message);
        }
    });
    //         onSuccess: async (data) => {
    //             // Fetch user roles after successful login
    //             const roles = await fetchUserRoles(); // Replace with your actual function

    //             // Set user roles in the query client or global state
    //             queryClient.setQueryData('userRoles', roles);

    //             // Continue with the rest of the logic
    //             queryClient.setQueryData('user', data);
    //             setAuthData(JSON.stringify(data));

    //             // Use roles to determine redirection or perform other role-based actions
    //             if (roles.includes(Roles.MASTER)) {
    //                 successToast('Success! Redirecting to Dashboard', env['base_name'] + "dashboard");
    //             } else {
    //                 errorToast('Unauthorized access!');
    //             }
    //         },
    //         onError: (error: any) => {
    //             errorToast('Login failed! ' + (error.message ?? ''));
    //         }
    //     });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: "",
            password: "",
            checkbox: false,
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().min(1).required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            infoToast("Checking Logging! Please wait!");
            mutate(values);
        },
    });

    // const { error } = useSelector((state: any) => ({
    //     error: state.login.error,
    // }));

    return (
        <React.Fragment>
            <div className="auth-page">
                {/* <Container fluid className="p-0"> */}
                <Row className="g-0">
                    <Col lg={4} md={5} className="col-xxl-3">
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5 text-center">
                                        <div className="d-block auth-logo">
                                            <img src={logo} alt="" height="140" />{" "}
                                        </div>
                                    </div>
                                    <Form
                                        className="custom-form mt-4 pt-2"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();

                                        }}
                                        autoComplete="off"
                                        noValidate
                                    >
                                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                                        <div className="mb-3">
                                            <Label className="form-label mb-0">Email</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                type="email"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                defaultValue={validation.values.username}
                                                invalid={
                                                    validation.touched.username &&
                                                        validation.errors.username
                                                        ? true : false
                                                }
                                            />
                                            {validation.touched.username &&
                                                validation.errors.username ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.username}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <Label className="form-label mb-0">Password</Label>
                                        <div className="mb-3 position-relative">
                                            <Input
                                                name="password"
                                                className="form-control"
                                                defaultValue={validation.values.password}
                                                type={PasswordType}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={
                                                    validation.touched.password &&
                                                        validation.errors.password
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <i
                                                className={`mdi ${PasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye`}
                                                onClick={() => setPasswordType(PasswordType === 'password' ? 'text' : 'password')}
                                            />
                                            {validation.touched.password &&
                                                validation.errors.password ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.password}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <div className="mt-3 d-grid">
                                                    <button className="primary btn btn-primary" disabled={isLoading} type="submit">
                                                        {(isLoading) ? 'Loading...' : 'Log In'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/*{env?.desktop_app === true &&
                                            verification === true &&
                                            <>
                                             <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="remember-check"
                                                        name="checkbox"
                                                        onChange={(e: any) => validation.handleChange(e)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="remember-check"
                                                    >
                                                        Check this to continue
                                                    </label>
                                                </div>
                                                <Row className="mt-3">
                                                    {validation.values.checkbox === false &&
                                                        <Navigate to={"/dashboard"} />
                                                    }
                                                </Row>
                                            </>
                                        } */}
                                    </Form>
                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">
                                            © {new Date().getFullYear()}. Developed by{" "}
                                            ecoSAIL
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <CarouselPage />
                </Row>
                {/* </Container> */}
            </div >
            <ToastContainer />
        </React.Fragment >
    );
};

export default withRouter(Login);
Login.propTypes = {
    history: PropTypes.object,
};


