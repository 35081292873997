import React from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import { Input, Label, Button, Row, Container, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Layout from "HorizontalMenu/Menu";
import Navbar from "HorizontalMenu/Navbar";
import apiGlobal from "global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import { queryKeyes } from "shared/queryKeys";
import { errResponse } from "GenericForms/Helper";

/**
 * OrganizationConfiguration Component
 * 
 * This component provides a form interface for configuring organizational settings,
 * including organization name, password policy, and tracking options.
 */
const OrganizationConfiguration = () => {
    // Initial values for the form fields
    const initialValues = {
        organisation_name: null as string | null, // Organization name
        default_password_policy: "random", // Default password policy ("random" or "default")
        default_password: null as string | null, // Default password
        track_old_password: false, // Whether to track old passwords
    };

    /**
     * Handles form submission by sending the form data to the backend API.
     * @param values - Form data submitted by the user
     */
    const handleSubmit = async (values: any) => {
        apiGlobal.post(queryKeyes.organization.OrganizationConfiguration.url(), values).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    successToast("Data saved successfully!");
                }
            })
            .catch((err) => {
                if (errResponse.includes(err?.response?.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
    };

    return (
        <React.Fragment>
            {/* Layout and Navbar */}
            <Layout children={Navbar} />
            <div className=" page-content">
                <Container fluid>
                    <Card>
                        {/* Formik for form handling */}
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ values, errors }) => (
                                <Form>
                                    {/* Title */}
                                    <CardHeader className="p-2">
                                        <div className="mb-3">
                                            <h4>Organization Configuration</h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {/* Organization Name Field */}
                                        <div className="mb-3">
                                            <Label htmlFor="organisation_name">Organization Name</Label>
                                            <Field name="organisation_name">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        type="text"
                                                        {...field}
                                                        id="organisation_name"
                                                        placeholder="Enter Organization Name"
                                                        className="form-control w-60"
                                                        style={{ width: '40%' }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        {/* Password Policy and Default Password Fields */}
                                        <Row>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <Label htmlFor="default_password_policy">Password Policy</Label>
                                                    <Field
                                                        name="default_password_policy"
                                                        as="select"
                                                        id="default_password_policy"
                                                        className="form-select"
                                                        style={{ width: '83%' }}
                                                    >
                                                        <option value="random">Random</option>
                                                        <option value="default">Default</option>
                                                    </Field>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Label htmlFor="default_password">Default Password</Label>
                                                    <Field name="default_password">
                                                        {({ field, form }: FieldProps) => (
                                                            <Input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(e: any) => {
                                                                    const value = e.target.value.trim() === "" ? null : e.target.value;
                                                                    form.setFieldValue(field.name, value);
                                                                }}
                                                                id="default_password"
                                                                placeholder="Enter default password"
                                                                className="form-control"
                                                                disabled={values.default_password_policy === "random"}
                                                                style={{ width: '83%' }}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </Row>
                                        {/* Track Old Password Checkbox */}
                                        <div className="form-check mb-3">
                                            <Field
                                                name="track_old_password"
                                                type="checkbox"
                                                id="track_old_password"
                                                className="form-check-input"
                                            />
                                            <Label
                                                htmlFor="track_old_password"
                                                className="form-check-label"
                                            >
                                                Track Old Password
                                            </Label>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        {/* Save Button */}
                                        <div className="d-flex justify-content-end">
                                            <Button type="submit" color="primary">
                                                Save
                                            </Button>
                                        </div>
                                    </CardFooter>
                                    {/* Debugging Tool for Form Values */}
                                    <Row>
                                        <FormValuesDebug values={[values, errors, initialValues]} />
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrganizationConfiguration;
