import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { Roles } from 'shared/constants'
import { hasRole } from 'utils/auth/authUtils'
const MessageCard = () => {
    return (
        <div className='mt-3'>
            <Col lg={4}>
                <Card className="border border-primary">
                    <div className="card-header bg-transparent border-primary">
                        <h5 className="my-0 text-primary">Notification</h5>
                    </div>
                    <CardBody>
                        <h5 className="card-title">Dashboard disabled</h5>
                        <p className="card-text">
                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) &&
                                "No vessels configured. Please add a vessel to enable dashboard features."}
                            {(hasRole(Roles.CHIEFENGINEER) || hasRole(Roles.MASTER)) &&
                                "No vessels available. Contact your admin to configure vessels."}
                        </p>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

export default MessageCard