import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
    CardFooter,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import { useQuery } from "react-query";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import { queryKeyes } from "shared/queryKeys";
import {
    loadFreshWaterObject,
    loadPreviousFreshWater,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldProps, Formik } from "formik";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import apiGlobal from "global/api.global";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import { queryClient } from "react-query/queryClient";
import { handleDynamicPreviousTabId, handleServerResponse } from "GenericForms/Helper";
import env from "environment_system/env_system";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { EngineerReportTabsConstant } from "shared/constants";
import ErrorTooltip from "Components/ErrorTooltip";

interface FreshWaterType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
    tabsIdList: any[];
    record: any;
    toggleDynamicTabs: any;
    previousTabIndex: number;
    setErrorMessage: any;
}

const FreshWaterComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    tabsIdList,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    setErrorMessage,
}: FreshWaterType) => {
    /** State variables */
    const [freshWaterROB, setFreshWaterROB] = useState(0);
    const [previousFreshWaterROB, setPreviousFreshWaterROB] = useState(0);
    /** State variables end */

    /** Queries */
    /** Previous report of fresh water */
    const {
        data: prevFreshWaterReport,
        isLoading: prevFreshWaterReportLoading,
        isError: prevFreshWaterReportError,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.PreviousFreshWater.key, VesselID, ReportID],
        async () => {
            return await loadPreviousFreshWater(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Fresh water object used for edit */
    const {
        data: FreshWaterObject,
        isLoading: FreshWaterObjectLoading,
        isError: FreshWaterObjectError,
    } = useQuery(
        [queryKeyes.vessel.FreshWaterObject.key, VesselID, ReportID],
        async () => {
            return await loadFreshWaterObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to initial object of Fresh water */
    const getInitialFreshWater = () => {
        if (FreshWaterObject && FreshWaterObject?.id > 0) {
            return FreshWaterObject;
        } else {
            return {
                rob: prevFreshWaterReport?.rob,
                previous_rob: prevFreshWaterReport?.rob,
                amount_of_fresh_water_produced: null,
                amount_of_fresh_water_received: null,
                amount_of_domestic_water_consumed: null,
                amount_of_distilled_water_consumed: null,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };

    /** Fresh water Formik object */
    const FreshWaterFormik = {
        initialValues: getInitialFreshWater(),
        validationSchema: Yup.object().shape({
            amount_of_fresh_water_produced: Yup.string().required(
                commonValidationMessages.required
            ),
            amount_of_fresh_water_received: Yup.string().required(
                commonValidationMessages.required
            ),
            amount_of_domestic_water_consumed: Yup.string().required(
                commonValidationMessages.required
            ),
            amount_of_distilled_water_consumed: Yup.string().required(
                commonValidationMessages.required
            ),
        }),
    };

    /** useEfect start */
    useEffect(() => {
        if (FreshWaterObject && FreshWaterObject.id > 0) {
            setFreshWaterROB(FreshWaterObject.rob);
            setPreviousFreshWaterROB(FreshWaterObject.previous_rob);
        } else if (prevFreshWaterReport) {
            setFreshWaterROB(prevFreshWaterReport.rob);
            setPreviousFreshWaterROB(prevFreshWaterReport.rob);
        }
    }, [prevFreshWaterReport, FreshWaterObject]);
    useEffect(() => {
        FreshWaterFormik.initialValues = getInitialFreshWater();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevFreshWaterReport]);
    /** useEfect end */

    /** Calculate fresh water ROB */
    const calculateFreshWaterROB = (
        e: any,
        values: any,
        form: any,
        name: string
    ) => {
        form.setFieldValue("previous_rob", previousFreshWaterROB);
        if (e.target.value === "") {
            e.target.value = 0;
            form.setFieldValue(name, 0);
        }
        if (name === "amount_of_fresh_water_produced") {
            setFreshWaterROB(
                previousFreshWaterROB +
                parseFloat(e.target.value) +
                parseFloat(values.amount_of_fresh_water_received) -
                parseFloat(values.amount_of_domestic_water_consumed) -
                parseFloat(values.amount_of_distilled_water_consumed)
            );
            form.setFieldValue(
                "rob",
                (
                    previousFreshWaterROB +
                    parseFloat(e.target.value) +
                    parseFloat(values.amount_of_fresh_water_received) -
                    parseFloat(values.amount_of_domestic_water_consumed) -
                    parseFloat(values.amount_of_distilled_water_consumed)
                )?.toFixed(2)
            );
        } else if (name === "amount_of_fresh_water_received") {
            setFreshWaterROB(
                previousFreshWaterROB +
                parseFloat(values.amount_of_fresh_water_produced) +
                parseFloat(e.target.value) -
                parseFloat(values.amount_of_domestic_water_consumed) -
                parseFloat(values.amount_of_distilled_water_consumed)
            );
            form.setFieldValue(
                "rob",
                (
                    previousFreshWaterROB +
                    parseFloat(values.amount_of_fresh_water_produced) +
                    parseFloat(e.target.value) -
                    parseFloat(values.amount_of_domestic_water_consumed) -
                    parseFloat(values.amount_of_distilled_water_consumed)
                )?.toFixed(2)
            );
        } else if (name === "amount_of_domestic_water_consumed") {
            setFreshWaterROB(
                previousFreshWaterROB +
                parseFloat(values.amount_of_fresh_water_produced) +
                parseFloat(values.amount_of_fresh_water_received) -
                parseFloat(e.target.value) -
                parseFloat(values.amount_of_distilled_water_consumed)
            );
            form.setFieldValue(
                "rob",
                (
                    previousFreshWaterROB +
                    parseFloat(values.amount_of_fresh_water_produced) +
                    parseFloat(values.amount_of_fresh_water_received) -
                    parseFloat(e.target.value) -
                    parseFloat(values.amount_of_distilled_water_consumed)
                )?.toFixed(2)
            );
        } else if (name === "amount_of_distilled_water_consumed") {
            setFreshWaterROB(
                previousFreshWaterROB +
                parseFloat(values.amount_of_fresh_water_produced) +
                parseFloat(values.amount_of_fresh_water_received) -
                parseFloat(values.amount_of_domestic_water_consumed) -
                parseFloat(e.target.value)
            );
            form.setFieldValue(
                "rob",
                (
                    previousFreshWaterROB +
                    parseFloat(values.amount_of_fresh_water_produced) +
                    parseFloat(values.amount_of_fresh_water_received) -
                    parseFloat(values.amount_of_domestic_water_consumed) -
                    parseFloat(e.target.value)
                )?.toFixed(2)
            );
        }
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Fresh Water ROB</h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(prevFreshWaterReportLoading || FreshWaterObjectLoading) && (
                <Loading message="Loading required data!" />
            )}
            {prevFreshWaterReportError && (
                <ErrorComponent message="Unable to load required data!" />
            )}
            {FreshWaterObjectError && getInitialFreshWater()}
            {!(prevFreshWaterReportLoading || FreshWaterObjectLoading) &&
                !prevFreshWaterReportError && (
                    <Formik
                        onSubmit={async (values: any, actions: any) => {
                            const responseArray: any = [];
                            if (env?.form_validation === false) {
                                setErrorMessage(null)
                                toggleDynamicTabs(previousTabIndex + 1);
                            }
                            actions.setSubmitting(true);
                            /** Fresh water submit */
                            if (FreshWaterObject && FreshWaterObject.id > 0) {
                                responseArray.push(apiGlobal
                                    .put(`/fresh_water/${values.id}/`, values))
                            } else {
                                responseArray.push(apiGlobal
                                    .post(`/fresh_water/`, values))
                            }
                            await handleServerResponse(responseArray).then(async (res) => {
                                if (res === true) {
                                    queryClient.invalidateQueries(queryKeyes.vessel.PreviousFreshWater.key);
                                    queryClient.invalidateQueries(queryKeyes.vessel.FreshWaterObject.key);
                                    if (env?.form_validation === true) {
                                        setErrorMessage(null)
                                        toggleDynamicTabs(previousTabIndex + 1);
                                    }
                                } else {
                                    setErrorMessage(res)
                                }
                                actions.setSubmitting(false);
                            })
                        }}
                        initialValues={FreshWaterFormik.initialValues}
                        validationSchema={env?.form_validation === true ? FreshWaterFormik.validationSchema : null}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            touched,
                            handleBlur,
                            isSubmitting,
                            setErrors,
                        }: {
                            values: any;
                            errors: any;
                            handleSubmit: any;
                            handleChange: any;
                            touched: any;
                            handleBlur: any;
                            isSubmitting: any;
                            setErrors: any;
                        }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                <CardBody className="engineer-card-body">
                                    <Row lg={12}>
                                        <Col lg={8} className="mb-1">
                                            <Row lg={12} className="pb-2 pt-1">
                                                <Col lg={6}>
                                                    <Label
                                                        className="asteric mb-0"
                                                        for="amount_of_fresh_water_produced"
                                                    >
                                                        Amount of fresh water produced
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="amount_of_fresh_water_produced_msg"
                                                        ></i>
                                                    </Label>
                                                    <ToolTip
                                                        target="amount_of_fresh_water_produced_msg"
                                                        message={`${TooltipMsg.Fresh_Water.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "amount_of_fresh_water_produced_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <div className="input-group">
                                                        <Field name={"amount_of_fresh_water_produced"}>
                                                            {({ field, form }: FieldProps) => (
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-7"
                                                                    id="amount_of_fresh_water_produced"
                                                                    name={field.name}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    onBlur={(e: any) => {
                                                                        handleBlur(e);
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        values?.amount_of_fresh_water_produced
                                                                    }
                                                                    autoFocus
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text round_border">
                                                            mt
                                                        </div>
                                                    </div>
                                                    {touched?.amount_of_fresh_water_produced &&
                                                        errors?.amount_of_fresh_water_produced &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target={`amount_of_fresh_water_produced`}
                                                                message={errors?.amount_of_fresh_water_produced}
                                                                open={
                                                                    errors?.amount_of_fresh_water_produced
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </Col>
                                                <Col lg={6}>
                                                    <Label
                                                        className="asteric mb-0"
                                                        for="amount_of_fresh_water_received"
                                                    >
                                                        Amount of fresh water recieved
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="amount_of_fresh_water_received_msg"
                                                        ></i>
                                                    </Label>
                                                    <ToolTip
                                                        target="amount_of_fresh_water_received_msg"
                                                        message={`${TooltipMsg.Fresh_Water.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "amount_of_fresh_water_received_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <div className="input-group">
                                                        <Field name={"amount_of_fresh_water_received"}>
                                                            {({ field, form }: FieldProps) => (
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-7"
                                                                    id="amount_of_fresh_water_received"
                                                                    name={field.name}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    onBlur={(e: any) => {
                                                                        handleBlur(e);
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        values?.amount_of_fresh_water_received
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text round_border">
                                                            mt
                                                        </div>
                                                    </div>
                                                    {touched?.amount_of_fresh_water_received &&
                                                        errors?.amount_of_fresh_water_received &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target={`amount_of_fresh_water_received`}
                                                                message={errors?.amount_of_fresh_water_received}
                                                                open={
                                                                    errors?.amount_of_fresh_water_received
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </Col>
                                            </Row>
                                            <Row lg={12}>
                                                <Col lg={6}>
                                                    <Label
                                                        className="mb-0 label-w-20 asteric"
                                                        for="amount_of_domestic_water_consumed"
                                                    >
                                                        Amount of domestic water consumed
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="amount_of_domestic_water_consumed_msg"
                                                        ></i>
                                                    </Label>
                                                    <ToolTip
                                                        target="amount_of_domestic_water_consumed_msg"
                                                        message={`${TooltipMsg.Fresh_Water.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "amount_of_domestic_water_consumed_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <div className="input-group">
                                                        <Field name={"amount_of_domestic_water_consumed"}>
                                                            {({ field, form }: FieldProps) => (
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-7"
                                                                    id="amount_of_domestic_water_consumed"
                                                                    name={field.name}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    onBlur={(e: any) => {
                                                                        handleBlur(e);
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        values?.amount_of_domestic_water_consumed
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text round_border">
                                                            mt
                                                        </div>
                                                    </div>
                                                    {touched?.amount_of_domestic_water_consumed &&
                                                        errors?.amount_of_domestic_water_consumed &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target={`amount_of_domestic_water_consumed`}
                                                                message={
                                                                    errors?.amount_of_domestic_water_consumed
                                                                }
                                                                open={
                                                                    errors?.amount_of_domestic_water_consumed
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </Col>
                                                <Col lg={6}>
                                                    <Label
                                                        className="mb-0 label-w-20 asteric"
                                                        for="amount_of_distilled_water_consumed"
                                                    >
                                                        Amount of distilled water consumed
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="amount_of_distilled_water_consumed_msg"
                                                        ></i>
                                                    </Label>
                                                    <ToolTip
                                                        target="amount_of_distilled_water_consumed_msg"
                                                        message={`${TooltipMsg.Fresh_Water.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "amount_of_distilled_water_consumed_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <div className="input-group">
                                                        <Field name={"amount_of_distilled_water_consumed"}>
                                                            {({ field, form }: FieldProps) => (
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-7"
                                                                    id="amount_of_distilled_water_consumed"
                                                                    name={field.name}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    onBlur={(e: any) => {
                                                                        handleBlur(e);
                                                                        handleChange(e);
                                                                        calculateFreshWaterROB(
                                                                            e,
                                                                            values,
                                                                            form,
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        values?.amount_of_distilled_water_consumed
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text round_border">
                                                            mt
                                                        </div>
                                                    </div>
                                                    {touched?.amount_of_distilled_water_consumed &&
                                                        errors?.amount_of_distilled_water_consumed &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target={`amount_of_distilled_water_consumed`}
                                                                message={
                                                                    errors?.amount_of_distilled_water_consumed
                                                                }
                                                                open={
                                                                    errors?.amount_of_distilled_water_consumed
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="d-flex align-items-center justify-content-center"
                                            sm={4}
                                        >
                                            <div className="badge-soft-info fresh_Water_Card d-flex flex-column align-items-center justify-content-center max-h-7 batch-bg-color">
                                                <div className="p-1">
                                                    <Label className="mb-0 font-large">
                                                        Fresh Water ROB
                                                    </Label>
                                                </div>
                                                <div className="p-1">
                                                    <strong className="waterROBlbl mb-0">
                                                        {Number.isNaN(freshWaterROB)
                                                            ? previousFreshWaterROB
                                                            : freshWaterROB?.toFixed(2)}{" "}
                                                        mt
                                                    </strong>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="p-2 py-3">
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm"
                                                onClick={() => {
                                                    setErrors({});
                                                    handleDynamicPreviousTabId(
                                                        false,
                                                        tabsIdList,
                                                        record,
                                                        toggleTab,
                                                        toggleDynamicTabs,
                                                        previousTabIndex,
                                                        null,
                                                        null,
                                                        EngineerReportTabsConstant.FreshWaterId
                                                    );
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug
                                    values={[
                                        values,
                                        errors,
                                        FreshWaterFormik.initialValues,
                                        touched,
                                    ]}
                                />
                            </Form>
                        )}
                    </Formik>
                )
            }
        </Card >
    );
};

export default FreshWaterComponent;
