import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import { checkInvalidPrimaryKey, errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Label, Row, Col, Button, Modal } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import N2AdjustmentTableHeadComponent from './N2AdjustmentTableHeadComponent';
import ToolTip from 'Components/ToolTip';
import ViewReport from '../../Media/VD2.png'
import LNGCargoQuality from 'Components/LNGCargoQuality';
import { queryKeyes } from 'shared/queryKeys';
import { GetPageWithID } from 'Dashboard/PendingFiles/pendingpage.hook';
import { useQuery } from 'react-query';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import { queryClient } from 'react-query/queryClient';

const ApprovedN2Adjustment = ({active}: any) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [n2adjustment, setN2Adjustment] = useState<any>({});
    const [disapproveBool, setDisapproveBool] = useState<any>({});
    const [viewDetailsBool, setViewDetailsBool] = useState(false);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const [url, setUrl] = useState<string>('');
    /** State variables end */

    /** Function to disapprove N2 Correction */
    const disapproveN2Correction = () => {
        const n2adjustmentObj = {
            'approval_status': AdjustmentApprovalStatus.PENDING
        }
        setDisapproveBool(false);
        apiGlobal.patch(`/n2_adjustment/${n2adjustment?.id}/`, n2adjustmentObj).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.N2AdjustmentObjectByVessel.key);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    const { data: ApprovedN2Adjustment } = useQuery(
        [queryKeyes.pagination.N2AdjustmentPage.key, VesselID, url],
        async () => await GetPageWithID(VesselID, url),
        {
            enabled: true,
            staleTime: Infinity,
        }
    )

    useEffect(() => {
        if (checkInvalidPrimaryKey(VesselID)) {
            setUrl(queryKeyes.pagination.N2AdjustmentPage.url(VesselID, AdjustmentApprovalStatus.APPROVED));
        }
    }, [VesselID, active])

    return (
        <React.Fragment>
            {ApprovedN2Adjustment?.results?.length === 0 || ApprovedN2Adjustment?.results?.length === undefined ?
                <div className='text-center'>
                    <Label className='mb-3'>No N<sub>2</sub> corrections are approved for this vessel</Label>
                </div> :
                <React.Fragment>
                    <Row className='mb-0'>
                        <Col lg={7}>
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <N2AdjustmentTableHeadComponent />
                                    <tbody>
                                        {ApprovedN2Adjustment && ApprovedN2Adjustment?.results?.map((n2: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                    <td className='nopadtop align-middle p-2'>
                                                        <>
                                                            <b>{n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                                {n2?.start_date_time?.toString().substring(11, 16)}</b> to
                                                            <b> {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                {n2?.end_date_time?.toString().substring(11, 16)}</b>
                                                        </>
                                                    </td>
                                                    {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                        <>
                                                            <td>
                                                                <img
                                                                    alt="View Report"
                                                                    className='align-middle pointer'
                                                                    src={ViewReport}
                                                                    height="29"
                                                                    id={`view_details${index}`}
                                                                    onMouseEnter={() => setHoverId(`view_details${index}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setViewDetailsBool(true);
                                                                        setHoverId(null);
                                                                    }}
                                                                />

                                                                <Modal
                                                                    size='xl'
                                                                    isOpen={viewDetailsBool}
                                                                    toggle={() => {
                                                                        setViewDetailsBool(!viewDetailsBool);
                                                                    }}
                                                                    backdrop={"static"}
                                                                    id="staticBackdrop"
                                                                >
                                                                    <div className="modal-header p-1 d-flex align-items-center">
                                                                        <h5 className="modal-title mb-0" id="staticBackdropLabel">
                                                                            LNG Cargo Quality Details
                                                                        </h5>
                                                                        <button
                                                                            onClick={() => {
                                                                                setViewDetailsBool(false);
                                                                            }}
                                                                            type="button"
                                                                            className="close pb-1"
                                                                            data-dismiss="modal"
                                                                            aria-label="Close"
                                                                        >
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>d
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <Row>
                                                                            <Col lg={6}>
                                                                                <LNGCargoQuality
                                                                                    VesselID={VesselID}
                                                                                    reportId={n2adjustment?.start_report}
                                                                                    cargoOperation={'loading'}
                                                                                    cardHeader={'LNG Cargo Loading Quality Details'}
                                                                                    form="view-n2Adjustment"
                                                                                    adjustmentDetails={n2adjustment}
                                                                                    setState={setViewDetailsBool}
                                                                                />
                                                                            </Col>
                                                                            <Col lg={6}>
                                                                                <LNGCargoQuality
                                                                                    VesselID={VesselID}
                                                                                    reportId={n2adjustment?.end_report}
                                                                                    cargoOperation={'discharging'}
                                                                                    cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                                    form="view-n2Adjustment"
                                                                                    adjustmentDetails={n2adjustment}
                                                                                    setState={setViewDetailsBool}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Modal>
                                                            </td>
                                                            <td className='align-middle text-center p-2'>
                                                                <Button type='button'
                                                                    className='btn btn-primary waves-effect btn-label waves-light'
                                                                    color='primary'
                                                                    id={`disapprove_btn${n2?.id}`}
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setDisapproveBool(true);
                                                                    }}
                                                                ><i className="bx bx-left-arrow-circle label-icon"></i>
                                                                    Mark as pending
                                                                </Button>
                                                            </td>
                                                        </>
                                                    }
                                                </tr>
                                            )
                                        })
                                        }
                                        {hoverId !== null &&
                                            <ToolTip
                                                target={hoverId}
                                                message={`View details`}
                                                isOpen={hoverId !== null}
                                            /> 
                                        }
                                        {disapproveBool === true &&
                                            <PopOver
                                                target={`disapprove_btn${n2adjustment?.id}`}
                                                handleClick={disapproveN2Correction}
                                                message={'Are you sure you want to disapprove?'}
                                                state={disapproveBool}
                                                setState={setDisapproveBool}
                                            />
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={7}>
                                                <PageNavButton
                                                    pageobj={ApprovedN2Adjustment}
                                                    setPageUrl={setUrl}
                                                    pageUrl={url}
                                                />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ApprovedN2Adjustment