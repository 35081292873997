import React, { useState } from 'react';
import TempAuth from './TempAuth';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { queryKeyes } from 'shared/queryKeys';
import { encrypt } from 'GenericForms/Helper';

interface ResetPasswordType {
    name: string;
    userID: number;
    setState: (state: boolean) => void;
}

const ResetPassword = ({ name, userID, setState }: ResetPasswordType) => {
    const [authenticated, setAuthenticated] = useState<boolean>(
        !!localStorage.getItem("Authorization_key")
    );
    const [tempPassword, setTempPassword] = useState<string>('')
    const [PasswordType, setPasswordType] = useState<'password' | 'text'>('text');
    const key = localStorage.getItem("Authorization_key");

    const handelUpdate = async (password: string) => {
        if (password.length < 1) {
            errorToast("Please enter password");
            return;
        }
        const encryptedPassword = encrypt(password);
        await apiGlobal.post(queryKeyes.user.reset_temp_password.url(),
            {
                password: encryptedPassword,
                user_id: userID,
                authorization_key: key
            }).then((res) => {
                if (res.status === 200) {
                    successToast("password updated succfully ");
                    setState(false);
                }
            }).catch((err) => {
                console.error(err);
                errorToast(`${err?.response?.data?.error}`);
            });
    }

    return (
        <div className="p-2">
            {authenticated ? (
                <React.Fragment>
                    <Label>{name}</Label>
                    <Col>
                        <Row className="align-items-center d-flex">
                            <Col>
                                <Input
                                    type={PasswordType}
                                    placeholder="Enter new password"
                                    onChange={(e) => setTempPassword(e.target.value)}
                                />
                                <i
                                    className={`mdi ${PasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye mr-1`}
                                    onClick={() => setPasswordType(PasswordType === 'password' ? 'text' : 'password')}
                                />
                            </Col>
                            <Col xs="auto">
                                <Button color="primary" size="m" onClick={() => handelUpdate(tempPassword)} >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <TempAuth setAuthenticated={setAuthenticated} />
                    <Label className='mt-2'> <b>[Note]</b> To view the temporary password of the user you need to authenticate with logged in password</Label>
                </React.Fragment>
            )}
        </div>
    );
};

export default ResetPassword;
