import React, { useState, useEffect } from "react";
import { CardBody, TabContent, TabPane, Col, Container, Row, Card, CardHeader, CardFooter } from 'reactstrap';
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import '../../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselDetailsHeader from "../../Components/VesselDetailsHeader";
import apiGlobal from "../../global/api.global";
import { useQuery } from "react-query";
import { queryKeyes } from "../../shared/queryKeys";
import { AddInfoConstant, DeckReportTabsConstant, EngineStatus, ReportingTypeConstant, VesselTypeConstant } from "../../shared/constants";
import { allDecofficerReport, loadPreviousVesselReport } from "../../VesselMaster/vesselMaster.hooks";
import { CpWarrantyComponent } from "./ReportComponents/CpWarrantyComponent";
import DraftsCargoLadingComponent from "./ReportComponents/DraftsCargoLadingComponent";
import STSOperationComponent from "./ReportComponents/STSOperationComponent";
import ETADComponent from "./ReportComponents/ETADComponent";
import LfondsAddInformation from "./AdditionalInformation/Lfonds/LfondsAddInformation";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import WeatherData from "./ReportComponents/WeatherData";
import env from "environment_system/env_system";
import ProactiveAddInformation from "./AdditionalInformation/Proactive/ProactiveAddInformation";
import CIIAdjustmentComponent from "./ReportComponents/CIIAdjustmentComponent";
import Remarks from "./ReportComponents/Remarks";
import EconAddInformation from "./AdditionalInformation/Econ/EconAddInformation";
import ErrorReport from "Components/ErrorReport";
import { useLocation } from "react-router-dom";

const DeckOfficerWizard = () => {
    const { VesselID, VoyageID, ReportID, Reports, Vessels } = useSelector((state: RootState) => state.Reporting);
    let record = Reports.find((rec: any) => rec.id === ReportID) ?? null;
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;
    const [activeTab, setactiveTab] = useState(1);
    const [lastRecord, setLastRecord] = useState({ created_on: '', arrival_datetime: '', arrival_port: '', departure_port: '', departure_datetime: '', reporting_time_utc: '' });
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>()
    const location = useLocation()

    const allReport: any = useQuery([queryKeyes.vessel.decOfficerAll.key, ReportID],
        async () => { return await allDecofficerReport(ReportID) }, {
        enabled: true,
    });
    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= (env?.additional_info_deck === AddInfoConstant.NONE ? DeckReportTabsConstant.RemarksId - 1 : DeckReportTabsConstant.RemarksId)) {
                setactiveTab(tab);
            }
        }
    }

    useEffect(() => {
        const loadLastRecord = async () => {
            await apiGlobal.get(`/vessel_reporting_information/get_previous_by_id/?id=${ReportID}&vessel_id=${VesselID}`).then(res => {
                setLastRecord(res.data);
                return res.data;
            }).catch(err => {
                console.error(err);
            })
        }; loadLastRecord();
    }, [ReportID, VesselID]);

    const { data: PreviousVesselReport, isLoading: PreviousVesselReportLoading, isError: PreviousVesselReportError } = useQuery(
        [queryKeyes.vessel.PreviousVesselReport.key, VesselID, ReportID],
        () => loadPreviousVesselReport(ReportID, VesselID),
        { staleTime: Infinity });

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    const ciiStsCpTabsVisible = () => {
        if (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && record?.is_cii_adjustment_report && record.status_name === EngineStatus.ringFullAway)) {
            return true;
        } else {
            return false;
        }
    }

    const ciiStsCpTabsAny2Visible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && record?.is_cii_adjustment_report && record?.status_name === EngineStatus.ringFullAway) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report && record?.status_name === EngineStatus.ringFullAway) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && record?.is_cii_adjustment_report && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    const ciiStsCpTabsAny1Visible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report && record?.status_name === EngineStatus.ringFullAway) ||
            (!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && record?.is_cii_adjustment_report && !(record?.status_name === EngineStatus.ringFullAway)) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    const ciiStsCpTabsAny0Visible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    const CIIAdjustmentTabId = () => {
        if (record && record?.is_cii_adjustment_report) {
            return DeckReportTabsConstant.CIIAdjustmentId;
        } else {
            return -1;
        }
    }

    const STSTabId = () => {
        if (vessel && record && ((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && record?.is_cii_adjustment_report)) {
            return DeckReportTabsConstant.STSId;
        } else if (vessel && record && ((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && !record?.is_cii_adjustment_report)) {
            return DeckReportTabsConstant.STSId - 1;
        } else {
            return -1
        }
    }

    const CPWarrantiesTabId = () => {
        if (vessel && record && record.status_name === EngineStatus.ringFullAway) {
            if (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true && record?.is_cii_adjustment_report)) {
                return DeckReportTabsConstant.CPWarrantiesId;
            } else if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && record?.is_cii_adjustment_report) ||
                (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                    && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report)
            ) {
                return DeckReportTabsConstant.CPWarrantiesId - 1;
            } else if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !record?.is_cii_adjustment_report)) {
                return DeckReportTabsConstant.CPWarrantiesId - 2;
            }
        } else {
            return -1;
        }
    }

    const deckOfficerTabId = (tab: string) => {
        if (ciiStsCpTabsVisible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId;
            } else if ((tab === 'Econ' || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId;
            } else if (tab === 'Remarks') {
                return DeckReportTabsConstant.RemarksId;
            }
        } else if (ciiStsCpTabsAny2Visible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 1;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 1;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 1;
            } else if (tab === 'Remarks') {
                return DeckReportTabsConstant.RemarksId - 1;
            }
        } else if (ciiStsCpTabsAny1Visible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 2;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 2;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 2;
            } else if (tab === 'Remarks') {
                if (record?.reporting_type === ReportingTypeConstant.ATSEA) {
                    return DeckReportTabsConstant.RemarksId - 2;
                } else {
                    return DeckReportTabsConstant.RemarksId - 3;
                }
            }
        } else if (ciiStsCpTabsAny0Visible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 3;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 3;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 3;
            } else if (tab === 'Remarks') {
                if (record?.reporting_type === ReportingTypeConstant.ATSEA) {
                    return DeckReportTabsConstant.RemarksId - 3;
                } else {
                    return DeckReportTabsConstant.RemarksId - 4;
                }
            }
        }
    }
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardHeader className="p-2">
                            <Row>
                                <Col sm="2" className="d-flex align-items-center">
                                    <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState('VESSEL_REPORTING') }}>
                                        <i className="bx bx-chevron-left me-1" /> Back
                                    </button>
                                </Col>
                                <Col sm="10">
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            {PreviousVesselReportLoading && <Loading message='Loading required data!' />}
                            {PreviousVesselReportError && <ErrorComponent message='Unable to load required data!' />}
                            {!PreviousVesselReportLoading && !PreviousVesselReportError &&
                                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                    <TabContent
                                        activeTab={activeTab}
                                    >
                                        {activeTab === DeckReportTabsConstant.WeatherDataId &&
                                            <TabPane tabId={DeckReportTabsConstant.WeatherDataId} className="tabHeight">
                                                <WeatherData
                                                    lastReocrd={lastRecord}
                                                    record={record}
                                                    vessel={vessel}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === CIIAdjustmentTabId() &&
                                            <TabPane tabId={CIIAdjustmentTabId()} className="">
                                                <CIIAdjustmentComponent
                                                    record={record}
                                                    VesselID={VesselID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === STSTabId() &&
                                            <TabPane tabId={STSTabId()}
                                                className="tabHeight">
                                                <STSOperationComponent
                                                    record={record}
                                                    lastRecord={PreviousVesselReport}
                                                    VesselID={VesselID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === CPWarrantiesTabId() &&
                                            <TabPane tabId={CPWarrantiesTabId()}>
                                                <CpWarrantyComponent
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    record={record}
                                                    toggleTab={toggleTab}
                                                    activeTab={activeTab}
                                                    CPWarrantySpeedDataObject={allReport?.data?.cp_warranties_speed}
                                                    CpWarrantyFuelObject={allReport?.data?.cp_warranties_fuel}
                                                    lastRecord={lastRecord}
                                                    vessel={vessel}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("DraftsCargoReeferBillBallast") &&
                                            <TabPane tabId={deckOfficerTabId("DraftsCargoReeferBillBallast")}>
                                                <DraftsCargoLadingComponent
                                                    record={record}
                                                    vessel={vessel}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("ETAD") &&
                                            <TabPane tabId={deckOfficerTabId("ETAD")}>
                                                <ETADComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleTab={toggleTab}
                                                    etaDataobject={allReport?.data?.expected_time_of_arrival}
                                                    key="ETADComponent"
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.ECON_DECK ? "Econ" : "") &&
                                            <TabPane tabId={deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.ECON_DECK ? "Econ" : "")}>
                                                <EconAddInformation
                                                    toggleTab={toggleTab}
                                                    activeTab={activeTab}
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    record={record}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ? "Lfonds" : "") &&
                                            <TabPane tabId={deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ? "Lfonds" : "")}>
                                                <LfondsAddInformation
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    vessel={vessel}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK ? "Proactive" : "") &&
                                            <TabPane tabId={deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK ? "Proactive" : "")}>
                                                <ProactiveAddInformation
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("Remarks") &&
                                            <TabPane className="px-2" tabId={deckOfficerTabId("Remarks")}>
                                                <Remarks
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    handleVesselState={handleVesselState}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                    </TabContent>
                                </div>
                            }
                        </CardBody>
                        {/* If we get error from server then call ErrorReport component to show error with fields in footer */}
                        {
                            errorMessage &&
                            <CardFooter>
                                <ErrorReport
                                    errorMessages={errorMessage}
                                    ui_url={location.pathname}
                                    setErrorMessage={setErrorMessage} />
                            </CardFooter>
                        }
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DeckOfficerWizard;
