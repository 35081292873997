import React, { useState } from 'react';
import { Card, Input, Button, Row, Col, Label } from 'reactstrap';
import '../global/GlobalCSS.css';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { queryKeyes } from 'shared/queryKeys';
import { encrypt } from 'GenericForms/Helper';
import { useNavigate } from 'react-router-dom';

interface ForsePasswordChangeType {
  message: string;
}

const ForcedPasswordChange = ({ message }: ForsePasswordChangeType) => {
  /** State variables */
  const [existingPasswordType, setExistingPasswordType] = useState<'password' | 'text'>('password');
  const [newPasswordType, setNewPasswordType] = useState<'password' | 'text'>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<'password' | 'text'>('password');
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  /** End of state variables */
  const navigate = useNavigate();
  /**function to validate the password length and passwords match */
  const validate = () => {
    if (newPassword !== confirmPassword) {
      errorToast('New password and confirm password do not match.');
      return false;
    } else if (newPassword.length === 0 || newPassword.length === 0 || oldPassword.length === 0) {
      errorToast('Password cannot be empty');
      return false;
    }
    return true;
  }

  const handleClearFields = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };
  /**Api call to change password */
  const handleResetPassword = () => {
    if (!validate()) {
      return;
    }
    const encryptedOldPassword = encrypt(oldPassword);
    const encryptedNewPassword = encrypt(newPassword);
    const encryptedConfirmPassword = encrypt(confirmPassword);

    apiGlobal.post(queryKeyes.user.changePassword.url(), {
      old_password: encryptedOldPassword,
      new_password: encryptedNewPassword,
      confirm_new_password: encryptedConfirmPassword
    }).then((res) => {
      if (res.status === 200) {
        successToast('Password changed successfully');
        localStorage.removeItem('authData');
        sessionStorage.removeItem('authData');
        navigate('/login');
      }
    }).catch((err) => {
      errorToast(err?.response?.data?.message);
    });
  }
  return (
    <React.Fragment>
      <Card className="shadow  p-4 mt-5 mx-auto max-width-20 justify-content-center align-items-center">
        <Row className="justify-content-center ">
          <Col className='max-width-16 p-0'>
            <h4 className="mb-3">Change Password</h4>
            <p>{message}</p>
            <Label className='mb-0'>Existing Password</Label>
            <div className='mr-4'>
              <div className="position-relative max-width-16 mb-3">
                <Input
                  type={existingPasswordType}
                  placeholder="Enter your password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <i
                  className={`mdi ${existingPasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye`}
                  onClick={() => setExistingPasswordType(existingPasswordType === 'password' ? 'text' : 'password')}
                />
              </div>
              <Label className='mb-0'>New Password</Label>
              <div className="position-relative max-width-16 mb-3">
                <Input
                  type={newPasswordType}
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <i
                  className={`mdi ${newPasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye`}
                  onClick={() => setNewPasswordType(newPasswordType === 'password' ? 'text' : 'password')}
                />
              </div>
              <Label className='mb-0'>Confirm New Password</Label>
              <div className="position-relative max-width-16 mb-3">
                <Input
                  type={confirmPasswordType}
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  className={`eye mdi ${confirmPasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'}`}
                  onClick={() => setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password')}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between gap-4">
              <Button onClick={handleClearFields} className="btn btn-danger" color="danger">
                Clear
              </Button>
              <Button onClick={handleResetPassword} className="btn btn-primary" color="primary">
                Reset Password
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment >
  );
};

export default ForcedPasswordChange;
