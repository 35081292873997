import Layout from 'HorizontalMenu/Menu'
import React, { useState } from 'react'
import Select from 'react-select'
import { Navbar, Container, Row, Col, Label } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { complainceBalanceTable } from './Pooling'
import { Link } from 'react-router-dom'
import PopUp from 'Components/PopUp'
import ExternalVessel from './ExternalVessel'

const PoolingVessels = () => {
    /** State varibales start */
    const [externalVesselBool, setExternalVesselBool] = useState(false)
    /** State variables end */

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className='mb-2'>
                        <Col sm={{ size: 2, offset: 2 }} className='d-flex align-items-center'>
                            <h4 className='mb-0'>Pool A</h4>
                        </Col>
                        <Col sm={{ size: 2, offset: 4 }}>
                            <Label className="mb-0">Status</Label>
                            <Select
                                options={[
                                    { id: 1, status: 'Draft' },
                                    { id: 2, status: 'Final' }
                                ]}
                                getOptionLabel={(option: any) => option.status}
                                getOptionValue={(option: any) => option.id}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={{ size: 2, offset: 8 }}>
                            <button color='primary' className='btn btn-primary justify_right' onClick={() => { setExternalVesselBool(true) }}>
                                <i className="dripicons-plus font-size-16 align-middle" />
                                External Vessel
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 8, offset: 2 }}>
                            <div className="table-responsive p-0">
                                <table className="table mb-2">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align-middle text-center sr-no-width">#</th>
                                            <th className="p-2 align-middle text-center">Vessel</th>
                                            <th className="p-2 align-middle text-center">IMO number</th>
                                            <th className="p-2 align-middle text-right">Compliance balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {complainceBalanceTable?.filter((item: any, indx: number) => indx <= 2)?.map((compliance: any) => {
                                            return (
                                                <tr>
                                                    <td className="p-2 align-middle sr-no-width">{compliance?.id}</td>
                                                    <td className="p-2 align-middle text-center">{compliance?.vessel}</td>
                                                    <td className="p-2 align-middle text-center">{compliance?.imo}</td>
                                                    <td className='p-2 align-middle text-right'>
                                                        <Label className=
                                                            {`mb-0  ${compliance?.compliance_balance > 0 ? 'coloured-bg-green' : 'label-red'}`}
                                                        >
                                                            {compliance?.compliance_balance}
                                                        </Label>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <th colSpan={3} className='p-2 align-middle text-right'>Total</th>
                                            <td className='p-2 align-middle text-right'><Label className='coloured-bg-green'>550</Label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 2, offset: 8 }}>
                            <Link
                                to="/pooling_list"
                                className="btn btn-primary justify_right mt-3"
                            >
                                Save
                            </Link>
                        </Col>
                    </Row>
                    {externalVesselBool &&
                        <PopUp
                            state={externalVesselBool}
                            setState={setExternalVesselBool}
                            body={
                                <ExternalVessel />
                            }
                            title='External Vessel'
                            size='sm'
                        />
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PoolingVessels