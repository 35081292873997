import { ReportingStatusConstant, VoyageConstant } from './constants';

/**
 * Declare the fetch query here and url here to avoid duplicate urls
 */
export const queryKeyes = {
  masters: {
    region: {
      title: 'Region',
      key: 'region',
      url: () => '/region/',
    },
    fuelMaster: {
      key: 'fuel-master',
      url: () => '/fuel_master/',
      title: 'Url for fuel master',
    },
    fuelTypeMaster: {
      title: 'fuel-type-master',
      key: 'fuel-type-master',
      url: () => '/fuel_type_master/',
    },
    portMaster: {
      title: 'port-master',
      url: () => '/port_master/',
    },
    FuelSubTypes: {
      title: 'fuel sub types',
      key: 'fuel-sub-types',
      url: () => `/fuel_sub_type_master/`,
    },
    PortActivities: {
      title: 'load port activities',
      key: 'port-activities',
      url: () => `/port_activity_master/`,
    },
    LNGElements: {
      title: 'load LNG elements',
      key: 'lng-elements',
      url: () => `/lng_element_master/`,
    },
    Fleets: {
      title: 'load fleets',
      key: 'fleeta',
      url: () => `/fleet_master/`,
    },
    FleetVessels: {
      title: 'Vessels present in fleets',
      key: 'fleet-vessels',
      url: () => `fleet_vessel/`,
    },
    Sludge: {
      title: 'load sludge tanks',
      key: 'sludge',
      url: () => `/sludge_master/`,
    },
    Bilge: {
      title: 'load bilge tanks',
      key: 'bilge',
      url: () => `/bilge_master/`,
    },
    VesselSubTypeMaster: {
      title: 'vessel sub types',
      key: 'vessel-sub-type-master',
      url: () => `/vessel_sub_type_master/`,
    },
    CountryMaster: {
      title: 'countries',
      key: 'country-master',
      url: () => `/country_master/`,
    },
    VesselOwnerMaster: {
      title: 'vessel owner',
      key: 'vessel-owner-master',
      url: () => `/vessel_owner_details/`,
    },
    HullTypeMaster: {
      title: 'hull type',
      key: 'hull-type-master',
      url: () => `/hull_type_master/`,
    },
    SpecialOperationMaster: {
      title: 'Special operation',
      key: 'special-operation-master',
      url: (constant: number) =>
        `special_operation_master/get_special_operation_by_constant/?constant=${constant}`,
    },
    OtherPortMaster: {
      title: 'Other port',
      key: 'other-port-master',
      url: () => `/other_port_data/`,
    },
    EngineTypeMaster: {
      title: 'Engine type',
      key: 'engine-type-master',
      url: () => `/engine_type_master/`,
    },
    year: {
      title: 'Year',
      key: 'year-master',
      url: () => `/year/`,
    },
    SensorListMaster: {
      title: 'required to get vessel sensor master',
      key: 'sensor-list-master',
      url: () => `sensor_list_master/`,
    },
    VesselMaster: {
      title: 'required to get vessel master data',
      key: 'vessel-master',
      url: () => `vessel_master/`,
    },
    CIIAdjustmentEvent: {
      title: 'get list of all cii adjustment year',
      key: 'cii-adjustment-events-master',
      url: () => `cii_adjustment_events_master`,
    },
    ReportingEventsMaster: {
      title: 'Reporting events master',
      key: 'reporting-events-master',
      url: () => `reporting_events_master/`,
    },
    ReportingOperationsMaster: {
      title: 'Reporting operations master',
      key: 'reporting-operations-master',
      url: () => `reporting_operation_mode_master/`,
    },
    FuelSubTypesByType: {
      title: 'Fuel sub types of selected fuel type',
      key: 'fuel-sub-types-by-type',
      url: (fuelType: number) =>
        `fuel_sub_type_master/get_fuel_sub_type_by_fuel_type/?fuel_type_id=${fuelType}`,
    },
    LubeOilMaster: {
      title: 'Lube Oil Master',
      key: 'lube-oil-master',
      url: () => `lube_oil_list_master/`,
    },
    FuelClassMaster: {
      title: 'Fuel Class Master',
      key: 'Fuel-class-master',
      url: () => `fuel_class_master/`,
    },
    MachineryMaster: {
      title: 'Machinery Master',
      key: 'machinery-master',
      url: () => `machinery_list_master/`,
    },
    LoadConditionMaster: {
      title: 'Vessel load condition master',
      key: 'load-condition--master',
      url: () => `vessel_load_condition_master/`,
    },
    RoleMaster: {
      title: 'role master',
      key: 'role-master',
      url: () => `role_master/`,
    },
    vesselType: {
      title: 'Vessel type master',
      key: 'vessel-type-master',
      url: () => `vessel_type_master/`,
    },
    vesselLoadingCondition: {
      title: 'Vessel loading condition master',
      key: 'vessel-loading-condition-master',
      url: () => `vessel_loading_condition_master/`,
    },
    currentDirection: {
      title: 'Current direction master',
      key: 'current-direction-master',
      url: () => `current_direction_master/`,
    },
    straitCanal: {
      title: 'Strait Canal master',
      key: 'strait-canal-master',
      url: () => `strait_canal_transit_list_master/`,
    },
    ReportingSensorMaster: {
      title: 'reporting sensor master',
      key: 'reporting-sensor-master',
      url: () => `reporting_sensor_master/`,
    },
    SensorUnitMaster: {
      title: 'sensor unit master',
      key: 'sensor-unit-master',
      url: () => `sensor_unit_master/`,
    },
  },
  vessel: {
    reporting: {
      title: '',
      key: 'vessel-reporting',
      url: (ReportID: number) => `/vessel_reporting_information/${ReportID}`,
    },
    fuel: {
      title: 'Distinct fuel types, vessel specific',
      key: 'vessel-fuel-type',
      url: (VesselID: number) =>
        `vessel_fuel_information/distinct_records_by_fuel_type/?vessel_id=${VesselID}`,
    },
    vesselFuel: {
      title: 'fuels vessel specific',
      key: 'vessel-fuel',
      url: (VesselID: number) =>
        `vessel_fuel_information/get_fuel_by_vessel/?vessel_id=${VesselID}`,
    },
    genralSetting: {
      title: '',
      key: 'Vessel-gernal-setting',
      url: '',
    },
    lastCPWarranties: {
      title: '',
      key: 'last-CP-Warranties',
      url: (vesselId: number, voyageId: number, reportID: number) =>
        `cp_warranties_data/get_cp_warranties_fuel_and_speed_data_by_vessel_voyage_reporting_id/?vessel_id=${vesselId}&voyage_id=${voyageId}&vessel_reporting_id=${reportID}`,
    },
    decOfficerAll: {
      title: '',
      key: 'dec-all-report',
      url: (ReportId: number) => `/all-reporting_data/${ReportId}`,
    },
    vesselVoyageReporting: {
      title: '',
      key: 'vesssel-voyage-reporting',
      url: (VesselID: number, VoyageID: number) =>
        `/vessel_reporting_information/get_reporting_info_same_vessel_and_voyage/?vessel_id=${VesselID}&voyage_information_id=${VoyageID}`,
    },
    nextReportingEvent: {
      title: '',
      key: 'next-reporting-event',
      url: (eventId: number) =>
        `/event_management/get_next_events_by_event/?event_id=${eventId}`,
    },
    lastRecordVesselVoyageSpecific: {
      title: 'Vessel voyage last report',
      key: 'vessel-voyage-specific-last-record',
      url: (VesselID: number, VoyageID: number) =>
        `/vessel_reporting_information/get_last_record_vessel_and_voyage_specific/?vessel_id=${VesselID}&voyage_id=${VoyageID}`,
    },
    CPWarrantiesSpeed: {
      title: 'used to submit the fomr',
      key: 'cpwarranties-speed',
      url: () => '/cp_warranties_speed/',
    },
    PreviousVesselReport: {
      title: 'Previous vessel report of the current report',
      key: 'Previous-Vessel-Report',
      url: (reportId: number, VesselID: number) =>
        `vessel_reporting_information/get_previous_by_id/?id=${reportId}&vessel_id=${VesselID}`,
    },
    FuelTypeROB: {
      title: 'ROB of vessel fuel types of current vessel report',
      key: 'fuel-type-rob',
      url: (VesselID: number, VoyageID: number, ReportID: number) =>
        `fo_rob/get_fo_rob_by_vessel_voyage_reporting_specific/?vessel_id=${VesselID}&voyage_id=${VoyageID}&vessel_reporting_id=${ReportID}`,
    },
    CIIReportingDocuments: {
      title: 'Get Documents for the CII Events',
      key: 'CII-Event-Documents',
      url: (cii_adjustment_event_id: number) =>
        `cii_adjustment_event_document/get_cii_document_by_cii_adjustment_event/?cii_adjustment_event_id=${cii_adjustment_event_id}`,
    },
    CIIEventsSupportingFileData: {
      title: 'Returns supporting file data',
      key: 'cii-adjustment-supporting-file',
      url: (vessel_reporting_cii_adjustment_id: number) =>
        `vessel_reporting_cii_adjustment_supporting_file/get_supporting_file_data_by_vessel_reporting_cii_adjustment/?vessel_reporting_cii_adjustment_id=${vessel_reporting_cii_adjustment_id}`,
    },
    VoyageList: {
      title: 'Get Voyage list of  vessels',
      key: 'voyage-list',
      url: (VesselID: number) =>
        `/voyage_information/get_voyage_by_vessel/?vessel_id=${VesselID}`,
    },
    PreviousLubeOilROB: {
      title: 'Lube oil ROB of last vessel report',
      key: 'lube-oil-rob',
      url: (VesselID: number, ReportID: number) =>
        `lo_rob/get_last_record_of_lo_rob_by_vessel_and_reporting_id/?vessel_id=${VesselID}&vessel_reporting_id=${ReportID}`,
    },
    MachinaryFuelGroupByVesselId: {
      title: 'Fetch url for the machinary fuel group of Vessel',
      key: 'machinary-fuel-group',
      url: (VesselID: number) =>
        `/vessel_machinery_fc_group/get_vessel_machinery_fc_group_by_vessel/?vessel_id=${VesselID}`,
    },
    MachinaryEnergyGroupByVesselId: {
      title: 'Fetch url for the machinary energy group of Vessel by vessel id',
      key: 'machinary-energy-group',
      url: (VesselID: number) =>
        `/vessel_machinery_ec_group/get_vessel_machinery_ec_group_by_vessel/?vessel_id=${VesselID}`,
    },
    MachinaryFuelGroup: {
      title: 'Fetch url for the machinary fuel group of Vessel',
      key: 'machinary-fuel-group',
      url: `/vessel_machinery_fc_group/`,
    },
    MachinaryEnergyGroup: {
      title: 'Fetch url for the machinary energy group of Vessel',
      key: 'machinary-energy-group',
      url: `/vessel_machinery_ec_group/`,
    },
    VesselMachineries: {
      title: 'Fetch url for the machinary of the vessel',
      key: 'vessel-machine',
      url: (VesselID: number) =>
        `/vessel_machinery_list/get_machinery_list_by_vessel/?vessel_id=${VesselID}`,
    },
    MEVesselMachineries: {
      title: 'Fetch url for the machinary of the vessel',
      key: 'me-vessel-machines',
      url: (VesselID: number) =>
        `/vessel_machinery_list/get_machinery_list_by_vessel/?vessel_id=${VesselID}`,
    },
    AEVesselMachineries: {
      title: 'Fetch url for the machinary of the vessel',
      key: 'ae-vessel-machines',
      url: (VesselID: number) =>
        `/vessel_machinery_list/get_machinery_list_by_vessel/?vessel_id=${VesselID}`,
    },
    DistinctMachinary: {
      title: `Fetch url for the distinc machinary of the vessel`,
      key: `vessel-distinct-machine`,
      url: (VesselID: number) =>
        `/vessel_machinery_list/get_distinct_machinery_list_by_vessel/?vessel_id=${VesselID}`,
    },
    BunkeringByVessel: {
      title: 'Fetch bukering batch information of the vessel',
      key: 'vessel-bunkering',
      url: (VesselID: number) =>
        `/bunkering/get_bunkering_by_vessel/?vessel_id=${VesselID}`,
    },
    FuelSettings: {
      title: 'Get Fuel Setting by Vessel',
      key: 'vessel-fuel-setting',
      url: (VesselID: any) =>
        `/vessel_fuel_setting/get_fuel_setting_by_vessel/?vessel_id=${VesselID}`,
    },
    VesselReportingObject: {
      title: 'Object of create vessel reporting for edit',
      key: 'vessel-report-object',
      url: (ReportID: number) => `vessel_reporting_information/${ReportID}/`,
    },
    FuelConsumptionBatch: {
      title: 'Fuel Consumption and Batch by vessel Reporting',
      key: 'fuel-consumption-batch',
      url: (ReportID: number) =>
        `/fuel_consumption_and_batch_data_by_vessel_reporting_id/${ReportID}/`,
    },
    PreviousFuelROB: {
      title: 'Fuel ROB from previous record',
      key: 'previous-fuel-rob',
      url: (VesselID: number, ReportID: number) =>
        `fo_rob/get_last_data/?vessel_id=${VesselID}&vessel_reporting_id=${ReportID}`,
    },
    Eumrv: {
      title: 'EUMRV Graph data',
      key: 'eumrv',
      url: (VesselID: number) =>
        `cummulative_eu_mrv_graph/get_cummulative_eumrv_related_graph_data_by_vessel/?vessel_id=${VesselID}`,
    },
    CIIAssessmentData: {
      title: ' CII Assessment Data',
      key: 'cii-assesment-data',
      url: (VesselID: number) =>
        `/vessel_event_related_graph_data/get_vessel_event_related_graph_data_by_vessel/?vessel_id=${VesselID}`,
    },
    IMODCSData: {
      title: ' IMODCS Data',
      key: 'imodcs-data',
      url: (VesselID: number) =>
        `/imo_dcs_related_graph_data/get_imodcs_related_graph_data_by_vessel/?vessel_id=${VesselID}`,
    },
    Vessels: {
      title: ' Vessel Data',
      key: 'vessel-data',
      url: () => `/vessel_master/`,
    },
    CIIAdjustmentAdditionalEvents: {
      title: 'CII Adjustment Additional Events',
      key: 'cii-adjustment-additional-events',
      url: (eventId: number) =>
        `/cii_adjustment_additional_events/get_next_possible_events_by_reporting_event/?reporting_event_id=${eventId}`,
    },
    FuelConsumptionObject: {
      title: "Fuel Consumption's current report Object",
      key: 'fuel-consumption-object',
      url: (vesselId: number, reportId: number) =>
        `/FO_fuel_consumption/get_fuel_consumption_by_vessel_voyage_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    BatchConsumptionObject: {
      title: "Batch Consumption's current report Object",
      key: 'batch-consumption-object',
      url: (vesselId: number, reportId: number) =>
        `/fuel_consumption_batch/get_fuel_consumption_batch_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    MainEngineSetting: {
      title: "Main engine's setting details",
      key: 'main-engine-setting',
      url: (vesselId: number) =>
        `/main_engine_setting/get_main_engine_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    CurrentSteamingTime: {
      title: "Current report's steaming time",
      key: 'current-steaming-time',
      url: (reportId: number) =>
        `/weather_data/get_steaming_time_by_vessel_reporting_information/?vessel_reporting_information_id=${reportId}`,
    },
    CurrentDistanceTravelled: {
      title: "Current report's distance travelled",
      key: 'current-distance-travelled',
      url: (reportId: number) =>
        `/weather_data/get_distance_travelled_by_vessel_reporting_information/?vessel_reporting_information_id=${reportId}`,
    },
    VesselLubeOils: {
      title: 'Lube oils on vessel',
      key: 'vessel-lube-oil',
      url: (vesselId: number) =>
        `/vessel_lube_oil_list/get_vessel_lubeoil_by_vessel/?vessel_id=${vesselId}`,
    },
    PreviousFreshWater: {
      title: 'Previous report of fresh water',
      key: 'previous-fresh-water-rob',
      url: (vesselId: number, reportId: number) =>
        `/fresh_water/get_last_data_fresh_water_by_vessel/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LastVesselReport: {
      title: 'Last vessel report of a vessel',
      key: 'last-vessel-report',
      url: (vesselId: number) =>
        `/vessel_reporting_information/get_last_record_by_vessel/?vessel_id=${vesselId}`,
    },
    DraftsDisplacement: {
      title: "Current report's drafts & displacement data",
      key: 'drafts-displacement',
      url: (vesselId: number, reportId: number) =>
        `draft_and_displacement/get_draft_and_displacement_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoDetails: {
      title: "Current report's cargo details",
      key: 'cargo-details',
      url: (vesselId: number, reportId: number) =>
        `cargo_detail/get_cargo_details_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    VoyageParameterObject: {
      title: "Voyage parameter's current report object",
      key: 'voyage-parameter-object',
      url: (vesselId: number, reportId: number) =>
        `voyage_parameter/get_voyage_parameter_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EnergyParameterObject: {
      title: "Energy parameter's current report object",
      key: 'energy-parameter-object',
      url: (vesselId: number, reportId: number) =>
        `energy_parameter/get_energy_parameter_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    BunkeringObject: {
      title: "Bunkering's current report object",
      key: 'bunkering-object',
      url: (vesselId: number, reportId: number) =>
        `bunkering/get_bunkering_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    vesselFuelType: {
      title: 'Fuel types used on vessel',
      key: 'vessel-fuel-type',
      url: (vesselId: number) =>
        `/vessel_fuel_information/distinct_records_by_fuel_type/?vessel_id=${vesselId}`,
    },
    DebunkeringObject: {
      title: "Debunkering's current report object",
      key: 'debunkering-object',
      url: (vesselId: number, reportId: number) =>
        `debunkering_details/get_debunkering_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LOBunkeringObject: {
      title: "Lube oil bunkering's current report object",
      key: 'lo-bunkering-object',
      url: (vesselId: number, reportId: number) =>
        `lube_oil_bunkering/get_lubeoil_bunkering_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    STSOngoingObject: {
      title: 'Ongoing STS on vessel',
      key: 'sts-ongoing-object',
      url: (vesselId: number) =>
        `/sts_operation_detail/sts_operation_ongoing_status_true_for_vessel/?vessel_id=${vesselId}`,
    },
    LODebunkeringObject: {
      title: "Lube oil debunkering's current report data",
      key: 'lo-debunkering-data',
      url: (vesselId: number, reportId: number) =>
        `lube_oil_debunkering/get_lube_oil_debunkering_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    FreshWaterObject: {
      title: "Current report's fresh water data",
      key: 'fresh-water-object',
      url: (vesselId: number, reportId: number) =>
        `fresh_water/get_fresh_water_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LubeOilROBObject: {
      title: "Current report's lube oil rob data",
      key: 'lube-oil-rob-object',
      url: (vesselId: number, reportId: number) =>
        `lo_rob/get_lo_rob_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    BillOfLadingObject: {
      title: "Current report's bill of lading data",
      key: 'bill-of-lading-object',
      url: (vesselId: number, reportId: number) =>
        `bill_of_lading/get_bill_of_lading_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ETADObject: {
      title: "Current report's ETAD data",
      key: 'ETAD-object',
      url: (vesselId: number, reportId: number) =>
        `expected_time_of_arrival/get_expected_time_of_arrival_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    PendingCIIFileUpload: {
      title: "CII adjustment event's files that are not yet uploaded",
      key: 'pending-cii-file-upload',
      url: (vesselId: number, fileStatus: string) =>
        `vessel_reporting_cii_adjustment_supporting_file/get_supporting_file_data_by_vessel/?vessel_id=${vesselId}&file_status=${fileStatus}`,
    },
    VesselReportingCIIAdjusmentObject: {
      title: 'vessel reporting cii adjustment',
      key: 'vessel-reporting-cii-adjustment',
      url: `vessel_reporting_cii_adjustment/`,
    },
    TimePeriodObject: {
      title: 'Time periods for 4 hours table',
      key: 'time-period-object',
      url: `time_period/`,
    },
    CargoDischargingFuelMachineries: {
      title:
        'Vessel machineries applicable for cargo discharging fuel consumption',
      key: 'cargo-discharging-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/cargo_discharging_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoDischargingWithkWhMachineries: {
      title:
        'Vessel machineries applicable for cargo discharging energy consumption',
      key: 'cargo-discharging-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/cargo_discharging_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoDischargingFuelObject: {
      title: "Current report's cargo discharging fuel data",
      key: 'cargo-discharging-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_cargo_discharging_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoDischargingEnergyObject: {
      title: "Current report's cargo discharging energy data",
      key: 'cargo-discharging-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_cargo_discharging_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoDischarging4hrObject: {
      title: "Current report's cargo discharging without kWh meter data",
      key: 'cargo-discharging-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_cargo_discharging_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    AuxEngineMaxSFOC: {
      title: "Maximum SFOC of vessel's auxiliary engine",
      key: 'aux-engine-max-sfoc',
      url: (vesselId: number) =>
        `vessel_machinery_list/get_sfoc_multiplier/?vessel_id=${vesselId}`,
    },
    BallastDetailsObject: {
      title: "Current report's ballast details' data",
      key: 'ballast-details-object',
      url: (vesselId: number, reportId: number) =>
        `ballast_details/get_ballast_details_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    vesselRunningHoursMachinery: {
      title: 'Vessel machineries for running hours',
      key: 'vessel-running-hours-machinery',
      url: (vesselId: number) =>
        `vessel_machinery_list/get_running_hours_or_running_counter_true_by_vessel/?vessel_id=${vesselId}`,
    },
    RunningHoursObject: {
      title: "Current report's machinery running hour data",
      key: 'running-hours-object',
      url: (vesselId: number, reportId: number) =>
        `machinery_running_hours/get_machinery_running_hours_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ShoreSupplyObject: {
      title: "Current report's shore supply data",
      key: 'shore-supply-object',
      url: (vesselId: number, reportId: number) =>
        `shore_supply/get_shore_supply_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsMainEngineParameterObject: {
      title: "Current report's main engine parameter data",
      key: 'main-engine-parameter-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_engine_parameter/get_engine_parameter_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsAuxEngineParameterObject: {
      title: "Current report's aux engine parameter data",
      key: 'aux-engine-parameter-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_auxiliary_engine_parameter/get_auxiliary_engine_parameter_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsThermalOilHeaterObject: {
      title: "Current report's thermal oil heater data",
      key: 'thermal-oil-heater-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_thermal_oil_heater/get_thermal_oil_heater_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsAnchorageObject: {
      title: "Current report's anchorage data",
      key: 'anchorage-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_anchorage_details/get_anchorage_details_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsPilotArrivalDetailsObject: {
      title: "Current report's pilot arrival details data",
      key: 'pilot-details-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_pilot_details/get_pilot_details_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsPilotDepartureDetailsObject: {
      title: "Current report's pilot departure details data",
      key: 'pilot-departure-details-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_pilot_departure_detail/get_pilot_departure_detail_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsTugsUsageObject: {
      title: "Current report's tugs usage data",
      key: 'tugs-usage-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_tug_usage/get_tug_usage_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsOtherDetailsObject: {
      title: "Current report's other details data",
      key: 'other-details-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_other_details/get_other_details_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ScrubingUnitObject: {
      title: "Current report's scrubing unit data",
      key: 'scrubing-unit-object',
      url: (vesselId: number, reportId: number) =>
        `reporting_scrubing_units_detail/get_reporting_scrubing_units_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsSludgeObject: {
      title: "Current report's sludge data",
      key: 'sludge-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_sludge_water_details/get_sludge_water_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsBilgeObject: {
      title: "Current report's bilge data",
      key: 'bilge-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_bilge_water_details/get_bilge_water_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LfondsPortActivitiesObject: {
      title: "Current report's port activities data",
      key: 'port-activities-object',
      url: (vesselId: number, reportId: number) =>
        `lfonds_port_activity_detail/get_port_activity_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LNGCargoQualityObject: {
      title: "Current voyage's LNG cargo quality data",
      key: 'lng-cargo-quality-object',
      url: (vesselId: number, reportId: number) =>
        `cargo_quality_details/get_cargo_quality_by_vessel_and_report/?vessel_id=${vesselId}&report_id=${reportId}`,
    },
    CargoHeatingFuelMachineries: {
      title: 'Vessel machineries applicable for cargo heating fuel consumption',
      key: 'cargo-heating-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/cargo_heating_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoHeatingWithkWhMachineries: {
      title:
        'Vessel machineries applicable for cargo heating energy consumption',
      key: 'cargo-heating-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/cargo_heating_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoHeatingFuelObject: {
      title: "Current report's cargo heating fuel data",
      key: 'cargo-heating-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_cargo_heating_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoHeatingEnergyObject: {
      title: "Current report's cargo heating energy data",
      key: 'cargo-heating-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_cargo_heating_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoHeating4hrObject: {
      title: "Current report's cargo heating without kWh meter data",
      key: 'cargo-heating-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_cargo_heating_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    VesselBasicInformation: {
      title: "Vessel's basic information required for IMO DCS, EU MRV & UK MRV",
      key: 'vessel-basic-information',
      url: (vesselId: number) =>
        `basic_information_of_vessel/get_basic_information_data_by_vessel/?vessel_id=${vesselId}`,
    },
    CargoCoolingFuelMachineries: {
      title: 'Vessel machineries applicable for cargo cooling fuel consumption',
      key: 'cargo-cooling-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/cargo_cooling_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoCoolingWithkWhMachineries: {
      title:
        'Vessel machineries applicable for cargo cooling energy consumption',
      key: 'cargo-cooling-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/cargo_cooling_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoCoolingFuelObject: {
      title: "Current report's cargo cooling fuel data",
      key: 'cargo-cooling-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_cargo_cooling_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoCoolingEnergyObject: {
      title: "Current report's cargo cooling energy data",
      key: 'cargo-cooling-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_cargo_cooling_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoCooling4hrObject: {
      title: "Current report's cargo cooling without kWh meter data",
      key: 'cargo-cooling-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_cargo_cooling_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoReliquificationFuelMachineries: {
      title:
        'Vessel machineries applicable for cargo reliquification fuel consumption',
      key: 'cargo-reliquification-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/cargo_reliquification_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoReliquificationWithkWhMachineries: {
      title:
        'Vessel machineries applicable for cargo reliquification energy consumption',
      key: 'cargo-reliquification-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/cargo_reliquification_true_for_vessel/?vessel_id=${vesselId}`,
    },
    CargoReliquificationFuelObject: {
      title: "Current report's cargo reliquification fuel data",
      key: 'cargo-reliquification-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_cargo_reliquification_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoReliquificationEnergyObject: {
      title: "Current report's cargo reliquification energy data",
      key: 'cargo-reliquification-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_cargo_reliquification_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CargoReliquification4hrObject: {
      title: "Current report's cargo reliquification without kWh meter data",
      key: 'cargo-reliquification-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_cargo_reliquification_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    TankCleaningFuelMachineries: {
      title: 'Vessel machineries applicable for tank cleaning fuel consumption',
      key: 'tank-cleaning-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/tank_cleaning_true_for_vessel/?vessel_id=${vesselId}`,
    },
    TankCleaningWithkWhMachineries: {
      title:
        'Vessel machineries applicable for tank cleaning energy consumption',
      key: 'tank-cleaning-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/tank_cleaning_true_for_vessel/?vessel_id=${vesselId}`,
    },
    TankCleaningFuelObject: {
      title: "Current report's tank cleaning fuel data",
      key: 'tank-cleaning-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_tank_cleaning_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    TankCleaningEnergyObject: {
      title: "Current report's tank cleaning energy data",
      key: 'tank-cleaning-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_tank_cleaning_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    TankCleaning4hrObject: {
      title: "Current report's tank cleaning without kWh meter data",
      key: 'tank-cleaning-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_tank_cleaning_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ReeferContainerFuelMachineries: {
      title:
        'Vessel machineries applicable for reefer container fuel consumption',
      key: 'reefer-container-fuel-machineries',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/reefer_container_true_for_vessel/?vessel_id=${vesselId}`,
    },
    ReeferContainerWithkWhMachineries: {
      title:
        'Vessel machineries applicable for reefer container energy consumption',
      key: 'reefer-container-energy-machineries',
      url: (vesselId: number) =>
        `special_operation_energy_setting/reefer_container_true_for_vessel/?vessel_id=${vesselId}`,
    },
    ReeferContainerFuelObject: {
      title: "Current report's reefer container fuel data",
      key: 'reefer-container-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_fuel/get_reefer_container_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ReeferContainerEnergyObject: {
      title: "Current report's reefer container energy data",
      key: 'reefer-container-energy-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_energy/get_reefer_container_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ReeferContainer4hrObject: {
      title: "Current report's reefer container without kWh meter data",
      key: 'reefer-container-4hr-object',
      url: (vesselId: number, reportId: number) =>
        `special_operation_4_hours_reporting/get_reefer_container_4hours_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ReeferContainerPowerRatingObject: {
      title: "Current report's reefer container power rating data",
      key: 'reefer-container-power-rating-object',
      url: (vesselId: number, reportId: number) =>
        `reefer_container_other/get_reefer_container_other_reporting_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    VesselCardDetails: {
      title: "Vessels' card details",
      key: 'vessel-card-details',
      url: () => `vessel_card_detail`,
    },
    OtherSpOperationFuelObject: {
      title: "Current report's other special operation's fuel consumption data",
      key: 'other-sp-operation-fuel-object',
      url: (vesselId: number, reportId: number) =>
        `other_special_operation_fuel/get_other_special_operation_fuel_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    OtherSpOperationEnergyObject: {
      title:
        "Current report's other special operation's energy consumption data",
      key: 'other-sp-operation-energy-object',
      url: (vesselId: number, reportId: number) =>
        `other_special_operation_energy/get_other_special_operation_energy_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    VesselAuxEngines: {
      title: "Vessel's auxilliary engines",
      key: 'vessel-aux-engine',
      url: (vesselId: number) =>
        `vessel_machinery_list/get_auxiliary_engine_by_vessel/?vessel_id=${vesselId}`,
    },
    WeatherDataObject: {
      title: "Current report's weather data",
      key: 'weather-data-object',
      url: (vesselId: number, reportId: number) =>
        `weather_data/get_weather_data_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EEOI: {
      title: "Vessel's EEOI",
      key: 'eeoi',
      url: (vesselId: number) =>
        `imo_dcs_related_graph_data/get_average_eeoi_value_by_vessel/?vessel_id=${vesselId}`,
    },
    FleetGraphData: {
      title: "Fleet's graph data",
      key: 'fleet-graph-data',
      url: (fleetId: number) =>
        `cii_graph/categorize_records/?fleet_id=${fleetId}`,
    },
    UserVessel: {
      title: 'Vessel assigned to a user',
      key: 'user-vessel',
      url: () => `user_vessel`,
    },
    AssgnedUserByvessel: {
      title: 'get assigned users',
      key: 'assigned-users',
      url: (vesselID: number) =>
        `user_vessel/get_all_vessel_user/?vessel_id=${vesselID}`,
    },
    VesselReportingEmail: {
      title: 'Vessel assigned to a user by vessel',
      key: 'vessel-reporting-email',
      url: (VesselId?: number) =>
        VesselId
          ? `vessel_reporting_email/?vessel_id=${VesselId}`
          : `vessel_reporting_email/`,
    },
    CIIData: {
      title: "Vessel's CII data",
      key: 'cii-data',
      url: (vesselId: number) =>
        `fcj_raw_data/get_calculate_cii/?vessel_id=${vesselId}`,
    },
    ReeferContainerDetailsObject: {
      title: "Reefer containers' fc electrical data",
      key: 'fc-electrical-reefer-object',
      url: (vesselId: number, reportId: number) =>
        `reefer_container_details/get_reefer_container_detail_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    CIIAdjustment: {
      title: 'CII adjustment report',
      key: 'cii-adjustment',
      url: (vesselId: number) =>
        `cii_adjustment_reporting_for_approval/get_cii_adjustment_records_by_vessel/?vessel_id=${vesselId}`,
    },
    CIIAdjustmentUpdate: {
      title: "CII adjustment's data that will be updated",
      key: 'cii-adjustment-update',
      url: (vesselId: number, ciiAdjustmentId: number) =>
        `cii_adjustment_reporting_for_approval/get_records_by_vessel_and_cii_adjustment/?vessel_id=${vesselId}&cii_adjustment_id=${ciiAdjustmentId}`,
    },
    VesselYear: {
      title: "Year's whose vessel data is available",
      key: 'vessel-year',
      url: (vesselId: number) =>
        `vessel_reporting_information/get_year_by_vessel/?vessel_id=${vesselId}`,
    },
    STSAdjustment: {
      title: "STS adjustment's data that will be updated",
      key: 'sts-adjustment',
      url: (vesselId: number) =>
        `sts_adjustment_reporting_for_approval/get_sts_adjustment_records/?vessel_id=${vesselId}`,
    },
    STSAdjustmentApprovalData: {
      title: "STS adjustment's data that will be approved",
      key: 'sts-adjustment-approval-data',
      url: (stsId: number) =>
        `sts_operation_detail/get_combined_vessel_reporting_info/?record_id=${stsId}`,
    },
    LNGCargoPreviousROB: {
      title: "LNG Cargo's ROB in previous report",
      key: 'lng-cargo-previous-rob',
      url: (vesselId: number, reportId: number) =>
        `lng_cargo_rob/get_lng_cargo_rob_previous_record_by_vessel_and_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    LNGCargoROBObject: {
      title: "Current report's LNG cargo ROB data",
      key: 'lng-cargo-rob-object',
      url: (vesselId: number, reportId: number) =>
        `lng_cargo_rob/get_record_of_lng_cargo_rob_by_vessel_and_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    InitialLNGCargoROBObject: {
      title: 'Applicable N2 correction',
      key: 'initial-lng-cargo-rob-object',
      url: (vesselId: number) =>
        `n2_adjustment/get_ongoing_n2_adjustment_by_vessel/?vessel_id=${vesselId}`,
    },
    voyageN2Adjustment: {
      title: 'Voyage N2 Adjustment',
      key: 'voyage-n2-adjustment',
      url: '/n2_adjustment',
    },
    N2AdjustmentObjectByVessel: {
      title: "N2 adjustment's object",
      key: 'n2-adjustment-object-by-vessel',
      url: (vesselId: number) =>
        `n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${vesselId}`,
    },
    VesselGenInfoObject: {
      title: "Vessel general info's object",
      key: 'vessel-gen-info-object',
      url: (vesselId: number) =>
        `vessel_general_information/get_vessel_info_by_vessel/?vessel_id=${vesselId}`,
    },
    VesselLNGBunkeringObject: {
      title: "Vessel's LNG bunkering object'",
      key: 'vessel-lng-bunkering-object',
      url: (vesselId: number) =>
        `bunkering/get_lng_bunkering_by_vessel/?vessel_id=${vesselId}`,
    },
    VesselLNGTanks: {
      title: 'Vessel LNG tanks',
      key: 'vessel-lng-tanks',
      url: (vesselId: number) =>
        `vessel_lng_tank/get_vessel_lng_tank_by_vessel/?vessel_id=${vesselId}`,
    },
    LNGTankDetailsObject: {
      title: "Current report's LNG tanks details data",
      key: 'lng-tank-details-object',
      url: (vesselId: number, reportId: number) =>
        `vessel_reporting_lng_tank_detail/get_lng_tank_details_by_vessel_and_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    VesselOtherSettingsObject: {
      title: "Vessel's other setting's object",
      key: 'vessel-other-settings-object',
      url: (vesselId: number) =>
        `other_setting/get_other_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    SteamDumpDetailsObject: {
      title: "Current report's steam dump details data",
      key: 'steam-dump-details-object',
      url: (vesselId: number, reportId: number) =>
        `steam_dump_details/get_steam_dump_details_by_vessel_and_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    SpOpFuelSubTypeObject: {
      title:
        "Current report's fuel sub type consumption according to special operation",
      key: 'sp-op-fuel-sub-type-object',
      url: (vesselId: number, reportId: number, spOperationId: number) =>
        `special_operation_sub_type_fuel_consumption/get_cargo_cooling_sp_ops_fuel_sub_type_fc_by_vessel_reporting_and_special_operation/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}&special_operation_id=${spOperationId}`,
    },
    ProactiveVesselAvgLoadInfo: {
      title: "Vessel's average load information data",
      key: 'vessel-avg-load-info',
      url: (vesselId: number) =>
        `proactive_avg_load_info/get_avg_load_info_by_vessel/?vessel_id=${vesselId}`,
    },
    ProactiveAvgLoadDetailsObject: {
      title: "Current report's average load details data",
      key: 'avg-load-details-object',
      url: (vesselId: number, reportId: number) =>
        `proactive_load_details/get_load_details_by_vessel_and_Vessel_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    ProactiveAddDetailsObject: {
      title: "Proactive's additional details data",
      key: 'proactive-additional-details-object',
      url: (vesselId: number, reportId: number) =>
        `proactive_additional_details/get_additional_details_by_vessel_and_Vessel_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    ProactivePreviousAddDetailsObject: {
      title: "Proactive's previous additional details data",
      key: 'proactive-previous-additional-details-object',
      url: (vesselId: number, reportId: number) =>
        `proactive_additional_details/get_previous_addtional_details_by_vessel_and_vessel_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    VoyageObject: {
      title: "Courrent voyage's object",
      key: 'voyage-object',
      url: (voyageId: number) => `voyage_information/${voyageId}`,
    },
    VoyageInformation: {
      title: 'voyage information',
      key: 'voyage-information',
      url: () => `voyage_information/`,
    },
    OnboardVoyageObject: {
      title: "Courrent voyage's object",
      key: 'onboard-voyage-object',
      url: () => `onboarding_voyage_information/`,
    },
    CheckNewVoyage: {
      title: 'Check if any New voyage already exists',
      key: 'check-new-voyage',
      url: (vesselId: number) =>
        `voyage_information/check_new_voyage/?vessel_id=${vesselId}`,
    },
    LastUpdatedOn: {
      title: "Selected vessel's latest report date with status sent",
      key: 'check-last-updated-voyage',
      url: (vesselId: number) =>
        `vessel_reporting_information/get_last_record_date_by_vessel/?vessel_id=${vesselId}`,
    },
    SpecialOperationFuelSettings: {
      title:
        "Selected vessel's special operation fuel settings with status sent",
      key: 'special-operation-fuel-setting',
      url: (vesselId: number) =>
        `special_operation_fuel_setting/get_special_operation_fuel_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    SpecialOperationEnergySettings: {
      title:
        "Selected vessel's special operation energy settings with status sent",
      key: 'special-operation-energy-setting',
      url: (vesselId: number) =>
        `special_operation_energy_setting/get_special_operation_energy_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    AuxillaryMachineSetting: {
      title: 'required to get data in vessel configuration for engine',
      key: 'auxillary-machine-setting',
      url: (vesselId: number) =>
        `auxillary_machine_setting/get_auxillary_machine_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    ProactiveETAAdditionalDetail: {
      title: 'required to get proactive eta additional detail',
      key: 'proactive-eta-additional-detail',
      url: (vesselId: number, reportId: number) =>
        `proactive_eta_additional_detail/get_eta_addtional_details_by_vessel_and_vessel_report/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    SpecialOperationSettingList: {
      title: "Selected vessel's special operation details",
      key: 'special-operation-setting',
      url: (vesselId: number) =>
        `special_operation_setting/get_special_operation_setting_by_vessel/?vessel_id=${vesselId}`,
    },
    VesselSensorList: {
      title: 'required to get vessel sensor list',
      key: 'vessel-sensor-list',
      url: (vesselId: number) =>
        `vessel_sensor_list/get_vessel_sensor_by_vessel/?vessel_id=${vesselId}`,
    },
    PreviousEOSPPort: {
      title: "previous End of Sea Passage's arrival port",
      key: 'previous-eosp-port',
      url: (vesselId: number, reportId: number) =>
        `expected_time_of_arrival/get_eta_port_of_eosp/?vessel_id=${vesselId}&vessel_report_id=${reportId}`,
    },
    CurrentYear: {
      title: 'load current year',
      key: 'current-year',
      url: () => `year/get_running_year/`,
    },
    FuelEUAnalysisData: {
      title: "FuelEU's analysis data",
      key: 'fuel-eu-analysis-data',
      url: (vesselId: number) =>
        `fuel_consumption_machine_specific/get_fuel_eu_record_by_vessel/?vessel_id=${vesselId}`,
    },
    FuelEUAnalysisCumulativeData: {
      title: "FuelEU's cumulative analysis data",
      key: 'fuel-eu-analysis-cumulative-data',
      url: (vesselId: number) =>
        `fuel_consumption_machine_specific/get_last_record_of_fuel_eu_record_by_vessel/?vessel_id=${vesselId}`,
    },
    SimulatorVesselDataObject: {
      title: "vessel's data required for simulator",
      key: 'simulator-vessel-data-object',
      url: (vesselId: number) => `simulator_data/?vessel_id=${vesselId}`,
    },
    UploadSpecialOperationFile: {
      title: 'required to get list of special operation files',
      key: 'upload-special-operation-file',
      url: (vesselId: number, reportId: number) =>
        `special_operation_file_upload/get_special_operation_file_by_vessel_and_report/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    ShoreSupply: {
      title: 'required to get list of shore supply',
      key: 'shore-supply',
      url: (vesselId: number, fileStatus: string) =>
        `shore_supply/get_shore_supply_by_vessel/?vessel_id=${vesselId}&status=${fileStatus}`,
    },
    SpecialOperationFile: {
      title: 'required to get list of special operation files',
      key: 'upload-special-operation-file-by-vessel',
      url: (vesselId: number, fileStatus: string) =>
        `special_operation_file_upload/get_special_operation_file_by_vessel/?vessel_id=${vesselId}&status=${fileStatus}`,
    },
    ReeferContainerByVessel: {
      title: 'required to get list of reefer container details',
      key: 'reefer-container-details',
      url: (vesselId: number, fileStatus: string) =>
        `reefer_container_details/get_reefer_container_detail_by_vessel/?vessel_id=${vesselId}&status=${fileStatus}`,
    },
    BDNfiles: {
      title: 'required to get list of bdn files',
      key: 'BDN-file-list',
      url: (vesselId: number, reportId: number) =>
        `bdn_file_upload/get_bdn_file_records_by_vessel_and_report/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    FuelTypesExcludingVesselFuelTypes: {
      title:
        'list of all fuel types excluding the distinct fuel types configured on the selected vessel',
      key: 'fuel-types-excluding-vessel-fuel-types',
      url: (vesselId: number) =>
        `vessel_fuel_information/get_fuel_type_exclude_vessel_fuel/?vessel_id=${vesselId}`,
    },
    VesselReportingCIIAdjusment: {
      title: 'get list of vessel reporting CII Adjusment',
      key: 'vessel-reporting-cii-adjustment',
      url: (vesselId: number) =>
        `vessel_reporting_cii_adjustment/get_cii_adjustment_by_vessel/?vessel_id=${vesselId}`,
    },
    MachineryListMaster: {
      title: 'get list of all machinery',
      key: 'machinery-list-master',
      url: () => `machinery_list_master`,
    },
    FCElectricalReeferObject: {
      title: 'get object of FC electrical reefer details',
      key: 'fc-electrical-reefer-object',
      url: () => `fc_electrical_reefer_details`,
    },
    OnboardingVesselReportingInformation: {
      title: 'onboarding vessel reporting information',
      key: 'onboarding-vessel-reporting-information',
      url: () => `onboarding_vessel_reporting_information/`,
    },
    OnboardingLngTankDetail: {
      title: 'get or post onboarding LNG Tank Detail',
      key: 'onboarding-lng-tank-detail',
      url: () => `onboarding_lng_tank_detail/`,
    },
    OnboardingBunkering: {
      title: 'get or post onboarding bunkering',
      key: 'onboarding-bunkering',
      url: () => `onboarding_bunkering/`,
    },
    Bunkering: {
      title: 'get or post  bunkering',
      key: 'bunkering',
      url: () => `bunkering/`,
    },
    onboardingOtherRob: {
      title: 'get or post on onboarding fresh water rob',
      key: 'onboarding-fresh-water-rob',
      url: () => `onboarding_other_rob/`,
    },
    onboardingLORob: {
      title: 'get or post on onboarding LO rob',
      key: 'onboarding_lo_rob',
      url: () => `onboarding_lo_rob/`,
    },
    SatelliteCommunication: {
      title: `Satellite communication information`,
      key: 'satellite-communication',
      url: () => `vessel_satellite_information/`,
    },
    SatelliteCommunicationByVessel: {
      title: `Vessel's satellite communication information`,
      key: 'satellite-communication-by-vessel',
      url: (vesselId: number) =>
        `vessel_satellite_information/get_vessel_satellite_info_by_vessel/?vessel_id=${vesselId}`,
    },
    OnboardingSpeedAndConsumption: {
      title: `onboardingspeed and consumption`,
      key: 'onboarding-speed-and-consumption',
      url: () => `onboarding_speed_and_consumption/`,
    },
    OnboardingSpeedAndConsumptionByVessel: {
      title: `onboardingspeed and consumption by vessel`,
      key: 'onboarding-speed-and-consumption-by-vessel',
      url: (vesselId: number) =>
        `onboarding_speed_and_consumption/get_onboarding_speed_and_consumption_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingVoyageByVessel: {
      title: `get onboarding voyage by vessel`,
      key: 'get-onboarding-voyage-by-vessel',
      url: (vesselId: number) =>
        `onboarding_voyage_information/get_onboarding_voyage_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingReportByVessel: {
      title: `get onboarding report by vessel`,
      key: 'get-onboarding-reporting-by-vessel',
      url: (vesselId: number) =>
        `onboarding_vessel_reporting_information/get_onboarding_reporting_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingBunkeringByVessel: {
      title: `get onboarding bunkering by vessel`,
      key: 'get-onboarding-bunkering-by-vessel',
      url: (vesselId: number) =>
        `onboarding_bunkering/get_onboarding_bunkering_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingLOROBByVessel: {
      title: `get onboarding lo rob by vessel`,
      key: 'get-onboarding-lo-rob-by-vessel',
      url: (vesselId: number) =>
        `onboarding_lo_rob/get_onboarding_lo_rob_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingOtherByVessel: {
      title: `get onboarding fresh water by vessel`,
      key: 'get-onboarding-fresh-water-by-vessel',
      url: (vesselId: number) =>
        `onboarding_other_rob/get_onboarding_other_rob_by_vessel/?vessel_id=${vesselId}`,
    },
    GetOnboardingLNGTankByVessel: {
      title: `get onboarding LNG Tank by vessel`,
      key: 'get-onboarding-LNG-Tank-by-vessel-and-onboarding-reporting',
      url: (vesselId: number) =>
        `onboarding_lng_tank_detail/get_onboarding_lng_tank_by_vessel/?vessel_id=${vesselId}`,
    },
    EconFMCounterMaster: {
      title: `Econship's flowmeters and counters master`,
      key: 'econ-fm-counter-master',
      url: (vesselId: number) =>
        `econship_fm_counter_master/get_econship_fm_counter_by_vessel/?vessel_id=${vesselId}`,
    },
    EconMEAdditionalInfo: {
      title: `Econship's main engine additional info`,
      key: 'econ-me-additional-info',
      url: () => `econship_main_engine_additional_detail/`,
    },
    EconMEAdditionalObject: {
      title: `Econship's main engine additional info`,
      key: 'econ-me-additional-info-object',
      url: (vesselId: number, reportId: number) =>
        `econship_main_engine_additional_detail/get_all_econ_main_engine_additional_detail_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EconMEAdditionalEdit: {
      title: `Econship's main engine additional info edit url`,
      key: 'econ-me-additional-info-edit-url',
      url: () =>
        `econship_main_engine_additional_detail/update_all_econ_main_engine_additional_details/`,
    },
    EconPrevMEExhaustUnits: {
      title: `Econship's main engine's exhaust units data from previous noon report`,
      key: 'econ-previous-me-exhaust-units',
      url: (vesselId: number, reportId: number) =>
        `econship_main_engine_exhaust_temperature/get_last_me_exhaust_temp_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EconAEAdditionalInfo: {
      title: `Econship's auxiliary engine additional info`,
      key: 'econ-ae-additional-info',
      url: () => `econship_aux_engine_additional_detail/`,
    },
    EconAEAdditionalObject: {
      title: `Econship's auxiliary engine additional info`,
      key: 'econ-ae-additional-info-object',
      url: (vesselId: number, reportId: number) =>
        `econship_aux_engine_additional_detail/get_all_econ_aux_engine_additional_detail_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EconAEAdditionalEdit: {
      title: `Econship's aux engine additional info edit url`,
      key: 'econ-ae-additional-info-edit-url',
      url: () =>
        `econship_aux_engine_additional_detail/update_all_econ_aux_engine_additional_details/`,
    },
    EconPrevAEExhaustUnits: {
      title: `Econship's auxiliary engine's exhaust units data from previous noon report`,
      key: 'econ-previous-ae-exhaust-units',
      url: (vesselId: number, reportId: number) =>
        `econship_aux_engine_exhaust_temperature/get_last_ae_exhaust_temperature_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EconFMCounter: {
      title: `Econship's flowmeters and counters api`,
      key: 'econ-fm-counter',
      url: () => `econship_counters_and_flowmeter/`,
    },
    EconFMCounterObject: {
      title: `Econship's flowmeters and counters report object`,
      key: 'econ-fm-counter-object',
      url: (vesselId: number, reportId: number) =>
        `econship_counters_and_flowmeter/get_econship_ae_exhaust_temperature_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EconDeckAdditionalInformation: {
      title: `Econship's additional information`,
      key: 'econ-deck-additional-information',
      url: (vesselId: number, reportId: number) =>
        `econship_deck_officer_additional/get_econship_deck_officer_additional_by_vessel_and_vessel_reporting/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    LastETADObject: {
      title: 'last ETAD data',
      key: 'last-ETAD-object',
      url: (vesselId: number, reportId: number) =>
        `expected_time_of_arrival/get_arrival_port/?vessel_reporting_id=${reportId}&vessel_id=${vesselId}`,
    },
    LastETADPort: {
      title: 'last ETAD data',
      key: 'last-ETAD-object-port',
      url: (vesselId: number, reportId: number) =>
        `expected_time_of_arrival/get_last_record_of_ETA_by_vessel_and_reporting_id/?vessel_reporting_id=${reportId}&vessel_id=${vesselId}`,
    },
    VoyageParameter: {
      title: `voyage parameter`,
      key: 'voyage-parameter',
      url: () => `voyage_parameter/`,
    },
    VesselGeneralInfo: {
      title: `vessel general information`,
      key: 'vessel-general-information',
      url: () => `vessel_general_information/`,
    },
    ConfigurationList: {
      title: `configuration list`,
      key: 'configuration-list',
      url: (vesselId: number) => `configuration_list?vessel_id=${vesselId}`,
    },
    ViewReportPDF: {
      title: `Vessel reporting's PDF`,
      key: 'view-report-pdf',
      url: (vesselId: number, reportId: number) =>
        `/noon-report-pdf/${vesselId}/${reportId}/`,
    },
    DeckOfficerRemark: {
      title: `deck officer remark`,
      key: 'deck-officer-remark',
      url: (vesselId: number, reportId: number) =>
        `deck_officer_remark/get_by_vessel_id_and_reporting_id/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    EngineerRemark: {
      title: `deck officer remark`,
      key: 'deck-officer-remark',
      url: (vesselId: number, reportId: number) =>
        `engineer_remark/get_by_vessel_id_and_reporting_id/?vessel_id=${vesselId}&vessel_reporting_id=${reportId}`,
    },
    PendingReeferContainer: {
      title: `reefer container details`,
      key: 'pending-reefer-container-details',
      url: (vesselId: number) =>
        `reefer_container_details/get_pending_files_by_vessel/?vessel_id=${vesselId}`,
    },
    sendEmail: {
      title: `send email`,
      key: 'send-email',
      url: () => `/vessel_reporting_email/send_daily_report_email/`,
    },
    Getuserbyrole: {
      title: `get user by role`,
      key: 'get-user-by-role',
      url: (role: string) => `user_vessel/role_users/?role_constant=${role}`,
    },
    VesselSensor: {
      title: `vessel sensor`,
      key: 'vessel-sensor',
      url: () => `vessel_sensor/`,
    },
    VesselSensorByVesselID: {
      title: `vessel sensor by vessel`,
      key: 'vessel-sensor-by-vessel',
      url: (vesselID: number) =>
        `vessel_sensor/get_vessel_sensor_by_vessel/?vessel_id=${vesselID}`,
    },
    VesselSensorByID: {
      title: `vessel sensor by ID`,
      key: 'vessel-sensor-by-ID',
      url: (id: number) => `vessel_sensor/${id}`,
    },
    ErrorReport: {
      title: `error report`,
      key: 'error-report',
      url: () => `/error_report/`,
    },
  },
  sync: {
    SyncFileStatusList: {
      title: 'required to get sync file status',
      key: 'sync-file-status',
      url: () => `sync_file_status`,
    },
    syncFileStatusByFileName: {
      title: 'required to get sync file status by file name',
      key: 'sync-file-status-by-file-name',
      url: (fileName: string) =>
        `sync_file_status/sync_file_by_file_name/?file_name=${fileName}`,
    },
  },
  notification: {
    Notification: {
      title: 'required to get list of notification',
      key: 'notification',
      url: (id: number) => `notification/${id}`,
    },
    NotificationListMaster: {
      title: 'required to get list of notification',
      key: 'notification-list-master',
      url: () => `notification/`,
    },
    NotificationCategoryList: {
      title: 'required to get list of category',
      key: 'notification-category-list',
      url: () => `notification_category`,
    },
    NotificationPriorityList: {
      title: 'required to get list of priority',
      key: 'notification-priority-master',
      url: () => `notification_priority_master`,
    },
    GroupList: {
      title: 'required to get list of group',
      key: 'group',
      url: () => `group`,
    },
    NotificationState: {
      title: 'required to get list of notification state',
      key: 'notification-state',
      url: () => `notification_state`,
    },
    NotificationInbox: {
      title: 'required to get list of Inbox notifications',
      key: 'notification-inbox',
      url: () => `notification/inbox/`,
    },
    NotificationSent: {
      title: 'required to get list of Sent notifications',
      key: 'notification-sent',
      url: () => `notification/sent/`,
    },
    NotificationDeleted: {
      title: 'required to get list of Deleted notifications',
      key: 'notification-trash',
      url: () => `notification/trash/`,
    },
    NotificationDraft: {
      title: 'required to get list of Deleted notifications',
      key: 'notification-draft',
      url: () => `notification/draft/`,
    },
    NotificationUserStatus: {
      title: 'required to get list of notifications user status',
      key: 'notification-user-status',
      url: () => `notification_user_status`,
    },
    NotificationUser: {
      title: 'required to get list of notifications user',
      key: 'notification-user',
      url: () => `notification_user`,
    },
    NotificationArchive: {
      title: 'required to get list of Deleted notifications',
      key: 'notification-archive',
      url: () => `notification/archive/`,
    },
  },
  user: {
    profile: {
      title: 'required to get user profile',
      key: 'users-profile',
      url: () => `profile/`,
    },
    block: {
      title: 'required to block user',
      key: 'block-user',
      url: () => `profile/block/`,
    },
    delete: {
      title: 'required to delete user',
      key: 'delete-user',
      url: () => `/profile/delete/`,
    },
    view_temp_password: {
      title: 'required to view temporary password',
      key: 'view-temp-password',
      url: () => `/profile/view_temp_password/`,
    },
    authenticate: {
      title: 'required to authenticate user',
      key: 'authenticate-user',
      url: () => `/profile/authenticate/`,
    },
    reset_temp_password: {
      title: 'required to reset temporary password',
      key: 'reset-temp-password',
      url: () => `/profile/reset_temp_password/`,
    },
    register: {
      title: 'required to register user',
      key: 'register-user',
      url: () => `register/`,
    },
    profileID: {
      title: 'required to get user profile by id',
      key: 'profile-id',
      url: (userId: number) => `/profile/${userId}/`,
    },
    group: {
      title: 'required to get user group',
      key: 'group',
      url: () => `/group/`,
    },
    changePassword: {
      title: 'required to change password',
      key: 'change-password',
      url: () => `/profile/change_password/`,
    },
  },
  organization: {
    OrganizationConfiguration: {
      title: 'required to get list of organization',
      key: 'organization-configuration',
      url: () => `org_config/`,
    },
  },
  pagination: {
    BDNPendingFilesPage: {
      title: 'required to get list of pending BDN files',
      key: 'pending-bdn-files',
      url: (vesselId: number, status: string) =>
        `/bdn_file_upload/get_bdn_file_records_by_vessel/?vessel_id=${vesselId}&pagination=true&status=${status}`,
    },
    EDNPendingFilesPage: {
      title: 'required to get list of pending EDN files',
      key: 'pending-edn-files',
      url: (vesselId: number, status: string) =>
        `shore_supply/get_shore_supply_by_vessel/?pagination=true&status=${status}&vessel_id=${vesselId}`,
    },
    LNGCargoPendingFilesPage: {
      title: 'required to get list of pending LNG Cargo files',
      key: 'pending-lng-cargo-files',
      url: (vesselId: number, status: string) =>
        `n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${vesselId}&pagination=true&ctms_end_file_status=${status}`,
    },
    VoyageListPage: {
      title: 'Get a list of compilted voyages',
      key: 'cmplited-voyage-list',
      url: (VesselID: number) =>
        `voyage_information/get_voyage_by_vessel/?vessel_id=${VesselID}&pagination=true&voyage_status=${VoyageConstant.CLOSED}`,
    },
    vesselListPage: {
      title: 'Get a list of vessels',
      key: 'vessel-list-page',
      url: (status: string) =>
        `vessel_master/?pagination=true&onboarding_status=${status}`,
    },
    completeReportPage: {
      title: 'Get a list of completed reports',
      key: 'compleate-report-page',
      url: (vesselId: number, voyageId: number) =>
        `vessel_reporting_information/get_reporting_info_same_vessel_and_voyage/?vessel_id=${vesselId}&voyage_information_id=${voyageId}&pagination=true&vessel_reporting_status=${ReportingStatusConstant.SENT}`,
    },
    CIIPendingFilesPage: {
      title: 'required to get list of pending CII adjustment files',
      key: 'pending-cii-file-upload',
      url: (vesselId: number) =>
        `vessel_reporting_cii_adjustment_supporting_file/get_pending_files_by_vessel/?vessel_id=${vesselId}`,
    },
    N2AdjustmentPage: {
      title: 'Get a list of N2 adjustments',
      key: 'n2-adjustment-list-page',
      url: (vesselId: number, status: string) =>
        `/n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${vesselId}&approval_status=${status}`,
    },
    VoyageAdjustmentPage: {
      title: 'Voyage Adjustment',
      key: 'voyage-adjustment',
      url: (vesselId: number, status: string) =>
        `vessel_reporting_cii_adjustment/get_voyage_adjustment_pagination_by_vessel/?vessel_id=${vesselId}&user_approved=${status}`,
    },
    SpOpLogBookPage: {
      title: 'Get a list of special operation log book',
      key: 'special-operation-log-book',
      url: (vesselId: number, status: string) =>
        `special_operation_file_upload/get_special_operation_file_by_vessel/?vessel_id=${vesselId}&status=${status}&pagination=true`,
    },
  },
};
