import apiGlobal from "../../../global/api.global";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
  loadMachineryEnergyGroup,
  loadMachineryFuelGroup,
  loadSpecialOperationEnergySettings,
  loadSpecialOperationFuelSettings,
  loadSpecialOperationSettingList,
} from "VesselMaster/vesselMaster.hooks";
import { Formik, FieldArray, Field, FieldProps } from "formik";
import { handleServerResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { VesselTypeConstant } from "shared/constants";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import React, { useEffect } from "react";
import { queryClient } from "react-query/queryClient";

const GenerelSetting = ({
  setCargoDischarging,
  setCargoHeating,
  setCargoCooling,
  setCargoReliquification,
  setReeferContainer,
  setTankCleaning,
  refreshVesselMachineries,
  setRefreshVesselMachineries,
}: any) => {
  const VesselID = useSelector((state: RootState) => state.Reporting.VesselID);
  const Vessels = useSelector((state: RootState) => state.Reporting.Vessels);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  /** Array containing the keys of special operation settings to be fetched via queries */
  const useQueryArray = [
    'SpecialOperationSettingList',
    'SpecialOperationFuelSettings',
    'SpecialOperationEnergySettings',
    'CargoCoolingWithkWhMachineries',
    'CargoDischargingWithkWhMachineries',
    'CargoHeatingWithkWhMachineries',
    'CargoReliquificationWithkWhMachineries',
    'ReeferContainerWithkWhMachineries',
    'TankCleaningWithkWhMachineries'
  ]

  /** Fetch special operation fuel settings for the selected vessel */
  const {
    data: SpecialOperationFcObject,
    isLoading: SpecialOperationFcObjectLoading,
    isError: SpecialOperationFcError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.SpecialOperationFuelSettings.key, VesselID],
    async () => {
      return await loadSpecialOperationFuelSettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );

  /** Fetch special operation energy settings for the selected vessel */
  const {
    data: vesselMachineryEC,
    isLoading: vesselMachineryECLoading,
    isError: vesselMachineryECError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.SpecialOperationEnergySettings.key, VesselID],
    async () => {
      return await loadSpecialOperationEnergySettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );

  /** Fetch general special operation settings for the selected vessel */
  const {
    data: generelSettingObject,
    isLoading: generelSettingObjectLoading,
    isError: generelSettingObjectError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.SpecialOperationSettingList.key, VesselID],
    async () => {
      return await loadSpecialOperationSettingList(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Fetch machinery fuel group data for the selected vessel */
  const {
    data: machineryFuelGroup,
    isLoading: machineryFuelGroupLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
    async () => {
      return await loadMachineryFuelGroup(VesselID);
    },
    { staleTime: Infinity }
  );

  /** Fetch machinery energy group data for the selected vessel */
  const {
    data: machineryEnergyGroup,
    isLoading: machineryEnergyGroupLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key, VesselID],
    async () => {
      return await loadMachineryEnergyGroup(VesselID);
    },
    { staleTime: Infinity }
  );

  /** Generate initial values for special operation fuel settings form */
  const getInitialValueSpecialOperationFcForm = () => {
    if (
      SpecialOperationFcObject?.length > 0 &&
      SpecialOperationFcObject[0]?.id > 0
    ) {
      let specialIds = new Set(SpecialOperationFcObject.map(obj => obj.vessel_machinery_fc_group));
      let arr = [...SpecialOperationFcObject];
      machineryFuelGroup?.forEach((item) => {
        if (!specialIds.has(item.id)) {
          arr.push(item);
        }
      });
      return arr;
    } else {
      let array: any[] = [];
      if (machineryFuelGroup !== undefined && machineryFuelGroup?.length > 0) {
        machineryFuelGroup?.forEach((vessel: any) => {
          array.push({
            cargo_heating: false,
            tank_cleaning: false,
            cargo_discharging: false,
            reefer_container: false,
            cargo_cooling: false,
            cargo_reliquification: false,
            vessel_machinery_fc_group_name: vessel.machinery_name,
            vessel_machinery_fc_group: vessel.id,
            vessel: VesselID,
          });
        });
      }
      return array;
    }
  }

  /** Generate initial values for special operation energy settings form */
  const getInitialValueSpecialOperationEcForm = () => {
    if (vesselMachineryEC?.length > 0 && vesselMachineryEC[0]?.id > 0) {
      let specialIds = new Set(vesselMachineryEC.map(obj => obj.vessel_machinery_ec_group));
      let arr = [...vesselMachineryEC];
      machineryEnergyGroup?.forEach((item) => {
        if (!specialIds.has(item.id)) {
          arr.push({
            cargo_discharging_kwh_meter: false,
            cargo_cooling_kwh_meter: false,
            cargo_heating_kwh_meter: false,
            tank_cleaning_kwh_meter: false,
            reefer_container_kwh_meter: false,
            cargo_reliquification_kwh_meter: false,
            cargo_heating: false,
            tank_cleaning: false,
            cargo_discharging: false,
            reefer_container: false,
            cargo_cooling: false,
            cargo_reliquification: false,
            vessel_machinery_ec_group: item.id,
            vessel: VesselID,
            vessel_machinery_ec_group_name: item.vessel_machinery_ec_group_name,
          });
        }
      });
      return arr;
    } else {
      let array: any[] = [];
      machineryEnergyGroup?.forEach((vessel: any) => {
        array.push({
          cargo_discharging_kwh_meter: false,
          cargo_cooling_kwh_meter: false,
          cargo_heating_kwh_meter: false,
          tank_cleaning_kwh_meter: false,
          reefer_container_kwh_meter: false,
          cargo_reliquification_kwh_meter: false,
          cargo_heating: false,
          tank_cleaning: false,
          cargo_discharging: false,
          reefer_container: false,
          cargo_cooling: false,
          cargo_reliquification: false,
          vessel_machinery_ec_group: vessel.id,
          vessel: VesselID,
          vessel_machinery_ec_group_name: vessel.vessel_machinery_ec_group_name,
        });
      });
      return array;
    }
  };

  /** Effect to update the form values when fuel or energy group data changes */
  useEffect(() => {
    GenerelSettingFormik.initialValues.SpecialOperationFc =
      getInitialValueSpecialOperationFcForm();
    GenerelSettingFormik.initialValues.SpecialOperationEc =
      getInitialValueSpecialOperationEcForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    SpecialOperationFcObject,
    machineryFuelGroup,
    VesselID,
    getInitialValueSpecialOperationFcForm,
    getInitialValueSpecialOperationEcForm,
    vesselMachineryEC,
    machineryEnergyGroup
  ]);

  /** Generate initial values for general settings form */
  const getInitialValueSpecialOperationGenerelSettingForm = () => {
    if (generelSettingObject?.length > 0) {
      return generelSettingObject[0];
    } else {
      return {
        cargo_cooling: false,
        cargo_discharging: false,
        cargo_reliquification: false,
        reefer_container: false,
        cargo_heating: false,
        tank_cleaning: false,
        vessel: VesselID,
      };
    }
  };

  /** Effect to load special operation data when vessel ID changes */
  useEffect(() => {
    loadSpecialOperationSettingList(VesselID);
  }, [VesselID])
  /** useEffect end */

  /** Formik object for managing the form state and validation */
  const GenerelSettingFormik = {
    initialValues: {
      generalSetting: getInitialValueSpecialOperationGenerelSettingForm(),
      SpecialOperationFc: getInitialValueSpecialOperationFcForm(),
      SpecialOperationEc: getInitialValueSpecialOperationEcForm(),
    }
  };

  return (
    <>
      <>
        <Card>
          <CardHeader className="p-2">
            <h5 className="mb-0">Special Operations Settings</h5>
          </CardHeader>
          <CardBody>
            {(SpecialOperationFcObjectLoading ||
              vesselMachineryECLoading ||
              generelSettingObjectLoading ||
              machineryEnergyGroupLoading ||
              machineryFuelGroupLoading) && (
                <Loading message="Loading required data!" />
              )}
            {(SpecialOperationFcError ||
              vesselMachineryECError ||
              generelSettingObjectError) && (
                <ErrorComponent message="Unable to load required data!" />
              )}
            {!(
              SpecialOperationFcObjectLoading ||
              vesselMachineryECLoading ||
              generelSettingObjectLoading ||
              machineryEnergyGroupLoading ||
              machineryFuelGroupLoading
            ) &&
              !(
                SpecialOperationFcError ||
                vesselMachineryECError ||
                generelSettingObjectError
              ) && (
                <Formik
                  onSubmit={async (values: any, actions: any) => {
                    actions.setSubmitting(true);
                    let responseArray: any[] = [];
                    if (SpecialOperationFcObject && SpecialOperationFcObject.length > 0) {
                      values?.SpecialOperationFc?.forEach((machine: any) => {
                        responseArray.push(apiGlobal.put(`/special_operation_fuel_setting/${machine?.id}/`, machine));
                      });
                    } else {
                      responseArray.push(apiGlobal.post(`/special_operation_fuel_setting/`, values?.SpecialOperationFc));
                    }
                    if (vesselMachineryEC && vesselMachineryEC?.length > 0) {
                      values?.SpecialOperationEc?.forEach((machine: any) => {
                        responseArray.push(apiGlobal.put(`/special_operation_energy_setting/${machine.id}/`, machine));
                      });
                    } else {
                      responseArray.push(apiGlobal.post(`/special_operation_energy_setting/`, values?.SpecialOperationEc));
                    }
                    if (generelSettingObject && generelSettingObject?.length > 0) {
                      responseArray.push(apiGlobal.put(`/special_operation_setting/${values?.generalSetting?.id}/`, values?.generalSetting));
                    } else {
                      responseArray.push(apiGlobal.post(`/special_operation_setting/`, values?.generalSetting));
                    }
                    /** handle server response */
                    await handleServerResponse(responseArray).then(async (res) => {
                      if (res) {
                        await queryClient.invalidateQueries(queryKeyes.vessel.SpecialOperationSettingList.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.SpecialOperationFuelSettings.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.SpecialOperationEnergySettings.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.CargoCoolingWithkWhMachineries.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.CargoDischargingWithkWhMachineries.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.CargoHeatingWithkWhMachineries.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.ReeferContainerWithkWhMachineries.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.TankCleaningWithkWhMachineries.key);
                        setRefreshVesselMachineries(refreshVesselMachineries + 1);
                      }
                    });
                    actions.setSubmitting(false);
                  }}
                  initialValues={GenerelSettingFormik?.initialValues}
                  key={refreshVesselMachineries}
                >
                  {(props) => (
                    <Form
                      onSubmit={props?.handleSubmit}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <FieldArray name="generalSetting">
                          {() => (
                            <>
                              <Row className="mb-3">
                                <Col sm={4}>
                                  <div>
                                    {vessel?.vessel_type ===
                                      VesselTypeConstant?.TANKER && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.cargo_heating`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.cargo_heating`}
                                                name={field?.name}
                                                checked={
                                                  props?.values?.generalSetting
                                                    ?.cargo_heating
                                                }
                                                onChange={(e: any) => {
                                                  props?.handleChange(e);
                                                  setCargoHeating(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.cargo_heating`}
                                          >
                                            Cargo Heating
                                          </Label>
                                        </div>
                                      )}
                                    {vessel?.vessel_type ===
                                      VesselTypeConstant?.TANKER && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.tank_cleaning`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.tank_cleaning`}
                                                name={field?.name}
                                                checked={
                                                  props?.values?.generalSetting
                                                    ?.tank_cleaning
                                                }
                                                onChange={(e: any) => {
                                                  props?.handleChange(e);
                                                  setTankCleaning(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.tank_cleaning`}
                                          >
                                            Tank Cleaning
                                          </Label>
                                        </div>
                                      )}
                                    {vessel?.vessel_type ===
                                      VesselTypeConstant?.TANKER && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.cargo_discharging`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.cargo_discharging`}
                                                name={field?.name}
                                                checked={
                                                  props?.values?.generalSetting
                                                    ?.cargo_discharging
                                                }
                                                onChange={(e: any) => {
                                                  props?.handleChange(e);
                                                  setCargoDischarging(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.cargo_discharging`}
                                          >
                                            Cargo Discharging
                                          </Label>
                                        </div>
                                      )}
                                    {(vessel?.vessel_type ===
                                      VesselTypeConstant?.CONTAINER ||
                                      vessel?.vessel_type ===
                                      VesselTypeConstant?.GENERAL_CARGO_SHIP) && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.reefer_container`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.reefer_container`}
                                                name={field?.name}
                                                checked={
                                                  props?.values?.generalSetting
                                                    ?.reefer_container
                                                }
                                                onChange={(e: any) => {
                                                  props?.handleChange(e);
                                                  setReeferContainer(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.reefer_container`}
                                          >
                                            Reefer Container
                                          </Label>
                                        </div>
                                      )}
                                    {(vessel?.vessel_type ===
                                      VesselTypeConstant?.GAS_CARRIER ||
                                      vessel?.vessel_type ===
                                      VesselTypeConstant?.LNG_CARRIER) && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.cargo_cooling`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.cargo_cooling`}
                                                name={field?.name}
                                                checked={
                                                  props?.values?.generalSetting
                                                    ?.cargo_cooling
                                                }
                                                onChange={(e: any) => {
                                                  props?.handleChange(e);
                                                  setCargoCooling(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.cargo_cooling`}
                                          >
                                            Cargo Cooling
                                          </Label>
                                        </div>
                                      )}
                                    {(vessel?.vessel_type ===
                                      VesselTypeConstant?.GAS_CARRIER ||
                                      vessel?.vessel_type ===
                                      VesselTypeConstant?.LNG_CARRIER) && (
                                        <div className="">
                                          <Field
                                            name={`generalSetting.cargo_reliquification`}
                                          >
                                            {({ field }: FieldProps) => (
                                              <Input
                                                type="checkbox"
                                                id={`generalSetting.cargo_reliquification`}
                                                name={field.name}
                                                checked={
                                                  props.values.generalSetting
                                                    .cargo_reliquification
                                                }
                                                onChange={(e: any) => {
                                                  props.handleChange(e);
                                                  setCargoReliquification(
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Label
                                            className="ms-2"
                                            for={`generalSetting.cargo_reliquification`}
                                          >
                                            Cargo Reliquification
                                          </Label>
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col sm={{ offset: 6, size: 2 }}>
                                  <Button
                                    type="button"
                                    color='primary'
                                    className='btn btn-primary waves-effect btn-label waves-light pos-end'
                                    onClick={() => {
                                      useQueryArray.forEach((item: any) => {
                                        queryClient.invalidateQueries(queryKeyes.vessel[item as keyof typeof queryKeyes.vessel].key);
                                      })
                                      setRefreshVesselMachineries(refreshVesselMachineries + 1);
                                    }}
                                  >
                                    <i className="mdi mdi-reload label-icon me-1" />Refresh
                                  </Button>
                                </Col>
                              </Row>
                              <Card>
                                {(props?.values?.generalSetting
                                  ?.cargo_heating === true ||
                                  props?.values?.generalSetting
                                    ?.cargo_cooling === true ||
                                  props?.values?.generalSetting
                                    ?.tank_cleaning === true ||
                                  props?.values?.generalSetting
                                    ?.cargo_reliquification === true ||
                                  props?.values?.generalSetting
                                    ?.reefer_container === true ||
                                  props?.values?.generalSetting
                                    ?.cargo_discharging === true) &&
                                  (vessel?.vessel_type ===
                                    VesselTypeConstant?.TANKER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.CONTAINER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.GAS_CARRIER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.LNG_CARRIER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.GENERAL_CARGO_SHIP) &&
                                  <React.Fragment>
                                    {props?.values?.SpecialOperationEc
                                      ?.length > 0 && (
                                        <React.Fragment>
                                          <CardHeader className="p-2">
                                            <h6 className="mb-0">
                                              Electrical energy Consumption
                                            </h6>
                                          </CardHeader>
                                          <CardBody className="p-0">
                                            <div className="table-responsive ">
                                              {props?.values?.SpecialOperationEc?.length > 0 ? (
                                                <table className="table" key={refreshVesselMachineries}>
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="p-2 align-middle sr-no-width">
                                                        #
                                                      </th>
                                                      <th className="p-2 align-middle">
                                                        Machinery name
                                                      </th>
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.tank_cleaning && (
                                                          <th className="p-2 align-middle text-center">
                                                            Tank Cleaning
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_heating && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo Heating
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_discharging && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo discharging
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_cooling && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo Cooling
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_reliquification && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo
                                                            Reliquification
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.reefer_container && (
                                                          <th className="p-2 align-middle text-center">
                                                            Reefer Container
                                                          </th>
                                                        )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <FieldArray name="SpecialOperationEc">
                                                      {() => (
                                                        <React.Fragment>
                                                          {props?.values?.SpecialOperationEc?.map(
                                                            (
                                                              vesselMachinery: any,
                                                              index: number
                                                            ) => {
                                                              return (
                                                                <tr key={index}>
                                                                  <td className="p-2 text-center align-middle">
                                                                    {index + 1}
                                                                  </td>
                                                                  <td className="p-2 align-middle">
                                                                    {
                                                                      vesselMachinery?.vessel_machinery_ec_group_name
                                                                    }
                                                                  </td>
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.tank_cleaning && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field
                                                                            name={`SpecialOperationEc.${index}.tank_cleaning`}
                                                                          >
                                                                            {({
                                                                              field,
                                                                            }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_tank_cleaning_${index}`}
                                                                                name={field?.name}
                                                                                onChange={(e: any) =>
                                                                                  props?.handleChange(e)}
                                                                                defaultChecked={props?.values?.SpecialOperationEc?.[index]?.tank_cleaning}
                                                                              />
                                                                            )}
                                                                          </Field>
                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.cargo_heating && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field
                                                                            name={`SpecialOperationEc.${index}.cargo_heating`}
                                                                          >
                                                                            {({
                                                                              field,
                                                                            }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_cargo_heating_${index}`}
                                                                                name={field?.name}
                                                                                onChange={(e: any) =>
                                                                                  props?.handleChange(e)}
                                                                                defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_heating
                                                                                }
                                                                              />
                                                                            )}
                                                                          </Field>
                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.cargo_discharging && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field
                                                                            name={`SpecialOperationEc.${index}.cargo_discharging`}
                                                                          >
                                                                            {({
                                                                              field,
                                                                            }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_cargo_discharging_${index}`}
                                                                                name={
                                                                                  field?.name
                                                                                }
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_discharging
                                                                                }
                                                                              />
                                                                            )}
                                                                          </Field>
                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.reefer_container && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field name={`SpecialOperationEc.${index}.reefer_container`}>
                                                                            {({ field }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_reefer_container_${index}`}
                                                                                name={field.name}
                                                                                checked={field.value}
                                                                                onChange={(e: any) => {
                                                                                  props.handleChange(e);
                                                                                }}
                                                                              />
                                                                            )}
                                                                          </Field>

                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.cargo_cooling && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field
                                                                            name={`SpecialOperationEc.${index}.cargo_cooling`}
                                                                          >
                                                                            {({
                                                                              field,
                                                                            }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_cargo_cooling_${index}`}
                                                                                name={field?.name}
                                                                                onChange={(e: any) =>
                                                                                  props?.handleChange(e)}
                                                                                defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_cooling}
                                                                              />
                                                                            )}
                                                                          </Field>
                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                  {props?.values
                                                                    ?.generalSetting
                                                                    ?.cargo_reliquification && (
                                                                      <td className="p-2 text-center align-middle">
                                                                        <div className="form-check d-inline-block">
                                                                          <Field
                                                                            name={`SpecialOperationEc.${index}.cargo_reliquification`}
                                                                          >
                                                                            {({
                                                                              field,
                                                                            }: FieldProps) => (
                                                                              <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chckbx_cargo_reliquification_${index}`}
                                                                                name={field.name}
                                                                                onChange={(e: any) =>
                                                                                  props?.handleChange(e)}
                                                                                defaultChecked={props?.values?.SpecialOperationEc?.[index]?.cargo_reliquification
                                                                                }
                                                                              />
                                                                            )}
                                                                          </Field>
                                                                        </div>
                                                                      </td>
                                                                    )}
                                                                </tr>
                                                              )
                                                            }
                                                          )}
                                                        </React.Fragment>
                                                      )}
                                                    </FieldArray>
                                                  </tbody>
                                                </table>
                                              ) : (
                                                <div className="text-center mt-3">
                                                  <strong>This vessel has no special operations.</strong>
                                                </div>
                                              )}
                                            </div>
                                          </CardBody>
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                }
                              </Card>
                              <Card>
                                {!(
                                  machineryFuelGroupLoading ||
                                  SpecialOperationFcObjectLoading
                                ) &&
                                  (props?.values?.generalSetting
                                    ?.cargo_heating === true ||
                                    props?.values?.generalSetting
                                      ?.cargo_cooling === true ||
                                    props?.values?.generalSetting
                                      ?.tank_cleaning === true ||
                                    props?.values?.generalSetting
                                      ?.cargo_reliquification === true ||
                                    props?.values?.generalSetting
                                      ?.cargo_discharging === true) &&
                                  (vessel?.vessel_type ===
                                    VesselTypeConstant?.TANKER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.GAS_CARRIER ||
                                    vessel?.vessel_type ===
                                    VesselTypeConstant?.LNG_CARRIER) &&
                                  <React.Fragment>
                                    {props?.values?.SpecialOperationFc
                                      ?.length > 0 && (
                                        <React.Fragment>
                                          <CardHeader className="p-2">
                                            <strong>
                                              Fuel Consumption
                                            </strong>
                                          </CardHeader>
                                          <CardBody className="p-0">
                                            <div className="table-responsive ">
                                              {props?.values?.SpecialOperationFc?.length > 0 ? (
                                                <table className="table" key={refreshVesselMachineries}>
                                                  <thead className="table-light">
                                                    <tr>
                                                      <th className="p-2 align-middle sr-no-width">
                                                        #
                                                      </th>
                                                      <th className="p-2 align-middle">
                                                        Machinery name
                                                      </th>
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.tank_cleaning && (
                                                          <th className="p-2 align-middle text-center">
                                                            Tank Cleaning
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_heating && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo Heating
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_discharging && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo discharging
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_reliquification && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo
                                                            Reliquification
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.cargo_cooling && (
                                                          <th className="p-2 align-middle text-center">
                                                            Cargo Cooling
                                                          </th>
                                                        )}
                                                      {props?.values
                                                        ?.generalSetting
                                                        ?.reefer_container && (
                                                          <th className="p-2 align-middle text-center">
                                                            Reefer Container
                                                          </th>
                                                        )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <FieldArray name="SpecialOperationFc">
                                                      {() => (
                                                        <React.Fragment>
                                                          {props?.values?.SpecialOperationFc?.map(
                                                            (
                                                              vesselMachinery: any,
                                                              index: number
                                                            ) => (
                                                              <tr key={index}>
                                                                <td className="p-2 text-center align-middle">
                                                                  {index + 1}
                                                                </td>
                                                                <td className="p-2 align-middle">
                                                                  {
                                                                    vesselMachinery?.vessel_machinery_fc_group_name
                                                                  }
                                                                </td>
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.tank_cleaning && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.tank_cleaning`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_tank_cleaning_${index}`}
                                                                              name={field.name}
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)}
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.tank_cleaning}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.cargo_heating && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.cargo_heating`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_cargo_heating_${index}`}
                                                                              name={field.name}
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)}
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_heating}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.cargo_discharging && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.cargo_discharging`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_cargo_discharging_${index}`}
                                                                              name={field.name}
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)}
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_discharging}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.reefer_container && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.reefer_container`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_reefer_container_${index}`}
                                                                              name={
                                                                                field.name
                                                                              }
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)
                                                                              }
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.reefer_container}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.cargo_cooling && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.cargo_cooling`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_cargo_cooling_${index}`}
                                                                              name={
                                                                                field.name
                                                                              }
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)}
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_cooling}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                                {props?.values
                                                                  ?.generalSetting
                                                                  ?.cargo_reliquification && (
                                                                    <td className="p-2 text-center align-middle">
                                                                      <div className="form-check d-inline-block">
                                                                        <Field
                                                                          name={`SpecialOperationFc.${index}.cargo_reliquification`}
                                                                        >
                                                                          {({
                                                                            field,
                                                                          }: FieldProps) => (
                                                                            <Input
                                                                              className="form-check-input"
                                                                              type="checkbox"
                                                                              id={`chckbx_cargo_reliquification_${index}`}
                                                                              name={field.name}
                                                                              onChange={(e: any) =>
                                                                                props?.handleChange(e)}
                                                                              defaultChecked={props?.values?.SpecialOperationFc?.[index]?.cargo_reliquification}
                                                                            />
                                                                          )}
                                                                        </Field>
                                                                      </div>
                                                                    </td>
                                                                  )}
                                                              </tr>
                                                            )
                                                          )}
                                                        </React.Fragment>
                                                      )}
                                                    </FieldArray>
                                                  </tbody>
                                                </table>
                                              ) :
                                                <div className="text-center mt-3">
                                                  <strong>There is no special operation for this vessel.</strong>
                                                </div>}
                                            </div>
                                          </CardBody>
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                }
                              </Card>
                            </>
                          )}
                        </FieldArray>
                      </div>
                      <CardFooter>
                        <Row>
                          <Col className="pb-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="pos-end"
                              disabled={isConfigurationButtonDisabled(vessel) || props?.isSubmitting}
                            >
                              {props?.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />} Save
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                      <Row className="mt-2">
                        <FormValuesDebug
                          values={[props?.values, props?.errors, GenerelSettingFormik?.initialValues,]}
                        />
                      </Row>
                    </Form>
                  )}
                </Formik>
              )}
          </CardBody>
        </Card>
      </>
    </>
  );
};
export default GenerelSetting;
