import React from 'react'
import { Field, FieldProps, useFormikContext } from 'formik';
import { Input } from 'reactstrap';
import { FuelTypes } from 'shared/constants';
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';

interface SpOpFuelConsumptionComponentType {
    fuelConsumptions: any
    setTotalConsumption: any,
    setFuelConsumed: any,
    setFuelSubTypeConsumption: any,
    machineryEnabled?: boolean
}

const SpOpFuelConsumptionComponent = ({
    fuelConsumptions,
    setTotalConsumption,
    setFuelConsumed,
    setFuelSubTypeConsumption,
    machineryEnabled
}: SpOpFuelConsumptionComponentType) => {
    const { values, errors, touched }: { values: any, errors: any, touched: any } = useFormikContext();
    return (
        <div className='d-inline-block'>
            <div className="input-group">
                <Field name={`fuelConsumption.${values.fuelConsumption.indexOf(fuelConsumptions)}.total_fuel_consumption`}>
                    {({ field, form }: FieldProps) => (
                        <Input
                            type="text"
                            className="form-control max-width-7 text-right"
                            id={`fuelConsumption_${values.fuelConsumption.indexOf(fuelConsumptions)}`}
                            name={field.name}
                            onChange={(e: any) => {
                                form.handleBlur(e);
                                form.setFieldValue(field.name, e.target.value);
                                if (values?.fuelSubTypeConsumption?.length > 0 &&
                                    values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.vessel_fuel === FuelTypes.FUEL_OIL) {
                                    let counter = 0;
                                    values?.fuelSubTypeConsumption?.forEach((subType: any) => {
                                        if (fuelConsumptions?.vessel_machinery_fc_group === subType?.vessel_machinery_fc_group) {
                                            counter++;
                                        }
                                    })
                                    if (counter > 1) {
                                        setTotalConsumption(e.target.value);
                                        setFuelConsumed(fuelConsumptions);
                                        setFuelSubTypeConsumption(true);
                                    } else {
                                        setTotalConsumption(e.target.value);
                                        setFuelConsumed(fuelConsumptions);
                                        values.fuelSubTypeConsumption.filter((subType: any) =>
                                            fuelConsumptions.vessel_machinery_fc_group === subType.vessel_machinery_fc_group)[0]
                                            .fuel_consumption = e.target.value;
                                    }
                                } else {
                                    setFuelSubTypeConsumption(false);
                                }
                            }}
                            onBlur={(e: any) => {
                                form.handleBlur(e);
                                form.setFieldValue(field.name, e.target.value);
                                if (values?.fuelSubTypeConsumption?.length > 0 &&
                                    values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.vessel_fuel === FuelTypes.FUEL_OIL) {
                                    let counter = 0;
                                    values?.fuelSubTypeConsumption?.forEach((subType: any) => {
                                        if (fuelConsumptions?.vessel_machinery_fc_group === subType?.vessel_machinery_fc_group) {
                                            counter++;
                                        }
                                    })
                                    if (counter > 1) {
                                        setTotalConsumption(e.target.value);
                                        setFuelConsumed(fuelConsumptions);
                                        setFuelSubTypeConsumption(true);
                                    } else {
                                        setTotalConsumption(e.target.value);
                                        setFuelConsumed(fuelConsumptions);
                                        values.fuelSubTypeConsumption.filter((subType: any) =>
                                            fuelConsumptions.vessel_machinery_fc_group === subType.vessel_machinery_fc_group)[0]
                                            .fuel_consumption = e.target.value;
                                    }
                                } else {
                                    setFuelSubTypeConsumption(false);
                                }
                            }}
                            defaultValue={values.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption}
                            disabled={machineryEnabled}
                        />
                    )}
                </Field>
                <div className="input-group-text">mt</div>
            </div>
            {
                errors?.fuelConsumption && touched?.fuelConsumption &&
                touched?.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption &&
                errors?.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption &&
                env?.form_validation === true &&
                <ErrorTooltip
                    target={`fuelConsumption_${values.fuelConsumption.indexOf(fuelConsumptions)}`}
                    message={errors?.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption}
                    open={
                        (errors && errors?.fuelConsumption[values.fuelConsumption.indexOf(fuelConsumptions)]?.total_fuel_consumption) ?
                            true : false
                    }
                />
            }
        </div>
    )
}

export default SpOpFuelConsumptionComponent