import { errorToast, successToast } from 'Components/Toasts';
import { Formik, Field, FieldArray } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from "yup";
import { Row, Col, Button, Form, CardHeader, CardBody, Card, CardFooter } from 'reactstrap';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { SludgeBilgeWaterMessages, commonValidationMessages } from 'Components/ValidationErrorMessages';
import apiGlobal from 'global/api.global';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadBilgeObject, loadBilgeTanks, loadSludgeObject, loadSludgeTanks } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import ErrorComponent from 'Components/ErrorComponent';
import { errResponse } from 'GenericForms/Helper';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';

interface SludgeBilgeWaterComponentType {
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    toggleAddTab: any,
    toggleDynamicTabs: any,
    previousTabIndex: number,
}

const SludgeBilgeWaterComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleAddTab,
    toggleDynamicTabs,
    previousTabIndex,
}: SludgeBilgeWaterComponentType) => {
    /** Queries */
    /** Sludge Tanks */
    const { data: SludgeTanks, isLoading: SludgeTanksLoading, isError: SludgeTanksError } = useQuery(
        [queryKeyes.masters.Sludge.key],
        async () => {
            return await loadSludgeTanks();
        }, { staleTime: Infinity }
    );
    /** Bilge  Tanks */
    const { data: BilgeTanks, isLoading: BilgeTanksLoading, isError: BilgeTanksError } = useQuery(
        [queryKeyes.masters.Bilge.key],
        async () => {
            return await loadBilgeTanks();
        }, { staleTime: Infinity }
    );
    /** Sludge Object used for edit */
    const { data: SludgeObject, isLoading: SludgeObjectLoading, isError: SludgeObjectError } = useQuery(
        [queryKeyes.vessel.LfondsSludgeObject.key, VesselID, ReportID],
        async () => {
            return await loadSludgeObject(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Bilge Object used for edit */
    const { data: BilgeObject, isLoading: BilgeObjectLoading, isError: BilgeObjectError } = useQuery(
        [queryKeyes.vessel.LfondsBilgeObject.key, VesselID, ReportID],
        async () => {
            return await loadBilgeObject(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign initial values to Sludge Details' formik object */
    const getInitialSludgeValues = () => {
        let obj: any[] = [];
        if (SludgeObject && SludgeObject.length > 0) {
            return SludgeObject;
        } else {
            if (SludgeTanks !== undefined) {
                SludgeTanks.map((tank: any) => {
                    obj.push({
                        tankname: tank.tank_name,
                        daily_production: 0,
                        total_quantity: 0,
                        tank_number: tank.id,
                        current_filling_ratio: 0,
                        remark: '',
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                    return tank;
                })
            }
            return obj;
        }
    }
    /** Assign initial values to Bilge Details' formik object */
    const getInitialBilgeValues = () => {
        let obj: any[] = [];
        if (BilgeObject && BilgeObject.length > 0) {
            return BilgeObject;
        } else {
            if (BilgeTanks !== undefined) {
                BilgeTanks.map((tank: any) => {
                    obj.push({
                        tankname: tank.tank_name,
                        daily_production: 0,
                        total_quantity: 0,
                        tank_number: tank.id,
                        current_filling_ratio: 0,
                        remark: '',
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                    return tank
                })
            }
            return obj;
        }
    }

    /** useEffect */
    useEffect(() => {
        SludgeBilgeDetailsFormik.initialValues.sludge = getInitialSludgeValues();
        SludgeBilgeDetailsFormik.initialValues.bilge = getInitialBilgeValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SludgeTanks, BilgeTanks, SludgeObject, BilgeObject])
    /** useEffect end */

    /** Sludge or Bilge's Formik Object */
    const SludgeBilgeDetailsFormik = {
        initialValues: {
            sludge: getInitialSludgeValues(),
            bilge: getInitialBilgeValues(),
        },
        validationSchema: Yup.object().shape({
            sludge: Yup.array(
                Yup.object({
                    daily_production: Yup.string().matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`),
                    total_quantity: Yup.string().matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`),
                    tanks: Yup.array(
                        Yup.object({
                            tank_number: Yup.string().matches(/^([A-Za-z0-9\- ]){1,50}$/, `${SludgeBilgeWaterMessages.tank_number}`)
                        }),
                    ),
                    remark: Yup.string().max(200, `${commonValidationMessages.max200}`)
                }),
            ),
            bilge: Yup.array(
                Yup.object({
                    daily_production: Yup.string().matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`),
                    total_quantity: Yup.string().matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`),
                    tanks: Yup.array(
                        Yup.object({
                            tank_number: Yup.string().matches(/^([A-Za-z0-9\- ]){1,50}$/, `${SludgeBilgeWaterMessages.tank_number}`)
                        }),
                    ),
                    remark: Yup.string().max(200, `${commonValidationMessages.max200}`)
                })
            )
        })
    }
    return (
        <Card className='mb-0 border-0'>
            <CardHeader className='p-2 pb-0'>
                <div className="text-center mb-2">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Sludge & Bilge Water Details</h4>
                            <p className="card-title-desc pos-start mb-0 ">All readings since last noon</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(SludgeTanksLoading || BilgeTanksLoading || SludgeObjectLoading || BilgeObjectLoading) && <Loading message='Loading required data!' />}
            {(SludgeTanksError || BilgeTanksError) && <ErrorComponent message='Unable to load required data!' />}
            {SludgeObjectError && getInitialSludgeValues()}
            {BilgeObjectError && getInitialBilgeValues()}
            {!(SludgeTanksLoading || BilgeTanksLoading || SludgeObjectLoading || BilgeObjectLoading) &&
                !(SludgeTanksError || BilgeTanksError) &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (SludgeObject && SludgeObject.length > 0) {
                            values.sludge.map((sludge: any) => {
                                apiGlobal.put(`/lfonds_sludge_water_details/${sludge.id}/`, sludge).then(res => {
                                    handleResponse(res)
                                    queryClient.invalidateQueries(queryKeyes.vessel.LfondsSludgeObject.key);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/lfonds_sludge_water_details/`, values.sludge).then(res => {
                                handleResponse(res)
                                queryClient.invalidateQueries(queryKeyes.vessel.LfondsSludgeObject.key);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                        if (BilgeObject && BilgeObject.length > 0) {
                            values.bilge.map((bilge: any) => {
                                apiGlobal.put(`/lfonds_bilge_water_details/${bilge.id}/`, bilge).then(res => {
                                    handleResponse(res)
                                    queryClient.invalidateQueries(queryKeyes.vessel.LfondsBilgeObject.key);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/lfonds_bilge_water_details/`, values.bilge).then(res => {
                                handleResponse(res)
                                queryClient.invalidateQueries(queryKeyes.vessel.LfondsBilgeObject.key);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                    }}
                    initialValues={SludgeBilgeDetailsFormik.initialValues}
                    validationSchema={SludgeBilgeDetailsFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <Card className='p-0 mb-0 border-0'>
                                <CardHeader className='p-2 border-bottom-0'>
                                    <h5>Sludge Water</h5>
                                </CardHeader>
                                <CardBody className='px-2 py-0'>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='sr-no-width p-2 align-middle'>#</th>
                                                    <th className='p-2 align-middle'>Tank name/number</th>
                                                    <th style={{ width: '12%' }} className='text-center p-2 align-middle'>Daily production</th>
                                                    <th style={{ width: '12%' }} className='text-center p-2 align-middle'>Total Quantity</th>
                                                    <th style={{ width: '13%' }} className='text-center p-2 align-middle'>Current filling ratio</th>
                                                    <th className='text-center p-2'>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="sludge">
                                                    {() => (
                                                        <>
                                                            {values && values?.sludge?.map((tank: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='align-middle text-center p-2'>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td className='align-middle p-2'>
                                                                            {tank.tankname}
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="sludge_daily_production"
                                                                                    name={`sludge.${index}.daily_production`}
                                                                                    className="form-control text-right max-width-7"
                                                                                /></div>
                                                                            {errors.sludge && errors?.sludge[index]?.daily_production && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`sludge_daily_production`}
                                                                                    message={errors?.sludge[index]?.daily_production}
                                                                                    open={(errors.sludge && errors?.sludge[index]?.daily_production) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="sludge_total_quantity"
                                                                                    name={`sludge.${index}.total_quantity`}
                                                                                    className="form-control text-right max-width-7"
                                                                                /></div>
                                                                            {errors.sludge && errors?.sludge[index]?.total_quantity && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`sludge_total_quantity`}
                                                                                    message={errors?.sludge[index]?.total_quantity}
                                                                                    open={(errors.sludge && errors?.sludge[index]?.total_quantity) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="current_filling_ratio"
                                                                                    name={`sludge.${index}.current_filling_ratio`}
                                                                                    className="form-control text-right max-width-7"
                                                                                /></div>
                                                                        </td>
                                                                        <td className='p-2'>
                                                                            <Field
                                                                                type="text"
                                                                                id="remark"
                                                                                name={`sludge.${index}.remark`}
                                                                                className="form-control"
                                                                            />
                                                                            {errors.sludge && errors?.sludge[index]?.remark && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`remark`}
                                                                                    message={errors?.sludge[index]?.remark}
                                                                                    open={(errors.sludge && errors?.sludge[index]?.remark) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className='p-0 mb-0 border-0'>
                                <CardHeader className='p-2 border-bottom-0'>
                                    <h5 className='m-0'>Bilge Water</h5>
                                </CardHeader>
                                <CardBody className='px-2 py-0'>
                                    <div className="table-responsive">
                                        <table className="table mb-2">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2 sr-no-width align-middle'>#</th>
                                                    <th className='p-2'>Tank name/number</th>
                                                    <th style={{ width: '12%' }} className='text-center align-middle p-2'>Daily production</th>
                                                    <th style={{ width: '12%' }} className='text-center align-middle p-2'>Total Quantity</th>
                                                    <th style={{ width: '13%' }} className='text-center align-middle p-2'>Current filling ratio</th>
                                                    <th className='text-center p-2'>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="bilge">
                                                    {() => (
                                                        <>
                                                            {values && values?.bilge?.map((tank: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='align-middle text-center p-2'>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td className='align-middle p-2'>
                                                                            {tank.tankname}
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="daily_production"
                                                                                    name={`bilge.${index}.daily_production`}
                                                                                    className="form-control text-right max-width-7"
                                                                                />
                                                                            </div>
                                                                            {errors.bilge && errors?.bilge[index]?.daily_production && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`daily_production`}
                                                                                    message={errors?.bilge[index]?.daily_production}
                                                                                    open={(errors.bilge && errors?.bilge[index]?.daily_production) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="total_quantity"
                                                                                    name={`bilge.${index}.total_quantity`}
                                                                                    className="form-control text-right max-width-7"
                                                                                />
                                                                            </div>
                                                                            {errors.bilge && errors?.bilge[index]?.total_quantity && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`total_quantity`}
                                                                                    message={errors?.bilge[index]?.total_quantity}
                                                                                    open={(errors.bilge && errors?.bilge[index]?.total_quantity) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td className='align-middle text-center p-2'>
                                                                            <div className='d-inline-block'>
                                                                                <Field
                                                                                    type="text"
                                                                                    id="current_filling_ratio"
                                                                                    name={`bilge.${index}.current_filling_ratio`}
                                                                                    className="form-control text-right max-width-7"
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td className='p-2'>
                                                                            <Field
                                                                                type="text"
                                                                                id="remark"
                                                                                name={`bilge.${index}.remark`}
                                                                                className="form-control"
                                                                            />
                                                                            {errors.bilge && errors?.bilge[index]?.remark && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`remark`}
                                                                                    message={errors?.bilge[index]?.remark}
                                                                                    open={(errors.bilge && errors?.bilge[index]?.remark) ? true : false}
                                                                                />
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <CardFooter className='px-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                            toggleAddTab(2);
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                            toggleDynamicTabs(previousTabIndex - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, SludgeBilgeDetailsFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default SludgeBilgeWaterComponent