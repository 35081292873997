import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadFuelClass, loadFuelSettings, loadFuelSubTypesByType, loadFuelTypes, loadVesselFuel, loadVesselFuelTypes } from '../../vesselMaster.hooks';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CardBody, Row, Col, Form, CardFooter, Button } from 'reactstrap';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import VesselFuelTable from './VesselFuelTable';
import AsyncSelect from 'react-select/async';
interface FuelConsumptionForm {
    [key: string]: string | number | boolean | Date | null;
}

interface VesselFuelConfigurationType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
}

const VesselFuelConfiguration = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
}: VesselFuelConfigurationType) => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    /** State variables end */
    /** useQueries */
    /** Load distinct fuel types configured on vessel */
    const {
        data: VesselFuelTypes,
        isLoading: VesselFuelTypesLoading,
    } = useQuery(
        [queryKeyes.vessel.vesselFuelType.key, VesselID],
        async () => {
            return await loadVesselFuelTypes(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Load fuel types */
    const {
        data: FuelTypes,
        isLoading: FuelTypesLoading,
        isError: FuelTypesError,
    } = useQuery(
        [queryKeyes.masters.fuelTypeMaster.key],
        async () => {
            return await loadFuelTypes();
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Load Fuel setting */
    const {
        data: FuelSetting,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.FuelSettings.key, VesselID],
        async () => {
            return await loadFuelSettings(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** useQueries end */

    // Filter distinct `fuel_class_precedence_id`
    const distinctFuelClasses = () => {
        let fuelClassIds: any = [];
        VesselFuelTypes && VesselFuelTypes.forEach((fuel: any) => {
            // Check if the fuel_class_precedence_id is already in the fuelClassIds array
            const existing = fuelClassIds.find((item: any) => item.fuel_class_precedence_id === fuel.fuel_class_precedence_id);
            // If not found, push the new fuel class
            if (!existing) {
                fuelClassIds.push({
                    fuel_class_precedence_id: fuel.fuel_class_precedence_id,
                    fuel_class_name: fuel.fuel_class_name
                });
            }
        });
        return fuelClassIds;
    };

    /** Assign initial values to fuel consumption Formik object */
    const getInitialValues = () => {
        let arr: FuelConsumptionForm[] = [];
        if (VesselFuelTypes && VesselFuelTypes?.length > 0) {
            if (arr?.length < FuelTypes?.length) {
                arr = VesselFuelTypes?.map((fuelType: FuelConsumptionForm) => ({
                    ...fuelType,
                    selected: true
                }))
                FuelTypes?.filter((item: FuelConsumptionForm) =>
                    !VesselFuelTypes.some((fuel: FuelConsumptionForm) => item?.id === fuel?.fuel_type)
                )?.forEach((fuelType: FuelConsumptionForm) => {
                    arr?.push({
                        fuel_name: '',
                        fuel_type_name: fuelType?.fuel_type_name,
                        fuel_type: fuelType?.id,
                        fuel_sub_type: 0,
                        selected: false,
                        vessel: VesselID,
                        fuel_class_precedence_id: fuelType.fuel_class_precedence_id
                    })
                })
            }
        } else {
            FuelTypes?.forEach((fuelType: FuelConsumptionForm) => {
                arr.push({
                    fuel_name: '',
                    fuel_type_name: fuelType?.fuel_type_name,
                    fuel_type: fuelType?.id,
                    fuel_sub_type: 0,
                    selected: false,
                    vessel: VesselID,
                    fuel_class_precedence_id: fuelType.fuel_class_precedence_id
                })
            })
        }
        return arr;
    }

    /** useEffect */
    useEffect(() => {
        VesselFuelFormik.initialValues.vesselFuels = getInitialValues()
        setRefreshVesselMachineries(refreshVesselMachineries + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselFuelTypes, FuelTypes, VesselID])
    /** useEffect end */

    /** Vessel fuel configuration's formik object */
    const VesselFuelFormik = {
        initialValues:
        {
            fuel_class: distinctFuelClasses() as any,
            vesselFuels: getInitialValues()
        }
    }

    return (
        <Formik
            onSubmit={
                (values: { [key: string]: FuelConsumptionForm[] },
                    actions: { setSubmitting: (arg: boolean) => void }) => {
                    actions?.setSubmitting(true);
                    let responseArray: any[] = [];
                    values?.vesselFuels?.forEach(async (value: FuelConsumptionForm) => {
                        if (value?.id as number > 0) {
                            if (value?.selected === false) {
                                const fuels = await loadVesselFuel(VesselID);
                                fuels?.filter((fuel: FuelConsumptionForm) =>
                                    fuel?.fuel_sub_type === value?.fuel_sub_type)?.forEach(
                                        (subType: FuelConsumptionForm) => {
                                            responseArray.push(apiGlobal.delete(`/vessel_fuel_information/${subType?.id as number}/`));
                                        })
                                FuelSetting && FuelSetting?.filter((setting: any) =>
                                    setting.fuel_name === value?.fuel_type)?.forEach((fuel: FuelConsumptionForm) => {
                                        responseArray.push(apiGlobal.delete(`/vessel_fuel_setting/${fuel.id}/`));
                                    })
                            }
                        } else {
                            if (value?.selected === true) {
                                const fuelSubTypes = await loadFuelSubTypesByType(value?.fuel_type as number);
                                fuelSubTypes.forEach((subType: FuelConsumptionForm) => {
                                    value.fuel_sub_type = subType.id;
                                    responseArray.push(apiGlobal.post(`/vessel_fuel_information/`, value));
                                })
                            }
                        }
                        /** handle server response */
                        await handleServerResponse(responseArray).then(async (res) => {
                            if (res) {
                                await queryClient.invalidateQueries(queryKeyes.vessel.fuel.key);
                                setRefreshVesselMachineries(refreshVesselMachineries + 1);
                            }
                        });
                    })
                }}
            initialValues={VesselFuelFormik.initialValues}
            key={refreshVesselMachineries}
        >
            {(props) => (
                <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                    <CardBody className="p-0">
                        {(FuelTypesLoading || VesselFuelTypesLoading) && (
                            <Loading message="Loading required data!" />
                        )}
                        {(FuelTypesError) && (
                            <ErrorComponent message="Error loading required data!" />
                        )}
                        {!(FuelTypesLoading || VesselFuelTypesLoading) &&
                            !(FuelTypesError) &&
                            // add multiselect dropdown
                            <React.Fragment>
                                <AsyncSelect
                                    isMulti
                                    name="fuel_class"
                                    className='max-width-15 mb-2'
                                    cacheOptions
                                    loadOptions={() => loadFuelClass()} // Pass the async function here
                                    defaultOptions // Automatically load options before typing
                                    getOptionLabel={(e: any) => e.name}
                                    getOptionValue={(e: any) => e.precedence_id}
                                    defaultValue={props.values.fuel_class.map((fuelClass: any) => ({
                                        precedence_id: fuelClass.fuel_class_precedence_id,
                                        name: fuelClass.fuel_class_name
                                    }))}
                                    onChange={(selectedOptions, actionMeta) => {
                                        // Map the selected options and return an array of precedence_ids
                                        props.setFieldValue(
                                            'fuel_class',
                                            selectedOptions.map(option => ({
                                                fuel_class_precedence_id: option.precedence_id,
                                                fuel_class_name: option.name
                                            }))
                                        );
                                        if (actionMeta.action === "remove-value") {
                                            // Find the removed option's precedence_id
                                            const removedPrecedenceId = actionMeta.removedValue.precedence_id;
                                            // Loop through vesselFuels and set selected to false for the corresponding fuel
                                            const updatedVesselFuels = props.values.vesselFuels.map((fuel: any) => {
                                                if (fuel.fuel_class_precedence_id === removedPrecedenceId && fuel.selected === true) {
                                                    // Set selected to false for the matching fuel
                                                    fuel.selected = false;
                                                }
                                                return fuel;
                                            });
                                            // Update the vesselFuels with the new selected state
                                            props.setFieldValue('vesselFuels', updatedVesselFuels);
                                            setRefreshKey(refreshKey + 1)
                                        }
                                    }}
                                    isClearable={false} // Disables the clear all behavior
                                    placeholder="Select Fuel Class"
                                />
                                <Row>
                                    {props.values?.fuel_class?.length > 0 ? (
                                        props.values.fuel_class.map((item: any, index: number) => (
                                            <Col md={3} className="px-0" key={index}>
                                                <VesselFuelTable
                                                    fuel_class_precedence_id={item.fuel_class_precedence_id}
                                                    fuel_class_name={item.fuel_class_name}
                                                    refreshKey={refreshKey}
                                                />
                                            </Col>
                                        ))
                                    ) : (
                                        <p>Select a fuel class to display respective fuel types</p>
                                    )}
                                </Row>
                            </React.Fragment>
                        }

                    </CardBody>
                    <CardFooter className='p-2 py-3 mb-3'>
                        <Row>
                            <Col className='pb-3'>
                                <Button type="submit" color='primary' className='pos-end' disabled={isConfigurationButtonDisabled(vessel)}
                                >Save</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                    <FormValuesDebug values={[props.values, props.errors, VesselFuelFormik.initialValues]} />
                </Form>
            )}
        </Formik>
    )
}

export default VesselFuelConfiguration