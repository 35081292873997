import React, { useEffect, useState } from 'react'
import { CardBody, Card, Col, Row, Input, Label, Button, Form, CardHeader, CardFooter } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { fetchMachinaryOptions, loadAuxillaryEngineSetting, loadEngineTypes, loadMainEngineSettings, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import * as Yup from "yup";
import { FuelTypes, VesselMachineryConstant } from 'shared/constants';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import { queryClient } from 'react-query/queryClient';
import Select from 'react-select';
import { customStyle } from 'shared/CommonCSS';

interface EnginesType {
  refreshVesselMachineries: number;
  setRefreshVesselMachineries: (value: number) => void;
  VesselConfActiveTab: number;
}

const Engines = ({
  refreshVesselMachineries,
  setRefreshVesselMachineries,
}: EnginesType) => {
  /** State variables start */
  const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [vesselFuelIds, setVesselFuelIds] = useState<Array<number>>([]);
  const [mainEngineSpinner, setMainEngineSpinner] = useState<boolean>(false);
  const [auxEngineSpinner, setAuxEngineSpinner] = useState<boolean>(false);
  const [spinnerIndex, setSpinnerIndex] = useState<number | null>();
  const [formRefreshKey, setFormRefreshKey] = useState<number>(0);
  /** State variables end */

  /** Queries */
  /** Load main engine setting */
  const {
    data: MainEngine,
    isLoading: MainEngineLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MainEngineSetting.key, VesselID],
    async () => {
      return await loadMainEngineSettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load machinery data*/
  const {
    data: VesselMachinery,
    isLoading: VesselMachineryLoading,
    isError: VesselMachineryError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.VesselMachineries.key, VesselID],
    async () => {
      return await fetchMachinaryOptions(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** load auxillary engine data*/
  const {
    data: AuxillaryEngine,
    isLoading: AuxillaryEngineLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.AuxillaryMachineSetting.key, VesselID],
    async () => {
      return await loadAuxillaryEngineSetting(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load distinct fuel types configured on vessel */
  const {
    data: VesselFuelTypes,
    isLoading: VesselFuelTypesLoading,
  } = useQuery(
    [queryKeyes.vessel.vesselFuelType.key, VesselID],
    async () => {
      return await loadVesselFuelTypes(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load engine type master for 2 stroke engines */
  const {
    data: EngineTypeMaster2Stroke,
    isLoading: EngineTypeMaster2StrokeLoading,
    isError: EngineTypeMaster2StrokeError,
  } = useQuery(
    [queryKeyes.masters.EngineTypeMaster.key, VesselID, vesselFuelIds],
    async () => {
      return await loadEngineTypes(VesselID, vesselFuelIds, "2_stroke");
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );  /** Load engine type master for 4 stroke engines */
  const {
    data: EngineTypeMaster4Stroke,
    isLoading: EngineTypeMaster4StrokeLoading,
    isError: EngineTypeMaster4StrokeError,
  } = useQuery(
    [queryKeyes.masters.EngineTypeMaster.key, VesselID, vesselFuelIds],
    async () => {
      return await loadEngineTypes(VesselID, vesselFuelIds, "4_stroke");
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Queries end */

  /**Initial values start */
  /** get specific mahinaries from vessel_machinery_list */
  const VesselMachineryList = (engine_type: any) => {
    const machinary: number[] = [];
    VesselMachinery?.forEach((machineObj: any) => {
      if (machineObj.precedence_id === engine_type) {
        machinary.push(machineObj);
      }
    });

    return machinary;
  };
  /** Assign initial values to Engines formik object */
  const getMainEngineInitialValues = () => {
    let mainEngineArr: any[] = [];
    let mainEngineIds: Set<number> = new Set(); // Use a Set to avoid duplicates
    const EngineListArr = VesselMachineryList(
      VesselMachineryConstant.MAIN_ENGINE
    );
    if (MainEngine?.length > 0) {
      EngineListArr.forEach((MachinaryObj: any) => {
        // Find the matching engine in MainEngine
        const matchingEngine = MainEngine.find(
          (engineObj) => engineObj.machinery_name === MachinaryObj?.id
        );
        if (matchingEngine && !mainEngineIds.has(matchingEngine.machinery_name)) {
          // Add the matching engine
          mainEngineArr.push(matchingEngine);
          mainEngineIds.add(matchingEngine.machinery_name);
        } else if (!mainEngineIds.has(MachinaryObj.id)) {
          // Add a default object if no matching engine is found
          mainEngineArr.push({
            machinery: MachinaryObj.vessel_machinery_name,
            engine_type: "2_stroke",
            propeller_type: "controllable_pitch_propeller",
            engine_type_master: null,
            engine_type_name: null,
            mcr_kw: null,
            mcr_rpm: null,
            shaft_power: 0,
            propeller_pitch: null,
            eedi: 0,
            eexi: 0,
            vessel: VesselID,
            machinery_name: MachinaryObj.id,
            precedence_id: MachinaryObj.precedence_id,
          });
          mainEngineIds.add(MachinaryObj.id);
        }
      });
    } else {
      // Add default objects for all machinery if MainEngine is empty
      EngineListArr.forEach((MachinaryObj: any) => {
        mainEngineArr.push({
          machinery: MachinaryObj.vessel_machinery_name,
          engine_type: "2_stroke",
          propeller_type: "controllable_pitch_propeller",
          engine_type_master: null,
          engine_type_name: null,
          mcr_kw: null,
          mcr_rpm: null,
          shaft_power: 0,
          propeller_pitch: null,
          eedi: 0,
          eexi: 0,
          vessel: VesselID,
          machinery_name: MachinaryObj.id,
          precedence_id: MachinaryObj.precedence_id,
        });
      });
    }
    return mainEngineArr;
  };  

  const getAuxillaryEngineInitialValues = () => {
    let AuxillaryEngineArr: any[] = [];
    let auxIds: Set<number> = new Set(); // Use a Set to avoid duplicates
    const VesselMachineryListArr = VesselMachineryList(
      VesselMachineryConstant.AUXILIARY_ENGINE
    );
    if (AuxillaryEngine?.length > 0 && AuxillaryEngine[0]?.id > 0) {
      VesselMachineryListArr.forEach((MachinaryObj: any) => {
        // Find the matching engine in AuxillaryEngine
        const matchingEngine = AuxillaryEngine.find(
          (engineObj) => engineObj.vessel_machinery_name === MachinaryObj?.id
        );
        if (matchingEngine && !auxIds.has(matchingEngine.vessel_machinery_name)) {
          // Add the matching engine
          AuxillaryEngineArr.push(matchingEngine);
          auxIds.add(matchingEngine.vessel_machinery_name);
        } else if (!auxIds.has(MachinaryObj.id)) {
          // Add a default object if no matching engine is found
          AuxillaryEngineArr.push({
            vessel_machinery: MachinaryObj.vessel_machinery_name,
            power_output_rating: 0,
            eedi: 0,
            rated_voltage: 0,
            rated_frequency: 0,
            rated_rpm: 0,
            alternator_power_rating: 0,
            vessel: VesselID,
            vessel_machinery_name: MachinaryObj.id,
            engine_type_name: null,
            engine_type: null,
          });
          auxIds.add(MachinaryObj.id);
        }
      });
    } else {
      // Add default objects for all machinery if AuxillaryEngine is empty
      VesselMachineryListArr.forEach((MachinaryObj: any) => {
        AuxillaryEngineArr.push({
          vessel_machinery: MachinaryObj.vessel_machinery_name,
          power_output_rating: 0,
          eedi: 0,
          rated_voltage: 0,
          rated_frequency: 0,
          rated_rpm: 0,
          alternator_power_rating: 0,
          vessel: VesselID,
          vessel_machinery_name: MachinaryObj.id,
          engine_type_name: null,
          engine_type: null,
        });
      });
    }
    return AuxillaryEngineArr;
  };
  /**Inital values end */

  /** useEffect */
  useEffect(() => {
    EngineFormik.initialValues.mainEngine =
      getMainEngineInitialValues();
    EngineFormik.initialValues.auxillaryEngine =
      getAuxillaryEngineInitialValues();
    setFormRefreshKey(formRefreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuxillaryEngine, VesselMachinery]);
  useEffect(() => {
    let arr: any[] = [];
    if (VesselFuelTypes && VesselFuelTypes?.length > 0) {
      VesselFuelTypes?.forEach((fuelType: { [key: string]: string | number | Date | boolean }) => {
        arr.push(fuelType?.precedence_id);
      })
      setVesselFuelIds(arr);
    }
  }, [VesselFuelTypes, VesselID]);
  /** useEffect end */

  // General Settings Formik Object
  const EngineFormik = {
    initialValues: {
      mainEngine: getMainEngineInitialValues(),
      auxillaryEngine: getAuxillaryEngineInitialValues(),
    },
    validationSchema: Yup.object().shape({
      mainEngine: Yup.array(
        Yup.object().shape({
          engine_type: Yup.string().oneOf([
            "2_stroke",
            "4_stroke",
            "steam_turbine_type",
          ]),
          propeller_type: Yup.string().oneOf([
            "controllable_pitch_propeller",
            "fixed_pitch_propeller",
          ]),
          shaft_power: Yup.number()
            .min(0, "Shaft power must be a positive number")
            .nullable(),
          propeller_pitch: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{1,10})?$/,
              commonValidationMessages.before5after10
            )
            .when("propeller_type", {
              is: "fixed_pitch_propeller",
              then: (schema: any) =>
                schema.required(commonValidationMessages.required),
              otherwise: (schema: any) => schema.nullable(),
            }),
          eedi: Yup.string()
            .matches(
              /^\d{1,4}(\.\d{0,4})?$/,
              commonValidationMessages.before4after4
            )
            .nullable(),
          eexi: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .nullable(),
        })
      ),
      auxillaryEngine: Yup.array(
        Yup.object().shape({
          power_output_rating: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .required(commonValidationMessages.required),
          rated_voltage: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .nullable(),
          rated_frequency: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .nullable(),
          rated_rpm: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .nullable(),
          alternator_power_rating: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,4})?$/,
              commonValidationMessages.before5after4
            )
            .nullable(),
          engine_type: Yup.number()
            .when(
              "$fieldVisibility",
              (fieldVisibility: any, schema) => {
                return (!vesselFuelIds?.includes(FuelTypes.LNG_BUNKER) && !vesselFuelIds?.includes(FuelTypes.LNG_CARGO))
                  ? schema.nullable()
                  : schema.required(commonValidationMessages.required);
              }
            ),
        })
      ),
    }),
  };
  const handleEngineSubmit = async (values: any, url: any, engineType: any, errors: any) => {
    let responseArray: any[] = [];
    let data: any;
    if (!errors) {
      if (values?.id) {
        data = apiGlobal.put(`/${url}/${values.id}/`, values)
        responseArray.push(data);
      } else {
        data = apiGlobal.post(`/${url}/`, values)
        responseArray.push(data);
      }
      /** handle server response */
      await handleServerResponse(responseArray).then(async (res) => {
        if (res) {
          if (engineType === "AuxillaryEngine") {
            await queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
          } else {
            await queryClient.invalidateQueries(queryKeyes.vessel.MainEngineSetting.key);
          }
          setRefreshVesselMachineries(refreshVesselMachineries + 1);
        } else {
          setMainEngineSpinner(false)
          setAuxEngineSpinner(false)
        }
      });
    }
    if (await data) {
      return await data;
    }
    setMainEngineSpinner(false)
  };

  return (
    <React.Fragment>
      {(AuxillaryEngineLoading ||
        VesselMachineryLoading ||
        MainEngineLoading ||
        VesselFuelTypesLoading ||
        EngineTypeMaster2StrokeLoading ||
        EngineTypeMaster4StrokeLoading) && <Loading message="Loading required data!" />}
      {(VesselMachineryError || EngineTypeMaster2StrokeError || EngineTypeMaster4StrokeError) && (
        <ErrorComponent message="Error loading required data!" />
      )}
      {!(
        AuxillaryEngineLoading ||
        VesselMachineryLoading ||
        MainEngineLoading ||
        VesselFuelTypesLoading ||
        EngineTypeMaster2StrokeLoading ||
        EngineTypeMaster4StrokeLoading
      ) &&
        !(VesselMachineryError || EngineTypeMaster2StrokeError || EngineTypeMaster4StrokeError) && (
          <Formik
            onSubmit={(_values: any, actions: any) => {
              actions.setSubmitting(false);
            }}
            initialValues={EngineFormik.initialValues}
            validationSchema={EngineFormik.validationSchema}
            key={formRefreshKey}
          >
            {(props: any) => {
              return (
                <Form
                  onSubmit={props.handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <FieldArray name="Engine">
                    {() => (
                      <React.Fragment>
                        <Card className='p-0'>
                          <CardHeader className="p-2">
                            <h4 className="mb-0">Main Engines</h4>
                          </CardHeader>
                          {/* Handle Main engine form */}
                          {props?.values?.mainEngine && props?.values?.mainEngine.length > 0 ? (
                            props.values.mainEngine.map((engine: any, index: number) => (<React.Fragment>
                              <Card className='mb-0' key={refreshVesselMachineries}>
                                <CardHeader className="p-2">
                                  <h5 className="mb-0">
                                    {engine.machinery}
                                  </h5>
                                </CardHeader>
                                <CardBody className="p-2">
                                  {props?.values?.mainEngine[index]
                                    ?.precedence_id !==
                                    VesselMachineryConstant.HPPED && (
                                      <Row>
                                        <Col sm={5}>
                                          <Label className="bold_lbl asteric">
                                            Engine Type
                                          </Label>
                                          <div className="ele_row1">
                                            <div className="form-check">
                                              <Field
                                                name={`mainEngine.${index}.engine_type`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={field.name}
                                                    id={`stroke2_${index}`}
                                                    value="2_stroke"
                                                    checked={
                                                      field.value ===
                                                      "2_stroke"
                                                    }
                                                    onChange={(e: any) => {
                                                      field.onChange(e);
                                                      props?.handleChange(e);
                                                      setRefreshKey(refreshKey + 1);
                                                    }}
                                                    defaultValue={
                                                      props.values
                                                        ?.mainEngine
                                                        .engine_type
                                                    }
                                                  />
                                                )}
                                              </Field>
                                              <Label
                                                className="form-check-label mr-1"
                                                htmlFor={`stroke2_${index}`}
                                              >
                                                2 stroke
                                              </Label>

                                            </div>
                                            <div className="form-check">
                                              <Field
                                                name={`mainEngine.${index}.engine_type`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={field.name}
                                                    id={`stroke4_${index}`}
                                                    value="4_stroke"
                                                    checked={
                                                      field.value ===
                                                      "4_stroke"
                                                    }
                                                    onChange={(e: any) => {
                                                      field.onChange(e);
                                                      props?.handleChange(e);
                                                      setRefreshKey(refreshKey + 1);
                                                    }}
                                                    defaultValue={
                                                      props.values
                                                        ?.mainEngine
                                                        .engine_type
                                                    }
                                                  />
                                                )}
                                              </Field>
                                              <Label
                                                htmlFor={`stroke4_${index}`}
                                              >
                                                4 stroke
                                              </Label>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col sm={3}>
                                          <Label className="mb-0">
                                            MCR
                                          </Label>
                                          <div className="ele_row1">
                                            <div className="input-group">
                                              <Field
                                                type="text"
                                                name={`mainEngine.${index}.mcr_kw`}
                                                id={`mainEngine.${index}.mcr_kw`}
                                                className="form-control text-right"
                                              />
                                              <div className="input-group-text small-unit-size">
                                                kW
                                              </div>
                                            </div>
                                            <p className="mb-0 mx-2 mt-2">@</p>
                                            <div className="input-group">
                                              <Field
                                                type="text"
                                                name={`mainEngine.${index}.mcr_rpm`}
                                                id={`mainEngine.${index}.mcr_rpm`}
                                                className="form-control text-right"
                                              />
                                              <div className="input-group-text small-unit-size">
                                                rpm
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col sm={4}>
                                          <div className="ele_row1">
                                            <div>
                                              <Label className="mb-0">
                                                EEDI
                                              </Label>
                                              <div className="input-group mb-3">
                                                <Field
                                                  name={`mainEngine.${index}.eedi`}
                                                >
                                                  {({
                                                    field,
                                                  }: FieldProps) => (
                                                    <Input
                                                      type="text"
                                                      className="form-control max-width-7 text-right"
                                                      {...field}
                                                    />
                                                  )}
                                                </Field>
                                                <div className="input-group-text round_border small-unit-size">
                                                  gCO<sub>2</sub>/ton-mile
                                                </div>
                                              </div>
                                            </div>
                                            <div className="ms-3">
                                              <Label className="mb-0">
                                                EEXI
                                              </Label>
                                              <div className="input-group mb-3">
                                                <Field
                                                  name={`mainEngine.${index}.eexi`}
                                                >
                                                  {({
                                                    field,
                                                  }: FieldProps) => (
                                                    <Input
                                                      type="text"
                                                      className="form-control max-width-7 text-right"
                                                      {...field}
                                                    />
                                                  )}
                                                </Field>
                                                <div className="input-group-text round_border small-unit-size">
                                                  gCO<sub>2</sub>/ton-mile
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    )}
                                  <Row>
                                    {props?.values?.mainEngine[index]
                                      ?.precedence_id !==
                                      VesselMachineryConstant.HPPED && (
                                        <Col sm={5} className="">
                                          <div className="ele_row1">
                                            <div>
                                              <Label className="bold_lbl mb-2 asteric">
                                                Propeller Type
                                              </Label>
                                              <div className="form-check">
                                                <Field
                                                  name={`mainEngine.${index}.propeller_type`}
                                                >
                                                  {({
                                                    field,
                                                    form,
                                                  }: FieldProps) => (
                                                    <Input
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={`ccpp_${index}`}
                                                      value={
                                                        "controllable_pitch_propeller"
                                                      }
                                                      checked={
                                                        props?.values
                                                          ?.mainEngine[
                                                          index
                                                        ]
                                                          ?.propeller_type ===
                                                        "controllable_pitch_propeller"
                                                      }
                                                      onChange={(e: any) =>
                                                        form.setFieldValue(
                                                          field.name,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Field>
                                                <Label
                                                  className="form-check-label mr-1"
                                                  htmlFor={`ccpp_${index}`}
                                                >
                                                  Controllable pitch
                                                  propeller
                                                </Label>
                                              </div>
                                            </div>
                                            <div className="form-check d-flex align-items-end">
                                              <Field
                                                name={`mainEngine.${index}.propeller_type`}
                                              >
                                                {({
                                                  field,
                                                  form,
                                                }: FieldProps) => (
                                                  <Input
                                                    className="form-check-input mb-1"
                                                    type="radio"
                                                    id={`fpp_${index}`}
                                                    value={
                                                      "fixed_pitch_propeller"
                                                    }
                                                    checked={
                                                      props?.values
                                                        ?.mainEngine[index]
                                                        ?.propeller_type ===
                                                      "fixed_pitch_propeller"
                                                    }
                                                    onChange={(e: any) =>
                                                      form.setFieldValue(
                                                        field.name,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                              </Field>
                                              <Label
                                                className="form-check-label ms-2"
                                                htmlFor={`fpp_${index}`}
                                              >
                                                Fixed pitch propeller
                                              </Label>
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                    <Col sm={7} className="">
                                      <div className="ele_row1">
                                        {props?.values?.mainEngine[index]
                                          ?.precedence_id !==
                                          VesselMachineryConstant.HPPED && (
                                            <React.Fragment>
                                              <div>
                                                {props?.values?.mainEngine[
                                                  index
                                                ]?.propeller_type ===
                                                  "fixed_pitch_propeller" && (
                                                    <React.Fragment>
                                                      <Label className={`mb-0 
                                                    ${props?.values?.mainEngine[index]?.propeller_type === "fixed_pitch_propeller" && 'asteric'}
                                                    `}>
                                                        Pitch
                                                      </Label>
                                                      <div className="input-group mb-3">
                                                        <Field
                                                          name={`mainEngine.${index}.propeller_pitch`}
                                                        >
                                                          {({
                                                            field,
                                                          }: FieldProps) => (
                                                            <Input
                                                              type="text"
                                                              className="form-control max-width-7 text-right"
                                                              {...field}
                                                            />
                                                          )}
                                                        </Field>
                                                        <div className="input-group-text round_border">
                                                          m
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                              </div>
                                              <div className={`
                                            ${props?.values?.mainEngine[index]?.propeller_type === "fixed_pitch_propeller" && 'ms-3'}
                                            `}>
                                                <Label className="mb-0">
                                                  Shaft Power
                                                </Label>
                                                <div className="input-group mb-3">
                                                  <Field
                                                    name={`mainEngine.${index}.shaft_power`}
                                                  >
                                                    {({
                                                      field,
                                                    }: FieldProps) => (
                                                      <Input
                                                        type="text"
                                                        className="form-control max-width-7 text-right"
                                                        {...field}
                                                      />
                                                    )}
                                                  </Field>
                                                  <div className="input-group-text round_border">
                                                    kW
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        <div className="ms-3">
                                          <Label
                                            className="mb-0"
                                            for={`engine_type_master_${index}`}
                                          >
                                            Engine Category
                                          </Label>
                                          <Field
                                            name={`mainEngine.${index}.engine_type_master`}
                                          >
                                            {({
                                              field,
                                              form,
                                            }: FieldProps) => (
                                              <Select
                                                key={refreshKey}
                                                name={field.name}
                                                inputId={`engine_type_master_${index}`}
                                                options={
                                                  props?.values && props?.values?.mainEngine[index]?.engine_type === '2_stroke' ?
                                                    EngineTypeMaster2Stroke :
                                                    EngineTypeMaster4Stroke
                                                }
                                                getOptionLabel={(
                                                  e: any
                                                ) => e.name}
                                                getOptionValue={(e: any) =>
                                                  e.id
                                                }
                                                onChange={(e: any) => {
                                                  form.setFieldValue(
                                                    field.name,
                                                    e?.id
                                                  );
                                                  form.setFieldValue(
                                                    `mainEngine.${index}.engine_type_name`,
                                                    e?.name
                                                  );
                                                }}
                                                defaultValue={
                                                  MainEngine &&
                                                  MainEngine?.length > 0 && {
                                                    id: props?.values
                                                      ?.mainEngine[index]
                                                      ?.engine_type_master,
                                                    name: props?.values
                                                      ?.mainEngine[index]
                                                      ?.engine_type_name,
                                                  }
                                                }
                                                styles={customStyle}
                                                className="select-height min-width-9-5"
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter className="p-2">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="justify_right "
                                    onClick={async () => {
                                      setSpinnerIndex(index)
                                      setMainEngineSpinner(true);
                                      const error = props?.errors?.mainEngine?.[index];
                                      const res: any = await handleEngineSubmit(
                                        props?.values?.mainEngine[index],
                                        "main_engine_setting",
                                        "MainEngine",
                                        error
                                      );
                                      if (res && res?.data) {
                                        // Update the specific index in auxillaryEngine array
                                        const updatedMainEngine = [...props.values.mainEngine]; // Create a copy to preserve immutability
                                        updatedMainEngine[index] = res.data; // Update the specific index with the response
                                        // Use setFieldValue to update Formik state
                                        props.setFieldValue('MainEngine', updatedMainEngine);
                                      }
                                      setMainEngineSpinner(false);
                                    }}
                                    disabled={isConfigurationButtonDisabled(vessel) || (index === spinnerIndex && mainEngineSpinner)}
                                  >
                                    {mainEngineSpinner &&
                                      index === spinnerIndex &&
                                      <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />} Save
                                  </Button>
                                </CardFooter>
                              </Card>
                            </React.Fragment>
                            ))) : (
                            <p>No Main Engine data available. Please add main engine details.</p>
                          )}
                        </Card>
                        <Card>
                          <CardHeader className="p-2">
                            <h4 className="mb-0">Auxiliary Engine</h4>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              {props?.values?.auxillaryEngine && props?.values?.auxillaryEngine.length > 0 ? (
                                props.values.auxillaryEngine.map((auxEngine: any, index: number) => (
                                  <Col md="6" key={index}>
                                    <Card key={refreshVesselMachineries}>
                                      <CardHeader className="p-2">
                                        <h5 className="mb-0">
                                          {auxEngine.vessel_machinery}
                                        </h5>
                                      </CardHeader>
                                      <CardBody className="p-2">
                                        <Row>
                                          <Col>
                                            {/* Power output rating */}
                                            <Label className="asteric mb-0">
                                              Power output rating
                                            </Label>
                                            <div className="input-group mb-3">
                                              <Field
                                                name={`auxillaryEngine.${index}.power_output_rating`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    type="text"
                                                    id={`power_output_rating_${index}`}
                                                    className="form-control max-width-7 text-right"
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.power_output_rating &&
                                                env?.form_validation ===
                                                true &&
                                                props?.touched?.auxillaryEngine &&
                                                props?.touched
                                                  ?.auxillaryEngine[index]
                                                  ?.power_output_rating &&
                                                (
                                                  <ErrorTooltip
                                                    target={`power_output_rating_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]?.power_output_rating
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]?.power_output_rating
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                              <div className="input-group-text round_border">
                                                kW
                                              </div>
                                            </div>
                                            {/* Rated frequency */}
                                            <Label className="mb-0">
                                              Rated frequency
                                            </Label>
                                            <div className="input-group mb-3">
                                              <Field
                                                name={`auxillaryEngine.${index}.rated_frequency`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    type="text"
                                                    id={`rated_frequency_${index}`}
                                                    className="form-control max-width-7 text-right"
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_frequency &&
                                                env?.form_validation ===
                                                true &&
                                                props?.touched
                                                  ?.auxillaryEngine &&
                                                props?.touched
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_frequency && (
                                                  <ErrorTooltip
                                                    target={`rated_frequency_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]?.rated_frequency
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]?.rated_frequency
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                              <div className="input-group-text round_border">
                                                Hz
                                              </div>
                                            </div>
                                            {/* Alternator power rating */}
                                            <Label className="mb-0">
                                              Alternator power rating
                                            </Label>
                                            <div className="input-group mb-3">
                                              <Field
                                                name={`auxillaryEngine.${index}.alternator_power_rating`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    type="text"
                                                    id={`alternator_power_rating_${index}`}
                                                    className="form-control max-width-7 text-right"
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.alternator_power_rating &&
                                                env?.form_validation ===
                                                true &&
                                                props?.touched
                                                  ?.auxillaryEngine &&
                                                props?.touched
                                                  ?.auxillaryEngine[index]
                                                  ?.alternator_power_rating &&
                                                (
                                                  <ErrorTooltip
                                                    target={`alternator_power_rating_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]
                                                        ?.alternator_power_rating
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]
                                                          ?.alternator_power_rating
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                              <div className="input-group-text round_border">
                                                kVA
                                              </div>
                                            </div>
                                          </Col>
                                          <Col>
                                            {/* Rated voltage */}
                                            <Label className="mb-0">
                                              Rated voltage
                                            </Label>
                                            <div className="input-group mb-3">
                                              <Field
                                                name={`auxillaryEngine.${index}.rated_voltage`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    type="text"
                                                    id={`rated_voltage_${index}`}
                                                    className="form-control max-width-7 text-right"
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_voltage &&
                                                env?.form_validation ===
                                                true &&
                                                props?.touched
                                                  ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_voltage &&
                                                (
                                                  <ErrorTooltip
                                                    target={`rated_voltage_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]?.rated_voltage
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]?.rated_voltage
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                              <div className="input-group-text round_border">
                                                V
                                              </div>
                                            </div>
                                            {/* Rated RPM */}
                                            <Label className="mb-0">
                                              Rated RPM
                                            </Label>
                                            <div className="input-group mb-3">
                                              <Field
                                                name={`auxillaryEngine.${index}.rated_rpm`}
                                              >
                                                {({
                                                  field,
                                                }: FieldProps) => (
                                                  <Input
                                                    type="text"
                                                    id={`rated_rpm_${index}`}
                                                    className="form-control max-width-7 text-right"
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_rpm &&
                                                env?.form_validation ===
                                                true && props?.touched
                                                  ?.auxillaryEngine &&
                                                props?.touched
                                                  ?.auxillaryEngine[index]
                                                  ?.rated_rpm && (
                                                  <ErrorTooltip
                                                    target={`rated_rpm_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]?.rated_rpm
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]?.rated_rpm
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                            </div>
                                            {/* Engine Category */}
                                            <React.Fragment>
                                              <Label
                                                className={`mb-0
                                                  ${(vesselFuelIds?.includes(FuelTypes.LNG_BUNKER) || vesselFuelIds?.includes(FuelTypes.LNG_BUNKER)) && 'asteric'}
                                                  `}
                                                for={`engine_type_${index}`}
                                              >
                                                Engine Category
                                              </Label>
                                              <Field
                                                name={`auxillaryEngine.${index}.engine_type`}
                                              >
                                                {({
                                                  field,
                                                  form,
                                                }: FieldProps) => (
                                                  <Select
                                                    name={field.name}
                                                    inputId={`engine_type_${index}`}
                                                    options={
                                                      props?.values && props?.values?.mainEngine[index]?.engine_type === '2_stroke' ?
                                                        EngineTypeMaster2Stroke :
                                                        EngineTypeMaster4Stroke
                                                    }
                                                    getOptionLabel={(
                                                      e: any
                                                    ) => e.name}
                                                    getOptionValue={(e: any) =>
                                                      e.id
                                                    }
                                                    onChange={(e: any) => {
                                                      form.setFieldValue(
                                                        field.name,
                                                        e?.id
                                                      );
                                                      form.setFieldValue(
                                                        `auxillaryEngine.${index}.engine_type_name`,
                                                        e?.name
                                                      );
                                                    }}
                                                    defaultValue={
                                                      AuxillaryEngine &&
                                                      AuxillaryEngine?.length >
                                                      0 && {
                                                        id: props?.values
                                                          ?.auxillaryEngine[index]
                                                          ?.engine_type,
                                                        name: props?.values
                                                          ?.auxillaryEngine[index]
                                                          ?.engine_type_name,
                                                      }
                                                    }
                                                    styles={customStyle}
                                                    className="select-height max-width-13"
                                                  />
                                                )}
                                              </Field>
                                              {props?.errors
                                                ?.auxillaryEngine &&
                                                props?.errors
                                                  ?.auxillaryEngine[index]
                                                  ?.engine_type &&
                                                env?.form_validation ===
                                                true &&
                                                props?.touched
                                                  ?.auxillaryEngine &&
                                                props?.touched
                                                  ?.auxillaryEngine[index]
                                                  ?.engine_type && (
                                                  <ErrorTooltip
                                                    target={`engine_type_${index}`}
                                                    message={
                                                      props?.errors
                                                        ?.auxillaryEngine[
                                                        index
                                                      ]?.engine_type
                                                    }
                                                    open={
                                                      props?.errors
                                                        ?.auxillaryEngine &&
                                                        props?.errors
                                                          ?.auxillaryEngine[
                                                          index
                                                        ]?.engine_type
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                )}
                                            </React.Fragment>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                      <CardFooter className="p-2">
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="justify_right "
                                          onClick={async () => {
                                            setSpinnerIndex(index)
                                            setAuxEngineSpinner(true);
                                            const error = props?.errors?.AuxillaryEngine?.[index];
                                            const res: any = await handleEngineSubmit(
                                              props?.values
                                                ?.auxillaryEngine[index],
                                              "auxillary_machine_setting",
                                              "AuxillaryEngine",
                                              error
                                            );
                                            if (res && res?.data) {
                                              // Update the specific index in auxillaryEngine array
                                              const updatedAuxillaryEngine = [...props.values.auxillaryEngine]; // Create a copy to preserve immutability
                                              updatedAuxillaryEngine[index] = res.data; // Update the specific index with the response
                                              // Use setFieldValue to update Formik state
                                              props.setFieldValue('auxillaryEngine', updatedAuxillaryEngine);
                                            }
                                            setAuxEngineSpinner(false);
                                          }}
                                          disabled={isConfigurationButtonDisabled(vessel) || (index === spinnerIndex && auxEngineSpinner)}
                                        >
                                          {auxEngineSpinner && index === spinnerIndex && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}Save
                                        </Button>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                ))) : (
                                <p>No Auxiliary Engine data available. Please add auxiliary engine details.</p>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <Row className="mt-2">
                    <FormValuesDebug
                      values={[
                        props?.values,
                        props?.errors,
                        EngineFormik.initialValues,
                        props.touched,
                      ]}
                    />
                  </Row>
                </Form>
              )
            }}
          </Formik>
        )}
      <p>- Set pre-defined engine parameters as per EEDI/EEXI Technical files.</p>
    </React.Fragment>
  );
};
export default Engines;
