import Layout from 'HorizontalMenu/Menu'
import React from 'react'
import Select from 'react-select'
import { Navbar, Container, Row, Col, Label } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { years } from './Pooling'

const PoolingList = () => {
    const poolingListTable = [
        { id: 1, pooling: 'Pooling-A', state: 'Draft', transferred_cb: 50, received_cb: 0 },
        { id: 2, pooling: 'Pooling-B', state: 'Final', transferred_cb: 0, received_cb: 50 },
    ]
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className='mb-2'>
                        <Col sm={{ size: 2, offset: 2 }}>
                            <Label className="mb-0">Select year</Label>
                            <Select
                                options={years}
                                getOptionLabel={(option: any) => option.year}
                                getOptionValue={(option: any) => option.id}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={{ size: 3, offset: 2 }} className='d-flex align-items-center'>
                            <h5 className='mb-0'>Pooling List</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 8, offset: 2 }}>
                            <div className="table-responsive p-0">
                                <table className="table mb-2">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align-middle text-center sr-no-width">#</th>
                                            <th className="p-2 align-middle text-center">Pooling name</th>
                                            <th className="p-2 align-middle text-center">State</th>
                                            <th className="p-2 align-middle text-right">Transferred CB</th>
                                            <th className="p-2 align-middle text-right">Received CB</th>
                                            <th className="p-2 align-middle text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolingListTable?.map((pool: any) => {
                                            return (
                                                <tr>
                                                    <td className="p-2 align-middle sr-no-width">{pool?.id}</td>
                                                    <td className="p-2 align-middle text-center">{pool?.pooling}</td>
                                                    <td className="p-2 align-middle text-center">{pool?.state}</td>
                                                    <td className='p-2 align-middle text-right'>
                                                        <Label className=
                                                            {`mb-0  ${pool?.transferred_cb > 0 ? 'coloured-bg-green' : 'label-red'}`}
                                                        >
                                                            {pool?.transferred_cb}
                                                        </Label>
                                                    </td>
                                                    <td className='p-2 align-middle text-right'>
                                                        <Label className=
                                                            {`mb-0  ${pool?.received_cb > 0 ? 'coloured-bg-green' : 'label-red'}`}
                                                        >
                                                            {pool?.received_cb}
                                                        </Label>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <th colSpan={3} className='p-2 align-middle text-right'>Total</th>
                                            <td className='p-2 align-middle text-right'><Label className='coloured-bg-green'>50</Label></td>
                                            <td className='p-2 align-middle text-right'><Label className='coloured-bg-green'>50</Label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PoolingList