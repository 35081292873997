import { handleServerResponse } from "GenericForms/Helper";
import apiGlobal from "global/api.global";
import { Button } from "reactstrap";
import { queryKeyes } from "shared/queryKeys";

const handleSubmit = (RequestArray: any[], ui_url: any, setErrorMessage: any) => {
    if (RequestArray) {
        const responseArray: any = [];
        RequestArray.map((RequestObj: any,) => {
            const RequestObject: any = {
                payload: RequestObj.payload,
                api_url: RequestObj.api_url,
                response_code: RequestObj.response_code,
                response_message: RequestObj.response_message,
                http_method: RequestObj.http_method,
                loggedin_user: RequestObj.loggedin_user,
                ui_url: ui_url,
                user_profile: RequestObj.user_profile
            }
            responseArray.push(apiGlobal
                .post(queryKeyes.vessel.ErrorReport.url(), RequestObject))
        })
        handleServerResponse(responseArray)
    }
    setErrorMessage(null)
}

const ErrorReport = ({ errorMessages, ui_url, setErrorMessage }: any) => {
    return (
        <div className="card border border-danger">
            <div className="card-header bg-transparent border-danger">
                <h5 className="my-0 text-danger">Form Submission Error Message</h5>
            </div>
            <div className="card-body">
                {/* Looping through the errorMessages array to render each table */}
                {errorMessages.error &&
                    errorMessages.error.map((errorMessage: any, index: number) => {
                        /** If it is array (nested array) */
                        if (Array.isArray(errorMessage)) {
                            return errorMessage.map((errorObj: any, subIndex: number) => (
                                <div key={`${index}-${subIndex}`}>
                                    <h4>{`${errorObj.api_name} - ${subIndex + 1}`}</h4>
                                    <div className="table-responsive mb-4">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Field</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(errorObj).map((key: string, objIndex) => (
                                                    key !== "api_name" && (
                                                        <tr key={objIndex}>
                                                            <td>{objIndex + 1}</td>
                                                            <td>{key}</td>
                                                            <td>
                                                                {`${errorObj[key]?.code ? errorObj[key].code + " - " : ""} ${errorObj[key]?.message}`}
                                                            </td>
                                                        </tr>
                                                    )
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ));
                        } else {
                            /** If it's a single error object, render it normally */
                            return (
                                <div key={index}>
                                    <h4>{errorMessage.api_name}</h4>
                                    <div className="table-responsive mb-4">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Field</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(errorMessage).map((key: string, objIndex) => (
                                                    key !== "api_name" && (
                                                        <tr key={objIndex}>
                                                            <td>{objIndex + 1}</td>
                                                            <td>{key}</td>
                                                            <td>
                                                                {`${errorMessage[key]?.code ? errorMessage[key].code + " - " : ""} ${errorMessage[key]?.message}`}
                                                            </td>
                                                        </tr>
                                                    )
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        }
                    })
                }
            </div>
            <div className="p-2 d-flex justify-content-end">
                {/* call handleSubmit function to send mail to admin by sending post request to error_report api */}
                <Button
                    color="primary"
                    onClick={() => handleSubmit(errorMessages.api_data, ui_url, setErrorMessage)}
                >Report</Button>
            </div>
        </div>
    );
};

export default ErrorReport;
