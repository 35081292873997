import { Field, FieldProps, useFormikContext } from 'formik';
import React, { useState } from 'react'
import { Button, Col, Input, Label, Modal, Row } from 'reactstrap';
import ErrorTooltip from './ErrorTooltip';

const FuelAdjustmentPopup = ({
    state,
    setState,
    tog_backdrop,
    target,
    fuelBatch,
    handleFuelBatchROB,
    bunker
}: any) => {
    const { values, errors }: { values: any, errors: any, handleChange: any } = useFormikContext<any>();
    const [quantity, setQuantity] = useState<number>(values?.fuelConsumptionBatch[fuelBatch]?.adjustment_quantity);
    const [reason, setReason] = useState(values?.fuelConsumptionBatch[fuelBatch]?.adjustment_reason)

    return (
        <Modal
            size='sm'
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={target}
        >
            <div className="modal-header p-2">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Fuel adjustment
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className='mb-1'>
                    <Col lg={12}>
                        <Label className='mb-0'>Adjustment quantity</Label>
                        <Field name={`fuelConsumptionBatch.${fuelBatch}.adjustment_quantity`}>
                            {({ field }: FieldProps) => (
                                <Input
                                    type="text"
                                    name={field.name}
                                    id={`quantity_${fuelBatch}`}
                                    className="form-control"
                                    onChange={(e: any) => e.target.value === "" ? setQuantity(null) : setQuantity(parseFloat(e.target.value))}
                                    onBlur={(e: any) => e.target.value === "" ? setQuantity(null) : setQuantity(parseFloat(e.target.value))}
                                    defaultValue={values?.fuelConsumptionBatch[fuelBatch]?.adjustment_quantity}
                                />
                            )}
                        </Field>
                        {errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch] && document.getElementById(`quantity_${fuelBatch}`) !== null &&
                            <ErrorTooltip
                                target={`quantity_${fuelBatch}`}
                                message={errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.adjustment_quantity}
                                open={
                                    (errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.adjustment_quantity && state && fuelBatch !== null)
                                        ? true : false
                                }
                            />
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col lg={12}>
                        <Label className='mb-0'>Reason for adjustment</Label>
                        <Field name={`fuelConsumptionBatch.${fuelBatch}.adjustment_reason`}>
                            {({ field }: FieldProps) => (
                                <Input
                                    type='textarea'
                                    name={field.name}
                                    id={`reason_${fuelBatch}`}
                                    className='form-control'
                                    onChange={(e: any) => e.target.value === "" ? setReason(null) : setReason(e.target.value)}
                                    onBlur={(e: any) => e.target.value === "" ? setReason(null) : setReason(e.target.value)}
                                    defaultValue={values?.fuelConsumptionBatch[fuelBatch]?.adjustment_reason}
                                />
                            )}
                        </Field>
                        {errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch] && document.getElementById(`reason_${fuelBatch}`) !== null &&
                            <ErrorTooltip
                                target={`reason_${fuelBatch}`}
                                message={errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.adjustment_reason}
                                open={
                                    (errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.adjustment_reason && state && fuelBatch !== null) ?
                                        true : false
                                }
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={2}>
                        <Button
                            type='button'
                            className='btn'
                            color='primary'
                            onClick={() => {
                                tog_backdrop();
                                values.fuelConsumptionBatch[fuelBatch].adjustment_quantity = quantity;
                                values.fuelConsumptionBatch[fuelBatch].adjustment_reason = reason;
                                handleFuelBatchROB(bunker, quantity, 'adjustment', values?.fuelConsumptionBatch[fuelBatch]);
                            }}
                            disabled={quantity === 0 ? false : (quantity === null || reason === null) ? true : false}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default FuelAdjustmentPopup