import React, { useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import MachineryMaster from './MachineryMaster';
import FuelConsumption from './FuelConsumer';
import EnergyConsumer from './EnergyConsumer';

interface VesselMachineryConfigType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
}

const VesselMachineryConfig = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries
}: VesselMachineryConfigType) => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(1);
    const [refreshInnerTables, setRefreshInnerTables] = useState(false);
    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    return (
        <React.Fragment>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === 1,
                            })}
                            onClick={() => {
                                toggle(1);
                            }}
                        >
                            Vessel Machinery List
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === 2,
                            })}
                            onClick={() => {
                                toggle(2);
                            }}
                        >
                            Fuel Consumer
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === 3,
                            })}
                            onClick={() => {
                                toggle(3);
                            }}
                        >
                            Electrical Energy Consumer
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId={1} className='m-0'>
                    <MachineryMaster
                        refreshVesselMachineries={refreshVesselMachineries}
                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                        setRefreshInnerTables={setRefreshInnerTables}
                    />
                </TabPane>
                <TabPane tabId={2}>
                    <FuelConsumption
                        refreshVesselMachineries={refreshVesselMachineries}
                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                        activeSettingsTab={activeSettingsTab}
                        refreshInnerTables={refreshInnerTables}
                    />
                </TabPane>
                <TabPane tabId={3}>
                    <EnergyConsumer
                        refreshVesselMachineries={refreshVesselMachineries}
                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                        activeSettingsTab={activeSettingsTab}
                        refreshInnerTables={refreshInnerTables}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment>
    )
}

export default VesselMachineryConfig
