import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

export default function ToolTip(props: any) {
    const [hover, setHover] = useState(false);
    const { message, target, isOpen, placement } = props;
    const tooltipIsOpen = isOpen !== undefined ? isOpen : hover;

    return (
        <Tooltip
            placement={placement ?? 'bottom'}
            id={target}
            isOpen={tooltipIsOpen}
            target={target}
            toggle={() => setHover(!hover)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {message}
        </Tooltip>
    );
}
